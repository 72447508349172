@charset "UTF-8";
.Navigation3000 {
  --breadcrumbs-height-full: 3.75rem;
  --breadcrumbs-height-compact: 2.75rem;
  --scene-padding-y: 1rem;
  --scene-padding-x: 1rem;
  display: flex;
  width: 100%;
  height: 100vh;
  background: var(--bg-3000);
  overflow: hidden;
}
.Navigation3000 > main {
  flex: 1;
  min-width: 0;
  overflow: auto;
}

.Navigation3000__scene {
  position: relative;
  margin: var(--scene-padding-y) var(--scene-padding-x);
  min-height: calc(100vh - var(--breadcrumbs-height-full) - var(--scene-padding-y) * 2);
}
.Navigation3000__scene.Navigation3000__scene--raw {
  --scene-padding-y: 0px;
  --scene-padding-x: 0px;
  display: flex;
  flex-direction: column;
}

.Navbar3000 {
  position: relative;
  display: flex;
  flex-direction: column;
  border-right-width: 1px;
}
.Navbar3000 .Navbar3000__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0.375rem;
  background: var(--accent-3000);
  overflow-y: auto;
  z-index: var(--z-main-nav);
}
.Navbar3000 .Navbar3000__content .LemonButton {
  min-height: 2.25rem !important;
  padding: 0.375rem !important;
}
.Navbar3000 .Navbar3000__content ul {
  padding: 0.5rem 0;
}
.Navbar3000 .Navbar3000__content ul + ul {
  border-top-width: 1px;
}
.Navbar3000 .Navbar3000__content li + li {
  margin-top: 1px;
}

.NavbarButton {
  position: relative;
}
.NavbarButton .LemonButton__icon {
  transition: color 100ms ease, transform 100ms ease;
}
.NavbarButton.NavbarButton--here::after {
  content: "•";
  position: absolute;
  bottom: 0.25rem;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1.25rem;
  line-height: 0.5625rem;
  color: var(--default);
}
.NavbarButton.NavbarButton--here .LemonButton__icon {
  color: var(--default);
  transform: translateY(-0.25rem);
}

.Sidebar3000 {
  --sidebar-slider-padding: 0.125rem;
  --sidebar-horizontal-padding: 0.5rem;
  --sidebar-row-height: 2rem;
  --sidebar-background: var(--bg-3000);
  position: relative;
  border-right: min(1px, var(--sidebar-width)) solid transparent;
  flex: 0 0 var(--sidebar-width);
  box-sizing: content-box;
}
[theme=dark] .Sidebar3000 {
  --sidebar-background: var(--accent-3000);
}
.Sidebar3000.Sidebar3000--resizing {
  will-change: flex-basis;
}
.Sidebar3000 h3,
.Sidebar3000 h4,
.Sidebar3000 h5 {
  color: var(--default);
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  line-height: inherit;
}
.Sidebar3000 h3 {
  font-weight: 600;
  line-height: 2rem;
  font-size: 0.75rem;
}
.Sidebar3000 h4 {
  font-weight: 600;
  line-height: 1.75rem;
  font-size: 0.6875rem;
  flex-grow: 1;
}
.Sidebar3000 h5 {
  font-weight: 400;
  font-size: 0.75rem;
  text-transform: none;
  letter-spacing: normal;
}
.Sidebar3000 b {
  font-weight: 700;
}
.Sidebar3000 .LemonInput--type-search {
  transition: none;
  max-width: none;
  border-color: transparent;
  border-radius: 0;
}

.Sidebar3000__content {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  width: var(--sidebar-width);
  font-size: 0.6875rem;
  box-sizing: content-box;
  white-space: nowrap;
  overflow: hidden;
  background: var(--sidebar-background);
  border-right: min(1px, var(--sidebar-width)) solid var(--border);
}
.Sidebar3000--resizing .Sidebar3000__content {
  will-change: width;
}
.Sidebar3000__content > div:not(:first-child) {
  border-top-width: 1px;
}

.Sidebar3000__lists {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  overflow: hidden auto;
}

.Sidebar3000__hint {
  z-index: 1;
  flex: 0 0 var(--sidebar-row-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--sidebar-horizontal-padding);
  background: var(--bg-3000);
  border-top-width: 1px;
  font-weight: 500;
  user-select: none;
}

.Sidebar3000__slider {
  position: absolute;
  top: 0;
  bottom: 0;
  right: calc(-1 * var(--sidebar-slider-padding) - min(1px, var(--sidebar-width)));
  width: calc(2 * var(--sidebar-slider-padding) + 1px);
  cursor: col-resize;
  user-select: none;
  z-index: var(--z-notifications-popover);
}
.Sidebar3000__slider::before, .Sidebar3000__slider::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: var(--sidebar-slider-padding);
  width: 1px;
  pointer-events: none;
}
.Sidebar3000__slider::before {
  transition: 100ms ease transform;
  background: var(--border);
}
.Sidebar3000__slider::after {
  transition: 100ms ease transform;
  background: var(--text-3000);
  opacity: 0;
}
.Sidebar3000__slider:hover::after, .Sidebar3000--resizing .Sidebar3000__slider::after {
  opacity: 0.25;
}
.Sidebar3000--resizing .Sidebar3000__slider::before, .Sidebar3000--resizing .Sidebar3000__slider::after {
  transform: scaleX(3);
}
.Sidebar3000[aria-hidden=true] .Sidebar3000__slider {
  cursor: e-resize;
}

.Sidebar3000__header {
  flex: 0 0 var(--sidebar-row-height);
  gap: 0.25rem;
  display: flex;
  align-items: center;
  padding: 0 var(--sidebar-horizontal-padding);
}

.Accordion {
  --accordion-arrow-size: 1rem;
  --accordion-row-height: 1.75rem;
  --accordion-inset-expandable: 1.25rem;
  --accordion-header-background: var(--accent-3000);
  --accordion-inset: 0rem;
  min-height: var(--accordion-row-height);
  flex-shrink: 0;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
}
[theme=dark] .Accordion {
  --accordion-header-background: var(--bg-3000);
}
.Accordion[aria-expanded] {
  --accordion-inset: var(--accordion-inset-expandable);
}
.Accordion:not([aria-expanded=false]) {
  flex-grow: 1;
}
.Accordion:not([aria-expanded=false]):not(:last-child) {
  border-bottom-width: 1px;
}

.Accordion[aria-disabled=true] .Accordion__header {
  cursor: default;
}
.Accordion[aria-disabled=true] .Accordion__header:hover {
  background: var(--accordion-header-background);
}
.Accordion[aria-disabled=true]:not([aria-busy=true]) .Accordion__header .LemonIcon {
  visibility: hidden;
}

.Accordion__header {
  z-index: 1;
  display: flex;
  align-items: center;
  background: var(--accordion-header-background);
  height: var(--accordion-row-height);
  padding: 0 var(--sidebar-horizontal-padding);
  border-bottom-width: 1px;
  cursor: pointer;
  user-select: none;
}
.Accordion__header:hover {
  background: var(--border-3000);
}
.Accordion__header > .LemonIcon {
  transition: 50ms ease transform;
  font-size: var(--accordion-arrow-size);
  flex-shrink: 0;
  margin-right: calc(var(--accordion-inset-expandable) - var(--accordion-arrow-size));
}
.Accordion[aria-expanded=true] .Accordion__header > .LemonIcon {
  transform: rotate(90deg);
}

.SidebarListItem {
  --sidebar-list-item-status-color: var(--muted);
  --sidebar-list-item-fold-size: 0.5rem;
  --sidebar-list-item-ribbon-width: 0.1875rem;
  --sidebar-list-item-background: var(--sidebar-background);
  position: relative;
  color: var(--muted);
  line-height: 1.125rem;
  opacity: 0.9;
  background: var(--sidebar-list-item-background);
  border-bottom-width: 1px;
  list-style: none;
}
.SidebarListItem:hover:not([aria-disabled=true]), .SidebarListItem[aria-current=page], .SidebarListItem.SidebarListItem--is-renaming {
  opacity: 1;
  --sidebar-list-item-background: var(--border-3000);
}
.SidebarListItem:hover .SidebarListItem__actions, .SidebarListItem:focus-within .SidebarListItem__actions, .SidebarListItem[aria-current=page] .SidebarListItem__actions, .SidebarListItem.SidebarListItem--is-renaming .SidebarListItem__actions, .SidebarListItem.SidebarListItem--is-menu-open .SidebarListItem__actions {
  display: flex;
}
.SidebarListItem:hover.SidebarListItem--has-menu:not(.SidebarListItem--extended) .SidebarListItem__link, .SidebarListItem:focus-within.SidebarListItem--has-menu:not(.SidebarListItem--extended) .SidebarListItem__link, .SidebarListItem[aria-current=page].SidebarListItem--has-menu:not(.SidebarListItem--extended) .SidebarListItem__link, .SidebarListItem.SidebarListItem--is-renaming.SidebarListItem--has-menu:not(.SidebarListItem--extended) .SidebarListItem__link, .SidebarListItem.SidebarListItem--is-menu-open.SidebarListItem--has-menu:not(.SidebarListItem--extended) .SidebarListItem__link {
  padding-right: calc(var(--sidebar-horizontal-padding) + 1.25rem);
}
.SidebarListItem:hover.SidebarListItem--has-menu.SidebarListItem--extended::after, .SidebarListItem:focus-within.SidebarListItem--has-menu.SidebarListItem--extended::after, .SidebarListItem[aria-current=page].SidebarListItem--has-menu.SidebarListItem--extended::after, .SidebarListItem.SidebarListItem--is-renaming.SidebarListItem--has-menu.SidebarListItem--extended::after, .SidebarListItem.SidebarListItem--is-menu-open.SidebarListItem--has-menu.SidebarListItem--extended::after {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  width: 3.5rem;
  background: linear-gradient(to left, var(--sidebar-list-item-background) 0%, var(--sidebar-list-item-background) 50%, transparent 100%);
}
.SidebarListItem.SidebarListItem--marker-fold::before, .SidebarListItem.SidebarListItem--marker-ribbon::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.SidebarListItem.SidebarListItem--marker-fold::before {
  width: 0;
  height: 0;
  border-left: var(--sidebar-list-item-fold-size) solid var(--sidebar-list-item-status-color);
  border-bottom: var(--sidebar-list-item-fold-size) solid transparent;
}
.SidebarListItem.SidebarListItem--marker-ribbon {
  --sidebar-list-item-marker-offset: var(--sidebar-list-item-ribbon-width);
}
.SidebarListItem.SidebarListItem--marker-ribbon::before {
  width: var(--sidebar-list-item-ribbon-width);
  height: 100%;
  background: var(--sidebar-list-item-status-color);
}
.SidebarListItem.SidebarListItem--marker-status-success {
  --sidebar-list-item-status-color: var(--success);
}
.SidebarListItem.SidebarListItem--marker-status-warning {
  --sidebar-list-item-status-color: var(--warning);
}
.SidebarListItem.SidebarListItem--marker-status-danger {
  --sidebar-list-item-status-color: var(--danger);
}
.SidebarListItem.SidebarListItem--marker-status-completion {
  --sidebar-list-item-status-color: var(--purple);
}

.SidebarListItem__link,
.SidebarListItem__rename {
  --sidebar-list-item-inset: calc(
      var(--accordion-inset, 0px) + var(--sidebar-horizontal-padding) + var(--sidebar-list-item-marker-offset, 0px)
  );
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: inherit;
}
.SidebarListItem__link:focus-visible::after,
.SidebarListItem__rename:focus-visible::after {
  content: "";
  position: absolute;
  inset: 0 0 -1px;
  border: 1px solid var(--default);
  pointer-events: none;
}

.SidebarListItem__link {
  row-gap: 1px;
  padding: 0 var(--sidebar-horizontal-padding) 0 var(--sidebar-list-item-inset);
  color: inherit !important;
}
.SidebarListItem[aria-disabled=true] .SidebarListItem__link {
  cursor: default;
}

.SidebarListItem__rename {
  background: var(--bg-light);
}
.SidebarListItem__rename input {
  outline: none;
  height: 100%;
  width: 100%;
  font-size: 0.75rem;
  color: var(--default);
  padding: 0 calc(var(--sidebar-horizontal-padding) + 2.5rem) 0 var(--sidebar-list-item-inset);
  background: none;
}
.SidebarListItem__rename::after {
  content: "";
  position: absolute;
  inset: 0 0 -1px;
  border: 1px solid var(--default);
  pointer-events: none;
}
.SidebarListItem[aria-invalid=true] .SidebarListItem__rename::after {
  border-color: var(--danger);
}

.SidebarListItem__error {
  position: absolute;
  top: 2rem;
  left: 0;
  line-height: 1.25rem;
  padding: 0 0.25rem;
  z-index: 1;
  background: var(--danger);
  color: #fff;
  white-space: normal;
}
.SidebarListItem__error::before {
  display: block;
  content: "";
  position: absolute;
  top: -0.5rem;
  left: 0;
  border: 0.25rem solid transparent;
  border-left-color: var(--danger);
  border-bottom-color: var(--danger);
}

.SidebarListItem__actions {
  z-index: 1;
  position: absolute;
  right: var(--sidebar-horizontal-padding);
  top: 50%;
  transform: translateY(-50%);
  display: none;
}