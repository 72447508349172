.LemonProgressCircle {
  display: inline-block;
  position: relative;
  vertical-align: text-bottom;
}
.LemonProgressCircle circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.LemonProgressCircle .LemonProgressCircle__content {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.LemonButton__icon .LemonProgressCircle {
  display: inline-flex;
}