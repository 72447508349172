.property-filters-property-value {
  min-width: 150px;
  min-height: 40px;
  border: 1px solid var(--border);
  border-radius: var(--radius);
  background-color: var(--bg-light);
}
.property-filters-property-value .ant-select-selection-search,
.property-filters-property-value .ant-select-selection-placeholder {
  display: flex;
  align-items: center;
  padding: 0 4px !important;
}
.property-filters-property-value.ant-select-single .ant-select-selector {
  min-height: 38px !important;
  height: unset;
  border: none !important;
  background-color: inherit;
}
.property-filters-property-value.ant-select-multiple .ant-select-selector {
  height: 100% !important;
  border: none !important;
  padding: 5px 40px 5px 11px;
  background-color: inherit;
}
.property-filters-property-value.ant-select-multiple .ant-select-selector .ant-select-selection-search {
  padding-left: 0 !important;
}
.property-filters-property-value.ant-select-multiple .ant-select-selector .ant-select-selection-placeholder {
  padding-left: 6px !important;
}