.FunnelBarGraph .funnel-step header .funnel-step-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 8px);
}

.FunnelBarGraph {
  min-height: 100%;
  padding: 1rem;
}
.InsightCard .FunnelBarGraph {
  padding-left: 1rem;
  padding-right: 1rem;
}
.FunnelBarGraph .funnel-step {
  position: relative;
  padding-left: 2rem;
}
.FunnelBarGraph .funnel-step:not(:first-child),
.FunnelBarGraph .funnel-step:not(:first-child) .funnel-series-container {
  padding-top: 0.75rem;
}
.FunnelBarGraph .funnel-step .graph-series-glyph {
  z-index: 2;
}
.FunnelBarGraph .funnel-step .funnel-conversion-metadata {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.FunnelBarGraph .funnel-step .funnel-conversion-metadata .funnel-inspect-button {
  line-height: 1.5rem;
}
.FunnelBarGraph .funnel-step .funnel-conversion-metadata .funnel-inspect-button .value-inspector-button-icon {
  font-size: 1.5rem;
  margin-right: 0.25rem;
  vertical-align: bottom;
}
.FunnelBarGraph .funnel-step .funnel-series-container {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.FunnelBarGraph .funnel-step .funnel-series-container .graph-series-glyph {
  user-select: none;
}
.FunnelBarGraph .funnel-step .funnel-series-container .funnel-series-linebox {
  position: absolute;
  left: 0;
  width: calc(1.5rem / 2 + 1px);
  box-sizing: border-box;
  border-right: 2px solid var(--mid);
}
.FunnelBarGraph .funnel-step .funnel-series-container .funnel-series-linebox.before {
  top: 0;
  height: calc(23px / 2);
}
.FunnelBarGraph .funnel-step .funnel-series-container .funnel-series-linebox.after {
  bottom: 0;
  top: calc(23px / 2);
  height: calc(100% - 23px / 2);
}
.FunnelBarGraph .funnel-step .funnel-step-metadata {
  display: flex;
  flex-wrap: wrap;
}
.FunnelBarGraph .funnel-step header,
.FunnelBarGraph .funnel-step footer {
  line-height: 23px;
  display: flex;
  align-items: center;
}
.FunnelBarGraph .funnel-step header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}
.FunnelBarGraph .funnel-step header .funnel-step-title {
  font-weight: bold;
}
.FunnelBarGraph .funnel-step header button {
  margin-left: 0.375rem;
}
.FunnelBarGraph .funnel-bar-wrapper {
  height: 32px;
  margin: 4px 0;
  background-color: var(--funnel-background);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
}
.FunnelBarGraph .funnel-bar-wrapper .funnel-bar {
  position: relative;
  height: 100%;
  background: var(--funnel-default);
  transition: width 0.2s ease, height 0.2s ease;
}
.FunnelBarGraph .funnel-bar-wrapper .funnel-bar.first {
  border-radius: 4px 0 0 4px;
}
.FunnelBarGraph .funnel-bar-wrapper .funnel-bar.last {
  border-radius: 0 4px 4px 0;
}
.FunnelBarGraph .funnel-bar-wrapper .funnel-bar.only {
  border-radius: 4px;
}
.FunnelBarGraph .funnel-bar-wrapper .funnel-bar .funnel-bar-percentage {
  position: absolute;
  top: 8px;
  line-height: 16px;
  font-weight: bold;
  color: #fff;
}
.FunnelBarGraph .funnel-bar-wrapper .funnel-bar .funnel-bar-percentage.inside {
  right: 8px;
}
.FunnelBarGraph .funnel-bar-wrapper .funnel-bar .funnel-bar-percentage.outside {
  left: calc(100% + 8px);
  color: var(--funnel-default);
}