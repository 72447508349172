.EditorFiltersWrapper {
  --editor-panel-group-min-width: 20rem;
  flex-shrink: 0;
  background: var(--bg-light);
}
.EditorFiltersWrapper:not(.EditorFiltersWrapper--embedded) {
  border: 1px solid var(--border);
  border-radius: var(--radius);
  padding: 1rem;
}
.EditorFiltersWrapper .EditorFilters {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
}
.EditorFiltersWrapper .EditorFilters .EditorFilterGroup {
  flex: 1;
  min-width: var(--editor-panel-group-min-width);
  max-width: 100%;
}