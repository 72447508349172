.PlayerMeta {
  position: relative;
  border-bottom: 1px solid var(--border);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;
}
.PlayerMeta .PlayerMetaPersonProperties {
  background: var(--side);
  overflow: auto;
  border-bottom: 1px solid transparent;
  padding: 0.5rem;
}
.PlayerMeta:not(.PlayerMeta--fullscreen) .PlayerMetaPersonProperties {
  transition: 200ms height ease-out, 200ms border-bottom-color ease-out;
}
.PlayerMeta:not(.PlayerMeta--fullscreen) .PlayerMetaPersonProperties--enter {
  height: 0;
}
.PlayerMeta:not(.PlayerMeta--fullscreen) .PlayerMetaPersonProperties--enter-active, .PlayerMeta:not(.PlayerMeta--fullscreen) .PlayerMetaPersonProperties--enter-done {
  height: 14rem;
  border-bottom-color: var(--border);
}
.PlayerMeta:not(.PlayerMeta--fullscreen) .PlayerMetaPersonProperties--exit {
  height: 14rem;
  border-bottom-color: var(--border);
}
.PlayerMeta:not(.PlayerMeta--fullscreen) .PlayerMetaPersonProperties--exit-active {
  height: 0;
}
.LemonModal .PlayerMeta:not(.PlayerMeta--fullscreen) .PlayerMeta__top {
  padding-right: 3.5rem;
}
.PlayerMeta--fullscreen {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
}
.PlayerMeta--fullscreen .PlayerMetaPersonProperties {
  position: fixed;
  top: 48px;
  left: 0;
  bottom: 97px;
  z-index: 1;
  max-width: 40rem;
  width: 100%;
  border-right: 1px solid var(--border);
  transition: 200ms transform ease-out;
}
.PlayerMeta--fullscreen .PlayerMetaPersonProperties--enter {
  transform: translateX(-100%);
}
.PlayerMeta--fullscreen .PlayerMetaPersonProperties--enter-active, .PlayerMeta--fullscreen .PlayerMetaPersonProperties--enter-done {
  transform: translateX(0);
}
.PlayerMeta--fullscreen .PlayerMetaPersonProperties--exit {
  transform: translateX(0);
}
.PlayerMeta--fullscreen .PlayerMetaPersonProperties--exit-active {
  transform: translateX(-100%);
}