.LemonInput {
  transition: background-color 200ms ease, color 200ms ease, border-color 200ms ease, opacity 200ms ease;
  display: flex;
  min-height: 2.5rem;
  padding: 0.25rem 0.5rem;
  background: none;
  border-radius: var(--radius);
  font-size: 0.875rem;
  text-align: left;
  line-height: 1.25rem;
  cursor: text;
  color: var(--default);
  border: 1px solid var(--border);
  background-color: var(--bg-light);
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}
.LemonInput:hover:not([aria-disabled=true]) {
  border-color: var(--primary-light);
}
.LemonInput.LemonInput--focused:not([aria-disabled=true]) {
  border-color: var(--primary);
}
.LemonInput.LemonInput--transparent-background {
  background-color: inherit;
}
.Field--error .LemonInput, .LemonInput.LemonInput--status-danger {
  border-color: var(--danger) !important;
}
.LemonInput .LemonInput__input {
  border: none;
  outline: none;
  background: none;
  width: 100%;
  align-self: stretch;
  text-overflow: ellipsis;
}
.LemonInput .LemonInput__input:disabled {
  opacity: var(--opacity-disabled);
  cursor: not-allowed;
}
.LemonInput .LemonIcon {
  width: 1em;
  height: 1em;
  transition: color 200ms ease;
  font-size: 1.5rem;
  color: var(--muted-alt);
  flex-shrink: 0;
}
.LemonInput.LemonInput--small {
  min-height: 2rem;
  padding: 0.125rem 0.25rem;
}
.LemonInput.LemonInput--small .LemonIcon {
  font-size: 1.25rem;
}
.LemonInput.LemonInput--has-content > .LemonIcon {
  color: var(--primary);
}
.LemonInput.LemonInput--type-search {
  max-width: 240px;
}
.LemonInput.LemonInput--full-width {
  width: 100%;
  max-width: 100%;
}