.FeatureFlagInstructionsHeader {
  flex-wrap: nowrap;
}
.FeatureFlagInstructionsHeader .FeatureFlagInstructionsHeader__header-title {
  display: flex;
  justify-items: center;
}
.FeatureFlagInstructionsHeader .FeatureFlagInstructionsHeader__header-title .FeatureFlagInstructionsHeader__header-title__icon {
  color: var(--text-muted-alt);
  margin-right: 12px;
  font-size: 1.6em;
}

.FeatureFlagInstructionsHeader__option {
  display: flex;
  justify-items: center;
}
.FeatureFlagInstructionsHeader__option .FeatureFlagInstructionsHeader__option__icon {
  margin-top: 2px;
  margin-right: 8px;
}