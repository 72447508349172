.HedgehogBuddy {
  z-index: var(--z-hedgehog-buddy);
  position: fixed;
  cursor: pointer;
  margin: 0;
}
.HedgehogBuddy::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: attr(data-content);
}