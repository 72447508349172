.PromptPopup {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 2000;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  padding-top: 5px;
  min-width: 300px;
  min-height: 100px;
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
  border: 1px solid #f0f0f0;
}