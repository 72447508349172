.FrameworkPanel {
  max-width: 400px;
}

.panel-footer {
  background-color: white;
  margin-bottom: 1rem;
  bottom: 0;
}

.ingestion-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
}

.IngestionSubtitle {
  font-size: 20px;
  font-weight: 800;
  margin: 1rem 0;
}

.prompt-text {
  margin-top: 1rem;
}

.ingestion-listening-for-events {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
}