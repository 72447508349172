.SidePanel3000 {
  position: relative;
  flex-shrink: 0;
  box-sizing: content-box;
  z-index: var(--z-main-nav);
  width: 3rem;
  display: flex;
  background: var(--accent-3000);
}
.SidePanel3000--open {
  position: relative;
  max-width: 60%;
  min-width: 30rem;
  width: min(30%, 40rem);
}
@media (max-width: 1200px) {
  .SidePanel3000--open {
    position: fixed;
    top: 0;
    right: 0;
    max-width: calc(100vw - 3rem);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  }
  [theme=dark] .SidePanel3000--open {
    box-shadow: none;
  }
}
.SidePanel3000--resizing {
  pointer-events: none;
}
.SidePanel3000 .SidePanel3000__bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 3rem;
  border-left-width: 1px;
  overflow-y: auto;
  user-select: none;
}
.SidePanel3000 .SidePanel3000__bar .LemonButton {
  min-height: 2.25rem !important;
}
.SidePanel3000 .SidePanel3000__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  border-left-width: 1px;
}