.BoldNumber {
  width: 100%;
  padding: 2rem 0 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}
@media screen and (min-width: 768px) {
  .BoldNumber {
    padding: 3rem 0 5rem;
  }
  .InsightCard .BoldNumber {
    padding: 2rem 0;
  }
}

.BoldNumber__value {
  width: 100%;
  text-align: center;
  padding: 0 2rem;
  margin: 0 auto;
  line-height: 1;
  font-weight: 700;
  letter-spacing: -0.025em;
}
.InsightCard .BoldNumber__value {
  padding: 0 1rem;
}
@media screen and (min-width: 768px) {
  .BoldNumber__value {
    padding: 0 5rem;
  }
  .InsightCard .BoldNumber__value {
    padding: 0 2rem;
  }
}

.BoldNumber__comparison {
  margin-top: 0.5rem;
  margin-bottom: -1.5rem;
  color: var(--muted-alt);
  font-weight: 500;
  cursor: default;
}