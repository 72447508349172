.SurveyTemplateContainer {
  flex: 1;
  display: flex;
  align-items: center;
  border: 1px solid var(--border);
  border-radius: 6px;
  min-height: 300px;
  margin-top: 2px;
}
.SurveyTemplateContainer:hover {
  cursor: pointer;
  border-color: var(--primary-light);
}
.SurveyTemplateContainer .SurveyTemplate {
  transform: scale(0.8, 0.8);
}