.ActionFilter > * + *,
.ActionFilter ul > li + li {
  margin-top: 0.5rem;
}
.ActionFilter .ActionFilterRow-filters {
  padding: 0.5rem 0;
}
.ActionFilter.ActionFilter--bordered {
  border-radius: var(--radius);
  border: 1px solid var(--border);
}
.ActionFilter.ActionFilter--bordered .ActionFilterRow {
  margin-top: 0;
}
.ActionFilter.ActionFilter--bordered .ActionFilterRow .ActionFilterRow-content {
  margin-bottom: 0;
  padding: 1rem;
  border-bottom: 1px solid var(--border);
}
.ActionFilter.ActionFilter--bordered .ActionFilter-footer {
  margin-top: 0;
  padding: 0.5rem;
}
.ActionFilter.ActionFilter--bordered .ActionFilterRow-filters {
  background-color: var(--side);
  border-bottom: 1px solid var(--border);
  padding: 0.5rem;
}