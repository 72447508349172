.InsightTooltipWrapper {
  position: absolute;
  transition: all 0.4s;
  z-index: var(--z-graph-tooltip);
  pointer-events: none;
}

.InsightTooltip {
  overflow: hidden;
  border: 1px solid var(--border);
  box-shadow: var(--shadow-elevation);
  font-size: 0.8125rem;
  max-width: 30rem;
  border-radius: var(--radius);
  background: var(--bg-light);
  pointer-events: none;
}
.InsightTooltip .LemonRow {
  font-size: 0.8125rem;
}
.InsightTooltip .LemonTable {
  font-size: 0.75rem;
  border: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.InsightTooltip .LemonTable:not(:last-child) {
  border-bottom: 1px solid var(--border);
}
.InsightTooltip .LemonTable .insights-label {
  margin: 0;
}
.InsightTooltip .LemonTable .datum-column {
  padding-left: 0.5rem;
  overflow-wrap: anywhere;
}
.InsightTooltip .LemonTable .scrollable__inner {
  width: 100%;
  overflow: hidden;
}
.InsightTooltip .LemonTable .LemonTable__content > table > thead {
  letter-spacing: 0;
}
.InsightTooltip .LemonTable .LemonTable__content > table > thead .datum-column .LemonTable__header-content {
  white-space: nowrap;
}
.InsightTooltip .LemonTable .LemonTable__content > table .datum-label-column {
  font-weight: 600;
  display: flex;
  align-items: center;
}
.InsightTooltip .LemonTable .LemonTable__content > table .series-data-cell {
  font-weight: 600;
}
.InsightTooltip .LemonTable .LemonTable__content > table .tag-pill {
  background-color: var(--border-3000);
  margin-right: 0;
  border: 0;
  color: var(--primary-alt);
  overflow: hidden;
  vertical-align: middle;
}
.InsightTooltip .table-subtext {
  font-size: 0.75rem;
  text-align: center;
  padding: 0.5rem 1rem;
  color: var(--muted);
  background-color: var(--bg-light);
}
.InsightTooltip .table-subtext .table-subtext-truncated {
  margin-bottom: 4px;
}
.InsightTooltip .table-subtext .table-subtext-click-to-inspect {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--default);
  font-weight: 600;
  white-space: nowrap;
}