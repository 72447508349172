.definition-popover {
  width: 20rem;
  padding: 0.5rem 0.75rem 0.75rem;
}
.definition-popover .definition-popover-header {
  display: flex;
  flex-direction: column;
}
.definition-popover .definition-popover-header .definition-popover-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.25rem;
  line-height: 2rem;
}
.definition-popover .definition-popover-header .definition-popover-header-row .definition-popover-header-row-title {
  font-size: 0.6875rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--muted);
}
.definition-popover .definition-popover-header .definition-popover-header-row .definition-popover-header-row-buttons {
  display: flex;
  flex-direction: row;
  font-size: 0.8125rem;
}
.definition-popover .definition-popover-header .definition-popover-header-row .definition-popover-header-row-buttons a {
  user-select: none;
  margin-left: 1rem;
}
.definition-popover .definition-popover-header .definition-popover-header-row .definition-popover-header-row-buttons .definition-popover-disabled-button {
  color: var(--muted);
  cursor: not-allowed;
}
.definition-popover .definition-popover-header .definition-popover-title {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.25rem;
  min-height: 1.5rem;
  margin-bottom: 0.25rem;
  white-space: normal;
  line-height: 1.2;
}
.definition-popover .definition-popover-header .definition-popover-title svg.taxonomy-icon {
  font-size: 1.5rem;
  margin-right: 0.75rem;
  flex-shrink: 0;
}
.definition-popover .definition-popover-header .definition-popover-title svg.taxonomy-icon.taxonomy-icon-muted {
  color: var(--muted-alt);
}
.definition-popover .definition-popover-header .definition-popover-title svg.taxonomy-icon.taxonomy-icon-verified {
  color: var(--success);
}
.definition-popover .definition-popover-header .definition-popover-title svg.taxonomy-icon.taxonomy-icon-built-in {
  color: var(--primary);
}
.definition-popover .definition-popover-tags {
  margin: 0 !important;
}
.definition-popover .definition-popover-description {
  font-weight: 400;
  margin: 0.5rem 0;
  overflow-wrap: break-word;
}
.definition-popover .definition-popover-description.empty {
  font-style: italic;
  color: var(--muted);
}
.definition-popover .definition-popover-examples {
  margin-bottom: 0.25rem;
  font-style: italic;
}
.definition-popover .definition-popover-timemeta {
  margin: 0.5rem 0;
}
.definition-popover .definition-popover-timemeta .definition-popover-timemeta-user {
  display: inline-flex;
}
.definition-popover .definition-popover-timemeta .definition-popover-timemeta-spacer {
  margin-right: 0.5rem;
}
.definition-popover .definition-popover-divider {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: flex-start;
  text-transform: uppercase;
  font-size: 0.6875rem;
}
.definition-popover .definition-popover-grid {
  display: grid;
  grid-gap: 0.5rem;
}
.definition-popover .definition-popover-grid .definition-popover-grid-card {
  display: flex;
  flex-direction: column;
}
.definition-popover .definition-popover-grid .definition-popover-grid-card .definition-popover-grid-card-title {
  font-size: 0.6875rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--muted);
}
.definition-popover .definition-popover-grid .definition-popover-grid-card .definition-popover-grid-card-content {
  margin-top: 0.25rem;
}
.definition-popover .definition-popover-edit-form {
  display: flex;
  flex-direction: column;
}
.definition-popover .definition-popover-edit-form .label-text {
  font-weight: 600;
  margin-right: 0.25rem;
}
.definition-popover .definition-popover-edit-form .definition-popover-edit-form-label {
  margin-bottom: 0.5rem;
}
.definition-popover .definition-popover-edit-form .definition-popover-edit-form-value {
  margin-bottom: 1rem;
}
.definition-popover .definition-popover-edit-form .definition-popover-edit-form-value.definition-popover-owner-select .ant-select-selector .ant-select-selection-placeholder {
  color: black;
  font-weight: normal;
}