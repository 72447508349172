.variant-form-list {
  font-size: 13px;
  border: 1px solid var(--border);
  border-radius: var(--radius);
  padding: 16px;
  margin-top: 16px;
}
.variant-form-list .ant-form-item {
  min-height: 32px;
}
.variant-form-list .label-row {
  font-weight: bold;
}
.variant-form-list .label-row .ant-col {
  min-height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feature-flag-property-display {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}
.feature-flag-property-display .arrow-right {
  margin-right: -8px;
  margin-top: 0.25rem;
}
.feature-flag-property-display .display-value {
  word-break: break-all;
}

.condition-set-separator {
  color: var(--primary-alt);
  font-size: 12px;
  font-weight: var(--font-semibold);
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}

.feature-flag-form-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.feature-flag-form-row .centered {
  display: flex;
  align-items: center;
  white-space: pre-wrap;
}

.FeatureConditionCard .FeatureConditionCard--border--highlight {
  border-color: var(--primary);
}