.EmptyDashboard .EmptyDashboard__fade::after {
  content: "";
  width: 100%;
  height: 3rem;
  background: linear-gradient(to top, var(--bg-light), transparent);
  position: absolute;
  pointer-events: none;
  bottom: 0;
}

.EmptyDashboard {
  position: relative;
  margin-top: 1rem;
}
.EmptyDashboard .EmptyDashboard__fade {
  margin-top: 1rem;
  height: 150px;
  overflow: hidden;
  position: relative;
}
.EmptyDashboard .EmptyDashboard__fade::after {
  width: 100%;
  height: 150px;
}
.posthog-3000 .EmptyDashboard .EmptyDashboard__fade::after {
  --bg-light: var(--bg-3000);
}
.EmptyDashboard .EmptyDashboard__cta {
  border-width: 1px;
  border-radius: var(--radius);
  padding: 1.5rem;
  box-shadow: var(--shadow-elevation);
  background: var(--bg-light);
  width: 360px;
  max-width: calc(100% - 32px);
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: var(--z-content-overlay);
}