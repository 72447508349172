.universal-search-box {
  max-width: 15rem;
  height: 100%;
  cursor: pointer;
  transition: 200ms ease margin;
}
.universal-search-box .ant-input-affix-wrapper,
.universal-search-box input {
  background: var(--bg-bridge);
}
@media screen and (min-width: 576px) {
  .universal-search-box {
    display: flex;
  }
}

.universal-search-popover {
  background: var(--bg-light);
  display: flex;
  flex-direction: column;
}
.universal-search-popover.force-minimum-width {
  min-width: 300px;
}
.universal-search-popover.one-taxonomic-tab .taxonomic-infinite-list {
  margin-top: 10px;
}