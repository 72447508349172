.dashboard-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
}
.dashboard-header.full-screen {
  margin-top: 1rem;
}
.dashboard-header .dashboard-meta {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.dashboard-header .dashboard-meta .ant-btn {
  margin-left: 10px;
}
.dashboard-header .dashboard-meta .ant-btn .anticon {
  vertical-align: baseline;
}
.dashboard-header .dashboard-meta .ant-btn.button-box {
  padding: 4px 8px;
}
@media (max-width: 768px) {
  .dashboard-header {
    flex-direction: column;
  }
  .dashboard-header .dashboard-meta {
    padding-top: 1rem;
    justify-content: flex-end;
  }
}