.Spinner {
  display: inline-block;
  vertical-align: -0.15em;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  --spinner-color: var(--brand-blue);
}
.Spinner.Spinner--textColored {
  --spinner-color: currentColor;
}

.Spinner__layer {
  transform-origin: center;
}
.Spinner__layer > circle {
  display: block;
  fill: transparent;
  stroke-width: 8px;
  transform-origin: center;
  stroke: var(--spinner-color);
  stroke-linecap: round;
}
.Spinner__layer:nth-child(1) {
  opacity: 0.333;
}
.Spinner__layer:nth-child(2) {
  animation: spin 1s infinite linear;
}
.Spinner__layer:nth-child(2) > circle {
  animation: Spinner__writhe 1.5s infinite ease both;
}

@keyframes Spinner__writhe {
  0%, 100% {
    stroke-dashoffset: -60;
    stroke-dasharray: 70;
  }
  50% {
    stroke-dashoffset: -30;
    stroke-dasharray: 70;
  }
}
.SpinnerOverlay {
  transition: opacity 0.2s ease;
  position: absolute;
  inset: 0;
  text-align: center;
  min-height: 6rem;
  z-index: var(--z-content-overlay);
  display: flex;
  align-items: center;
  justify-content: center;
}
.SpinnerOverlay[aria-hidden=true] {
  opacity: 0;
  pointer-events: none;
}
.SpinnerOverlay::before {
  content: "";
  position: absolute;
  inset: 0;
  background: var(--bg-light);
  opacity: 0.5;
}
.SpinnerOverlay .Spinner {
  position: relative;
}
.posthog-3000 .SpinnerOverlay.SpinnerOverlay--scene-level::before {
  background: var(--bg-3000);
}