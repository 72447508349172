.FunnelBarChart {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex: 1;
}
.FunnelBarChart table {
  --bar-width: 0.5rem;
  --bar-row-height: 18rem;
  width: 100%;
  height: 100%;
}
.FunnelBarChart table > tbody > tr:first-child {
  border-bottom: 1px solid var(--border);
}
.FunnelBarChart table > tbody > tr:first-child > td {
  padding: 1.5rem 0;
  padding-top: 1rem;
}
.FunnelBarChart table > tbody > tr > td {
  height: 1px;
  padding: 0.75rem 0;
}

.FunnelBarChart__content {
  min-width: fit-content;
}

.StepBarLabels {
  height: calc(var(--bar-row-height) - 3rem);
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}

.StepBarLabels__segment {
  flex-grow: 1;
  padding: 0 0.5rem;
}
.StepBarLabels__segment:first-child {
  flex-grow: 0;
  height: 0;
}

.StepBarLabels__label {
  transform: translateY(-50%);
  font-size: 0.75rem;
  font-weight: 500;
}

.StepBars {
  position: relative;
  display: flex;
  align-items: flex-end;
  gap: 0.125rem;
  border-bottom: 1px solid var(--border);
  height: calc(var(--bar-row-height) - 3rem);
  padding: 0 1rem;
}
.StepBars:not(.StepBars--first) {
  border-left: 1px dashed var(--border);
}

.StepBars__grid {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 100%;
}

.StepBars__gridline {
  flex-grow: 1;
}
.StepBars__gridline.StepBars__gridline--horizontal {
  border-top: 1px dashed var(--border);
}

.StepBar {
  --series-color: #000;
  --conversion-rate: 100%;
  position: relative;
  border-radius: var(--radius);
  width: calc(var(--bar-width) / 2);
  flex-shrink: 0;
  height: 100%;
}
@media screen and (min-width: 992px) {
  .StepBar {
    width: var(--bar-width);
  }
}
.InsightCard .StepBar {
  width: calc(var(--bar-width) / 2) !important;
}

.StepBar__backdrop,
.StepBar__fill {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: var(--radius);
  cursor: pointer;
}
.InsightCard .StepBar__backdrop,
.InsightCard .StepBar__fill {
  cursor: default;
}

.StepBar__unclickable .StepBar__backdrop,
.StepBar__unclickable .StepBar__fill {
  cursor: default;
}

.StepBar__backdrop {
  transition: opacity 200ms ease;
  height: 100%;
  background: repeating-linear-gradient(-22.5deg, transparent, transparent 0.5rem, rgba(255, 255, 255, 0.5) 0.5rem, rgba(255, 255, 255, 0.5) 1rem), var(--series-color);
  opacity: 0.125;
}
.StepBar__backdrop:hover {
  opacity: 0.2;
}
.StepBar__backdrop:active {
  opacity: 0.25;
}

.StepBar__fill {
  transition: filter 200ms ease;
  background: var(--series-color);
  height: var(--conversion-rate);
}
.StepBar__fill:hover {
  filter: brightness(0.9);
}
.StepBar__fill:active {
  filter: brightness(0.85);
}

.StepLegend {
  border-left: 1px solid var(--border);
  white-space: nowrap;
  height: 100%;
}
.StepLegend > .LemonRow {
  min-height: 1.5rem;
  padding: 0 0.5rem;
  font-weight: 500;
  margin-top: 0.25rem;
}
.StepLegend > .LemonRow:first-child {
  width: fit-content;
  font-weight: 600;
  margin-top: 0;
}
.StepLegend .funnel-inspect-button {
  line-height: 1.5rem;
  font-weight: inherit;
}

.FunnelTooltip {
  width: 20rem;
}
.FunnelTooltip table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.FunnelTooltip tr {
  height: 1.75rem;
}
.FunnelTooltip td:first-child {
  padding: 0 0.5rem;
  font-weight: 500;
}
.FunnelTooltip td:last-child {
  padding-right: 0.5rem;
  font-weight: 600;
  text-align: right;
}
.FunnelTooltip .table-subtext {
  padding-bottom: 0.25rem;
}