.CodeSnippet {
  position: relative;
  font-size: 0.875rem;
}
.CodeSnippet.CodeSnippet--compact {
  font-size: 0.8125rem;
}
.CodeSnippet.CodeSnippet--compact .CodeSnippet__actions {
  top: 0.375rem;
  right: 0.375rem;
}
.CodeSnippet .CodeSnippet__actions {
  position: absolute;
  display: flex;
  top: 0.25rem;
  right: 0.25rem;
  gap: 0.5rem;
}
.CodeSnippet .CodeSnippet__actions .LemonButton .LemonIcon {
  color: #fff;
}

.posthog-3000 .CodeSnippet pre {
  background: var(--accent-3000-dark) !important;
  border: solid 1px var(--border-3000-dark) !important;
  border-radius: var(--radius) !important;
}