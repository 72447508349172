.WorldMap {
  padding: 1rem 0;
  width: 100%;
  color: var(--border);
}
.WorldMap .landxx {
  fill: currentColor;
  stroke: var(--bg-light);
  stroke-width: 0.125rem;
  fill-rule: evenodd;
}
.WorldMap .landxx:hover {
  color: var(--primary-dark) !important;
}