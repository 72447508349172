.InviteModal__share_link {
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  white-space: nowrap;
  width: 175px;
  overflow: scroll;
}

.InviteModal__share_link::-webkit-scrollbar {
  display: none;
}