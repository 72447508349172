.JsonEditorInput {
  position: relative;
}
.JsonEditorInput .border {
  border-radius: var(--radius);
}
.JsonEditorInput .border .monaco-editor {
  border-radius: inherit;
}
.JsonEditorInput .border .monaco-editor .overflow-guard {
  border-radius: inherit;
}
.JsonEditorInput .placeholder {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
.JsonEditorInput .placeholder .placeholderLabelContainer {
  display: flex;
  align-items: center;
  pointer-events: none;
  height: 100%;
  padding-left: 18px;
  color: gray;
}