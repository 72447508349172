.NotebookNode {
  --notebook-node-meta-height: 2.5rem;
  --notebook-node-meta-padding: 0.25rem;
  --border-color: var(--border);
  transform: translate3d(0, 0, 0);
}
.NotebookNode .NotebookNode__box {
  transform: translate3d(0, 0, 0);
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  background-color: var(--bg-light);
  transition: border 150ms linear, margin-bottom 150ms linear;
  overflow: hidden;
}
.NotebookNode .NotebookNode__box .NotebookNode__meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--primary-alt);
  padding: var(--notebook-node-meta-padding);
  border-bottom: 1px solid var(--border);
  height: var(--notebook-node-meta-height);
  overflow: hidden;
}
.NotebookNode .NotebookNode__box .NotebookNode__settings {
  border-bottom: 1px solid var(--border);
}
.NotebookNode .NotebookNode__box .NotebookNode__content {
  transition: box-shadow 150ms ease-out;
  z-index: 1;
}
.NotebookNode .NotebookNode__gap {
  display: flex;
  gap: 0.2rem;
  overflow: hidden;
  transition: all 150ms linear 1000ms;
  opacity: 0;
  height: 1.25rem;
  align-items: center;
}
.NotebookNode--editable:hover .NotebookNode__gap, .NotebookNode--selected .NotebookNode__gap {
  opacity: 1;
  height: 2rem;
  transition: all 150ms linear;
}
.NotebookNode--selected {
  --border-color: var(--primary-3000);
}
.NotebookNode--auto-hide-metadata {
  --border-color: transparent;
}
.NotebookNode--auto-hide-metadata .NotebookNode__meta {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  visibility: hidden;
  z-index: var(--z-popover);
  background-color: var(--bg-light);
}
.Notebook--editable .NotebookNode--auto-hide-metadata:hover, .Notebook--editable .NotebookNode--auto-hide-metadata.NotebookNode--selected {
  border-color: var(--border-color);
}
.Notebook--editable .NotebookNode--auto-hide-metadata:hover .NotebookNode__meta, .Notebook--editable .NotebookNode--auto-hide-metadata.NotebookNode--selected .NotebookNode__meta {
  pointer-events: all;
  visibility: visible;
}
.NotebookNode--auto-hide-metadata:hover {
  --border-color: var(--border);
}

.NotebookNode.ph-image img {
  flex: 1;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.NotebookNodeTitle {
  padding: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.NotebookNodeTitle--editable {
  border-radius: var(--radius);
  transition: background-color 150ms linear;
}
.NotebookNodeTitle--editable:hover {
  background-color: var(--border);
}