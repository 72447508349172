.dashboard-items-wrapper {
  margin-top: 1rem;
  width: 100%;
}

.react-grid-layout {
  position: relative;
  transition: height 100ms ease;
  margin-bottom: 2rem;
  /* remove initial loading animation, animations are only needed in edit mode */
}
.react-grid-layout.dashboard-view-mode .react-grid-item {
  transition: border-color 100ms ease;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable {
  cursor: move;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 105;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  transition: 100ms ease;
  max-width: 100%;
  position: relative;
  border: 1px solid var(--primary);
  outline: 1px solid var(--primary);
  border-radius: var(--radius);
  z-index: 2;
  user-select: none;
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item {
  transition: all 100ms ease;
  transition-property: left, top;
}
.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 2rem;
  height: 2rem;
  bottom: -0.5rem;
  right: -0.5rem;
  cursor: se-resize;
  z-index: 10;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  cursor: se-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 0;
  bottom: 2rem;
  height: auto;
  cursor: ew-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 0;
  right: 2rem;
  width: auto;
  cursor: ns-resize;
}

.ant-dropdown {
  z-index: 700;
}