.series-name-wrapper-col {
  display: flex;
  align-items: center;
}
.series-name-wrapper-col .edit-icon {
  color: var(--primary);
  cursor: pointer;
  font-size: 1rem;
}
.series-name-wrapper-col .insights-label.editable {
  cursor: pointer;
}
.series-name-wrapper-col .insights-label.editable .EntityFilterInfo {
  color: var(--primary);
  font-weight: 500;
}