.FileDropTarget {
  position: relative;
}

.FileDropTarget--active::after {
  --file-drop-target-padding: 0.5rem;
  content: "";
  position: absolute;
  top: calc(-1 * var(--file-drop-target-padding));
  left: calc(-1 * var(--file-drop-target-padding));
  height: calc(100% + var(--file-drop-target-padding) * 2);
  width: calc(100% + var(--file-drop-target-padding) * 2);
  border: 3px dashed var(--primary);
  border-radius: var(--radius);
}