.LemonCollapse {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid var(--border);
  border-radius: var(--radius);
  background: var(--bg-light);
  overflow: hidden;
}

.LemonCollapsePanel {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.LemonCollapsePanel:not(:last-child) {
  border-bottom-width: 1px;
}

.LemonCollapsePanel__header {
  min-height: 2.875rem;
  border-radius: 0;
  padding: 0.5rem 0.75rem !important;
  font-weight: 500 !important;
}
.posthog-3000 .LemonCollapsePanel__header.LemonButton:active {
  transform: inherit;
}

.LemonCollapsePanel__body {
  transition: height 200ms ease;
  height: 0;
  overflow: hidden;
  border-top-width: 1px;
  box-sizing: content-box;
}

.LemonCollapsePanel__content {
  padding: 1rem;
}