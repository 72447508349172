.PlayerFrame {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: var(--bg-charcoal);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.PlayerFrame .PlayerFrame__content {
  position: absolute;
}
.PlayerFrame .PlayerFrame__content iframe {
  border: 0;
  background-color: white;
}
.PlayerFrame .PlayerFrame__content .replayer-wrapper {
  position: relative;
}
.PlayerFrame .PlayerFrame__content .replayer-mouse {
  position: absolute;
  width: 20px;
  height: 20px;
  transition: left 0.05s linear, top 0.05s linear;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGRhdGEtbmFtZT0iTGF5ZXIgMSIgdmlld0JveD0iMCAwIDUwIDUwIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPkRlc2lnbl90bnA8L3RpdGxlPjxwYXRoIGQ9Ik00OC43MSw0Mi45MUwzNC4wOCwyOC4yOSw0NC4zMywxOEExLDEsMCwwLDAsNDQsMTYuMzlMMi4zNSwxLjA2QTEsMSwwLDAsMCwxLjA2LDIuMzVMMTYuMzksNDRhMSwxLDAsMCwwLDEuNjUuMzZMMjguMjksMzQuMDgsNDIuOTEsNDguNzFhMSwxLDAsMCwwLDEuNDEsMGw0LjM4LTQuMzhBMSwxLDAsMCwwLDQ4LjcxLDQyLjkxWm0tNS4wOSwzLjY3TDI5LDMyYTEsMSwwLDAsMC0xLjQxLDBsLTkuODUsOS44NUwzLjY5LDMuNjlsMzguMTIsMTRMMzIsMjcuNThBMSwxLDAsMCwwLDMyLDI5TDQ2LjU5LDQzLjYyWiI+PC9wYXRoPjwvc3ZnPg==");
  border-color: transparent; /* otherwise we transition from black when .touch-device class is added */
}
.PlayerFrame .PlayerFrame__content .replayer-mouse::after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: rgb(73, 80, 246);
  border-radius: 100%;
  transform: translate(-50%, -50%);
  opacity: 0.3;
}
.PlayerFrame .PlayerFrame__content .replayer-mouse.active::after {
  animation: PlayerFrame__click 0.2s ease-in-out 1;
}
.PlayerFrame .PlayerFrame__content .replayer-mouse.touch-device {
  background-image: none; /* there's no passive cursor on touch-only screens */
  width: 70px;
  height: 70px;
  border-width: 4px;
  border-style: solid;
  border-radius: 100%;
  margin-left: -37px;
  margin-top: -37px;
  border-color: rgba(73, 80, 246, 0);
  transition: left 0s linear, top 0s linear, border-color 0.2s ease-in-out;
}
.PlayerFrame .PlayerFrame__content .replayer-mouse.touch-device.touch-active {
  border-color: rgb(73, 80, 246);
  transition: left 0.25s linear, top 0.25s linear, border-color 0.2s ease-in-out;
}
.PlayerFrame .PlayerFrame__content .replayer-mouse.touch-device::after {
  opacity: 0; /* there's no passive cursor on touch-only screens */
}
.PlayerFrame .PlayerFrame__content .replayer-mouse.touch-device.active::after {
  animation: PlayerFrame__touch-click 0.2s ease-in-out 1;
}
.PlayerFrame .PlayerFrame__content .replayer-mouse-tail {
  position: absolute;
  pointer-events: none;
}
@keyframes PlayerFrame__click {
  0% {
    opacity: 0.3;
    width: 20px;
    height: 20px;
  }
  50% {
    opacity: 0.5;
    width: 10px;
    height: 10px;
  }
}
@keyframes PlayerFrame__touch-click {
  0% {
    opacity: 0;
    width: 20px;
    height: 20px;
  }
  50% {
    opacity: 0.5;
    width: 10px;
    height: 10px;
  }
}