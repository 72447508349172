.LemonDivider {
  width: 100%;
  height: 1px;
  background: var(--border);
  flex-shrink: 0;
}
.LemonDivider--dashed {
  background: repeating-linear-gradient(to right, var(--border), var(--border) 4px, transparent 4px, transparent 8px);
}
.LemonDivider--thick {
  height: 3px;
}
.LemonDivider--vertical {
  width: 1px;
  height: auto;
  align-self: stretch;
}
.LemonDivider--vertical.LemonDivider--thick {
  width: 3px;
}
.LemonDivider--vertical.LemonDivider--dashed {
  background: repeating-linear-gradient(to bottom, var(--border), var(--border) 4px, transparent 4px, transparent 8px);
}