.LemonSkeleton {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 45%, rgba(190, 190, 190, 0.2) 65%);
  background-size: 400% 100%;
  animation: LemonSkeleton__shimmer 2s ease infinite;
}
@media (prefers-reduced-motion) {
  .LemonSkeleton {
    animation: none;
  }
}
.LemonSkeleton.LemonSkeleton--static {
  animation: none;
}
.LemonSkeleton > span {
  display: block;
  overflow: hidden;
  width: 1px;
  height: 1px;
  white-space: nowrap;
}

@keyframes LemonSkeleton__shimmer {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}