.ant-picker {
  box-shadow: none !important;
}

.posthog-3000 .ant-picker {
  color: var(--default);
  background: var(--secondary-3000);
  border-color: transparent;
}
.posthog-3000 .ant-picker .ant-picker-suffix {
  color: var(--default);
}
.posthog-3000 .ant-picker-panel-container {
  color: var(--default);
  background: var(--bg-3000);
  border: 1px solid var(--border);
}
.posthog-3000 .ant-picker-panel-container * {
  border-color: var(--border);
}
.posthog-3000 .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: var(--primary-3000);
}
.posthog-3000 .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.posthog-3000 .ant-picker-cell:hover:not(.ant-picker-cell-selected,
.ant-picker-cell-range-start,
.ant-picker-cell-range-end,
.ant-picker-cell-range-hover-start,
.ant-picker-cell-range-hover-end) .ant-picker-cell-inner,
.posthog-3000 .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: var(--secondary-3000);
}