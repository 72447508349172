.Breadcrumbs {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  cursor: default;
  overflow-x: auto;
}

.Breadcrumbs__breadcrumb {
  display: flex;
  align-items: center;
  font-weight: var(--font-medium);
  white-space: pre;
  user-select: none;
  gap: 0.5rem;
}
.Breadcrumbs__breadcrumb--current {
  color: var(--default);
  cursor: default;
}
.Breadcrumbs__breadcrumb--actionable {
  cursor: pointer;
  color: var(--primary);
}

.Breadcrumbs__separator {
  flex-shrink: 0;
  margin: 0 0.5rem;
  font-size: 1rem;
  color: var(--primary-alt);
}