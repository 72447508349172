.funnel-correlation {
  margin-bottom: 3rem;
}
.funnel-correlation .skew-warning {
  margin-top: 1rem;
  line-height: 2em;
  border: 1px solid var(--warning);
}
.funnel-correlation .skew-warning .ant-card-body {
  padding: 0.5rem 1rem;
}
.funnel-correlation .skew-warning h4 {
  font-size: 1.1em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border);
  padding: 0.5rem 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  position: relative;
}
.funnel-correlation .skew-warning h4 .close-button {
  position: absolute;
  right: 16px;
  color: var(--muted) !important;
  cursor: pointer;
}
.funnel-correlation .skew-warning b {
  padding-bottom: 0.5rem;
  font-weight: var(--font-medium);
}