.LemonActionableTooltip {
  max-width: var(--in-app-prompts-width);
  padding: 0.5rem;
}
.LemonActionableTooltip > * + * {
  margin-top: 0.5rem;
}
.LemonActionableTooltip .LemonActionableTooltip__header {
  display: flex;
  justify-content: space-between;
}
.LemonActionableTooltip .LemonActionableTooltip__header > * + * {
  margin-left: 0.5rem;
}
.LemonActionableTooltip .LemonActionableTooltip__title {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
}
.LemonActionableTooltip .LemonActionableTooltip__icon {
  color: var(--primary);
  display: flex;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
}
.LemonActionableTooltip .LemonActionableTooltip__icon > svg {
  width: 100%;
  height: 100%;
}
.LemonActionableTooltip .LemonActionableTooltip__body > * + * {
  margin-top: 0.5rem;
}
.LemonActionableTooltip .LemonActionableTooltip__footer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
.LemonActionableTooltip .LemonActionableTooltip__url-buttons {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.LemonActionableTooltip .LemonActionableTooltip__url-buttons > * + * {
  margin-top: 0.25rem;
}
.LemonActionableTooltip .LemonActionableTooltip__action-buttons {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.LemonActionableTooltip .LemonActionableTooltip__action-buttons > * + * {
  margin-top: 0.25rem;
}
.LemonActionableTooltip .LemonActionableTooltip__navigation {
  color: var(--muted);
  display: flex;
  align-items: center;
}
.LemonActionableTooltip .LemonActionableTooltip__navigation > * + * {
  margin-left: 0.25rem;
}