.BatchExportRunIcon--pulse {
  outline: 2px solid transparent;
  outline-offset: 0;
  animation: BatchExportRunIcon__pulse 2s infinite ease-out;
}

@keyframes BatchExportRunIcon__pulse {
  0% {
    outline-offset: 0;
    outline-color: var(--primary-light);
  }
  80% {
    outline-offset: 20px;
    outline-color: transparent;
  }
  100% {
    outline-offset: 20px;
    outline-color: transparent;
  }
}