.LemonModal__overlay {
  position: fixed;
  inset: 0;
  transition: background-color var(--modal-transition-time) ease-out, backdrop-filter var(--modal-transition-time) ease-out;
  z-index: var(--z-modal);
  display: flex;
  align-items: center;
  justify-content: center;
}
.LemonModal__overlay.LemonModal__overlay--force-modal-above-popovers {
  z-index: var(--z-force-modal-above-popovers);
}
.LemonModal__overlay.ReactModal__Overlay--after-open {
  background-color: var(--modal-backdrop-color);
  backdrop-filter: blur(var(--modal-backdrop-blur));
}
.LemonModal__overlay.ReactModal__Overlay--before-close {
  background-color: transparent;
  backdrop-filter: blur(0);
}

.LemonModal {
  position: relative;
  max-width: 90%;
  width: fit-content;
  min-width: min(28rem, 100%);
  max-height: 90%;
  margin: 1rem auto;
  border-radius: var(--radius);
  background-color: var(--bg-light);
  border: 1px solid var(--border);
  box-shadow: var(--shadow-elevation);
  transition: opacity var(--modal-transition-time) ease-out, transform var(--modal-transition-time) ease-out;
  display: flex;
  flex-direction: column;
  will-change: transform;
  transform: scale(0.85);
  opacity: 0;
}
.LemonModal.LemonModal--fullscreen {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  border-radius: 0;
  border: none;
  margin: 0;
}
.LemonModal.ReactModal__Content--after-open:not(.ReactModal__Content--before-close) {
  transform: scale(1);
  opacity: 1;
}
.LemonModal .LemonModal__close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  z-index: 1;
}
.LemonModal .LemonModal__close.LemonModal__close--highlighted {
  animation: LemonModal__tilt-shake 400ms;
}
.LemonModal .LemonModal__layout {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  flex: 1;
}
.LemonModal .LemonModal__content {
  padding: 1rem 1.5rem;
  overflow-y: auto;
}
.LemonModal .LemonModal__content.LemonModal__content--embedded {
  padding: 0;
}
.LemonModal .LemonModal__container {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.LemonModal__header {
  margin: 1.5rem;
  margin-bottom: 0;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid var(--border);
}
.LemonModal__header h3 {
  margin-bottom: 0;
  margin-right: 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
}
.LemonModal__header p {
  margin: 0.5rem 0 -0.25rem;
}

.LemonModal__footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 0.5rem;
  border-top: 1px solid var(--border);
  margin: 1.5rem;
  margin-top: 0;
  padding-top: 1.5rem;
  white-space: nowrap;
  align-items: center;
}

.LemonModal__header + .LemonModal__footer {
  border-top: none;
}

@keyframes LemonModal__tilt-shake {
  0% {
    transform: rotate(0deg);
  }
  16.666% {
    transform: rotate(12deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  83.333% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(0deg);
  }
}