.PropertyFilterButton {
  overflow: hidden;
  border-color: transparent;
  background: var(--border);
  display: inline-flex;
  transition-duration: 200ms;
  text-shadow: none;
  box-shadow: none;
  align-items: center;
  gap: 0.5rem;
}
.PropertyFilterButton:hover, .PropertyFilterButton:focus {
  border-color: var(--border);
  background: var(--border);
  color: var(--text-default);
}
.PropertyFilterButton > :not(.PropertyFilterButton-content) {
  flex-shrink: 0;
}
.PropertyFilterButton .PropertyFilterButton-content {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}