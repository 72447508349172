.LemonLabel {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 0.25rem;
  font-weight: 600;
  line-height: 1.5rem;
}
.LemonLabel .LemonLabel__extra {
  font-weight: 400;
}