.LemonBanner {
  border-radius: var(--radius);
  padding: 0.5rem 0.75rem;
  color: var(--primary-alt);
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: left;
  gap: 0.5rem;
  min-height: 3rem;
}
.LemonBanner.LemonBanner--info {
  background-color: var(--primary-alt-highlight);
}
.LemonBanner.LemonBanner--warning {
  background-color: var(--warning-highlight);
  color: var(--warning-dark);
}
.LemonBanner.LemonBanner--error {
  background-color: var(--danger-highlight);
  color: var(--danger);
}
.LemonBanner.LemonBanner--success {
  background-color: var(--success-highlight);
  color: var(--success-dark);
}
.LemonBanner p {
  margin-bottom: 0.25em;
}
.LemonBanner > .LemonIcon {
  line-height: 0;
  flex-shrink: 0;
  font-size: 1.5rem;
}