.DraggableToNotebook {
  position: relative;
}
.DraggableToNotebook--dragging {
  transform: translate3d(0, 0, 0);
  outline: 1px solid var(--primary);
  background-color: var(--bg-light);
}
.DraggableToNotebook[draggable=true] {
  cursor: grab;
}