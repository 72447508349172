.EditableField {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.EditableField:not(.EditableField--multiline) {
  line-height: 2rem;
}
.EditableField i {
  color: var(--muted);
}
.EditableField .EditableField__notice {
  font-size: 1.5rem;
  color: var(--muted);
  margin-left: 0.5rem;
}
.EditableField .EditableField__highlight {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  max-width: calc(100% + 0.5rem);
  min-height: 2rem;
  padding: 0.25rem;
  margin: -0.25rem;
  white-space: pre-wrap;
  overflow: auto;
}
.EditableField--editing .EditableField__highlight {
  flex-grow: 1;
  align-items: flex-end;
  width: auto;
  max-width: calc(100% + 0.5rem);
  outline: 1px solid var(--border);
  border-radius: var(--radius);
}
.EditableField .EditableField__autosize {
  align-self: center;
  min-width: 0;
}
.EditableField .EditableField__autosize__sizer {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  height: 0;
  overflow: scroll;
  white-space: pre;
}
.EditableField .EditableField__actions {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 0.5rem;
}
.EditableField input,
.EditableField textarea {
  max-width: 100%;
  border: none;
  background: transparent;
  font: inherit;
  color: var(--default);
  outline: none;
  padding: 0;
}
.EditableField textarea {
  align-self: center;
  width: 100%;
}