.filter-row-popover {
  overflow: visible;
}

.property-filter-row {
  overflow: hidden;
  flex-basis: 100%;
}
.property-filter-row.wrap-filters {
  flex-basis: auto;
}