.extra-ids {
  display: inline-flex;
  align-items: center;
  height: 1.25rem;
  line-height: 1.125rem;
  margin: 0 0 0 0.25rem;
  padding: 0 0.25rem 0 0.375rem;
  color: var(--primary);
  cursor: pointer;
}
.extra-ids svg {
  margin-left: 0.25rem;
}