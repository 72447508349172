.events-definition-table .LemonTable__content > table > tbody td.definition-column-icon {
  width: 36px;
}
.events-definition-table .LemonTable__content > table > tbody td.definition-column-icon .definition-column-name-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  font-size: 1.5rem;
}
.events-definition-table .LemonTable__content > table > tbody td.definition-column-icon .definition-column-name-icon svg.taxonomy-icon {
  flex-shrink: 0;
}
.events-definition-table .LemonTable__content > table > tbody td.definition-column-icon .definition-column-name-icon svg.taxonomy-icon.taxonomy-icon-muted {
  color: var(--muted-alt);
}
.events-definition-table .LemonTable__content > table > tbody td.definition-column-icon .definition-column-name-icon svg.taxonomy-icon.taxonomy-icon-verified {
  color: var(--success);
}
.events-definition-table .LemonTable__content > table > tbody td.definition-column-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.events-definition-table .LemonTable__content > table > tbody td.definition-column-name .definition-column-name-content {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 32px;
  justify-content: center;
}
.events-definition-table .LemonTable__content > table > tbody td.definition-column-name .definition-column-name-content .definition-column-name-content-title {
  font-weight: 600;
  cursor: pointer;
  position: relative;
  overflow: visible;
}
.events-definition-table .LemonTable__content > table > tbody td.definition-column-name .definition-column-name-content .definition-column-name-content-title::before {
  content: "";
  position: absolute;
  inset: -5px -50px -5px -10px;
  height: 22px;
}
.events-definition-table .LemonTable__content > table > tbody td.definition-example-type .definition-pill-value {
  float: right;
}
.events-definition-table .LemonTable__content > table > tbody .definition-pill-value {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.125rem 0.25rem;
  letter-spacing: 0.25px;
  border-radius: var(--radius);
  border: 1px solid var(--border-light);
  background: var(--mid);
  color: var(--muted-alt);
  font-size: 0.625rem;
  font-weight: 500;
  word-break: break-all;
  max-width: 300px;
  cursor: text;
}
.events-definition-table .LemonTable__content > table > tbody .LemonTable__expansion .event-properties-wrapper {
  padding: 1rem 0 1rem 1rem;
}
.events-definition-table .LemonTable__content > table > tbody .LemonTable__expansion .event-properties-wrapper .event-properties-header {
  font-weight: 600;
}