.Plugin__JobJsonEditor {
  border: 1px solid #efefef;
}

.Plugin__RunJobButton {
  color: var(--success);
  cursor: pointer;
}

.Plugin__RunJobButton--disabled {
  color: #9a9a9a;
  cursor: default;
}