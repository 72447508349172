.BridgePage {
  background-color: var(--bg-bridge);
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: scroll;
  -ms-overflow-style: none;
}
.BridgePage::-webkit-scrollbar {
  width: 0 !important;
}
.BridgePage .BridgePage__main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 1rem;
}
.BridgePage .BridgePage__content-wrapper {
  max-width: 100%;
}
.BridgePage .BridgePage__left-wrapper {
  margin-right: 60px;
  display: none;
}
@media screen and (min-width: 768px) {
  .BridgePage .BridgePage__left-wrapper {
    display: block;
    visibility: visible;
  }
}
.BridgePage .BridgePage__content {
  position: relative;
  box-shadow: var(--shadow-elevation);
  width: 100%;
  padding: 2rem;
  background-color: white;
  border-radius: var(--radius);
}
.BridgePage .BridgePage__content h2 {
  font-weight: bold;
  text-align: center;
}
.BridgePage .BridgePage__footer {
  display: flex;
  justify-content: center;
  gap: 10%;
  margin: 1rem 0;
  color: var(--muted);
  font-weight: 500;
  width: 100%;
}
.BridgePage .BridgePage__left {
  position: relative;
  visibility: none;
  display: none;
}
.BridgePage .BridgePage__left img {
  height: 300px;
}
.BridgePage .BridgePage__left .BridgePage__left__art {
  margin-top: 50px;
}
.BridgePage .BridgePage__left .BridgePage__left__message {
  position: absolute;
  left: 265px;
  bottom: 220px;
  max-width: 150px;
  background-color: var(--default);
  border-radius: 0.75rem;
  padding: 0.5rem 0.75rem;
  color: #fff;
  font-size: 0.8em;
  font-weight: 600;
  opacity: 0;
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.31, 1.585);
}
.BridgePage .BridgePage__left .BridgePage__left__message::after {
  position: absolute;
  top: 100%;
  left: 20px;
  margin-top: -10px;
  content: " ";
  border: 10px solid transparent;
  border-left-color: var(--default);
}
.BridgePage .BridgePage__left .BridgePage__left__message.BridgePage__left__message--enter {
  opacity: 0;
  transform: translateY(50px) rotate(0deg) scale(0.5);
}
.BridgePage .BridgePage__left .BridgePage__left__message.BridgePage__left__message--enter-active, .BridgePage .BridgePage__left .BridgePage__left__message.BridgePage__left__message--enter-done {
  opacity: 1;
  transform: translateY(0) rotate(5deg) scale(1);
  transition: 200ms opacity, 200ms transform;
}
@media (prefers-reduced-motion) {
  .BridgePage .BridgePage__left .BridgePage__left__message {
    transition: none;
  }
}
@media screen and (min-width: 768px) {
  .BridgePage .BridgePage__left {
    display: block;
    visibility: visible;
  }
}
.BridgePage .BridgePage__cta {
  max-width: 400px;
}
.BridgePage .BridgePage__header-logo.mobile {
  display: block;
}
@media screen and (min-width: 768px) {
  .BridgePage .BridgePage__header-logo.mobile {
    display: none;
  }
}
.BridgePage .BridgePage__header-logo .header-logo {
  padding-bottom: 3rem;
  text-align: center;
}
.BridgePage .BridgePage__header-logo .header-logo img {
  height: 24px;
}
@media screen and (min-width: 768px) {
  .BridgePage.BridgePage--fixed-width .BridgePage__content {
    max-width: 400px;
    width: 400px;
  }
}