.LemonSwitch {
  width: fit-content;
  font-weight: 500;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.LemonSwitch label[for] {
  cursor: pointer;
}
.LemonSwitch label {
  flex: 1;
  cursor: inherit;
}
.LemonSwitch.LemonSwitch--full-width {
  width: 100%;
}
.LemonSwitch.LemonSwitch--bordered {
  padding: 0 0.75rem;
  border-radius: var(--radius);
  border: 1px solid var(--border);
  background: var(--bg-light);
  min-height: 2.5rem;
}
.LemonSwitch.LemonSwitch--bordered.LemonSwitch--small {
  padding: 0 0.5rem;
  gap: 0.5rem;
  min-height: 2rem;
}
.LemonSwitch > .LemonIcon {
  font-size: 1.5rem;
  color: var(--muted-alt);
}
.LemonSwitch.LemonSwitch--disabled {
  cursor: not-allowed;
  opacity: var(--opacity-disabled);
}
.LemonSwitch.LemonSwitch--disabled label[for] {
  cursor: not-allowed;
}

.LemonSwitch__button {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  padding: 0;
  width: 2.25rem;
  height: 1.25rem;
  background: none;
  border: none;
  cursor: pointer;
}
.LemonSwitch--disabled .LemonSwitch__button {
  cursor: not-allowed;
}

.LemonSwitch__slider {
  position: absolute;
  left: 0;
  top: 5px;
  display: inline-block;
  width: 2.25rem;
  height: 0.625rem;
  border-radius: 0.625rem;
  background-color: var(--border);
  transition: background-color 100ms ease;
}
.LemonSwitch--checked .LemonSwitch__slider {
  background-color: var(--primary-highlight);
}

.LemonSwitch__handle {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.625rem;
  background-color: #fff;
  border: 2px solid var(--border);
  transition: background-color 100ms ease, transform 100ms ease, border-color 100ms ease;
  cursor: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
.LemonSwitch--checked .LemonSwitch__handle {
  transform: translateX(1rem);
  background-color: var(--primary);
  border-color: var(--primary);
}
.LemonSwitch--active .LemonSwitch__handle {
  transform: scale(1.1);
}
.LemonSwitch--active.LemonSwitch--checked .LemonSwitch__handle {
  transform: translateX(1rem) scale(1.1);
}