.taxonomic-filter {
  width: 550px;
  max-width: calc(100vw - 40px);
  background: var(--bg-light);
  display: flex;
  flex-direction: column;
}
.posthog-3000 .taxonomic-filter {
  background: var(--bg-3000);
}
.taxonomic-filter.force-minimum-width {
  min-width: 300px;
}
.taxonomic-filter.one-taxonomic-tab .taxonomic-infinite-list {
  margin-top: 10px;
}
.taxonomic-filter .taxonomic-group-title {
  display: flex;
  width: 100%;
  align-items: stretch;
  color: var(--muted);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  padding-top: 10px;
  padding-left: 10px;
  font-weight: 600;
}
.taxonomic-filter .taxonomic-group-title.with-border {
  border-top: 1px solid var(--border-light);
}
.taxonomic-filter .taxonomic-pills {
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 10px;
}
.taxonomic-filter .taxonomic-pills .ant-tag {
  transition: none;
  margin-right: 2px;
  margin-bottom: 2px;
  cursor: pointer;
  color: var(--link);
  background: var(--side);
  border-color: var(--side);
}
.taxonomic-filter .taxonomic-pills .ant-tag.taxonomic-count-zero {
  color: var(--muted);
  cursor: not-allowed;
}
.taxonomic-filter .taxonomic-pills .ant-tag.taxonomic-pill-active {
  color: #fff;
  background: var(--link);
  border-color: var(--link);
}