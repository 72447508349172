.Preflight {
  max-width: 400px;
  padding: 1rem;
}
.Preflight .Preflight__header p {
  margin-bottom: 0.5rem;
}
.Preflight .Preflight__header p.Preflight__header--title-text {
  font-size: 28px;
  font-weight: 800;
  line-height: 34px;
  letter-spacing: -0.02em;
}
.Preflight .Preflight__header p.Preflight__header--secondary-text {
  font-size: 14px;
  line-height: 24px;
}
.Preflight .Preflight__checks-container {
  border-radius: var(--radius);
  border: 1px solid var(--border);
}
.Preflight .Preflight__checks-container svg {
  font-size: 20px;
}
.Preflight .Preflight__checks-container .Preflight__check-summary {
  display: flex;
  padding: 12px;
  border-bottom: 1px solid var(--border);
  align-items: center;
}
.Preflight .Preflight__checks-container .Preflight__check-summary .Preflight__summary-icon-container {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Preflight .Preflight__checks-container .Preflight__check-summary .Preflight__summary-text-container {
  flex: 1;
  margin: 0 14px;
}
.Preflight .Preflight__checks-container .Preflight__check-summary .Preflight__summary-text-container p {
  margin-bottom: 0;
}
.Preflight .Preflight__checks-container .Preflight__check-summary .Preflight__summary-text-container .Preflight__summary-header {
  font-weight: 600;
  font-size: 14px;
}
.Preflight .Preflight__checks-container .Preflight__check-summary .Preflight__summary-text-container .Preflight__summary-description {
  font-size: 13px;
}
.Preflight .Preflight__cannot-continue {
  background-color: var(--side);
  border: 2px solid var(--mid);
  display: flex;
  align-items: center;
  justify-content: center;
}
.Preflight .Preflight__cannot-continue p {
  font-size: 14px;
  margin-bottom: 0;
}
.Preflight .Preflight--loading {
  background-color: #f6f8ff;
}
.Preflight .Preflight--loading svg,
.Preflight .Preflight--loading .Preflight__status-text {
  color: var(--primary);
}
.Preflight .Preflight--validated {
  background-color: #f8fcf8;
}
.Preflight .Preflight--validated svg,
.Preflight .Preflight--validated .Preflight__status-text {
  color: var(--success);
}
.Preflight .Preflight--warning {
  background-color: rgba(247, 165, 1, 0.1019607843);
}
.Preflight .Preflight--warning svg,
.Preflight .Preflight--warning .Preflight__status-text {
  color: var(--warning);
}
.Preflight .Preflight--optional {
  background-color: #fafaf9;
}
.Preflight .Preflight--optional svg,
.Preflight .Preflight--optional .Preflight__status-text {
  color: var(--border-bold);
}
.Preflight .Preflight--error {
  background-color: #feefea;
}
.Preflight .Preflight--error svg,
.Preflight .Preflight--error .Preflight__status-text {
  color: var(--danger);
}

.PreflightItem {
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--border);
}
.PreflightItem p {
  margin-bottom: 0;
  font-size: 14px;
}
.PreflightItem .PreflightItem__icon-container {
  display: flex;
}
.PreflightItem .PreflightItem__text-container {
  flex: 1;
  margin: 0 14px;
}
.PreflightItem .PreflightItem__item-name {
  font-weight: 600;
}
.PreflightItem svg {
  font-size: 20px;
}