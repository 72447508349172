.BillingGaugeItem {
  animation: BillingGaugeItem__expand 800ms cubic-bezier(0.15, 0.15, 0.2, 1) forwards;
}
.BillingGaugeItem .BillingGaugeItem__info {
  position: absolute;
  white-space: nowrap;
  border-left: 1px solid var(--border);
  left: 100%;
  margin-left: -1px;
  font-size: 0.8rem;
  background: var(--bg-light);
  bottom: 100%;
  padding: 0 0.25rem 0.5rem;
  line-height: 1rem;
}
.BillingGaugeItem .BillingGaugeItem__info--bottom {
  top: 100%;
  bottom: auto;
  padding: 0.5rem 0.25rem 0;
}
.BillingGaugeItem .BillingGaugeItem__info:hover {
  z-index: 100;
}

@keyframes BillingGaugeItem__expand {
  0% {
    width: 0%;
  }
  100% {
    width: var(--billing-gauge-item-width);
  }
}