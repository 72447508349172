@charset "UTF-8";
.experiment-form .metrics-selection {
  border-top: 1px solid var(--border);
  padding-top: 1rem;
  width: 100%;
}
.experiment-form .person-selection {
  width: 100%;
  border-top: 1px solid var(--border);
  padding-top: 1rem;
  align-items: center;
  justify-content: space-between;
}
.experiment-form .experiment-preview {
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--border);
}
.experiment-form .experiment-preview .mde-slider .ant-slider-handle {
  border: none;
}
.experiment-form .experiment-preview .mde-slider .ant-slider-rail {
  background-color: var(--primary-highlight);
}
.experiment-form .variants {
  margin-top: 0.5rem;
  padding-bottom: 1rem;
}
.experiment-form .variants .ant-form-horizontal {
  min-height: 32px;
}
.experiment-form .variants .border-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.experiment-form .variants .border-bottom {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.experiment-form .variants .feature-flag-variant {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background: var(--bg-light);
  border-width: 1px;
  border-color: var(--border);
  border-top-style: solid;
  border-left-style: solid;
  border-right-style: solid;
}
.experiment-form .variants .feature-flag-variant .extend-variant-fully {
  flex: 1;
}
.experiment-form .variants .variant-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  min-width: 52px;
  padding: 2px 6px;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.01em;
}
.experiment-form .secondary-metrics {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--border);
  margin-bottom: 1rem;
  width: 100%;
}
.experiment-form .ant-input-number-disabled {
  color: black;
}
.experiment-form .ant-input[disabled] {
  color: black;
}

.view-experiment .draft-header {
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--border);
}
.view-experiment .exp-description {
  font-style: italic;
  color: var(--muted);
}
.view-experiment .participants {
  background-color: white;
}
.view-experiment .participants .ant-collapse-header {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
}
.view-experiment .variants-list li {
  display: inline;
}
.view-experiment .variants-list li::after {
  content: ", ";
}
.view-experiment .variants-list li:last-child::after {
  content: "";
}
.view-experiment .experiment-result {
  padding-top: 1rem;
}
.view-experiment .ant-collapse-content-box {
  display: flex;
  flex-flow: column;
}
.view-experiment .secondary-progress {
  margin-top: 0.5rem;
}
.view-experiment .secondary-progress li::before {
  content: "•";
  display: inline-block;
  font-weight: 900;
  margin-right: 4px;
}
.view-experiment .secondary-progress .ant-progress-inner {
  border-radius: 4px;
}
.view-experiment .secondary-progress .ant-progress-inner .ant-progress-bg {
  border-radius: 4px;
}
.view-experiment .no-experiment-results {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  background-color: var(--side);
  border: 1px solid var(--border);
  width: 100%;
  min-height: 320px;
  font-size: 24px;
}
.view-experiment .computation-time-and-sampling-notice {
  margin-top: 8px;
}

.preview-conversion-goal-num {
  height: 24px;
  width: 24px;
  text-align: center;
  background-color: var(--side);
  color: var(--primary-alt);
  font-weight: 700;
  margin-right: 0.5rem;
}

.experiment-preview-row {
  border-bottom: 1px solid var(--border);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.experiment-preview-row:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.metric-name {
  border: 1px solid var(--border);
  border-radius: 4px;
  padding: 8px 8px 8px 16px;
  margin-left: 0.5rem;
  flex: 1;
}