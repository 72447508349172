.SessionRecordingPlayerExplorer {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 0.5rem;
  overflow: hidden;
}
.SessionRecordingPlayerExplorer .SessionRecordingPlayerExplorer__wrapper {
  flex: 1;
  border: 1px solid var(--border);
  padding: 0.5rem;
  background-color: var(--default);
  overflow: hidden;
}
.SessionRecordingPlayerExplorer .SessionRecordingPlayerExplorer__wrapper .SessionRecordingPlayerExplorer__iframe {
  transform-origin: top left;
}