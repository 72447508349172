.insights-label {
  max-width: 100%;
}
.insights-label .value {
  margin-left: 0.3rem;
  font-size: 1.176em;
  font-weight: bold;
  padding-right: 4px;
}
.insights-label .ant-tag {
  margin-right: 0;
  background-color: var(--border-light);
  border: 0;
  color: var(--primary-alt);
}
.insights-label .graph-series-glyph {
  padding: 0 6px;
  margin-right: 4px;
  margin-left: 2px;
}
.insights-label .color-icon {
  border-radius: 50%;
  margin-left: 2px;
  margin-right: 6px;
  border: 2px solid #fff;
  box-sizing: border-box;
}