.LemonRow {
  transition: background-color 200ms ease, color 200ms ease, border 200ms ease, opacity 200ms ease;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: center;
  min-height: 2.5rem;
  padding: 0.25rem 1rem;
  background: none;
  border-radius: var(--radius);
  border: none;
  font-size: 0.875rem;
  text-align: left;
  line-height: 1.25rem;
}
.LemonRow.LemonRow--status-highlighted {
  background: var(--primary-bg-hover);
  color: var(--default);
  font-weight: 600;
}
.LemonRow.LemonRow--status-highlighted .LemonRow__icon {
  color: var(--primary);
}
.LemonRow.LemonRow--status-danger .LemonRow__icon {
  color: var(--danger);
}
.LemonRow.LemonRow--status-warning .LemonRow__icon {
  color: var(--warning);
}
.LemonRow.LemonRow--status-success .LemonRow__icon {
  color: var(--success);
}
.LemonRow.LemonRow--status-muted {
  background: var(--side);
}
.LemonRow.LemonRow--disabled {
  opacity: var(--opacity-disabled);
}

.LemonRow__main-area {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LemonRow__extended-area {
  display: flex;
  flex-direction: column;
  font-size: 0.8125rem;
  margin-left: 2rem;
  padding-bottom: 0.125rem;
}

.LemonRow--outlined {
  border: 1px solid var(--border);
}

.LemonRow--full-width {
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.LemonRow--full-width .LemonRow__icon:not(:first-child):last-child {
  margin-right: 0;
}
.LemonRow--full-width > .LemonRow__main-area {
  justify-content: flex-start;
}

.LemonRow--center .LemonRow__main-area {
  justify-content: center !important;
}
.LemonRow--center .LemonRow__content {
  flex-grow: 0;
}

.LemonRow--symbolic {
  display: block;
  min-height: 0;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
}

.LemonRow--tall, .LemonRow--large {
  min-height: 3.5rem;
  padding: 0.5rem 1rem;
}
.LemonRow--tall.LemonRow--symbolic, .LemonRow--symbolic.LemonRow--large {
  min-height: 0;
  height: 1.75rem;
  width: 1.75rem;
  padding: 0;
}

.LemonRow--large {
  font-size: 1rem;
}
.LemonRow--large .LemonRow__icon {
  font-size: 1.75rem;
}

.LemonRow--small {
  min-height: 2rem;
  padding: 0.125rem 0.5rem;
}
.LemonRow--small.LemonRow--symbolic {
  min-height: 0;
  height: 1.25rem;
  width: 1.25rem;
  padding: 0;
}
.LemonRow--small .LemonRow__icon {
  font-size: 1.25rem;
}

.LemonRow__icon {
  transition: background-color 200ms ease, color 200ms ease;
  display: flex;
  flex-shrink: 0;
  font-size: 1.5rem;
  color: var(--muted-alt);
}

.LemonRow__content {
  flex-grow: 1;
  min-width: 0;
  display: flex;
  align-items: center;
}

.LemonRow__content:not(:first-child),
.LemonRow__icon:not(:first-child) {
  margin-left: 0.5rem;
}