.Notebook {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.Notebook .NotebookEditor {
  flex: 1;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.Notebook .NotebookEditor .ProseMirror-focused {
  outline: none;
}
.Notebook .NotebookEditor .ProseMirror > h1,
.Notebook .NotebookEditor .ProseMirror > h2,
.Notebook .NotebookEditor .ProseMirror > h3,
.Notebook .NotebookEditor .ProseMirror > h4,
.Notebook .NotebookEditor .ProseMirror > h5 {
  margin-top: 0.5rem;
}
.Notebook .NotebookEditor .ProseMirror > p {
  margin-bottom: 0.2rem;
}
.Notebook .NotebookEditor .ProseMirror > .is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: rgba(0, 0, 0, 0.2);
  pointer-events: none;
  height: 0;
}
[theme=dark] .Notebook .NotebookEditor .ProseMirror > .is-empty::before {
  color: rgba(255, 255, 255, 0.2);
}
.Notebook .NotebookEditor .ProseMirror > ol {
  list-style-type: decimal;
}
.Notebook .NotebookEditor .ProseMirror > ul {
  list-style-type: disc;
}
.Notebook .NotebookEditor .ProseMirror > ul[data-type=taskList] {
  list-style-type: none;
  padding-left: 0;
}
.Notebook .NotebookEditor .ProseMirror > ul[data-type=taskList] li {
  display: flex;
}
.Notebook .NotebookEditor .ProseMirror > ul[data-type=taskList] li > label {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  user-select: none;
}
.Notebook .NotebookEditor .ProseMirror > ul[data-type=taskList] li > div {
  flex: 1 1 auto;
}
.Notebook .NotebookEditor .ProseMirror > ul[data-type=taskList] li ul li,
.Notebook .NotebookEditor .ProseMirror > ul[data-type=taskList] li ol li {
  display: list-item;
}
.Notebook .NotebookEditor .ProseMirror > ul[data-type=taskList] li ul[data-type=taskList] > li {
  display: flex;
}
.Notebook .NotebookEditor .ProseMirror > ul,
.Notebook .NotebookEditor .ProseMirror > ol {
  padding-left: 1rem;
}
.Notebook .NotebookEditor .ProseMirror > ul li p,
.Notebook .NotebookEditor .ProseMirror > ol li p {
  margin-bottom: 0.2rem;
}
.Notebook .NotebookEditor .ProseMirror > pre {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: var(--radius);
  overflow-x: auto;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}
.Notebook .NotebookEditor .ProseMirror > code,
.Notebook .NotebookEditor .ProseMirror > p code {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: var(--radius);
  padding: 0.2rem;
}
.Notebook .NotebookEditor .Backlink {
  padding: 0.125rem 0.25rem;
  border: 1px solid var(--border);
  border-radius: var(--radius);
  background-color: var(--white);
}
.Notebook .NotebookEditor .Backlink:hover {
  background-color: var(--side);
}
.Notebook .NotebookEditor .Backlink svg {
  color: var(--muted-dark);
}
.Notebook .NotebookEditor .Backlink.Backlink--selected {
  outline-style: solid;
  outline-color: var(--primary-bg-active);
}
.Notebook .NotebookEditor .Backlink.Backlink--active {
  border: 1px solid var(--brand-blue);
  background: var(--brand-blue);
  color: var(--white);
}
.Notebook .NotebookEditor .Backlink.Backlink--active .Backlink__label, .Notebook .NotebookEditor .Backlink.Backlink--active svg {
  color: var(--white);
}
.Notebook .NotebookEditor[contenteditable=true] a {
  cursor: text;
}
.Notebook--compact:not(.Notebook--canvas) .NotebookEditor {
  max-width: 800px;
}
.Notebook--editable .NotebookEditor .ProseMirror {
  padding-bottom: 10rem;
  flex: 1;
}
.Notebook .NotebookColumn {
  position: relative;
  width: 0;
  transition: width var(--notebook-popover-transition-properties);
  --notebook-sidebar-height: calc(100vh - 9rem);
}
.Notebook .NotebookColumn .NotebookColumn__content {
  position: sticky;
  align-self: flex-start;
  top: 0;
  transform: translateX(-100%);
  transition: transform var(--notebook-popover-transition-properties);
}
.NotebookScene .Notebook .NotebookColumn, .NotebookPopover .Notebook .NotebookColumn {
  --notebook-sidebar-height: calc(100vh - 11rem);
}
.NotebookScene .Notebook .NotebookColumn .NotebookColumn__padding, .NotebookPopover .Notebook .NotebookColumn .NotebookColumn__padding {
  height: 4rem;
}
.NotebookScene .Notebook .NotebookColumn .NotebookColumn__content {
  top: 4rem;
}
.Notebook .NotebookColumn--left .NotebookColumn__content {
  width: var(--notebook-column-left-width);
  transform: translateX(-100%);
}
.Notebook .NotebookColumn--right .NotebookColumn__content {
  width: var(--notebook-column-right-width);
  transform: translateX(100%);
}
.Notebook .NotebookColumn--showing.NotebookColumn--left {
  width: var(--notebook-column-left-width);
  margin-right: 1rem;
}
.Notebook .NotebookColumn--showing.NotebookColumn--right {
  width: var(--notebook-column-right-width);
  margin-left: 1rem;
}
.Notebook .NotebookColumn--showing .NotebookColumn__content {
  transform: translateX(0);
}
.Notebook .NotebookHistory {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: var(--notebook-sidebar-height);
  overflow: hidden;
}
.Notebook .NotebookInlineMenu {
  margin-bottom: -0.2rem;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
}
.Notebook .NotebookInlineMenu .LemonInput {
  border: 0;
  min-height: 0;
}
.Notebook .NotebookColumnLeft__widget > .LemonWidget__content {
  max-height: calc(100vh - 220px);
  overflow: auto;
}
.Notebook .LemonTable__content > table > thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
.Notebook .NotebookRecordingTimestamp {
  display: inline-flex;
  max-height: 22px;
}
.Notebook .NotebookMention {
  display: inline-flex;
  max-height: 22px;
}
.Notebook span::selection {
  color: var(--primary);
}
.Notebook .InsightVizDisplay .InsightDisplayConfig {
  padding: 0;
  border-bottom-width: 0;
}