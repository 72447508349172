.LemonSegmentedButton {
  position: relative;
  flex-shrink: 0;
  width: fit-content;
  background: var(--bg-light);
  border-radius: var(--radius);
  border: 1px solid var(--border);
}
.LemonSegmentedButton > ul {
  z-index: 1;
  list-style: none;
  display: flex;
  align-items: stretch;
}
.LemonSegmentedButton.LemonSegmentedButton--full-width {
  width: 100%;
}

.LemonSegmentedButton__slider {
  position: absolute;
  top: -1px;
  left: -1px;
  height: calc(100% + 2px);
  width: calc(var(--lemon-segmented-button-slider-width) + 2px);
  transform: translateX(var(--lemon-segmented-button-slider-offset));
  background: var(--primary);
}
.LemonSegmentedButton__slider.LemonSegmentedButton__slider--first {
  border-top-left-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
}
.LemonSegmentedButton__slider.LemonSegmentedButton__slider--last {
  border-top-right-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}
.LemonSegmentedButton--transitioning .LemonSegmentedButton__slider {
  transition: width 200ms ease, transform 200ms ease, border-radius 200ms ease;
  will-change: width, transform, border-radius;
}

.LemonSegmentedButton__option {
  display: flex;
  flex: 1;
}
.LemonSegmentedButton__option .LemonButton {
  transition: background-color 200ms ease, color 200ms ease, border 200ms ease, opacity 200ms ease, outline 200ms ease;
  outline: 1px solid transparent;
  border-radius: 0;
  min-height: calc(var(--lemon-button-height) - 2px);
}
.LemonSegmentedButton__option:first-child, .LemonSegmentedButton__option:first-child .LemonButton {
  border-top-left-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
}
.LemonSegmentedButton__option:last-child, .LemonSegmentedButton__option:last-child .LemonButton {
  border-top-right-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}
.LemonSegmentedButton__option:not(:last-child) {
  border-right: 1px solid var(--border);
}
.LemonSegmentedButton__option:not(.LemonSegmentedButton__option--disabled, .LemonSegmentedButton__option--selected):hover .LemonButton {
  outline-color: var(--primary);
}
.LemonSegmentedButton__option:not(.LemonSegmentedButton__option--disabled, .LemonSegmentedButton__option--selected):active .LemonButton {
  outline-color: var(--primary-dark);
}
.LemonSegmentedButton__option.LemonSegmentedButton__option--selected .LemonButton,
.LemonSegmentedButton__option.LemonSegmentedButton__option--selected .LemonButton__icon {
  color: #fff;
}
.LemonSegmentedButton__option.LemonSegmentedButton__option--selected .LemonButton:hover, .LemonSegmentedButton__option.LemonSegmentedButton__option--selected .LemonButton:active {
  background: none;
}