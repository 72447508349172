.SideBar {
  display: flex;
  overflow: hidden;
  position: relative;
  flex: 1;
}

.SideBar__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.SideBar__slider {
  transition: 200ms ease margin, 200ms ease transform;
  position: absolute;
  z-index: var(--z-lemon-sidebar);
  flex-shrink: 0;
  height: 100%;
  width: 15.5rem;
  background: var(--side);
  border-right: 1px solid var(--border);
  display: flex;
  flex-direction: column;
}
.SideBar--hidden .SideBar__slider {
  transform: translateX(-15.5rem);
}
@media screen and (min-width: 992px) {
  .SideBar__slider {
    height: initial;
    position: relative;
  }
  .SideBar--hidden .SideBar__slider {
    margin-left: -15.5rem;
  }
}
.SideBar__slider .SideBar__slider__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
}
.SideBar__slider .SideBar__slider__content > ul {
  overflow: auto;
  padding: 1rem 0.5rem;
}
.SideBar__slider .SideBar__slider__content > ul li {
  margin-top: 1px;
}

.SideBar__overlay {
  z-index: var(--z-mobile-nav-overlay);
  transition: background-color var(--modal-transition-time) ease-out, backdrop-filter var(--modal-transition-time) ease-out;
  opacity: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--modal-backdrop-color);
  backdrop-filter: blur(var(--modal-backdrop-blur));
}
.SideBar--hidden .SideBar__overlay {
  background-color: transparent;
  backdrop-filter: blur(0);
  pointer-events: none;
}
@media screen and (min-width: 992px) {
  .SideBar__overlay {
    display: none;
  }
}

.SideBar__heading {
  margin-left: 0.5rem;
  color: var(--muted);
  line-height: 1.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-top: 1rem;
}
.SideBar__heading:first-of-type {
  margin-top: 0;
}

.ProjectSwitcher__label {
  padding: 0 0.5rem;
  color: var(--muted);
  line-height: 1.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.SideBar__side-actions {
  max-height: calc(100vh - 20rem);
  max-width: calc(100vw - 14rem);
}
.SideBar__side-actions .LaunchToolbarButton {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.SideBar__side-actions .LaunchToolbarButton .LemonButton__content {
  display: block;
  text-overflow: ellipsis;
}