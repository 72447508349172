.PlayerSeekbar {
  --bar-height: 4px;
  --slider-height: 20px;
  --thumb-size: 15px;
  width: 100%;
  height: var(--slider-height);
  position: relative;
  display: flex;
}
.PlayerSeekbar:hover, .PlayerSeekbar--scrubbing {
  --bar-height: 8px;
}
.PlayerSeekbar:hover .PlayerSeekBarPreview, .PlayerSeekbar--scrubbing .PlayerSeekBarPreview {
  opacity: 1;
}
.PlayerSeekbar .PlayerSeekbar__slider {
  z-index: 2;
  height: var(--bar-height);
  background-color: var(--border-light);
  border-radius: var(--bar-height);
  position: absolute;
  left: 0;
  right: 0;
  top: calc((var(--slider-height) - var(--bar-height)) / 2);
  transition: height 150ms ease-in-out, top 150ms ease-in-out;
  cursor: pointer;
}
.PlayerSeekbar .PlayerSeekbar__slider .PlayerSeekbar__bufferbar,
.PlayerSeekbar .PlayerSeekbar__slider .PlayerSeekbar__currentbar,
.PlayerSeekbar .PlayerSeekbar__slider .PlayerSeekbar__segments {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 0;
}
.PlayerSeekbar .PlayerSeekbar__slider .PlayerSeekbar__bufferbar {
  background-color: var(--border);
  z-index: 2;
  border-radius: var(--bar-height);
}
.PlayerSeekbar .PlayerSeekbar__slider .PlayerSeekbar__currentbar {
  z-index: 3;
  background-color: var(--recording-seekbar-red);
  border-radius: var(--bar-height) 0 0 var(--bar-height);
}
.PlayerSeekbar .PlayerSeekbar__slider .PlayerSeekbar__segments {
  height: 100%;
  white-space: nowrap;
  z-index: 4;
  width: 100%;
}
.PlayerSeekbar .PlayerSeekbar__slider .PlayerSeekbar__segments .PlayerSeekbar__segments__item {
  float: left;
  height: 100%;
  background-color: var(--bg-light);
  opacity: 0.5;
}
.PlayerSeekbar .PlayerSeekbar__slider .PlayerSeekbar__segments .PlayerSeekbar__segments__item--active {
  opacity: 0;
}
.PlayerSeekbar .PlayerSeekbar__slider .PlayerSeekbar__thumb {
  z-index: 5;
  backface-visibility: hidden;
  top: calc(var(--bar-height) / 2);
  margin-top: calc(var(--thumb-size) / 2 * -1);
  position: absolute;
  border-radius: 50%;
  width: var(--thumb-size);
  height: var(--thumb-size);
  border: 2px solid var(--bg-light);
  background-color: var(--recording-seekbar-red);
  transition: top 150ms ease-in-out;
}
.PlayerSeekbar .PlayerSeekBarPreview {
  position: absolute;
  z-index: 6;
  bottom: 100%;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 150ms ease-in-out;
}
.PlayerSeekbar .PlayerSeekBarPreview .PlayerSeekBarPreview__tooltip {
  width: 100%;
}
.PlayerSeekbar .PlayerSeekBarPreview .PlayerSeekBarPreview__tooltip .PlayerSeekBarPreview__tooltip__content {
  display: inline-block;
  border-radius: var(--radius);
  padding: 0.1rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #fff;
  background-color: var(--muted-dark);
  transform: translateX(-50%);
  margin-bottom: 0.5rem;
}

.PlayerSeekbarTicks {
  z-index: 1;
  width: 100%;
  height: 0.75rem;
  position: relative;
}
.PlayerSeekbarTicks .PlayerSeekbarTick {
  --tick-color: var(--muted-alt);
  --tick-width: 3px;
  cursor: pointer;
  position: absolute;
  height: 100%;
  top: 0;
  transition: transform 150ms ease-in-out;
}
.PlayerSeekbarTicks .PlayerSeekbarTick--warning {
  --tick-color: var(--warning);
}
.PlayerSeekbarTicks .PlayerSeekbarTick--danger {
  --tick-color: var(--danger);
}
.PlayerSeekbarTicks .PlayerSeekbarTick--primary {
  --tick-color: var(--primary);
}
.PlayerSeekbarTicks .PlayerSeekbarTick .PlayerSeekbarTick__line {
  position: absolute;
  width: var(--tick-width);
  margin-left: calc(var(--tick-width) / 2 * -1);
  border-radius: 2px;
  opacity: 0.5;
  height: 100%;
  background-color: var(--tick-color);
}
.PlayerSeekbarTicks .PlayerSeekbarTick .PlayerSeekbarTick__info {
  position: absolute;
  bottom: 100%;
  background-color: var(--tick-color);
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  border-radius: 0.25rem;
  transform: translateX(-50%);
  white-space: nowrap;
  color: #fff;
  margin-bottom: 0.25rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 150ms ease-in-out;
  max-width: 20rem;
}
.PlayerSeekbarTicks .PlayerSeekbarTick:hover {
  --tick-width: 5px;
}
.PlayerSeekbarTicks .PlayerSeekbarTick:hover .PlayerSeekbarTick__info {
  opacity: 1;
  visibility: visible;
}
.PlayerSeekbarTicks .PlayerSeekbarTick:hover .PlayerSeekbarTick__line {
  opacity: 1;
}