.InsightCard {
  transition: border 200ms ease;
  position: relative;
  height: 30rem;
  min-width: 0;
  max-width: 100%;
  border-radius: var(--radius);
  border: 1px solid var(--border);
  z-index: 3;
  background: var(--bg-light);
  display: flex;
  flex-direction: column;
}
.InsightCard--highlighted {
  border-color: var(--primary);
  outline: 1px solid var(--primary);
}
.InsightCard .ant-alert {
  margin: 1rem;
  width: 100%;
  overflow: auto;
}

.InsightCard__viz {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}
.InsightCard__viz .LineGraph,
.InsightCard__viz .AnnotationsOverlay {
  padding: 0.5rem;
}
.InsightCard__viz .insight-empty-state {
  height: 100%;
  font-size: 0.875rem;
  padding-top: 0;
  padding-bottom: 0;
}
.InsightCard__viz .LemonTable {
  border: none;
  border-radius: 0;
  background: none;
}

.InsightDetails {
  font-size: 0.8125rem;
  line-height: 1.5rem;
}
.InsightDetails h5 {
  margin-bottom: 0.125rem;
}
.InsightDetails section:not(:last-child) {
  margin-bottom: 0.5rem;
}
.InsightDetails .LemonRow {
  min-height: 2rem;
  font-size: inherit;
}

.InsightDetails__query {
  margin-top: 0.25rem;
  padding: 0.5rem;
  border-radius: var(--radius);
  background: var(--side);
}
.InsightDetails__query .LemonRow {
  padding-left: 0;
  padding-right: 0;
}

.InsightDetails__formula code {
  font-weight: 600;
  margin-left: 0.375rem;
}

.InsightDetails__series {
  margin: -0.125rem 0;
}
.InsightDetails__series:not(:first-child) {
  margin-top: 0.5rem;
}
.InsightDetails__series .LemonDivider {
  width: calc(100% - 1.5rem);
  margin-left: 1.5rem;
}

.InsightDetails__breakdown {
  margin-bottom: 0.5rem;
}
.InsightDetails__breakdown .breakdown-tag {
  font-size: 0.8125rem;
  line-height: 0.8125rem;
  padding: 0.375rem 0.75rem;
}

.InsightDetails__footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.InsightDetails__footer .profile-package {
  vertical-align: middle;
}
.InsightDetails__footer .tz-label {
  line-height: normal;
  vertical-align: middle;
}
.InsightDetails__footer .taxonomic-breakdown-filter.tag-pill {
  font-size: 0.8125rem;
  padding: 0;
  border: none;
  border-radius: 0;
  background: none;
  vertical-align: unset;
}

.SeriesDisplay {
  line-height: 1.5rem;
}

.SeriesDisplay__raw-name {
  display: inline-flex;
  align-items: center;
  padding: 0.125rem 0.25rem;
  margin: 0 0.25rem;
  background: var(--primary-bg-hover);
  border-radius: var(--radius);
  color: var(--primary-alt);
  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 1rem;
}
.SeriesDisplay__raw-name.SeriesDisplay__raw-name--action, .SeriesDisplay__raw-name.SeriesDisplay__raw-name--event {
  padding: 0.25rem;
}
.SeriesDisplay__raw-name.SeriesDisplay__raw-name--action::before, .SeriesDisplay__raw-name.SeriesDisplay__raw-name--event::before {
  display: inline-block;
  flex-shrink: 0;
  text-align: center;
  width: 1rem;
  border-radius: 0.25rem;
  margin-right: 0.25rem;
  background: var(--primary);
  color: var(--bg-light);
  line-height: 1rem;
  font-size: 0.625rem;
  font-weight: 700;
}
.SeriesDisplay__raw-name.SeriesDisplay__raw-name--action::before {
  content: "A";
}
.SeriesDisplay__raw-name.SeriesDisplay__raw-name--event::before {
  content: "E";
}

.SeriesDisplay__condition {
  display: flex;
}

.SeriesDisplay__arrow {
  flex-shrink: 0;
  margin-right: 0.25rem;
  color: var(--border-bold);
  font-size: 1.25rem;
}