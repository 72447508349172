.Settings {
  display: flex;
  gap: 2rem;
  align-items: start;
  margin-top: 1rem;
}
.Settings .Settings__sections {
  flex-shrink: 0;
  position: sticky;
  max-width: 20rem;
  min-width: 14rem;
  width: 20%;
  top: 0.5rem;
}
.posthog-3000 .Settings .Settings__sections {
  top: 4rem;
}
.SidePanel3000 .Settings .Settings__sections {
  top: 0;
}
.Settings--compact {
  gap: 0;
  flex-direction: column;
}
.Settings--compact .Settings__sections {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  position: relative;
}
.posthog-3000 .Settings, .LemonModal .Settings {
  margin-top: 0;
}