.LemonTabs {
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: stretch;
}
.Navigation3000__scene > .LemonTabs:first-child, .Navigation3000__scene > :first-child > .LemonTabs:first-child {
  margin-top: -0.75rem;
}

.LemonTabs__bar {
  position: relative;
  flex-shrink: 0;
  margin-bottom: 1rem;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow-x: auto;
}
.LemonTabs__bar::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background: var(--border);
}
.LemonTabs__bar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.125rem;
  width: var(--lemon-tabs-slider-width);
  transform: translateX(var(--lemon-tabs-slider-offset));
  background: var(--link);
}
.LemonTabs--transitioning .LemonTabs__bar::after {
  transition: width 200ms ease, transform 200ms ease;
}

.LemonTabs--transitioning .LemonTabs__tab {
  transition: color 200ms ease;
}
.LemonTabs__tab:not(:last-child) {
  margin-right: 2rem;
}
.LemonTabs__tab:hover {
  color: var(--link);
}
.LemonTabs__tab:active {
  color: var(--primary-dark);
}
.LemonTabs__tab.LemonTabs__tab--active {
  color: var(--link);
  text-shadow: 0 0 0.25px currentColor;
}
.LemonTabs__tab a {
  text-decoration: none;
  color: inherit;
  transition: none;
}

.LemonTabs__tab-content {
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  cursor: pointer;
}