.LemonTable {
  position: relative;
  width: 100%;
  background: var(--bg-light);
  border-radius: var(--radius);
  border: 1px solid var(--border);
  overflow: hidden;
  flex: 1;
  --row-base-height: 3rem;
  --row-horizontal-padding: 1rem;
}
.LemonTable.LemonTable--with-ribbon {
  --row-ribbon-width: 0.25rem;
}
.LemonTable.LemonTable--with-ribbon .LemonTable__content > table > tbody > tr > :first-child {
  position: relative;
}
.LemonTable.LemonTable--with-ribbon .LemonTable__content > table > tbody > tr > :first-child::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: var(--row-ribbon-width);
  height: 100%;
  background: var(--row-ribbon-color);
}
.LemonTable--xs {
  --row-base-height: 2rem;
}
.LemonTable--xs .LemonTable__content > table > tbody > tr > td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.LemonTable--small {
  --row-base-height: 2.5rem;
}
.LemonTable--embedded {
  border: none;
  border-radius: 0;
  background: none;
}
.LemonTable--borderless-rows tr {
  border: none !important;
}
.LemonTable--stealth {
  border: none;
  border-radius: 0;
  background: none;
}
.LemonTable--stealth .LemonTable__content > table > thead {
  background: none;
  border: none;
}
.LemonTable.LemonTable--inset {
  --row-horizontal-padding: 0.5rem;
}
.LemonTable .PaginationControl {
  height: var(--row-base-height);
  padding: 0.5rem;
  border-top: 1px solid var(--border);
}
.LemonTable .row-name {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
}
.LemonTable .row-name:not(:last-child) {
  margin-bottom: 0.125rem;
}
.LemonTable .row-description {
  display: inline-block;
  max-width: 30rem;
  font-size: 0.75rem;
}

.LemonTable__content > table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.LemonTable__content > table > thead {
  position: relative;
  border-bottom: 1px solid var(--border);
  background: var(--mid);
  font-size: 0.75rem;
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
}
.LemonTable__content > table > thead > tr > th {
  font-weight: 700;
  text-align: left;
}
.LemonTable__content > table > thead > tr.LemonTable__row--grouping {
  --row-base-height: 2.5rem;
}
.LemonTable__content > table > tbody > tr.LemonTable__expansion {
  position: relative;
  background: var(--side);
}
.LemonTable__content > table > tbody > tr.LemonTable__expansion > td {
  padding: 0 !important;
}
.LemonTable__content > table > tbody > tr.LemonTable__row--status-highlighted {
  background: var(--primary-bg-hover);
  color: var(--default);
  font-weight: 600;
}
.LemonTable__content > table > tbody > tr > td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.LemonTable__content > table > thead > tr,
.LemonTable__content > table > tbody > tr {
  height: var(--row-base-height);
}
.LemonTable__content > table > thead > tr:not(:first-child),
.LemonTable__content > table > tbody > tr:not(:first-child) {
  border-top: 1px solid var(--border);
}
.LemonTable__content > table > thead > tr > th,
.LemonTable__content > table > thead > tr > td,
.LemonTable__content > table > tbody > tr > th,
.LemonTable__content > table > tbody > tr > td {
  padding-right: var(--row-horizontal-padding);
  overflow: hidden;
  text-overflow: ellipsis;
}
.LemonTable__content > table > thead > tr > th:first-child,
.LemonTable__content > table > thead > tr > td:first-child,
.LemonTable__content > table > tbody > tr > th:first-child,
.LemonTable__content > table > tbody > tr > td:first-child {
  padding-left: calc(var(--row-horizontal-padding) + var(--row-ribbon-width, 0px));
}
.LemonTable__content > table > thead > tr > th.LemonTable__boundary:not(:first-child),
.LemonTable__content > table > thead > tr > td.LemonTable__boundary:not(:first-child),
.LemonTable__content > table > tbody > tr > th.LemonTable__boundary:not(:first-child),
.LemonTable__content > table > tbody > tr > td.LemonTable__boundary:not(:first-child) {
  padding-left: var(--row-horizontal-padding);
}
.LemonTable__content > table > thead > tr > th.LemonTable__boundary:not(:first-of-type),
.LemonTable__content > table > thead > tr > td.LemonTable__boundary:not(:first-of-type),
.LemonTable__content > table > tbody > tr > th.LemonTable__boundary:not(:first-of-type),
.LemonTable__content > table > tbody > tr > td.LemonTable__boundary:not(:first-of-type) {
  border-left: 1px solid var(--border);
}
.LemonTable__content > table > thead > tr > th.LemonTable__toggle,
.LemonTable__content > table > thead > tr > td.LemonTable__toggle,
.LemonTable__content > table > tbody > tr > th.LemonTable__toggle,
.LemonTable__content > table > tbody > tr > td.LemonTable__toggle {
  padding-right: 0;
}
.LemonTable__content > table > thead > tr > th.LemonTable__toggle + *,
.LemonTable__content > table > thead > tr > td.LemonTable__toggle + *,
.LemonTable__content > table > tbody > tr > th.LemonTable__toggle + *,
.LemonTable__content > table > tbody > tr > td.LemonTable__toggle + * {
  border-left: none !important;
}
.LemonTable__content > table > thead > tr > th[colspan="0"],
.LemonTable__content > table > thead > tr > td[colspan="0"],
.LemonTable__content > table > tbody > tr > th[colspan="0"],
.LemonTable__content > table > tbody > tr > td[colspan="0"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.LemonTable__empty-state {
  color: var(--muted-alt);
}

.LemonTable__overlay {
  transition: opacity 200ms ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--bg-light);
  opacity: 0;
  pointer-events: none;
  z-index: 2;
}
.LemonTable--loading .LemonTable__overlay {
  opacity: 0.5;
  pointer-events: auto;
}

.LemonTable__header {
  cursor: default;
}
.LemonTable__header.LemonTable__header--actionable {
  cursor: pointer;
}

.LemonTable__header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.5;
}

.LemonTable__footer {
  border-top: 1px solid var(--border);
  cursor: default;
}

body:not(.storybook-test-runner) .LemonTable__header--sticky,
body:not(.storybook-test-runner) .LemonTable__cell--sticky {
  position: sticky !important;
  left: 0;
  z-index: 1;
  overflow: visible !important;
}
body:not(.storybook-test-runner) .LemonTable__header--sticky::before,
body:not(.storybook-test-runner) .LemonTable__cell--sticky::before {
  transition: box-shadow 200ms ease;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  clip-path: inset(0 -16px 0 0);
  box-shadow: -16px 0 16px 16px transparent;
}
body:not(.storybook-test-runner) .LemonTable__cell--sticky::before {
  background: var(--bg-light);
}
body:not(.storybook-test-runner) tr.LemonTable__row--status-highlighted .LemonTable__cell--sticky::before {
  background: #e8ecff;
}
body:not(.storybook-test-runner) .LemonTable__header--sticky::before {
  background: var(--mid);
}
body:not(.storybook-test-runner) .scrollable--left .LemonTable__cell--sticky::before,
body:not(.storybook-test-runner) .scrollable--left .LemonTable__header--sticky::before {
  box-shadow: -16px 0 16px 16px rgba(0, 0, 0, 0.25);
}