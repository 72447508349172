.LemonButton {
  --lemon-button-height: 2.5rem;
  position: relative;
  transition: background-color 200ms ease, color 200ms ease, border 200ms ease, opacity 200ms ease, transform 100ms ease;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-start;
  min-height: var(--lemon-button-height);
  padding: 0.25rem 0.75rem;
  gap: 0.5rem;
  background: none;
  border-radius: var(--radius);
  border: none;
  font-size: 0.875rem;
  text-align: left;
  line-height: 1.5rem;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  appearance: none !important;
}
.LemonButton .LemonButton__content {
  flex: 1;
  line-height: initial;
  display: flex;
  align-items: center;
}
.LemonButton[aria-disabled=true]:not(.LemonButton--loading) {
  cursor: not-allowed;
  opacity: var(--opacity-disabled);
}
.LemonButton.LemonButton--loading {
  cursor: default;
}
.LemonButton.LemonButton--full-width {
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  overflow: hidden;
}
.LemonButton.LemonButton--full-width .LemonButton__content {
  overflow: hidden;
}
.LemonButton.LemonButton--centered {
  justify-content: center;
}
.LemonButton.LemonButton--centered .LemonButton__content {
  flex: initial;
  text-align: center;
}
.LemonButton.LemonButton--has-icon {
  padding-left: 0.5rem;
}
.LemonButton.LemonButton--has-side-icon {
  padding-right: 0.5rem;
}
.LemonButton.LemonButton--no-content {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.LemonButton.LemonButton--xsmall {
  --lemon-button-height: 1.5rem;
  padding: 0.125rem 0.375rem;
  gap: 0.25rem;
  font-size: 0.75rem;
}
.LemonButton.LemonButton--xsmall .LemonButton__icon {
  font-size: 0.875rem;
}
.LemonButton.LemonButton--xsmall.LemonButton--has-icon:not(.LemonButton--no-padding), .LemonButton.LemonButton--xsmall.LemonButton--no-content:not(.LemonButton--no-padding) {
  padding-left: 0.25rem;
}
.LemonButton.LemonButton--xsmall.LemonButton--has-side-icon:not(.LemonButton--no-padding), .LemonButton.LemonButton--xsmall.LemonButton--no-content:not(.LemonButton--no-padding) {
  padding-right: 0.25rem;
}
.LemonButton.LemonButton--small, .Breadcrumbs3000 .LemonButton {
  --lemon-button-height: 2rem;
  padding: 0.125rem 0.5rem;
  gap: 0.25rem;
}
.LemonButton.LemonButton--small .LemonButton__icon, .Breadcrumbs3000 .LemonButton .LemonButton__icon {
  font-size: 1.25rem;
}
.LemonButton.LemonButton--small.LemonButton--has-icon:not(.LemonButton--no-padding), .LemonButton.LemonButton--small.LemonButton--no-content:not(.LemonButton--no-padding), .Breadcrumbs3000 .LemonButton.LemonButton--has-icon:not(.LemonButton--no-padding), .Breadcrumbs3000 .LemonButton.LemonButton--no-content:not(.LemonButton--no-padding) {
  padding-left: 0.375rem;
}
.LemonButton.LemonButton--small.LemonButton--has-side-icon:not(.LemonButton--no-padding), .LemonButton.LemonButton--small.LemonButton--no-content:not(.LemonButton--no-padding), .Breadcrumbs3000 .LemonButton.LemonButton--has-side-icon:not(.LemonButton--no-padding), .Breadcrumbs3000 .LemonButton.LemonButton--no-content:not(.LemonButton--no-padding) {
  padding-right: 0.375rem;
}
.LemonButton.LemonButton--large {
  --lemon-button-height: 3.5rem;
  padding: 0.5rem 1rem;
  gap: 0.75rem;
  font-size: 1rem;
}
.LemonButton.LemonButton--large .LemonButton__icon {
  font-size: 1.75rem;
}
.LemonButton.LemonButton--large.LemonButton--has-icon:not(.LemonButton--no-padding), .LemonButton.LemonButton--large.LemonButton--no-content:not(.LemonButton--no-padding) {
  padding-left: 0.75rem;
}
.LemonButton.LemonButton--large.LemonButton--has-side-icon:not(.LemonButton--no-padding), .LemonButton.LemonButton--large.LemonButton--no-content:not(.LemonButton--no-padding) {
  padding-right: 0.75rem;
}
.LemonButton.LemonButton--no-padding {
  min-height: 0;
  height: auto;
  width: auto;
  padding: 0;
  padding-left: 0;
  padding-right: 0;
}
.LemonButton.LemonButton--no-padding.LemonButton--full-width {
  width: 100%;
}
.LemonButton .LemonButton__icon {
  display: flex;
  font-size: 1.5rem;
  flex-shrink: 0;
  transition: color 200ms ease;
  justify-items: center;
}
.LemonButton.LemonButton--status-stealth {
  font-weight: 400;
  color: var(--default);
}
.LemonButton.LemonButton--status-stealth:not([aria-disabled=true]):hover, .LemonButton.LemonButton--status-stealth.LemonButton--active {
  background: var(--primary-highlight);
  color: inherit;
}
.LemonButton.LemonButton--status-stealth.LemonButton--active {
  font-weight: 500;
}
.LemonButton.LemonButton--status-stealth.LemonButton--active.LemonButtonWithSideAction, .LemonButton.LemonButton--status-stealth.LemonButton--active[role=menuitem], .LemonButton.LemonButton--status-stealth.LemonButton--active[aria-haspopup=true] {
  font-weight: 400;
}
.LemonButton.LemonButton--status-stealth .LemonButton__icon {
  color: var(--muted-alt);
}
.LemonButton.LemonButton--status-stealth.LemonButton--secondary {
  background: var(--bg-light);
  border: 1px solid var(--border);
}
.LemonButton.LemonButton--status-stealth.LemonButton--secondary:not([aria-disabled=true]):hover, .LemonButton.LemonButton--status-stealth.LemonButton--secondary.LemonButton--active {
  background: var(--primary-highlight);
  border-color: var(--primary);
}
.LemonButton.LemonButton--status-stealth.LemonButton--secondary:not([aria-disabled=true]):active {
  border-color: var(--primary-dark);
}
.LemonButton.LemonButton--status-primary {
  color: var(--primary, var(--primary));
}
.LemonButton.LemonButton--status-primary:not([aria-disabled=true]):hover, .LemonButton.LemonButton--status-primary.LemonButton--active {
  background: var(--primary-highlight, var(--primary-highlight));
}
.LemonButton.LemonButton--status-primary:not([aria-disabled=true]):active {
  color: var(--primary-dark, var(--primary-dark));
}
.LemonButton.LemonButton--status-primary:not([aria-disabled=true]):active .LemonButton__icon {
  color: var(--primary-dark, var(--primary-dark));
}
.LemonButton.LemonButton--status-primary .LemonButton__icon {
  color: var(--primary);
}
.LemonButton.LemonButton--status-primary.LemonButton--primary {
  color: #fff;
  background: var(--primary);
}
.LemonButton.LemonButton--status-primary.LemonButton--primary .LemonButton__icon {
  color: #fff;
}
.LemonButton.LemonButton--status-primary.LemonButton--primary:not([aria-disabled=true]):hover, .LemonButton.LemonButton--status-primary.LemonButton--primary.LemonButton--active {
  color: #fff;
  background: var(--primary-light, var(--primary));
}
.LemonButton.LemonButton--status-primary.LemonButton--primary:not([aria-disabled=true]):hover .LemonButton__icon, .LemonButton.LemonButton--status-primary.LemonButton--primary.LemonButton--active .LemonButton__icon {
  color: #fff;
}
.LemonButton.LemonButton--status-primary.LemonButton--primary:not([aria-disabled=true]):active {
  background: var(--primary-dark, var(--primary));
  color: #fff;
}
.LemonButton.LemonButton--status-primary.LemonButton--primary:not([aria-disabled=true]):active .LemonButton__icon {
  color: #fff;
}
.LemonButton.LemonButton--status-primary.LemonButton--secondary {
  background: var(--bg-light);
  border: 1px solid var(--border);
}
.LemonButton.LemonButton--status-primary.LemonButton--secondary:not([aria-disabled=true]):hover, .LemonButton.LemonButton--status-primary.LemonButton--secondary.LemonButton--active {
  background: var(--primary-highlight, var(--primary-highlight));
  border-color: var(--primary);
}
.LemonButton.LemonButton--status-primary.LemonButton--secondary:not([aria-disabled=true]):active {
  border-color: var(--primary-dark, var(--status));
}
.LemonButton.LemonButton--status-danger {
  color: var(--danger, var(--primary));
}
.LemonButton.LemonButton--status-danger:not([aria-disabled=true]):hover, .LemonButton.LemonButton--status-danger.LemonButton--active {
  background: var(--danger-highlight, var(--primary-highlight));
}
.LemonButton.LemonButton--status-danger:not([aria-disabled=true]):active {
  color: var(--danger-dark, var(--primary-dark));
}
.LemonButton.LemonButton--status-danger:not([aria-disabled=true]):active .LemonButton__icon {
  color: var(--danger-dark, var(--primary-dark));
}
.LemonButton.LemonButton--status-danger .LemonButton__icon {
  color: var(--danger);
}
.LemonButton.LemonButton--status-danger.LemonButton--primary {
  color: #fff;
  background: var(--danger);
}
.LemonButton.LemonButton--status-danger.LemonButton--primary .LemonButton__icon {
  color: #fff;
}
.LemonButton.LemonButton--status-danger.LemonButton--primary:not([aria-disabled=true]):hover, .LemonButton.LemonButton--status-danger.LemonButton--primary.LemonButton--active {
  color: #fff;
  background: var(--danger-light, var(--danger));
}
.LemonButton.LemonButton--status-danger.LemonButton--primary:not([aria-disabled=true]):hover .LemonButton__icon, .LemonButton.LemonButton--status-danger.LemonButton--primary.LemonButton--active .LemonButton__icon {
  color: #fff;
}
.LemonButton.LemonButton--status-danger.LemonButton--primary:not([aria-disabled=true]):active {
  background: var(--danger-dark, var(--danger));
  color: #fff;
}
.LemonButton.LemonButton--status-danger.LemonButton--primary:not([aria-disabled=true]):active .LemonButton__icon {
  color: #fff;
}
.LemonButton.LemonButton--status-danger.LemonButton--secondary {
  background: var(--bg-light);
  border: 1px solid var(--border);
}
.LemonButton.LemonButton--status-danger.LemonButton--secondary:not([aria-disabled=true]):hover, .LemonButton.LemonButton--status-danger.LemonButton--secondary.LemonButton--active {
  background: var(--danger-highlight, var(--primary-highlight));
  border-color: var(--danger);
}
.LemonButton.LemonButton--status-danger.LemonButton--secondary:not([aria-disabled=true]):active {
  border-color: var(--danger-dark, var(--status));
}
.LemonButton.LemonButton--status-primary-alt {
  color: var(--primary-alt, var(--primary));
}
.LemonButton.LemonButton--status-primary-alt:not([aria-disabled=true]):hover, .LemonButton.LemonButton--status-primary-alt.LemonButton--active {
  background: var(--primary-alt-highlight, var(--primary-highlight));
}
.LemonButton.LemonButton--status-primary-alt:not([aria-disabled=true]):active {
  color: var(--primary-alt-dark, var(--primary-dark));
}
.LemonButton.LemonButton--status-primary-alt:not([aria-disabled=true]):active .LemonButton__icon {
  color: var(--primary-alt-dark, var(--primary-dark));
}
.LemonButton.LemonButton--status-primary-alt .LemonButton__icon {
  color: var(--primary-alt);
}
.LemonButton.LemonButton--status-primary-alt.LemonButton--primary {
  color: #fff;
  background: var(--primary-alt);
}
.LemonButton.LemonButton--status-primary-alt.LemonButton--primary .LemonButton__icon {
  color: #fff;
}
.LemonButton.LemonButton--status-primary-alt.LemonButton--primary:not([aria-disabled=true]):hover, .LemonButton.LemonButton--status-primary-alt.LemonButton--primary.LemonButton--active {
  color: #fff;
  background: var(--primary-alt-light, var(--primary-alt));
}
.LemonButton.LemonButton--status-primary-alt.LemonButton--primary:not([aria-disabled=true]):hover .LemonButton__icon, .LemonButton.LemonButton--status-primary-alt.LemonButton--primary.LemonButton--active .LemonButton__icon {
  color: #fff;
}
.LemonButton.LemonButton--status-primary-alt.LemonButton--primary:not([aria-disabled=true]):active {
  background: var(--primary-alt-dark, var(--primary-alt));
  color: #fff;
}
.LemonButton.LemonButton--status-primary-alt.LemonButton--primary:not([aria-disabled=true]):active .LemonButton__icon {
  color: #fff;
}
.LemonButton.LemonButton--status-primary-alt.LemonButton--secondary {
  background: var(--bg-light);
  border: 1px solid var(--border);
}
.LemonButton.LemonButton--status-primary-alt.LemonButton--secondary:not([aria-disabled=true]):hover, .LemonButton.LemonButton--status-primary-alt.LemonButton--secondary.LemonButton--active {
  background: var(--primary-alt-highlight, var(--primary-highlight));
  border-color: var(--primary-alt);
}
.LemonButton.LemonButton--status-primary-alt.LemonButton--secondary:not([aria-disabled=true]):active {
  border-color: var(--primary-alt-dark, var(--status));
}
.LemonButton.LemonButton--status-muted {
  color: var(--muted, var(--primary));
}
.LemonButton.LemonButton--status-muted:not([aria-disabled=true]):hover, .LemonButton.LemonButton--status-muted.LemonButton--active {
  background: var(--muted-highlight, var(--primary-highlight));
}
.LemonButton.LemonButton--status-muted:not([aria-disabled=true]):active {
  color: var(--muted-dark, var(--primary-dark));
}
.LemonButton.LemonButton--status-muted:not([aria-disabled=true]):active .LemonButton__icon {
  color: var(--muted-dark, var(--primary-dark));
}
.LemonButton.LemonButton--status-muted .LemonButton__icon {
  color: var(--muted);
}
.LemonButton.LemonButton--status-muted.LemonButton--primary {
  color: #fff;
  background: var(--muted);
}
.LemonButton.LemonButton--status-muted.LemonButton--primary .LemonButton__icon {
  color: #fff;
}
.LemonButton.LemonButton--status-muted.LemonButton--primary:not([aria-disabled=true]):hover, .LemonButton.LemonButton--status-muted.LemonButton--primary.LemonButton--active {
  color: #fff;
  background: var(--muted-light, var(--muted));
}
.LemonButton.LemonButton--status-muted.LemonButton--primary:not([aria-disabled=true]):hover .LemonButton__icon, .LemonButton.LemonButton--status-muted.LemonButton--primary.LemonButton--active .LemonButton__icon {
  color: #fff;
}
.LemonButton.LemonButton--status-muted.LemonButton--primary:not([aria-disabled=true]):active {
  background: var(--muted-dark, var(--muted));
  color: #fff;
}
.LemonButton.LemonButton--status-muted.LemonButton--primary:not([aria-disabled=true]):active .LemonButton__icon {
  color: #fff;
}
.LemonButton.LemonButton--status-muted.LemonButton--secondary {
  background: var(--bg-light);
  border: 1px solid var(--border);
}
.LemonButton.LemonButton--status-muted.LemonButton--secondary:not([aria-disabled=true]):hover, .LemonButton.LemonButton--status-muted.LemonButton--secondary.LemonButton--active {
  background: var(--muted-highlight, var(--primary-highlight));
  border-color: var(--muted);
}
.LemonButton.LemonButton--status-muted.LemonButton--secondary:not([aria-disabled=true]):active {
  border-color: var(--muted-dark, var(--status));
}
.ant-tooltip .LemonButton:hover {
  background: rgba(255, 255, 255, 0.15) !important;
}
.ant-tooltip .LemonButton:active {
  background: rgba(255, 255, 255, 0.2) !important;
}
.ant-tooltip .LemonButton .LemonButton__icon {
  color: #fff !important;
}
.posthog-3000 .LemonButton {
  font-size: 0.8125rem;
  border: none !important;
}
.posthog-3000 .LemonButton .LemonButton__icon {
  color: var(--muted);
}
.posthog-3000 .LemonButton.LemonButton--status-primary {
  color: var(--muted);
}
.posthog-3000 .LemonButton.LemonButton--status-stealth {
  color: var(--default);
}
.posthog-3000 .LemonButton.LemonButton--primary {
  color: #fff;
  background: var(--primary-3000);
}
.posthog-3000 .LemonButton.LemonButton--primary:not([aria-disabled=true]):hover, .posthog-3000 .LemonButton.LemonButton--primary.LemonButton--active {
  background: var(--primary-3000-hover);
  color: #fff;
}
.posthog-3000 .LemonButton.LemonButton--primary .LemonButton__icon {
  color: #fff;
}
.posthog-3000 .LemonButton.LemonButton--secondary {
  color: var(--default);
  background: var(--secondary-3000);
}
.posthog-3000 .LemonButton.LemonButton--secondary:not([aria-disabled=true]):hover, .posthog-3000 .LemonButton.LemonButton--secondary.LemonButton--active {
  background: var(--secondary-3000-hover);
  color: var(--default);
}
.posthog-3000 .LemonButton.LemonButton--secondary .LemonButton__icon {
  color: var(--default);
}
.posthog-3000 .LemonButton:not([aria-disabled=true]):hover, .posthog-3000 .LemonButton.LemonButton--active {
  color: var(--default);
  background: var(--border);
}
.posthog-3000 .LemonButton:not([aria-disabled=true]):hover .LemonButton__icon, .posthog-3000 .LemonButton.LemonButton--active .LemonButton__icon {
  color: var(--default);
}
.posthog-3000 .LemonButton:not([aria-disabled=true]):active {
  transform: scale(0.9722222222);
}

.LemonButtonWithSideAction {
  position: relative;
}

.LemonButtonWithSideAction__spacer {
  height: 1.5rem;
  width: 1.5rem;
  box-sizing: content-box;
}
.LemonButtonWithSideAction__spacer.LemonButtonWithSideAction__spacer--divider {
  opacity: 0.17;
  padding-left: 0.375rem;
  border-left: 1px solid currentColor;
}
.LemonButton--small .LemonButtonWithSideAction__spacer {
  margin-left: 0.25rem;
}

.LemonButtonWithSideAction__side-button {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  background: none;
}
.LemonButtonWithSideAction--small .LemonButtonWithSideAction__side-button {
  right: 0.375rem;
}