.correlation-matrix .ant-modal-body {
  display: flex;
  justify-content: center;
}
.correlation-matrix .correlation-table-wrapper table {
  border-radius: var(--radius);
  border: 1px solid var(--border);
  border-collapse: separate;
  border-spacing: 0;
  margin: 0 auto;
}
.correlation-matrix .correlation-table-wrapper table td {
  padding: 0.5rem 1rem;
  border-left: 1px solid var(--border);
  border-top: 1px solid var(--border);
  text-align: center;
}
.correlation-matrix .correlation-table-wrapper table td .percentage {
  font-weight: bold;
  padding-bottom: 0.25rem;
}
.correlation-matrix .correlation-table-wrapper table td:first-child {
  border-left: none;
}
.correlation-matrix .correlation-table-wrapper table .table-title {
  color: var(--muted-alt);
}
.correlation-matrix .correlation-table-wrapper table .table-title td {
  border-top: none;
}
.correlation-matrix .correlation-table-wrapper table thead,
.correlation-matrix .correlation-table-wrapper table .horizontal-header {
  font-weight: bold;
  background-color: var(--antd-table-background-dark);
  text-transform: uppercase;
  color: var(--muted);
}
.correlation-matrix .correlation-table-wrapper table tbody td:not(.horizontal-header) {
  text-align: right;
}