.RetentionTable {
  --retention-table-color: var(--primary);
  font-weight: 500;
  width: 100%;
}
.RetentionTable th {
  white-space: nowrap;
  font-weight: 500;
  border-left: 1px solid var(--border);
  padding: 0 0.5rem;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  text-align: left;
}
.RetentionTable th:first-of-type {
  border-left: none;
}
.RetentionTable tr {
  border-left: 0.25rem solid transparent;
}
.RetentionTable tr:not(:first-of-type) {
  cursor: pointer;
}
.RetentionTable tr:not(:first-of-type):hover {
  border-left-color: var(--retention-table-color);
}
.RetentionTable .RetentionTable__TextTab {
  padding: 0 1rem 0 0.5rem;
  white-space: nowrap;
}
.RetentionTable .RetentionTable__Tab {
  padding: 0.5rem;
  margin: 2px;
  border-radius: 0.25rem;
  text-align: right;
  min-height: 2rem;
  transition: transform 200ms ease-out;
  background-color: var(--retention-table-color);
}
.RetentionTable .RetentionTable__Tab:hover {
  transform: scale(1.05);
}
.RetentionTable .RetentionTable__Tab.RetentionTable__Tab--period {
  background-color: var(--bg-light);
  border: 1px solid var(--retention-table-color);
  opacity: 0.75;
}
.RetentionTable.RetentionTable--non-interactive tr:hover {
  border-left-color: transparent;
}
.RetentionTable.RetentionTable--non-interactive .RetentionTable__Tab {
  cursor: initial;
}
.RetentionTable.RetentionTable--non-interactive .RetentionTable__Tab:hover {
  transform: none;
}