@charset "UTF-8";
/* Only styles that are shared across multiple components (i.e. global) should go here, trying to keep this file
nimble to simplify maintenance. We separate variables and mixins in vars.scss to be able to import those into local
style files without adding already imported styles.

Font weights:
Only 400 (`normal`), 500 (`var(--font-medium)`), 600 (`var(--font-semibold)`), or 700 (`bold`) should be used

*/
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast-body {
  margin: auto 0;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 6px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.Toastify__toast-body > div:last-child {
  -ms-flex: 1;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
  width: 20px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -ms-flexbox;
  display: flex;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  transform-origin: left;
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}

.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  animation: Toastify__spin 0.65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=ReactToastify.css.map */
/* Inter; bold (700); latin */
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../../public/Inter.woff2") format("woff2"), url("../../public/Inter.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../public/Inter.woff2") format("woff2"), url("../../public/Inter.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Inter; normal (400); latin */
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../public/Inter.woff2") format("woff2"), url("../../public/Inter.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.subtitle {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
}
.subtitle.highlighted {
  padding: 1rem 1rem 1rem 0.5rem;
}

.l3 {
  /* Level 3 title (ideally H3) */
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
}

.l4 {
  font-size: 14px;
  font-weight: bold;
}

.col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}

.highlighted {
  border-radius: var(--radius);
  animation: highlight 2000ms ease-out;
}

/*
    PLEASE READ BEFORE MODIFYING THIS FILE

    We don't use Tailwind in PostHog but we do use a lot of helpful Utility classes to make straightforward CSS easy.
    To keep this maintainable we are attempting to emulate as much of Tailwind's API as possible.
    As such, if something is missing here, please go to Tailwind's docs and copy their implementation rather than creating your own
    custom naming conventions 🙌
*/
.space-y-0 > * + * {
  margin-top: 0rem;
}

.space-x-0 > * + * {
  margin-left: 0rem;
}

.gap-0 {
  gap: 0rem;
}

.gap-x-0 {
  column-gap: 0rem;
}

.gap-y-0 {
  row-gap: 0rem;
}

.m-0 {
  margin: 0rem;
}

.-m-0 {
  margin: 0rem;
}

.mx-0 {
  margin-left: 0rem;
  margin-right: 0rem;
}

.-mx-0 {
  margin-left: 0rem;
  margin-right: 0rem;
}

.my-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.-my-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.px-0 {
  padding-left: 0rem;
  padding-right: 0rem;
}

.py-0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.p-0 {
  padding: 0rem;
}

.inset-0 {
  inset: 0rem 0rem 0rem 0rem;
}

.inset-x-0 {
  left: 0rem;
  right: 0rem;
}

.inset-y-0 {
  top: 0rem;
  bottom: 0rem;
}

.top-0 {
  top: 0rem;
}

.bottom-0 {
  bottom: 0rem;
}

.right-0 {
  right: 0rem;
}

.left-0 {
  left: 0rem;
}

.mt-0 {
  margin-top: 0rem;
}

.-mt-0 {
  margin-top: 0rem;
}

.pt-0 {
  padding-top: 0rem;
}

.mr-0 {
  margin-right: 0rem;
}

.-mr-0 {
  margin-right: 0rem;
}

.pr-0 {
  padding-right: 0rem;
}

.mb-0 {
  margin-bottom: 0rem;
}

.-mb-0 {
  margin-bottom: 0rem;
}

.pb-0 {
  padding-bottom: 0rem;
}

.ml-0 {
  margin-left: 0rem;
}

.-ml-0 {
  margin-left: 0rem;
}

.pl-0 {
  padding-left: 0rem;
}

.space-y-0\.5 > * + * {
  margin-top: 0.125rem;
}

.space-x-0\.5 > * + * {
  margin-left: 0.125rem;
}

.gap-0\.5 {
  gap: 0.125rem;
}

.gap-x-0\.5 {
  column-gap: 0.125rem;
}

.gap-y-0\.5 {
  row-gap: 0.125rem;
}

.m-0\.5 {
  margin: 0.125rem;
}

.-m-0\.5 {
  margin: -0.125rem;
}

.mx-0\.5 {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.-mx-0\.5 {
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}

.my-0\.5 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}

.-my-0\.5 {
  margin-top: -0.125rem;
  margin-bottom: -0.125rem;
}

.px-0\.5 {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.p-0\.5 {
  padding: 0.125rem;
}

.inset-0\.5 {
  inset: 0.125rem 0.125rem 0.125rem 0.125rem;
}

.inset-x-0\.5 {
  left: 0.125rem;
  right: 0.125rem;
}

.inset-y-0\.5 {
  top: 0.125rem;
  bottom: 0.125rem;
}

.top-0\.5 {
  top: 0.125rem;
}

.bottom-0\.5 {
  bottom: 0.125rem;
}

.right-0\.5 {
  right: 0.125rem;
}

.left-0\.5 {
  left: 0.125rem;
}

.mt-0\.5 {
  margin-top: 0.125rem;
}

.-mt-0\.5 {
  margin-top: -0.125rem;
}

.pt-0\.5 {
  padding-top: 0.125rem;
}

.mr-0\.5 {
  margin-right: 0.125rem;
}

.-mr-0\.5 {
  margin-right: -0.125rem;
}

.pr-0\.5 {
  padding-right: 0.125rem;
}

.mb-0\.5 {
  margin-bottom: 0.125rem;
}

.-mb-0\.5 {
  margin-bottom: -0.125rem;
}

.pb-0\.5 {
  padding-bottom: 0.125rem;
}

.ml-0\.5 {
  margin-left: 0.125rem;
}

.-ml-0\.5 {
  margin-left: -0.125rem;
}

.pl-0\.5 {
  padding-left: 0.125rem;
}

.space-y-1 > * + * {
  margin-top: 0.25rem;
}

.space-x-1 > * + * {
  margin-left: 0.25rem;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-x-1 {
  column-gap: 0.25rem;
}

.gap-y-1 {
  row-gap: 0.25rem;
}

.m-1 {
  margin: 0.25rem;
}

.-m-1 {
  margin: -0.25rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.-my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.p-1 {
  padding: 0.25rem;
}

.inset-1 {
  inset: 0.25rem 0.25rem 0.25rem 0.25rem;
}

.inset-x-1 {
  left: 0.25rem;
  right: 0.25rem;
}

.inset-y-1 {
  top: 0.25rem;
  bottom: 0.25rem;
}

.top-1 {
  top: 0.25rem;
}

.bottom-1 {
  bottom: 0.25rem;
}

.right-1 {
  right: 0.25rem;
}

.left-1 {
  left: 0.25rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.-mt-1 {
  margin-top: -0.25rem;
}

.pt-1 {
  padding-top: 0.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.-mr-1 {
  margin-right: -0.25rem;
}

.pr-1 {
  padding-right: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.-mb-1 {
  margin-bottom: -0.25rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.-ml-1 {
  margin-left: -0.25rem;
}

.pl-1 {
  padding-left: 0.25rem;
}

.space-y-1\.5 > * + * {
  margin-top: 0.375rem;
}

.space-x-1\.5 > * + * {
  margin-left: 0.375rem;
}

.gap-1\.5 {
  gap: 0.375rem;
}

.gap-x-1\.5 {
  column-gap: 0.375rem;
}

.gap-y-1\.5 {
  row-gap: 0.375rem;
}

.m-1\.5 {
  margin: 0.375rem;
}

.-m-1\.5 {
  margin: -0.375rem;
}

.mx-1\.5 {
  margin-left: 0.375rem;
  margin-right: 0.375rem;
}

.-mx-1\.5 {
  margin-left: -0.375rem;
  margin-right: -0.375rem;
}

.my-1\.5 {
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}

.-my-1\.5 {
  margin-top: -0.375rem;
  margin-bottom: -0.375rem;
}

.px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.p-1\.5 {
  padding: 0.375rem;
}

.inset-1\.5 {
  inset: 0.375rem 0.375rem 0.375rem 0.375rem;
}

.inset-x-1\.5 {
  left: 0.375rem;
  right: 0.375rem;
}

.inset-y-1\.5 {
  top: 0.375rem;
  bottom: 0.375rem;
}

.top-1\.5 {
  top: 0.375rem;
}

.bottom-1\.5 {
  bottom: 0.375rem;
}

.right-1\.5 {
  right: 0.375rem;
}

.left-1\.5 {
  left: 0.375rem;
}

.mt-1\.5 {
  margin-top: 0.375rem;
}

.-mt-1\.5 {
  margin-top: -0.375rem;
}

.pt-1\.5 {
  padding-top: 0.375rem;
}

.mr-1\.5 {
  margin-right: 0.375rem;
}

.-mr-1\.5 {
  margin-right: -0.375rem;
}

.pr-1\.5 {
  padding-right: 0.375rem;
}

.mb-1\.5 {
  margin-bottom: 0.375rem;
}

.-mb-1\.5 {
  margin-bottom: -0.375rem;
}

.pb-1\.5 {
  padding-bottom: 0.375rem;
}

.ml-1\.5 {
  margin-left: 0.375rem;
}

.-ml-1\.5 {
  margin-left: -0.375rem;
}

.pl-1\.5 {
  padding-left: 0.375rem;
}

.space-y-2 > * + * {
  margin-top: 0.5rem;
}

.space-x-2 > * + * {
  margin-left: 0.5rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-x-2 {
  column-gap: 0.5rem;
}

.gap-y-2 {
  row-gap: 0.5rem;
}

.m-2 {
  margin: 0.5rem;
}

.-m-2 {
  margin: -0.5rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.-my-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.p-2 {
  padding: 0.5rem;
}

.inset-2 {
  inset: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.inset-x-2 {
  left: 0.5rem;
  right: 0.5rem;
}

.inset-y-2 {
  top: 0.5rem;
  bottom: 0.5rem;
}

.top-2 {
  top: 0.5rem;
}

.bottom-2 {
  bottom: 0.5rem;
}

.right-2 {
  right: 0.5rem;
}

.left-2 {
  left: 0.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.-mt-2 {
  margin-top: -0.5rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.-mr-2 {
  margin-right: -0.5rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.-mb-2 {
  margin-bottom: -0.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.-ml-2 {
  margin-left: -0.5rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.space-y-2\.5 > * + * {
  margin-top: 0.625rem;
}

.space-x-2\.5 > * + * {
  margin-left: 0.625rem;
}

.gap-2\.5 {
  gap: 0.625rem;
}

.gap-x-2\.5 {
  column-gap: 0.625rem;
}

.gap-y-2\.5 {
  row-gap: 0.625rem;
}

.m-2\.5 {
  margin: 0.625rem;
}

.-m-2\.5 {
  margin: -0.625rem;
}

.mx-2\.5 {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.-mx-2\.5 {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}

.my-2\.5 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.-my-2\.5 {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}

.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.p-2\.5 {
  padding: 0.625rem;
}

.inset-2\.5 {
  inset: 0.625rem 0.625rem 0.625rem 0.625rem;
}

.inset-x-2\.5 {
  left: 0.625rem;
  right: 0.625rem;
}

.inset-y-2\.5 {
  top: 0.625rem;
  bottom: 0.625rem;
}

.top-2\.5 {
  top: 0.625rem;
}

.bottom-2\.5 {
  bottom: 0.625rem;
}

.right-2\.5 {
  right: 0.625rem;
}

.left-2\.5 {
  left: 0.625rem;
}

.mt-2\.5 {
  margin-top: 0.625rem;
}

.-mt-2\.5 {
  margin-top: -0.625rem;
}

.pt-2\.5 {
  padding-top: 0.625rem;
}

.mr-2\.5 {
  margin-right: 0.625rem;
}

.-mr-2\.5 {
  margin-right: -0.625rem;
}

.pr-2\.5 {
  padding-right: 0.625rem;
}

.mb-2\.5 {
  margin-bottom: 0.625rem;
}

.-mb-2\.5 {
  margin-bottom: -0.625rem;
}

.pb-2\.5 {
  padding-bottom: 0.625rem;
}

.ml-2\.5 {
  margin-left: 0.625rem;
}

.-ml-2\.5 {
  margin-left: -0.625rem;
}

.pl-2\.5 {
  padding-left: 0.625rem;
}

.space-y-3 > * + * {
  margin-top: 0.75rem;
}

.space-x-3 > * + * {
  margin-left: 0.75rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-x-3 {
  column-gap: 0.75rem;
}

.gap-y-3 {
  row-gap: 0.75rem;
}

.m-3 {
  margin: 0.75rem;
}

.-m-3 {
  margin: -0.75rem;
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.-my-3 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.p-3 {
  padding: 0.75rem;
}

.inset-3 {
  inset: 0.75rem 0.75rem 0.75rem 0.75rem;
}

.inset-x-3 {
  left: 0.75rem;
  right: 0.75rem;
}

.inset-y-3 {
  top: 0.75rem;
  bottom: 0.75rem;
}

.top-3 {
  top: 0.75rem;
}

.bottom-3 {
  bottom: 0.75rem;
}

.right-3 {
  right: 0.75rem;
}

.left-3 {
  left: 0.75rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.-mt-3 {
  margin-top: -0.75rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.-mr-3 {
  margin-right: -0.75rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.-mb-3 {
  margin-bottom: -0.75rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.-ml-3 {
  margin-left: -0.75rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.space-y-3\.5 > * + * {
  margin-top: 0.875rem;
}

.space-x-3\.5 > * + * {
  margin-left: 0.875rem;
}

.gap-3\.5 {
  gap: 0.875rem;
}

.gap-x-3\.5 {
  column-gap: 0.875rem;
}

.gap-y-3\.5 {
  row-gap: 0.875rem;
}

.m-3\.5 {
  margin: 0.875rem;
}

.-m-3\.5 {
  margin: -0.875rem;
}

.mx-3\.5 {
  margin-left: 0.875rem;
  margin-right: 0.875rem;
}

.-mx-3\.5 {
  margin-left: -0.875rem;
  margin-right: -0.875rem;
}

.my-3\.5 {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}

.-my-3\.5 {
  margin-top: -0.875rem;
  margin-bottom: -0.875rem;
}

.px-3\.5 {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}

.py-3\.5 {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.p-3\.5 {
  padding: 0.875rem;
}

.inset-3\.5 {
  inset: 0.875rem 0.875rem 0.875rem 0.875rem;
}

.inset-x-3\.5 {
  left: 0.875rem;
  right: 0.875rem;
}

.inset-y-3\.5 {
  top: 0.875rem;
  bottom: 0.875rem;
}

.top-3\.5 {
  top: 0.875rem;
}

.bottom-3\.5 {
  bottom: 0.875rem;
}

.right-3\.5 {
  right: 0.875rem;
}

.left-3\.5 {
  left: 0.875rem;
}

.mt-3\.5 {
  margin-top: 0.875rem;
}

.-mt-3\.5 {
  margin-top: -0.875rem;
}

.pt-3\.5 {
  padding-top: 0.875rem;
}

.mr-3\.5 {
  margin-right: 0.875rem;
}

.-mr-3\.5 {
  margin-right: -0.875rem;
}

.pr-3\.5 {
  padding-right: 0.875rem;
}

.mb-3\.5 {
  margin-bottom: 0.875rem;
}

.-mb-3\.5 {
  margin-bottom: -0.875rem;
}

.pb-3\.5 {
  padding-bottom: 0.875rem;
}

.ml-3\.5 {
  margin-left: 0.875rem;
}

.-ml-3\.5 {
  margin-left: -0.875rem;
}

.pl-3\.5 {
  padding-left: 0.875rem;
}

.space-y-4 > * + * {
  margin-top: 1rem;
}

.space-x-4 > * + * {
  margin-left: 1rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.m-4 {
  margin: 1rem;
}

.-m-4 {
  margin: -1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.p-4 {
  padding: 1rem;
}

.inset-4 {
  inset: 1rem 1rem 1rem 1rem;
}

.inset-x-4 {
  left: 1rem;
  right: 1rem;
}

.inset-y-4 {
  top: 1rem;
  bottom: 1rem;
}

.top-4 {
  top: 1rem;
}

.bottom-4 {
  bottom: 1rem;
}

.right-4 {
  right: 1rem;
}

.left-4 {
  left: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.space-y-5 > * + * {
  margin-top: 1.25rem;
}

.space-x-5 > * + * {
  margin-left: 1.25rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.m-5 {
  margin: 1.25rem;
}

.-m-5 {
  margin: -1.25rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.p-5 {
  padding: 1.25rem;
}

.inset-5 {
  inset: 1.25rem 1.25rem 1.25rem 1.25rem;
}

.inset-x-5 {
  left: 1.25rem;
  right: 1.25rem;
}

.inset-y-5 {
  top: 1.25rem;
  bottom: 1.25rem;
}

.top-5 {
  top: 1.25rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.right-5 {
  right: 1.25rem;
}

.left-5 {
  left: 1.25rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.-mr-5 {
  margin-right: -1.25rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.-mb-5 {
  margin-bottom: -1.25rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.-ml-5 {
  margin-left: -1.25rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.space-y-6 > * + * {
  margin-top: 1.5rem;
}

.space-x-6 > * + * {
  margin-left: 1.5rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.m-6 {
  margin: 1.5rem;
}

.-m-6 {
  margin: -1.5rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.-my-6 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.p-6 {
  padding: 1.5rem;
}

.inset-6 {
  inset: 1.5rem 1.5rem 1.5rem 1.5rem;
}

.inset-x-6 {
  left: 1.5rem;
  right: 1.5rem;
}

.inset-y-6 {
  top: 1.5rem;
  bottom: 1.5rem;
}

.top-6 {
  top: 1.5rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.right-6 {
  right: 1.5rem;
}

.left-6 {
  left: 1.5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.-mr-6 {
  margin-right: -1.5rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.-mb-6 {
  margin-bottom: -1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.-ml-6 {
  margin-left: -1.5rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.space-y-7 > * + * {
  margin-top: 1.75rem;
}

.space-x-7 > * + * {
  margin-left: 1.75rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-x-7 {
  column-gap: 1.75rem;
}

.gap-y-7 {
  row-gap: 1.75rem;
}

.m-7 {
  margin: 1.75rem;
}

.-m-7 {
  margin: -1.75rem;
}

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.-mx-7 {
  margin-left: -1.75rem;
  margin-right: -1.75rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.-my-7 {
  margin-top: -1.75rem;
  margin-bottom: -1.75rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.p-7 {
  padding: 1.75rem;
}

.inset-7 {
  inset: 1.75rem 1.75rem 1.75rem 1.75rem;
}

.inset-x-7 {
  left: 1.75rem;
  right: 1.75rem;
}

.inset-y-7 {
  top: 1.75rem;
  bottom: 1.75rem;
}

.top-7 {
  top: 1.75rem;
}

.bottom-7 {
  bottom: 1.75rem;
}

.right-7 {
  right: 1.75rem;
}

.left-7 {
  left: 1.75rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.-mt-7 {
  margin-top: -1.75rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.mr-7 {
  margin-right: 1.75rem;
}

.-mr-7 {
  margin-right: -1.75rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.-mb-7 {
  margin-bottom: -1.75rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.-ml-7 {
  margin-left: -1.75rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.space-y-8 > * + * {
  margin-top: 2rem;
}

.space-x-8 > * + * {
  margin-left: 2rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.m-8 {
  margin: 2rem;
}

.-m-8 {
  margin: -2rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-my-8 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.p-8 {
  padding: 2rem;
}

.inset-8 {
  inset: 2rem 2rem 2rem 2rem;
}

.inset-x-8 {
  left: 2rem;
  right: 2rem;
}

.inset-y-8 {
  top: 2rem;
  bottom: 2rem;
}

.top-8 {
  top: 2rem;
}

.bottom-8 {
  bottom: 2rem;
}

.right-8 {
  right: 2rem;
}

.left-8 {
  left: 2rem;
}

.mt-8 {
  margin-top: 2rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.pt-8 {
  padding-top: 2rem;
}

.mr-8 {
  margin-right: 2rem;
}

.-mr-8 {
  margin-right: -2rem;
}

.pr-8 {
  padding-right: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.-mb-8 {
  margin-bottom: -2rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.ml-8 {
  margin-left: 2rem;
}

.-ml-8 {
  margin-left: -2rem;
}

.pl-8 {
  padding-left: 2rem;
}

.space-y-9 > * + * {
  margin-top: 2.25rem;
}

.space-x-9 > * + * {
  margin-left: 2.25rem;
}

.gap-9 {
  gap: 2.25rem;
}

.gap-x-9 {
  column-gap: 2.25rem;
}

.gap-y-9 {
  row-gap: 2.25rem;
}

.m-9 {
  margin: 2.25rem;
}

.-m-9 {
  margin: -2.25rem;
}

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.-mx-9 {
  margin-left: -2.25rem;
  margin-right: -2.25rem;
}

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.-my-9 {
  margin-top: -2.25rem;
  margin-bottom: -2.25rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.p-9 {
  padding: 2.25rem;
}

.inset-9 {
  inset: 2.25rem 2.25rem 2.25rem 2.25rem;
}

.inset-x-9 {
  left: 2.25rem;
  right: 2.25rem;
}

.inset-y-9 {
  top: 2.25rem;
  bottom: 2.25rem;
}

.top-9 {
  top: 2.25rem;
}

.bottom-9 {
  bottom: 2.25rem;
}

.right-9 {
  right: 2.25rem;
}

.left-9 {
  left: 2.25rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.-mt-9 {
  margin-top: -2.25rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.mr-9 {
  margin-right: 2.25rem;
}

.-mr-9 {
  margin-right: -2.25rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.-mb-9 {
  margin-bottom: -2.25rem;
}

.pb-9 {
  padding-bottom: 2.25rem;
}

.ml-9 {
  margin-left: 2.25rem;
}

.-ml-9 {
  margin-left: -2.25rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.space-y-10 > * + * {
  margin-top: 2.5rem;
}

.space-x-10 > * + * {
  margin-left: 2.5rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.m-10 {
  margin: 2.5rem;
}

.-m-10 {
  margin: -2.5rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.-mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.-my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.p-10 {
  padding: 2.5rem;
}

.inset-10 {
  inset: 2.5rem 2.5rem 2.5rem 2.5rem;
}

.inset-x-10 {
  left: 2.5rem;
  right: 2.5rem;
}

.inset-y-10 {
  top: 2.5rem;
  bottom: 2.5rem;
}

.top-10 {
  top: 2.5rem;
}

.bottom-10 {
  bottom: 2.5rem;
}

.right-10 {
  right: 2.5rem;
}

.left-10 {
  left: 2.5rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.-mr-10 {
  margin-right: -2.5rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.-mb-10 {
  margin-bottom: -2.5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.-ml-10 {
  margin-left: -2.5rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.space-y-11 > * + * {
  margin-top: 2.75rem;
}

.space-x-11 > * + * {
  margin-left: 2.75rem;
}

.gap-11 {
  gap: 2.75rem;
}

.gap-x-11 {
  column-gap: 2.75rem;
}

.gap-y-11 {
  row-gap: 2.75rem;
}

.m-11 {
  margin: 2.75rem;
}

.-m-11 {
  margin: -2.75rem;
}

.mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem;
}

.-mx-11 {
  margin-left: -2.75rem;
  margin-right: -2.75rem;
}

.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}

.-my-11 {
  margin-top: -2.75rem;
  margin-bottom: -2.75rem;
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.p-11 {
  padding: 2.75rem;
}

.inset-11 {
  inset: 2.75rem 2.75rem 2.75rem 2.75rem;
}

.inset-x-11 {
  left: 2.75rem;
  right: 2.75rem;
}

.inset-y-11 {
  top: 2.75rem;
  bottom: 2.75rem;
}

.top-11 {
  top: 2.75rem;
}

.bottom-11 {
  bottom: 2.75rem;
}

.right-11 {
  right: 2.75rem;
}

.left-11 {
  left: 2.75rem;
}

.mt-11 {
  margin-top: 2.75rem;
}

.-mt-11 {
  margin-top: -2.75rem;
}

.pt-11 {
  padding-top: 2.75rem;
}

.mr-11 {
  margin-right: 2.75rem;
}

.-mr-11 {
  margin-right: -2.75rem;
}

.pr-11 {
  padding-right: 2.75rem;
}

.mb-11 {
  margin-bottom: 2.75rem;
}

.-mb-11 {
  margin-bottom: -2.75rem;
}

.pb-11 {
  padding-bottom: 2.75rem;
}

.ml-11 {
  margin-left: 2.75rem;
}

.-ml-11 {
  margin-left: -2.75rem;
}

.pl-11 {
  padding-left: 2.75rem;
}

.space-y-12 > * + * {
  margin-top: 3rem;
}

.space-x-12 > * + * {
  margin-left: 3rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.m-12 {
  margin: 3rem;
}

.-m-12 {
  margin: -3rem;
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.-mx-12 {
  margin-left: -3rem;
  margin-right: -3rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.-my-12 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.p-12 {
  padding: 3rem;
}

.inset-12 {
  inset: 3rem 3rem 3rem 3rem;
}

.inset-x-12 {
  left: 3rem;
  right: 3rem;
}

.inset-y-12 {
  top: 3rem;
  bottom: 3rem;
}

.top-12 {
  top: 3rem;
}

.bottom-12 {
  bottom: 3rem;
}

.right-12 {
  right: 3rem;
}

.left-12 {
  left: 3rem;
}

.mt-12 {
  margin-top: 3rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.pt-12 {
  padding-top: 3rem;
}

.mr-12 {
  margin-right: 3rem;
}

.-mr-12 {
  margin-right: -3rem;
}

.pr-12 {
  padding-right: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.-mb-12 {
  margin-bottom: -3rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.ml-12 {
  margin-left: 3rem;
}

.-ml-12 {
  margin-left: -3rem;
}

.pl-12 {
  padding-left: 3rem;
}

.space-y-13 > * + * {
  margin-top: 3.25rem;
}

.space-x-13 > * + * {
  margin-left: 3.25rem;
}

.gap-13 {
  gap: 3.25rem;
}

.gap-x-13 {
  column-gap: 3.25rem;
}

.gap-y-13 {
  row-gap: 3.25rem;
}

.m-13 {
  margin: 3.25rem;
}

.-m-13 {
  margin: -3.25rem;
}

.mx-13 {
  margin-left: 3.25rem;
  margin-right: 3.25rem;
}

.-mx-13 {
  margin-left: -3.25rem;
  margin-right: -3.25rem;
}

.my-13 {
  margin-top: 3.25rem;
  margin-bottom: 3.25rem;
}

.-my-13 {
  margin-top: -3.25rem;
  margin-bottom: -3.25rem;
}

.px-13 {
  padding-left: 3.25rem;
  padding-right: 3.25rem;
}

.py-13 {
  padding-top: 3.25rem;
  padding-bottom: 3.25rem;
}

.p-13 {
  padding: 3.25rem;
}

.inset-13 {
  inset: 3.25rem 3.25rem 3.25rem 3.25rem;
}

.inset-x-13 {
  left: 3.25rem;
  right: 3.25rem;
}

.inset-y-13 {
  top: 3.25rem;
  bottom: 3.25rem;
}

.top-13 {
  top: 3.25rem;
}

.bottom-13 {
  bottom: 3.25rem;
}

.right-13 {
  right: 3.25rem;
}

.left-13 {
  left: 3.25rem;
}

.mt-13 {
  margin-top: 3.25rem;
}

.-mt-13 {
  margin-top: -3.25rem;
}

.pt-13 {
  padding-top: 3.25rem;
}

.mr-13 {
  margin-right: 3.25rem;
}

.-mr-13 {
  margin-right: -3.25rem;
}

.pr-13 {
  padding-right: 3.25rem;
}

.mb-13 {
  margin-bottom: 3.25rem;
}

.-mb-13 {
  margin-bottom: -3.25rem;
}

.pb-13 {
  padding-bottom: 3.25rem;
}

.ml-13 {
  margin-left: 3.25rem;
}

.-ml-13 {
  margin-left: -3.25rem;
}

.pl-13 {
  padding-left: 3.25rem;
}

.space-y-14 > * + * {
  margin-top: 3.5rem;
}

.space-x-14 > * + * {
  margin-left: 3.5rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-x-14 {
  column-gap: 3.5rem;
}

.gap-y-14 {
  row-gap: 3.5rem;
}

.m-14 {
  margin: 3.5rem;
}

.-m-14 {
  margin: -3.5rem;
}

.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.-mx-14 {
  margin-left: -3.5rem;
  margin-right: -3.5rem;
}

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.-my-14 {
  margin-top: -3.5rem;
  margin-bottom: -3.5rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.p-14 {
  padding: 3.5rem;
}

.inset-14 {
  inset: 3.5rem 3.5rem 3.5rem 3.5rem;
}

.inset-x-14 {
  left: 3.5rem;
  right: 3.5rem;
}

.inset-y-14 {
  top: 3.5rem;
  bottom: 3.5rem;
}

.top-14 {
  top: 3.5rem;
}

.bottom-14 {
  bottom: 3.5rem;
}

.right-14 {
  right: 3.5rem;
}

.left-14 {
  left: 3.5rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.-mt-14 {
  margin-top: -3.5rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.mr-14 {
  margin-right: 3.5rem;
}

.-mr-14 {
  margin-right: -3.5rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.-mb-14 {
  margin-bottom: -3.5rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.ml-14 {
  margin-left: 3.5rem;
}

.-ml-14 {
  margin-left: -3.5rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.space-y-15 > * + * {
  margin-top: 3.75rem;
}

.space-x-15 > * + * {
  margin-left: 3.75rem;
}

.gap-15 {
  gap: 3.75rem;
}

.gap-x-15 {
  column-gap: 3.75rem;
}

.gap-y-15 {
  row-gap: 3.75rem;
}

.m-15 {
  margin: 3.75rem;
}

.-m-15 {
  margin: -3.75rem;
}

.mx-15 {
  margin-left: 3.75rem;
  margin-right: 3.75rem;
}

.-mx-15 {
  margin-left: -3.75rem;
  margin-right: -3.75rem;
}

.my-15 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}

.-my-15 {
  margin-top: -3.75rem;
  margin-bottom: -3.75rem;
}

.px-15 {
  padding-left: 3.75rem;
  padding-right: 3.75rem;
}

.py-15 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

.p-15 {
  padding: 3.75rem;
}

.inset-15 {
  inset: 3.75rem 3.75rem 3.75rem 3.75rem;
}

.inset-x-15 {
  left: 3.75rem;
  right: 3.75rem;
}

.inset-y-15 {
  top: 3.75rem;
  bottom: 3.75rem;
}

.top-15 {
  top: 3.75rem;
}

.bottom-15 {
  bottom: 3.75rem;
}

.right-15 {
  right: 3.75rem;
}

.left-15 {
  left: 3.75rem;
}

.mt-15 {
  margin-top: 3.75rem;
}

.-mt-15 {
  margin-top: -3.75rem;
}

.pt-15 {
  padding-top: 3.75rem;
}

.mr-15 {
  margin-right: 3.75rem;
}

.-mr-15 {
  margin-right: -3.75rem;
}

.pr-15 {
  padding-right: 3.75rem;
}

.mb-15 {
  margin-bottom: 3.75rem;
}

.-mb-15 {
  margin-bottom: -3.75rem;
}

.pb-15 {
  padding-bottom: 3.75rem;
}

.ml-15 {
  margin-left: 3.75rem;
}

.-ml-15 {
  margin-left: -3.75rem;
}

.pl-15 {
  padding-left: 3.75rem;
}

.space-y-16 > * + * {
  margin-top: 4rem;
}

.space-x-16 > * + * {
  margin-left: 4rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-x-16 {
  column-gap: 4rem;
}

.gap-y-16 {
  row-gap: 4rem;
}

.m-16 {
  margin: 4rem;
}

.-m-16 {
  margin: -4rem;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.-mx-16 {
  margin-left: -4rem;
  margin-right: -4rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.-my-16 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.p-16 {
  padding: 4rem;
}

.inset-16 {
  inset: 4rem 4rem 4rem 4rem;
}

.inset-x-16 {
  left: 4rem;
  right: 4rem;
}

.inset-y-16 {
  top: 4rem;
  bottom: 4rem;
}

.top-16 {
  top: 4rem;
}

.bottom-16 {
  bottom: 4rem;
}

.right-16 {
  right: 4rem;
}

.left-16 {
  left: 4rem;
}

.mt-16 {
  margin-top: 4rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.pt-16 {
  padding-top: 4rem;
}

.mr-16 {
  margin-right: 4rem;
}

.-mr-16 {
  margin-right: -4rem;
}

.pr-16 {
  padding-right: 4rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.-mb-16 {
  margin-bottom: -4rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.ml-16 {
  margin-left: 4rem;
}

.-ml-16 {
  margin-left: -4rem;
}

.pl-16 {
  padding-left: 4rem;
}

.space-y-17 > * + * {
  margin-top: 4.25rem;
}

.space-x-17 > * + * {
  margin-left: 4.25rem;
}

.gap-17 {
  gap: 4.25rem;
}

.gap-x-17 {
  column-gap: 4.25rem;
}

.gap-y-17 {
  row-gap: 4.25rem;
}

.m-17 {
  margin: 4.25rem;
}

.-m-17 {
  margin: -4.25rem;
}

.mx-17 {
  margin-left: 4.25rem;
  margin-right: 4.25rem;
}

.-mx-17 {
  margin-left: -4.25rem;
  margin-right: -4.25rem;
}

.my-17 {
  margin-top: 4.25rem;
  margin-bottom: 4.25rem;
}

.-my-17 {
  margin-top: -4.25rem;
  margin-bottom: -4.25rem;
}

.px-17 {
  padding-left: 4.25rem;
  padding-right: 4.25rem;
}

.py-17 {
  padding-top: 4.25rem;
  padding-bottom: 4.25rem;
}

.p-17 {
  padding: 4.25rem;
}

.inset-17 {
  inset: 4.25rem 4.25rem 4.25rem 4.25rem;
}

.inset-x-17 {
  left: 4.25rem;
  right: 4.25rem;
}

.inset-y-17 {
  top: 4.25rem;
  bottom: 4.25rem;
}

.top-17 {
  top: 4.25rem;
}

.bottom-17 {
  bottom: 4.25rem;
}

.right-17 {
  right: 4.25rem;
}

.left-17 {
  left: 4.25rem;
}

.mt-17 {
  margin-top: 4.25rem;
}

.-mt-17 {
  margin-top: -4.25rem;
}

.pt-17 {
  padding-top: 4.25rem;
}

.mr-17 {
  margin-right: 4.25rem;
}

.-mr-17 {
  margin-right: -4.25rem;
}

.pr-17 {
  padding-right: 4.25rem;
}

.mb-17 {
  margin-bottom: 4.25rem;
}

.-mb-17 {
  margin-bottom: -4.25rem;
}

.pb-17 {
  padding-bottom: 4.25rem;
}

.ml-17 {
  margin-left: 4.25rem;
}

.-ml-17 {
  margin-left: -4.25rem;
}

.pl-17 {
  padding-left: 4.25rem;
}

.space-y-18 > * + * {
  margin-top: 4.5rem;
}

.space-x-18 > * + * {
  margin-left: 4.5rem;
}

.gap-18 {
  gap: 4.5rem;
}

.gap-x-18 {
  column-gap: 4.5rem;
}

.gap-y-18 {
  row-gap: 4.5rem;
}

.m-18 {
  margin: 4.5rem;
}

.-m-18 {
  margin: -4.5rem;
}

.mx-18 {
  margin-left: 4.5rem;
  margin-right: 4.5rem;
}

.-mx-18 {
  margin-left: -4.5rem;
  margin-right: -4.5rem;
}

.my-18 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}

.-my-18 {
  margin-top: -4.5rem;
  margin-bottom: -4.5rem;
}

.px-18 {
  padding-left: 4.5rem;
  padding-right: 4.5rem;
}

.py-18 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.p-18 {
  padding: 4.5rem;
}

.inset-18 {
  inset: 4.5rem 4.5rem 4.5rem 4.5rem;
}

.inset-x-18 {
  left: 4.5rem;
  right: 4.5rem;
}

.inset-y-18 {
  top: 4.5rem;
  bottom: 4.5rem;
}

.top-18 {
  top: 4.5rem;
}

.bottom-18 {
  bottom: 4.5rem;
}

.right-18 {
  right: 4.5rem;
}

.left-18 {
  left: 4.5rem;
}

.mt-18 {
  margin-top: 4.5rem;
}

.-mt-18 {
  margin-top: -4.5rem;
}

.pt-18 {
  padding-top: 4.5rem;
}

.mr-18 {
  margin-right: 4.5rem;
}

.-mr-18 {
  margin-right: -4.5rem;
}

.pr-18 {
  padding-right: 4.5rem;
}

.mb-18 {
  margin-bottom: 4.5rem;
}

.-mb-18 {
  margin-bottom: -4.5rem;
}

.pb-18 {
  padding-bottom: 4.5rem;
}

.ml-18 {
  margin-left: 4.5rem;
}

.-ml-18 {
  margin-left: -4.5rem;
}

.pl-18 {
  padding-left: 4.5rem;
}

.space-y-19 > * + * {
  margin-top: 4.75rem;
}

.space-x-19 > * + * {
  margin-left: 4.75rem;
}

.gap-19 {
  gap: 4.75rem;
}

.gap-x-19 {
  column-gap: 4.75rem;
}

.gap-y-19 {
  row-gap: 4.75rem;
}

.m-19 {
  margin: 4.75rem;
}

.-m-19 {
  margin: -4.75rem;
}

.mx-19 {
  margin-left: 4.75rem;
  margin-right: 4.75rem;
}

.-mx-19 {
  margin-left: -4.75rem;
  margin-right: -4.75rem;
}

.my-19 {
  margin-top: 4.75rem;
  margin-bottom: 4.75rem;
}

.-my-19 {
  margin-top: -4.75rem;
  margin-bottom: -4.75rem;
}

.px-19 {
  padding-left: 4.75rem;
  padding-right: 4.75rem;
}

.py-19 {
  padding-top: 4.75rem;
  padding-bottom: 4.75rem;
}

.p-19 {
  padding: 4.75rem;
}

.inset-19 {
  inset: 4.75rem 4.75rem 4.75rem 4.75rem;
}

.inset-x-19 {
  left: 4.75rem;
  right: 4.75rem;
}

.inset-y-19 {
  top: 4.75rem;
  bottom: 4.75rem;
}

.top-19 {
  top: 4.75rem;
}

.bottom-19 {
  bottom: 4.75rem;
}

.right-19 {
  right: 4.75rem;
}

.left-19 {
  left: 4.75rem;
}

.mt-19 {
  margin-top: 4.75rem;
}

.-mt-19 {
  margin-top: -4.75rem;
}

.pt-19 {
  padding-top: 4.75rem;
}

.mr-19 {
  margin-right: 4.75rem;
}

.-mr-19 {
  margin-right: -4.75rem;
}

.pr-19 {
  padding-right: 4.75rem;
}

.mb-19 {
  margin-bottom: 4.75rem;
}

.-mb-19 {
  margin-bottom: -4.75rem;
}

.pb-19 {
  padding-bottom: 4.75rem;
}

.ml-19 {
  margin-left: 4.75rem;
}

.-ml-19 {
  margin-left: -4.75rem;
}

.pl-19 {
  padding-left: 4.75rem;
}

.space-y-20 > * + * {
  margin-top: 5rem;
}

.space-x-20 > * + * {
  margin-left: 5rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-x-20 {
  column-gap: 5rem;
}

.gap-y-20 {
  row-gap: 5rem;
}

.m-20 {
  margin: 5rem;
}

.-m-20 {
  margin: -5rem;
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.-mx-20 {
  margin-left: -5rem;
  margin-right: -5rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.-my-20 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.p-20 {
  padding: 5rem;
}

.inset-20 {
  inset: 5rem 5rem 5rem 5rem;
}

.inset-x-20 {
  left: 5rem;
  right: 5rem;
}

.inset-y-20 {
  top: 5rem;
  bottom: 5rem;
}

.top-20 {
  top: 5rem;
}

.bottom-20 {
  bottom: 5rem;
}

.right-20 {
  right: 5rem;
}

.left-20 {
  left: 5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.mr-20 {
  margin-right: 5rem;
}

.-mr-20 {
  margin-right: -5rem;
}

.pr-20 {
  padding-right: 5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.-mb-20 {
  margin-bottom: -5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.ml-20 {
  margin-left: 5rem;
}

.-ml-20 {
  margin-left: -5rem;
}

.pl-20 {
  padding-left: 5rem;
}

.w-px {
  width: 1px;
}

.h-px {
  height: 1px;
}

.min-w-px {
  min-width: 1px;
}

.max-w-px {
  max-width: 1px;
}

.min-h-px {
  min-height: 1px;
}

.max-h-px {
  max-height: 1px;
}

.w-sm {
  width: 576px;
}

.h-sm {
  height: 576px;
}

.min-w-sm {
  min-width: 576px;
}

.max-w-sm {
  max-width: 576px;
}

.min-h-sm {
  min-height: 576px;
}

.max-h-sm {
  max-height: 576px;
}

.w-md {
  width: 768px;
}

.h-md {
  height: 768px;
}

.min-w-md {
  min-width: 768px;
}

.max-w-md {
  max-width: 768px;
}

.min-h-md {
  min-height: 768px;
}

.max-h-md {
  max-height: 768px;
}

.w-lg {
  width: 992px;
}

.h-lg {
  height: 992px;
}

.min-w-lg {
  min-width: 992px;
}

.max-w-lg {
  max-width: 992px;
}

.min-h-lg {
  min-height: 992px;
}

.max-h-lg {
  max-height: 992px;
}

.w-xl {
  width: 1200px;
}

.h-xl {
  height: 1200px;
}

.min-w-xl {
  min-width: 1200px;
}

.max-w-xl {
  max-width: 1200px;
}

.min-h-xl {
  min-height: 1200px;
}

.max-h-xl {
  max-height: 1200px;
}

.w-xxl {
  width: 1600px;
}

.h-xxl {
  height: 1600px;
}

.min-w-xxl {
  min-width: 1600px;
}

.max-w-xxl {
  max-width: 1600px;
}

.min-h-xxl {
  min-height: 1600px;
}

.max-h-xxl {
  max-height: 1600px;
}

.w-0 {
  width: 0rem;
}

.h-0 {
  height: 0rem;
}

.max-w-0 {
  max-width: 0rem;
}

.max-h-0 {
  max-height: 0rem;
}

.min-w-0 {
  min-width: 0rem;
}

.min-h-0 {
  min-height: 0rem;
}

.w-0\.5 {
  width: 0.125rem;
}

.h-0\.5 {
  height: 0.125rem;
}

.max-w-0\.5 {
  max-width: 0.125rem;
}

.max-h-0\.5 {
  max-height: 0.125rem;
}

.min-w-0\.5 {
  min-width: 0.125rem;
}

.min-h-0\.5 {
  min-height: 0.125rem;
}

.w-1 {
  width: 0.25rem;
}

.h-1 {
  height: 0.25rem;
}

.max-w-1 {
  max-width: 0.25rem;
}

.max-h-1 {
  max-height: 0.25rem;
}

.min-w-1 {
  min-width: 0.25rem;
}

.min-h-1 {
  min-height: 0.25rem;
}

.w-1\.5 {
  width: 0.375rem;
}

.h-1\.5 {
  height: 0.375rem;
}

.max-w-1\.5 {
  max-width: 0.375rem;
}

.max-h-1\.5 {
  max-height: 0.375rem;
}

.min-w-1\.5 {
  min-width: 0.375rem;
}

.min-h-1\.5 {
  min-height: 0.375rem;
}

.w-2 {
  width: 0.5rem;
}

.h-2 {
  height: 0.5rem;
}

.max-w-2 {
  max-width: 0.5rem;
}

.max-h-2 {
  max-height: 0.5rem;
}

.min-w-2 {
  min-width: 0.5rem;
}

.min-h-2 {
  min-height: 0.5rem;
}

.w-2\.5 {
  width: 0.625rem;
}

.h-2\.5 {
  height: 0.625rem;
}

.max-w-2\.5 {
  max-width: 0.625rem;
}

.max-h-2\.5 {
  max-height: 0.625rem;
}

.min-w-2\.5 {
  min-width: 0.625rem;
}

.min-h-2\.5 {
  min-height: 0.625rem;
}

.w-3 {
  width: 0.75rem;
}

.h-3 {
  height: 0.75rem;
}

.max-w-3 {
  max-width: 0.75rem;
}

.max-h-3 {
  max-height: 0.75rem;
}

.min-w-3 {
  min-width: 0.75rem;
}

.min-h-3 {
  min-height: 0.75rem;
}

.w-3\.5 {
  width: 0.875rem;
}

.h-3\.5 {
  height: 0.875rem;
}

.max-w-3\.5 {
  max-width: 0.875rem;
}

.max-h-3\.5 {
  max-height: 0.875rem;
}

.min-w-3\.5 {
  min-width: 0.875rem;
}

.min-h-3\.5 {
  min-height: 0.875rem;
}

.w-4 {
  width: 1rem;
}

.h-4 {
  height: 1rem;
}

.max-w-4 {
  max-width: 1rem;
}

.max-h-4 {
  max-height: 1rem;
}

.min-w-4 {
  min-width: 1rem;
}

.min-h-4 {
  min-height: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.h-5 {
  height: 1.25rem;
}

.max-w-5 {
  max-width: 1.25rem;
}

.max-h-5 {
  max-height: 1.25rem;
}

.min-w-5 {
  min-width: 1.25rem;
}

.min-h-5 {
  min-height: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.h-6 {
  height: 1.5rem;
}

.max-w-6 {
  max-width: 1.5rem;
}

.max-h-6 {
  max-height: 1.5rem;
}

.min-w-6 {
  min-width: 1.5rem;
}

.min-h-6 {
  min-height: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.h-7 {
  height: 1.75rem;
}

.max-w-7 {
  max-width: 1.75rem;
}

.max-h-7 {
  max-height: 1.75rem;
}

.min-w-7 {
  min-width: 1.75rem;
}

.min-h-7 {
  min-height: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.h-8 {
  height: 2rem;
}

.max-w-8 {
  max-width: 2rem;
}

.max-h-8 {
  max-height: 2rem;
}

.min-w-8 {
  min-width: 2rem;
}

.min-h-8 {
  min-height: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.h-9 {
  height: 2.25rem;
}

.max-w-9 {
  max-width: 2.25rem;
}

.max-h-9 {
  max-height: 2.25rem;
}

.min-w-9 {
  min-width: 2.25rem;
}

.min-h-9 {
  min-height: 2.25rem;
}

.w-10 {
  width: 2.5rem;
}

.h-10 {
  height: 2.5rem;
}

.max-w-10 {
  max-width: 2.5rem;
}

.max-h-10 {
  max-height: 2.5rem;
}

.min-w-10 {
  min-width: 2.5rem;
}

.min-h-10 {
  min-height: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.h-11 {
  height: 2.75rem;
}

.max-w-11 {
  max-width: 2.75rem;
}

.max-h-11 {
  max-height: 2.75rem;
}

.min-w-11 {
  min-width: 2.75rem;
}

.min-h-11 {
  min-height: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.h-12 {
  height: 3rem;
}

.max-w-12 {
  max-width: 3rem;
}

.max-h-12 {
  max-height: 3rem;
}

.min-w-12 {
  min-width: 3rem;
}

.min-h-12 {
  min-height: 3rem;
}

.w-13 {
  width: 3.25rem;
}

.h-13 {
  height: 3.25rem;
}

.max-w-13 {
  max-width: 3.25rem;
}

.max-h-13 {
  max-height: 3.25rem;
}

.min-w-13 {
  min-width: 3.25rem;
}

.min-h-13 {
  min-height: 3.25rem;
}

.w-14 {
  width: 3.5rem;
}

.h-14 {
  height: 3.5rem;
}

.max-w-14 {
  max-width: 3.5rem;
}

.max-h-14 {
  max-height: 3.5rem;
}

.min-w-14 {
  min-width: 3.5rem;
}

.min-h-14 {
  min-height: 3.5rem;
}

.w-15 {
  width: 3.75rem;
}

.h-15 {
  height: 3.75rem;
}

.max-w-15 {
  max-width: 3.75rem;
}

.max-h-15 {
  max-height: 3.75rem;
}

.min-w-15 {
  min-width: 3.75rem;
}

.min-h-15 {
  min-height: 3.75rem;
}

.w-16 {
  width: 4rem;
}

.h-16 {
  height: 4rem;
}

.max-w-16 {
  max-width: 4rem;
}

.max-h-16 {
  max-height: 4rem;
}

.min-w-16 {
  min-width: 4rem;
}

.min-h-16 {
  min-height: 4rem;
}

.w-17 {
  width: 4.25rem;
}

.h-17 {
  height: 4.25rem;
}

.max-w-17 {
  max-width: 4.25rem;
}

.max-h-17 {
  max-height: 4.25rem;
}

.min-w-17 {
  min-width: 4.25rem;
}

.min-h-17 {
  min-height: 4.25rem;
}

.w-18 {
  width: 4.5rem;
}

.h-18 {
  height: 4.5rem;
}

.max-w-18 {
  max-width: 4.5rem;
}

.max-h-18 {
  max-height: 4.5rem;
}

.min-w-18 {
  min-width: 4.5rem;
}

.min-h-18 {
  min-height: 4.5rem;
}

.w-19 {
  width: 4.75rem;
}

.h-19 {
  height: 4.75rem;
}

.max-w-19 {
  max-width: 4.75rem;
}

.max-h-19 {
  max-height: 4.75rem;
}

.min-w-19 {
  min-width: 4.75rem;
}

.min-h-19 {
  min-height: 4.75rem;
}

.w-20 {
  width: 5rem;
}

.h-20 {
  height: 5rem;
}

.max-w-20 {
  max-width: 5rem;
}

.max-h-20 {
  max-height: 5rem;
}

.min-w-20 {
  min-width: 5rem;
}

.min-h-20 {
  min-height: 5rem;
}

.w-24 {
  width: 6rem;
}

.h-24 {
  height: 6rem;
}

.max-w-24 {
  max-width: 6rem;
}

.max-h-24 {
  max-height: 6rem;
}

.min-w-24 {
  min-width: 6rem;
}

.min-h-24 {
  min-height: 6rem;
}

.w-30 {
  width: 7.5rem;
}

.h-30 {
  height: 7.5rem;
}

.max-w-30 {
  max-width: 7.5rem;
}

.max-h-30 {
  max-height: 7.5rem;
}

.min-w-30 {
  min-width: 7.5rem;
}

.min-h-30 {
  min-height: 7.5rem;
}

.w-32 {
  width: 8rem;
}

.h-32 {
  height: 8rem;
}

.max-w-32 {
  max-width: 8rem;
}

.max-h-32 {
  max-height: 8rem;
}

.min-w-32 {
  min-width: 8rem;
}

.min-h-32 {
  min-height: 8rem;
}

.w-40 {
  width: 10rem;
}

.h-40 {
  height: 10rem;
}

.max-w-40 {
  max-width: 10rem;
}

.max-h-40 {
  max-height: 10rem;
}

.min-w-40 {
  min-width: 10rem;
}

.min-h-40 {
  min-height: 10rem;
}

.w-50 {
  width: 12.5rem;
}

.h-50 {
  height: 12.5rem;
}

.max-w-50 {
  max-width: 12.5rem;
}

.max-h-50 {
  max-height: 12.5rem;
}

.min-w-50 {
  min-width: 12.5rem;
}

.min-h-50 {
  min-height: 12.5rem;
}

.w-60 {
  width: 15rem;
}

.h-60 {
  height: 15rem;
}

.max-w-60 {
  max-width: 15rem;
}

.max-h-60 {
  max-height: 15rem;
}

.min-w-60 {
  min-width: 15rem;
}

.min-h-60 {
  min-height: 15rem;
}

.w-80 {
  width: 20rem;
}

.h-80 {
  height: 20rem;
}

.max-w-80 {
  max-width: 20rem;
}

.max-h-80 {
  max-height: 20rem;
}

.min-w-80 {
  min-width: 20rem;
}

.min-h-80 {
  min-height: 20rem;
}

.w-100 {
  width: 25rem;
}

.h-100 {
  height: 25rem;
}

.max-w-100 {
  max-width: 25rem;
}

.max-h-100 {
  max-height: 25rem;
}

.min-w-100 {
  min-width: 25rem;
}

.min-h-100 {
  min-height: 25rem;
}

.w-120 {
  width: 30rem;
}

.h-120 {
  height: 30rem;
}

.max-w-120 {
  max-width: 30rem;
}

.max-h-120 {
  max-height: 30rem;
}

.min-w-120 {
  min-width: 30rem;
}

.min-h-120 {
  min-height: 30rem;
}

.w-140 {
  width: 35rem;
}

.h-140 {
  height: 35rem;
}

.max-w-140 {
  max-width: 35rem;
}

.max-h-140 {
  max-height: 35rem;
}

.min-w-140 {
  min-width: 35rem;
}

.min-h-140 {
  min-height: 35rem;
}

.w-160 {
  width: 40rem;
}

.h-160 {
  height: 40rem;
}

.max-w-160 {
  max-width: 40rem;
}

.max-h-160 {
  max-height: 40rem;
}

.min-w-160 {
  min-width: 40rem;
}

.min-h-160 {
  min-height: 40rem;
}

.w-180 {
  width: 45rem;
}

.h-180 {
  height: 45rem;
}

.max-w-180 {
  max-width: 45rem;
}

.max-h-180 {
  max-height: 45rem;
}

.min-w-180 {
  min-width: 45rem;
}

.min-h-180 {
  min-height: 45rem;
}

.w-200 {
  width: 50rem;
}

.h-200 {
  height: 50rem;
}

.max-w-200 {
  max-width: 50rem;
}

.max-h-200 {
  max-height: 50rem;
}

.min-w-200 {
  min-width: 50rem;
}

.min-h-200 {
  min-height: 50rem;
}

.space-y-px > * + * {
  margin-top: 1px;
}

.space-x-px > * + * {
  margin-left: 1px;
}

.border {
  border-width: 1px;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.m-px {
  margin: 1px;
}

.mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.mt-auto {
  margin-top: auto;
}

.mt-px {
  margin-top: 1px;
}

.mr-auto {
  margin-right: auto;
}

.mr-px {
  margin-right: 1px;
}

.mb-auto {
  margin-bottom: auto;
}

.mb-px {
  margin-bottom: 1px;
}

.ml-auto {
  margin-left: auto;
}

.ml-px {
  margin-left: 1px;
}

.p-auto {
  padding: auto;
}

.px-auto {
  padding-left: auto;
  padding-right: auto;
}

.py-auto {
  padding-top: auto;
  padding-bottom: auto;
}

.p-px {
  padding: 1px;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.pt-auto {
  padding-top: auto;
}

.pt-px {
  padding-top: 1px;
}

.pr-auto {
  padding-right: auto;
}

.pr-px {
  padding-right: 1px;
}

.pb-auto {
  padding-bottom: auto;
}

.pb-px {
  padding-bottom: 1px;
}

.pl-auto {
  padding-left: auto;
}

.pl-px {
  padding-left: 1px;
}

.-mt-px {
  margin-top: -1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-6 {
  border-width: 6px;
}

.border-8 {
  border-width: 8px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-t-6 {
  border-top-width: 6px;
}

.border-t-8 {
  border-top-width: 8px;
}

.border-t {
  border-top-width: 1px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-r-6 {
  border-right-width: 6px;
}

.border-r-8 {
  border-right-width: 8px;
}

.border-r {
  border-right-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-b-6 {
  border-bottom-width: 6px;
}

.border-b-8 {
  border-bottom-width: 8px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l-0 {
  border-left-width: 0;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-l-6 {
  border-left-width: 6px;
}

.border-l-8 {
  border-left-width: 8px;
}

.border-l {
  border-left-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.border-double {
  border-style: double;
}

.border-hidden {
  border-style: hidden;
}

.border-none {
  border-style: none;
}

.underline {
  text-decoration-line: underline;
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.overline {
  text-decoration-line: overline;
}

.hover\:overline:hover {
  text-decoration-line: overline;
}

.line-through {
  text-decoration-line: line-through;
}

.hover\:line-through:hover {
  text-decoration-line: line-through;
}

.no-underline {
  text-decoration-line: no-underline;
}

.hover\:no-underline:hover {
  text-decoration-line: no-underline;
}

.decoration-inherit {
  text-decoration-color: inherit;
}

.decoration-current {
  text-decoration-color: currentColor;
}

.decoration-transparent {
  text-decoration-color: transparent;
}

.text-primary-highlight {
  color: var(--primary-highlight);
}

.bg-primary-highlight {
  background-color: var(--primary-highlight);
}

.border-primary-highlight {
  border-color: var(--primary-highlight);
}

.border-x-primary-highlight {
  border-left-color: var(--primary-highlight);
  border-right-color: var(--primary-highlight);
}

.border-y-primary-highlight {
  border-top-color: var(--primary-highlight);
  border-bottom-color: var(--primary-highlight);
}

.decoration-primary-highlight {
  text-decoration-color: var(--primary-highlight);
}

.text-primary-light {
  color: var(--primary-light);
}

.bg-primary-light {
  background-color: var(--primary-light);
}

.border-primary-light {
  border-color: var(--primary-light);
}

.border-x-primary-light {
  border-left-color: var(--primary-light);
  border-right-color: var(--primary-light);
}

.border-y-primary-light {
  border-top-color: var(--primary-light);
  border-bottom-color: var(--primary-light);
}

.decoration-primary-light {
  text-decoration-color: var(--primary-light);
}

.text-primary {
  color: var(--primary);
}

.bg-primary {
  background-color: var(--primary);
}

.border-primary {
  border-color: var(--primary);
}

.border-x-primary {
  border-left-color: var(--primary);
  border-right-color: var(--primary);
}

.border-y-primary {
  border-top-color: var(--primary);
  border-bottom-color: var(--primary);
}

.decoration-primary {
  text-decoration-color: var(--primary);
}

.text-primary-dark {
  color: var(--primary-dark);
}

.bg-primary-dark {
  background-color: var(--primary-dark);
}

.border-primary-dark {
  border-color: var(--primary-dark);
}

.border-x-primary-dark {
  border-left-color: var(--primary-dark);
  border-right-color: var(--primary-dark);
}

.border-y-primary-dark {
  border-top-color: var(--primary-dark);
  border-bottom-color: var(--primary-dark);
}

.decoration-primary-dark {
  text-decoration-color: var(--primary-dark);
}

.text-danger-highlight {
  color: var(--danger-highlight);
}

.bg-danger-highlight {
  background-color: var(--danger-highlight);
}

.border-danger-highlight {
  border-color: var(--danger-highlight);
}

.border-x-danger-highlight {
  border-left-color: var(--danger-highlight);
  border-right-color: var(--danger-highlight);
}

.border-y-danger-highlight {
  border-top-color: var(--danger-highlight);
  border-bottom-color: var(--danger-highlight);
}

.decoration-danger-highlight {
  text-decoration-color: var(--danger-highlight);
}

.text-danger-lighter {
  color: var(--danger-lighter);
}

.bg-danger-lighter {
  background-color: var(--danger-lighter);
}

.border-danger-lighter {
  border-color: var(--danger-lighter);
}

.border-x-danger-lighter {
  border-left-color: var(--danger-lighter);
  border-right-color: var(--danger-lighter);
}

.border-y-danger-lighter {
  border-top-color: var(--danger-lighter);
  border-bottom-color: var(--danger-lighter);
}

.decoration-danger-lighter {
  text-decoration-color: var(--danger-lighter);
}

.text-danger-light {
  color: var(--danger-light);
}

.bg-danger-light {
  background-color: var(--danger-light);
}

.border-danger-light {
  border-color: var(--danger-light);
}

.border-x-danger-light {
  border-left-color: var(--danger-light);
  border-right-color: var(--danger-light);
}

.border-y-danger-light {
  border-top-color: var(--danger-light);
  border-bottom-color: var(--danger-light);
}

.decoration-danger-light {
  text-decoration-color: var(--danger-light);
}

.text-danger {
  color: var(--danger);
}

.bg-danger {
  background-color: var(--danger);
}

.border-danger {
  border-color: var(--danger);
}

.border-x-danger {
  border-left-color: var(--danger);
  border-right-color: var(--danger);
}

.border-y-danger {
  border-top-color: var(--danger);
  border-bottom-color: var(--danger);
}

.decoration-danger {
  text-decoration-color: var(--danger);
}

.text-danger-dark {
  color: var(--danger-dark);
}

.bg-danger-dark {
  background-color: var(--danger-dark);
}

.border-danger-dark {
  border-color: var(--danger-dark);
}

.border-x-danger-dark {
  border-left-color: var(--danger-dark);
  border-right-color: var(--danger-dark);
}

.border-y-danger-dark {
  border-top-color: var(--danger-dark);
  border-bottom-color: var(--danger-dark);
}

.decoration-danger-dark {
  text-decoration-color: var(--danger-dark);
}

.text-warning-highlight {
  color: var(--warning-highlight);
}

.bg-warning-highlight {
  background-color: var(--warning-highlight);
}

.border-warning-highlight {
  border-color: var(--warning-highlight);
}

.border-x-warning-highlight {
  border-left-color: var(--warning-highlight);
  border-right-color: var(--warning-highlight);
}

.border-y-warning-highlight {
  border-top-color: var(--warning-highlight);
  border-bottom-color: var(--warning-highlight);
}

.decoration-warning-highlight {
  text-decoration-color: var(--warning-highlight);
}

.text-warning-light {
  color: var(--warning-light);
}

.bg-warning-light {
  background-color: var(--warning-light);
}

.border-warning-light {
  border-color: var(--warning-light);
}

.border-x-warning-light {
  border-left-color: var(--warning-light);
  border-right-color: var(--warning-light);
}

.border-y-warning-light {
  border-top-color: var(--warning-light);
  border-bottom-color: var(--warning-light);
}

.decoration-warning-light {
  text-decoration-color: var(--warning-light);
}

.text-warning {
  color: var(--warning);
}

.bg-warning {
  background-color: var(--warning);
}

.border-warning {
  border-color: var(--warning);
}

.border-x-warning {
  border-left-color: var(--warning);
  border-right-color: var(--warning);
}

.border-y-warning {
  border-top-color: var(--warning);
  border-bottom-color: var(--warning);
}

.decoration-warning {
  text-decoration-color: var(--warning);
}

.text-warning-dark {
  color: var(--warning-dark);
}

.bg-warning-dark {
  background-color: var(--warning-dark);
}

.border-warning-dark {
  border-color: var(--warning-dark);
}

.border-x-warning-dark {
  border-left-color: var(--warning-dark);
  border-right-color: var(--warning-dark);
}

.border-y-warning-dark {
  border-top-color: var(--warning-dark);
  border-bottom-color: var(--warning-dark);
}

.decoration-warning-dark {
  text-decoration-color: var(--warning-dark);
}

.text-success-highlight {
  color: var(--success-highlight);
}

.bg-success-highlight {
  background-color: var(--success-highlight);
}

.border-success-highlight {
  border-color: var(--success-highlight);
}

.border-x-success-highlight {
  border-left-color: var(--success-highlight);
  border-right-color: var(--success-highlight);
}

.border-y-success-highlight {
  border-top-color: var(--success-highlight);
  border-bottom-color: var(--success-highlight);
}

.decoration-success-highlight {
  text-decoration-color: var(--success-highlight);
}

.text-success-light {
  color: var(--success-light);
}

.bg-success-light {
  background-color: var(--success-light);
}

.border-success-light {
  border-color: var(--success-light);
}

.border-x-success-light {
  border-left-color: var(--success-light);
  border-right-color: var(--success-light);
}

.border-y-success-light {
  border-top-color: var(--success-light);
  border-bottom-color: var(--success-light);
}

.decoration-success-light {
  text-decoration-color: var(--success-light);
}

.text-success {
  color: var(--success);
}

.bg-success {
  background-color: var(--success);
}

.border-success {
  border-color: var(--success);
}

.border-x-success {
  border-left-color: var(--success);
  border-right-color: var(--success);
}

.border-y-success {
  border-top-color: var(--success);
  border-bottom-color: var(--success);
}

.decoration-success {
  text-decoration-color: var(--success);
}

.text-success-dark {
  color: var(--success-dark);
}

.bg-success-dark {
  background-color: var(--success-dark);
}

.border-success-dark {
  border-color: var(--success-dark);
}

.border-x-success-dark {
  border-left-color: var(--success-dark);
  border-right-color: var(--success-dark);
}

.border-y-success-dark {
  border-top-color: var(--success-dark);
  border-bottom-color: var(--success-dark);
}

.decoration-success-dark {
  text-decoration-color: var(--success-dark);
}

.text-primary-alt-highlight {
  color: var(--primary-alt-highlight);
}

.bg-primary-alt-highlight {
  background-color: var(--primary-alt-highlight);
}

.border-primary-alt-highlight {
  border-color: var(--primary-alt-highlight);
}

.border-x-primary-alt-highlight {
  border-left-color: var(--primary-alt-highlight);
  border-right-color: var(--primary-alt-highlight);
}

.border-y-primary-alt-highlight {
  border-top-color: var(--primary-alt-highlight);
  border-bottom-color: var(--primary-alt-highlight);
}

.decoration-primary-alt-highlight {
  text-decoration-color: var(--primary-alt-highlight);
}

.text-primary-alt {
  color: var(--primary-alt);
}

.bg-primary-alt {
  background-color: var(--primary-alt);
}

.border-primary-alt {
  border-color: var(--primary-alt);
}

.border-x-primary-alt {
  border-left-color: var(--primary-alt);
  border-right-color: var(--primary-alt);
}

.border-y-primary-alt {
  border-top-color: var(--primary-alt);
  border-bottom-color: var(--primary-alt);
}

.decoration-primary-alt {
  text-decoration-color: var(--primary-alt);
}

.text-primary-alt-dark {
  color: var(--primary-alt-dark);
}

.bg-primary-alt-dark {
  background-color: var(--primary-alt-dark);
}

.border-primary-alt-dark {
  border-color: var(--primary-alt-dark);
}

.border-x-primary-alt-dark {
  border-left-color: var(--primary-alt-dark);
  border-right-color: var(--primary-alt-dark);
}

.border-y-primary-alt-dark {
  border-top-color: var(--primary-alt-dark);
  border-bottom-color: var(--primary-alt-dark);
}

.decoration-primary-alt-dark {
  text-decoration-color: var(--primary-alt-dark);
}

.text-default, .Toastify__toast-body {
  color: var(--default);
}

.bg-default {
  background-color: var(--default);
}

.border-default {
  border-color: var(--default);
}

.border-x-default {
  border-left-color: var(--default);
  border-right-color: var(--default);
}

.border-y-default {
  border-top-color: var(--default);
  border-bottom-color: var(--default);
}

.decoration-default {
  text-decoration-color: var(--default);
}

.text-default-dark {
  color: var(--default-dark);
}

.bg-default-dark {
  background-color: var(--default-dark);
}

.border-default-dark {
  border-color: var(--default-dark);
}

.border-x-default-dark {
  border-left-color: var(--default-dark);
  border-right-color: var(--default-dark);
}

.border-y-default-dark {
  border-top-color: var(--default-dark);
  border-bottom-color: var(--default-dark);
}

.decoration-default-dark {
  text-decoration-color: var(--default-dark);
}

.text-muted {
  color: var(--muted);
}

.bg-muted {
  background-color: var(--muted);
}

.border-muted {
  border-color: var(--muted);
}

.border-x-muted {
  border-left-color: var(--muted);
  border-right-color: var(--muted);
}

.border-y-muted {
  border-top-color: var(--muted);
  border-bottom-color: var(--muted);
}

.decoration-muted {
  text-decoration-color: var(--muted);
}

.text-muted-dark {
  color: var(--muted-dark);
}

.bg-muted-dark {
  background-color: var(--muted-dark);
}

.border-muted-dark {
  border-color: var(--muted-dark);
}

.border-x-muted-dark {
  border-left-color: var(--muted-dark);
  border-right-color: var(--muted-dark);
}

.border-y-muted-dark {
  border-top-color: var(--muted-dark);
  border-bottom-color: var(--muted-dark);
}

.decoration-muted-dark {
  text-decoration-color: var(--muted-dark);
}

.text-muted-alt {
  color: var(--muted-alt);
}

.bg-muted-alt {
  background-color: var(--muted-alt);
}

.border-muted-alt {
  border-color: var(--muted-alt);
}

.border-x-muted-alt {
  border-left-color: var(--muted-alt);
  border-right-color: var(--muted-alt);
}

.border-y-muted-alt {
  border-top-color: var(--muted-alt);
  border-bottom-color: var(--muted-alt);
}

.decoration-muted-alt {
  text-decoration-color: var(--muted-alt);
}

.text-muted-alt-dark {
  color: var(--muted-alt-dark);
}

.bg-muted-alt-dark {
  background-color: var(--muted-alt-dark);
}

.border-muted-alt-dark {
  border-color: var(--muted-alt-dark);
}

.border-x-muted-alt-dark {
  border-left-color: var(--muted-alt-dark);
  border-right-color: var(--muted-alt-dark);
}

.border-y-muted-alt-dark {
  border-top-color: var(--muted-alt-dark);
  border-bottom-color: var(--muted-alt-dark);
}

.decoration-muted-alt-dark {
  text-decoration-color: var(--muted-alt-dark);
}

.text-mark {
  color: var(--mark);
}

.bg-mark {
  background-color: var(--mark);
}

.border-mark {
  border-color: var(--mark);
}

.border-x-mark {
  border-left-color: var(--mark);
  border-right-color: var(--mark);
}

.border-y-mark {
  border-top-color: var(--mark);
  border-bottom-color: var(--mark);
}

.decoration-mark {
  text-decoration-color: var(--mark);
}

.text-white {
  color: var(--white);
}

.bg-white {
  background-color: var(--white);
}

.border-white {
  border-color: var(--white);
}

.border-x-white {
  border-left-color: var(--white);
  border-right-color: var(--white);
}

.border-y-white {
  border-top-color: var(--white);
  border-bottom-color: var(--white);
}

.decoration-white {
  text-decoration-color: var(--white);
}

.text-bg-light {
  color: var(--bg-light);
}

.bg-bg-light {
  background-color: var(--bg-light);
}

.border-bg-light {
  border-color: var(--bg-light);
}

.border-x-bg-light {
  border-left-color: var(--bg-light);
  border-right-color: var(--bg-light);
}

.border-y-bg-light {
  border-top-color: var(--bg-light);
  border-bottom-color: var(--bg-light);
}

.decoration-bg-light {
  text-decoration-color: var(--bg-light);
}

.text-side {
  color: var(--side);
}

.bg-side {
  background-color: var(--side);
}

.border-side {
  border-color: var(--side);
}

.border-x-side {
  border-left-color: var(--side);
  border-right-color: var(--side);
}

.border-y-side {
  border-top-color: var(--side);
  border-bottom-color: var(--side);
}

.decoration-side {
  text-decoration-color: var(--side);
}

.text-mid {
  color: var(--mid);
}

.bg-mid {
  background-color: var(--mid);
}

.border-mid {
  border-color: var(--mid);
}

.border-x-mid {
  border-left-color: var(--mid);
  border-right-color: var(--mid);
}

.border-y-mid {
  border-top-color: var(--mid);
  border-bottom-color: var(--mid);
}

.decoration-mid {
  text-decoration-color: var(--mid);
}

.text-border {
  color: var(--border);
}

.bg-border {
  background-color: var(--border);
}

.border-border {
  border-color: var(--border);
}

.border-x-border {
  border-left-color: var(--border);
  border-right-color: var(--border);
}

.border-y-border {
  border-top-color: var(--border);
  border-bottom-color: var(--border);
}

.decoration-border {
  text-decoration-color: var(--border);
}

.text-border-light {
  color: var(--border-light);
}

.bg-border-light {
  background-color: var(--border-light);
}

.border-border-light {
  border-color: var(--border-light);
}

.border-x-border-light {
  border-left-color: var(--border-light);
  border-right-color: var(--border-light);
}

.border-y-border-light {
  border-top-color: var(--border-light);
  border-bottom-color: var(--border-light);
}

.decoration-border-light {
  text-decoration-color: var(--border-light);
}

.text-border-bold {
  color: var(--border-bold);
}

.bg-border-bold {
  background-color: var(--border-bold);
}

.border-border-bold {
  border-color: var(--border-bold);
}

.border-x-border-bold {
  border-left-color: var(--border-bold);
  border-right-color: var(--border-bold);
}

.border-y-border-bold {
  border-top-color: var(--border-bold);
  border-bottom-color: var(--border-bold);
}

.decoration-border-bold {
  text-decoration-color: var(--border-bold);
}

.text-border-active {
  color: var(--border-active);
}

.bg-border-active {
  background-color: var(--border-active);
}

.border-border-active {
  border-color: var(--border-active);
}

.border-x-border-active {
  border-left-color: var(--border-active);
  border-right-color: var(--border-active);
}

.border-y-border-active {
  border-top-color: var(--border-active);
  border-bottom-color: var(--border-active);
}

.decoration-border-active {
  text-decoration-color: var(--border-active);
}

.text-transparent {
  color: var(--transparent);
}

.bg-transparent {
  background-color: var(--transparent);
}

.border-transparent {
  border-color: var(--transparent);
}

.border-x-transparent {
  border-left-color: var(--transparent);
  border-right-color: var(--transparent);
}

.border-y-transparent {
  border-top-color: var(--transparent);
  border-bottom-color: var(--transparent);
}

.decoration-transparent {
  text-decoration-color: var(--transparent);
}

.text-link {
  color: var(--link);
}

.bg-link {
  background-color: var(--link);
}

.border-link {
  border-color: var(--link);
}

.border-x-link {
  border-left-color: var(--link);
  border-right-color: var(--link);
}

.border-y-link {
  border-top-color: var(--link);
  border-bottom-color: var(--link);
}

.decoration-link {
  text-decoration-color: var(--link);
}

.text-brand-blue {
  color: var(--brand-blue);
}

.bg-brand-blue {
  background-color: var(--brand-blue);
}

.border-brand-blue {
  border-color: var(--brand-blue);
}

.border-x-brand-blue {
  border-left-color: var(--brand-blue);
  border-right-color: var(--brand-blue);
}

.border-y-brand-blue {
  border-top-color: var(--brand-blue);
  border-bottom-color: var(--brand-blue);
}

.decoration-brand-blue {
  text-decoration-color: var(--brand-blue);
}

.text-brand-red {
  color: var(--brand-red);
}

.bg-brand-red {
  background-color: var(--brand-red);
}

.border-brand-red {
  border-color: var(--brand-red);
}

.border-x-brand-red {
  border-left-color: var(--brand-red);
  border-right-color: var(--brand-red);
}

.border-y-brand-red {
  border-top-color: var(--brand-red);
  border-bottom-color: var(--brand-red);
}

.decoration-brand-red {
  text-decoration-color: var(--brand-red);
}

.text-brand-yellow {
  color: var(--brand-yellow);
}

.bg-brand-yellow {
  background-color: var(--brand-yellow);
}

.border-brand-yellow {
  border-color: var(--brand-yellow);
}

.border-x-brand-yellow {
  border-left-color: var(--brand-yellow);
  border-right-color: var(--brand-yellow);
}

.border-y-brand-yellow {
  border-top-color: var(--brand-yellow);
  border-bottom-color: var(--brand-yellow);
}

.decoration-brand-yellow {
  text-decoration-color: var(--brand-yellow);
}

.text-brand-key {
  color: var(--brand-key);
}

.bg-brand-key {
  background-color: var(--brand-key);
}

.border-brand-key {
  border-color: var(--brand-key);
}

.border-x-brand-key {
  border-left-color: var(--brand-key);
  border-right-color: var(--brand-key);
}

.border-y-brand-key {
  border-top-color: var(--brand-key);
  border-bottom-color: var(--brand-key);
}

.decoration-brand-key {
  text-decoration-color: var(--brand-key);
}

.text-text-3000-light {
  color: var(--text-3000-light);
}

.bg-text-3000-light {
  background-color: var(--text-3000-light);
}

.border-text-3000-light {
  border-color: var(--text-3000-light);
}

.border-x-text-3000-light {
  border-left-color: var(--text-3000-light);
  border-right-color: var(--text-3000-light);
}

.border-y-text-3000-light {
  border-top-color: var(--text-3000-light);
  border-bottom-color: var(--text-3000-light);
}

.decoration-text-3000-light {
  text-decoration-color: var(--text-3000-light);
}

.text-muted-3000-light {
  color: var(--muted-3000-light);
}

.bg-muted-3000-light {
  background-color: var(--muted-3000-light);
}

.border-muted-3000-light {
  border-color: var(--muted-3000-light);
}

.border-x-muted-3000-light {
  border-left-color: var(--muted-3000-light);
  border-right-color: var(--muted-3000-light);
}

.border-y-muted-3000-light {
  border-top-color: var(--muted-3000-light);
  border-bottom-color: var(--muted-3000-light);
}

.decoration-muted-3000-light {
  text-decoration-color: var(--muted-3000-light);
}

.text-trace-3000-light {
  color: var(--trace-3000-light);
}

.bg-trace-3000-light {
  background-color: var(--trace-3000-light);
}

.border-trace-3000-light {
  border-color: var(--trace-3000-light);
}

.border-x-trace-3000-light {
  border-left-color: var(--trace-3000-light);
  border-right-color: var(--trace-3000-light);
}

.border-y-trace-3000-light {
  border-top-color: var(--trace-3000-light);
  border-bottom-color: var(--trace-3000-light);
}

.decoration-trace-3000-light {
  text-decoration-color: var(--trace-3000-light);
}

.text-primary-3000-light {
  color: var(--primary-3000-light);
}

.bg-primary-3000-light {
  background-color: var(--primary-3000-light);
}

.border-primary-3000-light {
  border-color: var(--primary-3000-light);
}

.border-x-primary-3000-light {
  border-left-color: var(--primary-3000-light);
  border-right-color: var(--primary-3000-light);
}

.border-y-primary-3000-light {
  border-top-color: var(--primary-3000-light);
  border-bottom-color: var(--primary-3000-light);
}

.decoration-primary-3000-light {
  text-decoration-color: var(--primary-3000-light);
}

.text-primary-3000-hover-light {
  color: var(--primary-3000-hover-light);
}

.bg-primary-3000-hover-light {
  background-color: var(--primary-3000-hover-light);
}

.border-primary-3000-hover-light {
  border-color: var(--primary-3000-hover-light);
}

.border-x-primary-3000-hover-light {
  border-left-color: var(--primary-3000-hover-light);
  border-right-color: var(--primary-3000-hover-light);
}

.border-y-primary-3000-hover-light {
  border-top-color: var(--primary-3000-hover-light);
  border-bottom-color: var(--primary-3000-hover-light);
}

.decoration-primary-3000-hover-light {
  text-decoration-color: var(--primary-3000-hover-light);
}

.text-secondary-3000-light {
  color: var(--secondary-3000-light);
}

.bg-secondary-3000-light {
  background-color: var(--secondary-3000-light);
}

.border-secondary-3000-light {
  border-color: var(--secondary-3000-light);
}

.border-x-secondary-3000-light {
  border-left-color: var(--secondary-3000-light);
  border-right-color: var(--secondary-3000-light);
}

.border-y-secondary-3000-light {
  border-top-color: var(--secondary-3000-light);
  border-bottom-color: var(--secondary-3000-light);
}

.decoration-secondary-3000-light {
  text-decoration-color: var(--secondary-3000-light);
}

.text-secondary-3000-hover-light {
  color: var(--secondary-3000-hover-light);
}

.bg-secondary-3000-hover-light {
  background-color: var(--secondary-3000-hover-light);
}

.border-secondary-3000-hover-light {
  border-color: var(--secondary-3000-hover-light);
}

.border-x-secondary-3000-hover-light {
  border-left-color: var(--secondary-3000-hover-light);
  border-right-color: var(--secondary-3000-hover-light);
}

.border-y-secondary-3000-hover-light {
  border-top-color: var(--secondary-3000-hover-light);
  border-bottom-color: var(--secondary-3000-hover-light);
}

.decoration-secondary-3000-hover-light {
  text-decoration-color: var(--secondary-3000-hover-light);
}

.text-accent-3000-light {
  color: var(--accent-3000-light);
}

.bg-accent-3000-light {
  background-color: var(--accent-3000-light);
}

.border-accent-3000-light {
  border-color: var(--accent-3000-light);
}

.border-x-accent-3000-light {
  border-left-color: var(--accent-3000-light);
  border-right-color: var(--accent-3000-light);
}

.border-y-accent-3000-light {
  border-top-color: var(--accent-3000-light);
  border-bottom-color: var(--accent-3000-light);
}

.decoration-accent-3000-light {
  text-decoration-color: var(--accent-3000-light);
}

.text-bg-3000-light {
  color: var(--bg-3000-light);
}

.bg-bg-3000-light {
  background-color: var(--bg-3000-light);
}

.border-bg-3000-light {
  border-color: var(--bg-3000-light);
}

.border-x-bg-3000-light {
  border-left-color: var(--bg-3000-light);
  border-right-color: var(--bg-3000-light);
}

.border-y-bg-3000-light {
  border-top-color: var(--bg-3000-light);
  border-bottom-color: var(--bg-3000-light);
}

.decoration-bg-3000-light {
  text-decoration-color: var(--bg-3000-light);
}

.text-border-3000-light {
  color: var(--border-3000-light);
}

.bg-border-3000-light {
  background-color: var(--border-3000-light);
}

.border-border-3000-light {
  border-color: var(--border-3000-light);
}

.border-x-border-3000-light {
  border-left-color: var(--border-3000-light);
  border-right-color: var(--border-3000-light);
}

.border-y-border-3000-light {
  border-top-color: var(--border-3000-light);
  border-bottom-color: var(--border-3000-light);
}

.decoration-border-3000-light {
  text-decoration-color: var(--border-3000-light);
}

.text-border-bold-3000-light {
  color: var(--border-bold-3000-light);
}

.bg-border-bold-3000-light {
  background-color: var(--border-bold-3000-light);
}

.border-border-bold-3000-light {
  border-color: var(--border-bold-3000-light);
}

.border-x-border-bold-3000-light {
  border-left-color: var(--border-bold-3000-light);
  border-right-color: var(--border-bold-3000-light);
}

.border-y-border-bold-3000-light {
  border-top-color: var(--border-bold-3000-light);
  border-bottom-color: var(--border-bold-3000-light);
}

.decoration-border-bold-3000-light {
  text-decoration-color: var(--border-bold-3000-light);
}

.text-glass-bg-3000-light {
  color: var(--glass-bg-3000-light);
}

.bg-glass-bg-3000-light {
  background-color: var(--glass-bg-3000-light);
}

.border-glass-bg-3000-light {
  border-color: var(--glass-bg-3000-light);
}

.border-x-glass-bg-3000-light {
  border-left-color: var(--glass-bg-3000-light);
  border-right-color: var(--glass-bg-3000-light);
}

.border-y-glass-bg-3000-light {
  border-top-color: var(--glass-bg-3000-light);
  border-bottom-color: var(--glass-bg-3000-light);
}

.decoration-glass-bg-3000-light {
  text-decoration-color: var(--glass-bg-3000-light);
}

.text-glass-border-3000-light {
  color: var(--glass-border-3000-light);
}

.bg-glass-border-3000-light {
  background-color: var(--glass-border-3000-light);
}

.border-glass-border-3000-light {
  border-color: var(--glass-border-3000-light);
}

.border-x-glass-border-3000-light {
  border-left-color: var(--glass-border-3000-light);
  border-right-color: var(--glass-border-3000-light);
}

.border-y-glass-border-3000-light {
  border-top-color: var(--glass-border-3000-light);
  border-bottom-color: var(--glass-border-3000-light);
}

.decoration-glass-border-3000-light {
  text-decoration-color: var(--glass-border-3000-light);
}

.text-link-3000-light {
  color: var(--link-3000-light);
}

.bg-link-3000-light {
  background-color: var(--link-3000-light);
}

.border-link-3000-light {
  border-color: var(--link-3000-light);
}

.border-x-link-3000-light {
  border-left-color: var(--link-3000-light);
  border-right-color: var(--link-3000-light);
}

.border-y-link-3000-light {
  border-top-color: var(--link-3000-light);
  border-bottom-color: var(--link-3000-light);
}

.decoration-link-3000-light {
  text-decoration-color: var(--link-3000-light);
}

.text-text-3000-dark {
  color: var(--text-3000-dark);
}

.bg-text-3000-dark {
  background-color: var(--text-3000-dark);
}

.border-text-3000-dark {
  border-color: var(--text-3000-dark);
}

.border-x-text-3000-dark {
  border-left-color: var(--text-3000-dark);
  border-right-color: var(--text-3000-dark);
}

.border-y-text-3000-dark {
  border-top-color: var(--text-3000-dark);
  border-bottom-color: var(--text-3000-dark);
}

.decoration-text-3000-dark {
  text-decoration-color: var(--text-3000-dark);
}

.text-muted-3000-dark {
  color: var(--muted-3000-dark);
}

.bg-muted-3000-dark {
  background-color: var(--muted-3000-dark);
}

.border-muted-3000-dark {
  border-color: var(--muted-3000-dark);
}

.border-x-muted-3000-dark {
  border-left-color: var(--muted-3000-dark);
  border-right-color: var(--muted-3000-dark);
}

.border-y-muted-3000-dark {
  border-top-color: var(--muted-3000-dark);
  border-bottom-color: var(--muted-3000-dark);
}

.decoration-muted-3000-dark {
  text-decoration-color: var(--muted-3000-dark);
}

.text-trace-3000-dark {
  color: var(--trace-3000-dark);
}

.bg-trace-3000-dark {
  background-color: var(--trace-3000-dark);
}

.border-trace-3000-dark {
  border-color: var(--trace-3000-dark);
}

.border-x-trace-3000-dark {
  border-left-color: var(--trace-3000-dark);
  border-right-color: var(--trace-3000-dark);
}

.border-y-trace-3000-dark {
  border-top-color: var(--trace-3000-dark);
  border-bottom-color: var(--trace-3000-dark);
}

.decoration-trace-3000-dark {
  text-decoration-color: var(--trace-3000-dark);
}

.text-primary-3000-dark {
  color: var(--primary-3000-dark);
}

.bg-primary-3000-dark {
  background-color: var(--primary-3000-dark);
}

.border-primary-3000-dark {
  border-color: var(--primary-3000-dark);
}

.border-x-primary-3000-dark {
  border-left-color: var(--primary-3000-dark);
  border-right-color: var(--primary-3000-dark);
}

.border-y-primary-3000-dark {
  border-top-color: var(--primary-3000-dark);
  border-bottom-color: var(--primary-3000-dark);
}

.decoration-primary-3000-dark {
  text-decoration-color: var(--primary-3000-dark);
}

.text-primary-3000-hover-dark {
  color: var(--primary-3000-hover-dark);
}

.bg-primary-3000-hover-dark {
  background-color: var(--primary-3000-hover-dark);
}

.border-primary-3000-hover-dark {
  border-color: var(--primary-3000-hover-dark);
}

.border-x-primary-3000-hover-dark {
  border-left-color: var(--primary-3000-hover-dark);
  border-right-color: var(--primary-3000-hover-dark);
}

.border-y-primary-3000-hover-dark {
  border-top-color: var(--primary-3000-hover-dark);
  border-bottom-color: var(--primary-3000-hover-dark);
}

.decoration-primary-3000-hover-dark {
  text-decoration-color: var(--primary-3000-hover-dark);
}

.text-secondary-3000-dark {
  color: var(--secondary-3000-dark);
}

.bg-secondary-3000-dark {
  background-color: var(--secondary-3000-dark);
}

.border-secondary-3000-dark {
  border-color: var(--secondary-3000-dark);
}

.border-x-secondary-3000-dark {
  border-left-color: var(--secondary-3000-dark);
  border-right-color: var(--secondary-3000-dark);
}

.border-y-secondary-3000-dark {
  border-top-color: var(--secondary-3000-dark);
  border-bottom-color: var(--secondary-3000-dark);
}

.decoration-secondary-3000-dark {
  text-decoration-color: var(--secondary-3000-dark);
}

.text-secondary-3000-hover-dark {
  color: var(--secondary-3000-hover-dark);
}

.bg-secondary-3000-hover-dark {
  background-color: var(--secondary-3000-hover-dark);
}

.border-secondary-3000-hover-dark {
  border-color: var(--secondary-3000-hover-dark);
}

.border-x-secondary-3000-hover-dark {
  border-left-color: var(--secondary-3000-hover-dark);
  border-right-color: var(--secondary-3000-hover-dark);
}

.border-y-secondary-3000-hover-dark {
  border-top-color: var(--secondary-3000-hover-dark);
  border-bottom-color: var(--secondary-3000-hover-dark);
}

.decoration-secondary-3000-hover-dark {
  text-decoration-color: var(--secondary-3000-hover-dark);
}

.text-accent-3000-dark {
  color: var(--accent-3000-dark);
}

.bg-accent-3000-dark {
  background-color: var(--accent-3000-dark);
}

.border-accent-3000-dark {
  border-color: var(--accent-3000-dark);
}

.border-x-accent-3000-dark {
  border-left-color: var(--accent-3000-dark);
  border-right-color: var(--accent-3000-dark);
}

.border-y-accent-3000-dark {
  border-top-color: var(--accent-3000-dark);
  border-bottom-color: var(--accent-3000-dark);
}

.decoration-accent-3000-dark {
  text-decoration-color: var(--accent-3000-dark);
}

.text-bg-3000-dark {
  color: var(--bg-3000-dark);
}

.bg-bg-3000-dark {
  background-color: var(--bg-3000-dark);
}

.border-bg-3000-dark {
  border-color: var(--bg-3000-dark);
}

.border-x-bg-3000-dark {
  border-left-color: var(--bg-3000-dark);
  border-right-color: var(--bg-3000-dark);
}

.border-y-bg-3000-dark {
  border-top-color: var(--bg-3000-dark);
  border-bottom-color: var(--bg-3000-dark);
}

.decoration-bg-3000-dark {
  text-decoration-color: var(--bg-3000-dark);
}

.text-border-3000-dark {
  color: var(--border-3000-dark);
}

.bg-border-3000-dark {
  background-color: var(--border-3000-dark);
}

.border-border-3000-dark {
  border-color: var(--border-3000-dark);
}

.border-x-border-3000-dark {
  border-left-color: var(--border-3000-dark);
  border-right-color: var(--border-3000-dark);
}

.border-y-border-3000-dark {
  border-top-color: var(--border-3000-dark);
  border-bottom-color: var(--border-3000-dark);
}

.decoration-border-3000-dark {
  text-decoration-color: var(--border-3000-dark);
}

.text-border-bold-3000-dark {
  color: var(--border-bold-3000-dark);
}

.bg-border-bold-3000-dark {
  background-color: var(--border-bold-3000-dark);
}

.border-border-bold-3000-dark {
  border-color: var(--border-bold-3000-dark);
}

.border-x-border-bold-3000-dark {
  border-left-color: var(--border-bold-3000-dark);
  border-right-color: var(--border-bold-3000-dark);
}

.border-y-border-bold-3000-dark {
  border-top-color: var(--border-bold-3000-dark);
  border-bottom-color: var(--border-bold-3000-dark);
}

.decoration-border-bold-3000-dark {
  text-decoration-color: var(--border-bold-3000-dark);
}

.text-glass-bg-3000-dark {
  color: var(--glass-bg-3000-dark);
}

.bg-glass-bg-3000-dark {
  background-color: var(--glass-bg-3000-dark);
}

.border-glass-bg-3000-dark {
  border-color: var(--glass-bg-3000-dark);
}

.border-x-glass-bg-3000-dark {
  border-left-color: var(--glass-bg-3000-dark);
  border-right-color: var(--glass-bg-3000-dark);
}

.border-y-glass-bg-3000-dark {
  border-top-color: var(--glass-bg-3000-dark);
  border-bottom-color: var(--glass-bg-3000-dark);
}

.decoration-glass-bg-3000-dark {
  text-decoration-color: var(--glass-bg-3000-dark);
}

.text-glass-border-3000-dark {
  color: var(--glass-border-3000-dark);
}

.bg-glass-border-3000-dark {
  background-color: var(--glass-border-3000-dark);
}

.border-glass-border-3000-dark {
  border-color: var(--glass-border-3000-dark);
}

.border-x-glass-border-3000-dark {
  border-left-color: var(--glass-border-3000-dark);
  border-right-color: var(--glass-border-3000-dark);
}

.border-y-glass-border-3000-dark {
  border-top-color: var(--glass-border-3000-dark);
  border-bottom-color: var(--glass-border-3000-dark);
}

.decoration-glass-border-3000-dark {
  text-decoration-color: var(--glass-border-3000-dark);
}

.text-link-3000-dark {
  color: var(--link-3000-dark);
}

.bg-link-3000-dark {
  background-color: var(--link-3000-dark);
}

.border-link-3000-dark {
  border-color: var(--link-3000-dark);
}

.border-x-link-3000-dark {
  border-left-color: var(--link-3000-dark);
  border-right-color: var(--link-3000-dark);
}

.border-y-link-3000-dark {
  border-top-color: var(--link-3000-dark);
  border-bottom-color: var(--link-3000-dark);
}

.decoration-link-3000-dark {
  text-decoration-color: var(--link-3000-dark);
}

.text-text-3000 {
  color: var(--text-3000);
}

.bg-text-3000 {
  background-color: var(--text-3000);
}

.border-text-3000 {
  border-color: var(--text-3000);
}

.border-x-text-3000 {
  border-left-color: var(--text-3000);
  border-right-color: var(--text-3000);
}

.border-y-text-3000 {
  border-top-color: var(--text-3000);
  border-bottom-color: var(--text-3000);
}

.decoration-text-3000 {
  text-decoration-color: var(--text-3000);
}

.text-muted-3000 {
  color: var(--muted-3000);
}

.bg-muted-3000 {
  background-color: var(--muted-3000);
}

.border-muted-3000 {
  border-color: var(--muted-3000);
}

.border-x-muted-3000 {
  border-left-color: var(--muted-3000);
  border-right-color: var(--muted-3000);
}

.border-y-muted-3000 {
  border-top-color: var(--muted-3000);
  border-bottom-color: var(--muted-3000);
}

.decoration-muted-3000 {
  text-decoration-color: var(--muted-3000);
}

.text-trace-3000 {
  color: var(--trace-3000);
}

.bg-trace-3000 {
  background-color: var(--trace-3000);
}

.border-trace-3000 {
  border-color: var(--trace-3000);
}

.border-x-trace-3000 {
  border-left-color: var(--trace-3000);
  border-right-color: var(--trace-3000);
}

.border-y-trace-3000 {
  border-top-color: var(--trace-3000);
  border-bottom-color: var(--trace-3000);
}

.decoration-trace-3000 {
  text-decoration-color: var(--trace-3000);
}

.text-primary-3000 {
  color: var(--primary-3000);
}

.bg-primary-3000 {
  background-color: var(--primary-3000);
}

.border-primary-3000 {
  border-color: var(--primary-3000);
}

.border-x-primary-3000 {
  border-left-color: var(--primary-3000);
  border-right-color: var(--primary-3000);
}

.border-y-primary-3000 {
  border-top-color: var(--primary-3000);
  border-bottom-color: var(--primary-3000);
}

.decoration-primary-3000 {
  text-decoration-color: var(--primary-3000);
}

.text-primary-3000-hover {
  color: var(--primary-3000-hover);
}

.bg-primary-3000-hover {
  background-color: var(--primary-3000-hover);
}

.border-primary-3000-hover {
  border-color: var(--primary-3000-hover);
}

.border-x-primary-3000-hover {
  border-left-color: var(--primary-3000-hover);
  border-right-color: var(--primary-3000-hover);
}

.border-y-primary-3000-hover {
  border-top-color: var(--primary-3000-hover);
  border-bottom-color: var(--primary-3000-hover);
}

.decoration-primary-3000-hover {
  text-decoration-color: var(--primary-3000-hover);
}

.text-secondary-3000 {
  color: var(--secondary-3000);
}

.bg-secondary-3000 {
  background-color: var(--secondary-3000);
}

.border-secondary-3000 {
  border-color: var(--secondary-3000);
}

.border-x-secondary-3000 {
  border-left-color: var(--secondary-3000);
  border-right-color: var(--secondary-3000);
}

.border-y-secondary-3000 {
  border-top-color: var(--secondary-3000);
  border-bottom-color: var(--secondary-3000);
}

.decoration-secondary-3000 {
  text-decoration-color: var(--secondary-3000);
}

.text-secondary-3000-hover {
  color: var(--secondary-3000-hover);
}

.bg-secondary-3000-hover {
  background-color: var(--secondary-3000-hover);
}

.border-secondary-3000-hover {
  border-color: var(--secondary-3000-hover);
}

.border-x-secondary-3000-hover {
  border-left-color: var(--secondary-3000-hover);
  border-right-color: var(--secondary-3000-hover);
}

.border-y-secondary-3000-hover {
  border-top-color: var(--secondary-3000-hover);
  border-bottom-color: var(--secondary-3000-hover);
}

.decoration-secondary-3000-hover {
  text-decoration-color: var(--secondary-3000-hover);
}

.text-accent-3000 {
  color: var(--accent-3000);
}

.bg-accent-3000 {
  background-color: var(--accent-3000);
}

.border-accent-3000 {
  border-color: var(--accent-3000);
}

.border-x-accent-3000 {
  border-left-color: var(--accent-3000);
  border-right-color: var(--accent-3000);
}

.border-y-accent-3000 {
  border-top-color: var(--accent-3000);
  border-bottom-color: var(--accent-3000);
}

.decoration-accent-3000 {
  text-decoration-color: var(--accent-3000);
}

.text-bg-3000 {
  color: var(--bg-3000);
}

.bg-bg-3000 {
  background-color: var(--bg-3000);
}

.border-bg-3000 {
  border-color: var(--bg-3000);
}

.border-x-bg-3000 {
  border-left-color: var(--bg-3000);
  border-right-color: var(--bg-3000);
}

.border-y-bg-3000 {
  border-top-color: var(--bg-3000);
  border-bottom-color: var(--bg-3000);
}

.decoration-bg-3000 {
  text-decoration-color: var(--bg-3000);
}

.text-border-3000 {
  color: var(--border-3000);
}

.bg-border-3000 {
  background-color: var(--border-3000);
}

.border-border-3000 {
  border-color: var(--border-3000);
}

.border-x-border-3000 {
  border-left-color: var(--border-3000);
  border-right-color: var(--border-3000);
}

.border-y-border-3000 {
  border-top-color: var(--border-3000);
  border-bottom-color: var(--border-3000);
}

.decoration-border-3000 {
  text-decoration-color: var(--border-3000);
}

.text-border-bold-3000 {
  color: var(--border-bold-3000);
}

.bg-border-bold-3000 {
  background-color: var(--border-bold-3000);
}

.border-border-bold-3000 {
  border-color: var(--border-bold-3000);
}

.border-x-border-bold-3000 {
  border-left-color: var(--border-bold-3000);
  border-right-color: var(--border-bold-3000);
}

.border-y-border-bold-3000 {
  border-top-color: var(--border-bold-3000);
  border-bottom-color: var(--border-bold-3000);
}

.decoration-border-bold-3000 {
  text-decoration-color: var(--border-bold-3000);
}

.text-glass-bg-3000 {
  color: var(--glass-bg-3000);
}

.bg-glass-bg-3000 {
  background-color: var(--glass-bg-3000);
}

.border-glass-bg-3000 {
  border-color: var(--glass-bg-3000);
}

.border-x-glass-bg-3000 {
  border-left-color: var(--glass-bg-3000);
  border-right-color: var(--glass-bg-3000);
}

.border-y-glass-bg-3000 {
  border-top-color: var(--glass-bg-3000);
  border-bottom-color: var(--glass-bg-3000);
}

.decoration-glass-bg-3000 {
  text-decoration-color: var(--glass-bg-3000);
}

.text-glass-border-3000 {
  color: var(--glass-border-3000);
}

.bg-glass-border-3000 {
  background-color: var(--glass-border-3000);
}

.border-glass-border-3000 {
  border-color: var(--glass-border-3000);
}

.border-x-glass-border-3000 {
  border-left-color: var(--glass-border-3000);
  border-right-color: var(--glass-border-3000);
}

.border-y-glass-border-3000 {
  border-top-color: var(--glass-border-3000);
  border-bottom-color: var(--glass-border-3000);
}

.decoration-glass-border-3000 {
  text-decoration-color: var(--glass-border-3000);
}

.text-link-3000 {
  color: var(--link-3000);
}

.bg-link-3000 {
  background-color: var(--link-3000);
}

.border-link-3000 {
  border-color: var(--link-3000);
}

.border-x-link-3000 {
  border-left-color: var(--link-3000);
  border-right-color: var(--link-3000);
}

.border-y-link-3000 {
  border-top-color: var(--link-3000);
  border-bottom-color: var(--link-3000);
}

.decoration-link-3000 {
  text-decoration-color: var(--link-3000);
}

.text-inherit {
  color: inherit;
}

.text-current {
  color: currentColor;
}

.hover\:text-primary-highlight:hover {
  color: rgba(29, 74, 255, 0.1);
}

.hover\:bg-primary-highlight:hover {
  background-color: rgba(29, 74, 255, 0.1);
}

.hover\:border-primary-highlight:hover {
  border-color: rgba(29, 74, 255, 0.1);
}

.hover\:text-primary-light:hover {
  color: #345cff;
}

.hover\:bg-primary-light:hover {
  background-color: #345cff;
}

.hover\:border-primary-light:hover {
  border-color: #345cff;
}

.hover\:text-primary:hover {
  color: #1d4aff;
}

.hover\:bg-primary:hover {
  background-color: #1d4aff;
}

.hover\:border-primary:hover {
  border-color: #1d4aff;
}

.hover\:text-primary-dark:hover {
  color: #1330a6;
}

.hover\:bg-primary-dark:hover {
  background-color: #1330a6;
}

.hover\:border-primary-dark:hover {
  border-color: #1330a6;
}

.hover\:text-danger-highlight:hover {
  color: rgba(219, 55, 7, 0.1);
}

.hover\:bg-danger-highlight:hover {
  background-color: rgba(219, 55, 7, 0.1);
}

.hover\:border-danger-highlight:hover {
  border-color: rgba(219, 55, 7, 0.1);
}

.hover\:text-danger-lighter:hover {
  color: #ffa599;
}

.hover\:bg-danger-lighter:hover {
  background-color: #ffa599;
}

.hover\:border-danger-lighter:hover {
  border-color: #ffa599;
}

.hover\:text-danger-light:hover {
  color: #df4b20;
}

.hover\:bg-danger-light:hover {
  background-color: #df4b20;
}

.hover\:border-danger-light:hover {
  border-color: #df4b20;
}

.hover\:text-danger:hover {
  color: #db3707;
}

.hover\:bg-danger:hover {
  background-color: #db3707;
}

.hover\:border-danger:hover {
  border-color: #db3707;
}

.hover\:text-danger-dark:hover {
  color: #992705;
}

.hover\:bg-danger-dark:hover {
  background-color: #992705;
}

.hover\:border-danger-dark:hover {
  border-color: #992705;
}

.hover\:text-warning-highlight:hover {
  color: rgba(247, 165, 1, 0.1);
}

.hover\:bg-warning-highlight:hover {
  background-color: rgba(247, 165, 1, 0.1);
}

.hover\:border-warning-highlight:hover {
  border-color: rgba(247, 165, 1, 0.1);
}

.hover\:text-warning-light:hover {
  color: #f8b633;
}

.hover\:bg-warning-light:hover {
  background-color: #f8b633;
}

.hover\:border-warning-light:hover {
  border-color: #f8b633;
}

.hover\:text-warning:hover {
  color: #f7a501;
}

.hover\:bg-warning:hover {
  background-color: #f7a501;
}

.hover\:border-warning:hover {
  border-color: #f7a501;
}

.hover\:text-warning-dark:hover {
  color: #a06b01;
}

.hover\:bg-warning-dark:hover {
  background-color: #a06b01;
}

.hover\:border-warning-dark:hover {
  border-color: #a06b01;
}

.hover\:text-success-highlight:hover {
  color: rgba(56, 134, 0, 0.1);
}

.hover\:bg-success-highlight:hover {
  background-color: rgba(56, 134, 0, 0.1);
}

.hover\:border-success-highlight:hover {
  border-color: rgba(56, 134, 0, 0.1);
}

.hover\:text-success-light:hover {
  color: #5f9d32;
}

.hover\:bg-success-light:hover {
  background-color: #5f9d32;
}

.hover\:border-success-light:hover {
  border-color: #5f9d32;
}

.hover\:text-success:hover {
  color: #388600;
}

.hover\:bg-success:hover {
  background-color: #388600;
}

.hover\:border-success:hover {
  border-color: #388600;
}

.hover\:text-success-dark:hover {
  color: #245700;
}

.hover\:bg-success-dark:hover {
  background-color: #245700;
}

.hover\:border-success-dark:hover {
  border-color: #245700;
}

.hover\:text-primary-alt-highlight:hover {
  color: rgba(53, 65, 107, 0.1);
}

.hover\:bg-primary-alt-highlight:hover {
  background-color: rgba(53, 65, 107, 0.1);
}

.hover\:border-primary-alt-highlight:hover {
  border-color: rgba(53, 65, 107, 0.1);
}

.hover\:text-primary-alt:hover {
  color: #35416b;
}

.hover\:bg-primary-alt:hover {
  background-color: #35416b;
}

.hover\:border-primary-alt:hover {
  border-color: #35416b;
}

.hover\:text-primary-alt-dark:hover {
  color: #222a46;
}

.hover\:bg-primary-alt-dark:hover {
  background-color: #222a46;
}

.hover\:border-primary-alt-dark:hover {
  border-color: #222a46;
}

.hover\:text-default:hover {
  color: #2d2d2d;
}

.hover\:bg-default:hover {
  background-color: #2d2d2d;
}

.hover\:border-default:hover {
  border-color: #2d2d2d;
}

.hover\:text-default-dark:hover {
  color: #050505;
}

.hover\:bg-default-dark:hover {
  background-color: #050505;
}

.hover\:border-default-dark:hover {
  border-color: #050505;
}

.hover\:text-muted:hover {
  color: #5f5f5f;
}

.hover\:bg-muted:hover {
  background-color: #5f5f5f;
}

.hover\:border-muted:hover {
  border-color: #5f5f5f;
}

.hover\:text-muted-dark:hover {
  color: #403939;
}

.hover\:bg-muted-dark:hover {
  background-color: #403939;
}

.hover\:border-muted-dark:hover {
  border-color: #403939;
}

.hover\:text-muted-alt:hover {
  color: #747ea1;
}

.hover\:bg-muted-alt:hover {
  background-color: #747ea1;
}

.hover\:border-muted-alt:hover {
  border-color: #747ea1;
}

.hover\:text-muted-alt-dark:hover {
  color: #515871;
}

.hover\:bg-muted-alt-dark:hover {
  background-color: #515871;
}

.hover\:border-muted-alt-dark:hover {
  border-color: #515871;
}

.hover\:text-mark:hover {
  color: hsla(42deg, 93%, 86%, 0.8);
}

.hover\:bg-mark:hover {
  background-color: hsla(42deg, 93%, 86%, 0.8);
}

.hover\:border-mark:hover {
  border-color: hsla(42deg, 93%, 86%, 0.8);
}

.hover\:text-white:hover {
  color: #fff;
}

.hover\:bg-white:hover {
  background-color: #fff;
}

.hover\:border-white:hover {
  border-color: #fff;
}

.hover\:text-bg-light:hover {
  color: #fff;
}

.hover\:bg-bg-light:hover {
  background-color: #fff;
}

.hover\:border-bg-light:hover {
  border-color: #fff;
}

.hover\:text-side:hover {
  color: #fafaf9;
}

.hover\:bg-side:hover {
  background-color: #fafaf9;
}

.hover\:border-side:hover {
  border-color: #fafaf9;
}

.hover\:text-mid:hover {
  color: #f2f2f2;
}

.hover\:bg-mid:hover {
  background-color: #f2f2f2;
}

.hover\:border-mid:hover {
  border-color: #f2f2f2;
}

.hover\:text-border:hover {
  color: rgba(0, 0, 0, 0.15);
}

.hover\:bg-border:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.hover\:border-border:hover {
  border-color: rgba(0, 0, 0, 0.15);
}

.hover\:text-border-light:hover {
  color: rgba(0, 0, 0, 0.08);
}

.hover\:bg-border-light:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.hover\:border-border-light:hover {
  border-color: rgba(0, 0, 0, 0.08);
}

.hover\:text-border-bold:hover {
  color: rgba(0, 0, 0, 0.24);
}

.hover\:bg-border-bold:hover {
  background-color: rgba(0, 0, 0, 0.24);
}

.hover\:border-border-bold:hover {
  border-color: rgba(0, 0, 0, 0.24);
}

.hover\:text-border-active:hover {
  color: rgba(0, 0, 0, 0.36);
}

.hover\:bg-border-active:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.hover\:border-border-active:hover {
  border-color: rgba(0, 0, 0, 0.36);
}

.hover\:text-transparent:hover {
  color: transparent;
}

.hover\:bg-transparent:hover {
  background-color: transparent;
}

.hover\:border-transparent:hover {
  border-color: transparent;
}

.hover\:text-link:hover {
  color: var(--link);
}

.hover\:bg-link:hover {
  background-color: var(--link);
}

.hover\:border-link:hover {
  border-color: var(--link);
}

.hover\:text-brand-blue:hover {
  color: #1d4aff;
}

.hover\:bg-brand-blue:hover {
  background-color: #1d4aff;
}

.hover\:border-brand-blue:hover {
  border-color: #1d4aff;
}

.hover\:text-brand-red:hover {
  color: #f54e00;
}

.hover\:bg-brand-red:hover {
  background-color: #f54e00;
}

.hover\:border-brand-red:hover {
  border-color: #f54e00;
}

.hover\:text-brand-yellow:hover {
  color: #f9bd2b;
}

.hover\:bg-brand-yellow:hover {
  background-color: #f9bd2b;
}

.hover\:border-brand-yellow:hover {
  border-color: #f9bd2b;
}

.hover\:text-brand-key:hover {
  color: #000;
}

.hover\:bg-brand-key:hover {
  background-color: #000;
}

.hover\:border-brand-key:hover {
  border-color: #000;
}

.hover\:text-text-3000-light:hover {
  color: #111;
}

.hover\:bg-text-3000-light:hover {
  background-color: #111;
}

.hover\:border-text-3000-light:hover {
  border-color: #111;
}

.hover\:text-muted-3000-light:hover {
  color: rgba(17, 17, 17, 0.5);
}

.hover\:bg-muted-3000-light:hover {
  background-color: rgba(17, 17, 17, 0.5);
}

.hover\:border-muted-3000-light:hover {
  border-color: rgba(17, 17, 17, 0.5);
}

.hover\:text-trace-3000-light:hover {
  color: rgba(17, 17, 17, 0.25);
}

.hover\:bg-trace-3000-light:hover {
  background-color: rgba(17, 17, 17, 0.25);
}

.hover\:border-trace-3000-light:hover {
  border-color: rgba(17, 17, 17, 0.25);
}

.hover\:text-primary-3000-light:hover {
  color: rgba(0, 0, 0, 0.75);
}

.hover\:bg-primary-3000-light:hover {
  background-color: rgba(0, 0, 0, 0.75);
}

.hover\:border-primary-3000-light:hover {
  border-color: rgba(0, 0, 0, 0.75);
}

.hover\:text-primary-3000-hover-light:hover {
  color: #000;
}

.hover\:bg-primary-3000-hover-light:hover {
  background-color: #000;
}

.hover\:border-primary-3000-hover-light:hover {
  border-color: #000;
}

.hover\:text-secondary-3000-light:hover {
  color: rgba(207, 209, 194, 0.6);
}

.hover\:bg-secondary-3000-light:hover {
  background-color: rgba(207, 209, 194, 0.6);
}

.hover\:border-secondary-3000-light:hover {
  border-color: rgba(207, 209, 194, 0.6);
}

.hover\:text-secondary-3000-hover-light:hover {
  color: #cfd1c2;
}

.hover\:bg-secondary-3000-hover-light:hover {
  background-color: #cfd1c2;
}

.hover\:border-secondary-3000-hover-light:hover {
  border-color: #cfd1c2;
}

.hover\:text-accent-3000-light:hover {
  color: #eeefe9;
}

.hover\:bg-accent-3000-light:hover {
  background-color: #eeefe9;
}

.hover\:border-accent-3000-light:hover {
  border-color: #eeefe9;
}

.hover\:text-bg-3000-light:hover {
  color: #f3f4ef;
}

.hover\:bg-bg-3000-light:hover {
  background-color: #f3f4ef;
}

.hover\:border-bg-3000-light:hover {
  border-color: #f3f4ef;
}

.hover\:text-border-3000-light:hover {
  color: #dadbd2;
}

.hover\:bg-border-3000-light:hover {
  background-color: #dadbd2;
}

.hover\:border-border-3000-light:hover {
  border-color: #dadbd2;
}

.hover\:text-border-bold-3000-light:hover {
  color: #c1c2b9;
}

.hover\:bg-border-bold-3000-light:hover {
  background-color: #c1c2b9;
}

.hover\:border-border-bold-3000-light:hover {
  border-color: #c1c2b9;
}

.hover\:text-glass-bg-3000-light:hover {
  color: rgba(228, 229, 222, 0.7019607843);
}

.hover\:bg-glass-bg-3000-light:hover {
  background-color: rgba(228, 229, 222, 0.7019607843);
}

.hover\:border-glass-bg-3000-light:hover {
  border-color: rgba(228, 229, 222, 0.7019607843);
}

.hover\:text-glass-border-3000-light:hover {
  color: #e4e5de;
}

.hover\:bg-glass-border-3000-light:hover {
  background-color: #e4e5de;
}

.hover\:border-glass-border-3000-light:hover {
  border-color: #e4e5de;
}

.hover\:text-link-3000-light:hover {
  color: var(--primary);
}

.hover\:bg-link-3000-light:hover {
  background-color: var(--primary);
}

.hover\:border-link-3000-light:hover {
  border-color: var(--primary);
}

.hover\:text-text-3000-dark:hover {
  color: #fff;
}

.hover\:bg-text-3000-dark:hover {
  background-color: #fff;
}

.hover\:border-text-3000-dark:hover {
  border-color: #fff;
}

.hover\:text-muted-3000-dark:hover {
  color: rgba(255, 255, 255, 0.5);
}

.hover\:bg-muted-3000-dark:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.hover\:border-muted-3000-dark:hover {
  border-color: rgba(255, 255, 255, 0.5);
}

.hover\:text-trace-3000-dark:hover {
  color: rgba(255, 255, 255, 0.25);
}

.hover\:bg-trace-3000-dark:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.hover\:border-trace-3000-dark:hover {
  border-color: rgba(255, 255, 255, 0.25);
}

.hover\:text-primary-3000-dark:hover {
  color: var(--primary);
}

.hover\:bg-primary-3000-dark:hover {
  background-color: var(--primary);
}

.hover\:border-primary-3000-dark:hover {
  border-color: var(--primary);
}

.hover\:text-primary-3000-hover-dark:hover {
  color: var(--primary-light);
}

.hover\:bg-primary-3000-hover-dark:hover {
  background-color: var(--primary-light);
}

.hover\:border-primary-3000-hover-dark:hover {
  border-color: var(--primary-light);
}

.hover\:text-secondary-3000-dark:hover {
  color: #3b4159;
}

.hover\:bg-secondary-3000-dark:hover {
  background-color: #3b4159;
}

.hover\:border-secondary-3000-dark:hover {
  border-color: #3b4159;
}

.hover\:text-secondary-3000-hover-dark:hover {
  color: #575d77;
}

.hover\:bg-secondary-3000-hover-dark:hover {
  background-color: #575d77;
}

.hover\:border-secondary-3000-hover-dark:hover {
  border-color: #575d77;
}

.hover\:text-accent-3000-dark:hover {
  color: #1d1f27;
}

.hover\:bg-accent-3000-dark:hover {
  background-color: #1d1f27;
}

.hover\:border-accent-3000-dark:hover {
  border-color: #1d1f27;
}

.hover\:text-bg-3000-dark:hover {
  color: #151619;
}

.hover\:bg-bg-3000-dark:hover {
  background-color: #151619;
}

.hover\:border-bg-3000-dark:hover {
  border-color: #151619;
}

.hover\:text-border-3000-dark:hover {
  color: #2b2c32;
}

.hover\:bg-border-3000-dark:hover {
  background-color: #2b2c32;
}

.hover\:border-border-3000-dark:hover {
  border-color: #2b2c32;
}

.hover\:text-border-bold-3000-dark:hover {
  color: #3f4046;
}

.hover\:bg-border-bold-3000-dark:hover {
  background-color: #3f4046;
}

.hover\:border-border-bold-3000-dark:hover {
  border-color: #3f4046;
}

.hover\:text-glass-bg-3000-dark:hover {
  color: rgba(29, 31, 39, 0.7019607843);
}

.hover\:bg-glass-bg-3000-dark:hover {
  background-color: rgba(29, 31, 39, 0.7019607843);
}

.hover\:border-glass-bg-3000-dark:hover {
  border-color: rgba(29, 31, 39, 0.7019607843);
}

.hover\:text-glass-border-3000-dark:hover {
  color: var(--border-3000-dark);
}

.hover\:bg-glass-border-3000-dark:hover {
  background-color: var(--border-3000-dark);
}

.hover\:border-glass-border-3000-dark:hover {
  border-color: var(--border-3000-dark);
}

.hover\:text-link-3000-dark:hover {
  color: rgb(47, 129, 247);
}

.hover\:bg-link-3000-dark:hover {
  background-color: rgb(47, 129, 247);
}

.hover\:border-link-3000-dark:hover {
  border-color: rgb(47, 129, 247);
}

.hover\:text-text-3000:hover {
  color: var(--text-3000);
}

.hover\:bg-text-3000:hover {
  background-color: var(--text-3000);
}

.hover\:border-text-3000:hover {
  border-color: var(--text-3000);
}

.hover\:text-muted-3000:hover {
  color: var(--muted-3000);
}

.hover\:bg-muted-3000:hover {
  background-color: var(--muted-3000);
}

.hover\:border-muted-3000:hover {
  border-color: var(--muted-3000);
}

.hover\:text-trace-3000:hover {
  color: var(--trace-3000);
}

.hover\:bg-trace-3000:hover {
  background-color: var(--trace-3000);
}

.hover\:border-trace-3000:hover {
  border-color: var(--trace-3000);
}

.hover\:text-primary-3000:hover {
  color: var(--primary-3000);
}

.hover\:bg-primary-3000:hover {
  background-color: var(--primary-3000);
}

.hover\:border-primary-3000:hover {
  border-color: var(--primary-3000);
}

.hover\:text-primary-3000-hover:hover {
  color: var(--primary-3000-hover);
}

.hover\:bg-primary-3000-hover:hover {
  background-color: var(--primary-3000-hover);
}

.hover\:border-primary-3000-hover:hover {
  border-color: var(--primary-3000-hover);
}

.hover\:text-secondary-3000:hover {
  color: var(--secondary-3000);
}

.hover\:bg-secondary-3000:hover {
  background-color: var(--secondary-3000);
}

.hover\:border-secondary-3000:hover {
  border-color: var(--secondary-3000);
}

.hover\:text-secondary-3000-hover:hover {
  color: var(--secondary-3000-hover);
}

.hover\:bg-secondary-3000-hover:hover {
  background-color: var(--secondary-3000-hover);
}

.hover\:border-secondary-3000-hover:hover {
  border-color: var(--secondary-3000-hover);
}

.hover\:text-accent-3000:hover {
  color: var(--accent-3000);
}

.hover\:bg-accent-3000:hover {
  background-color: var(--accent-3000);
}

.hover\:border-accent-3000:hover {
  border-color: var(--accent-3000);
}

.hover\:text-bg-3000:hover {
  color: var(--bg-3000);
}

.hover\:bg-bg-3000:hover {
  background-color: var(--bg-3000);
}

.hover\:border-bg-3000:hover {
  border-color: var(--bg-3000);
}

.hover\:text-border-3000:hover {
  color: var(--border-3000);
}

.hover\:bg-border-3000:hover {
  background-color: var(--border-3000);
}

.hover\:border-border-3000:hover {
  border-color: var(--border-3000);
}

.hover\:text-border-bold-3000:hover {
  color: var(--border-bold-3000);
}

.hover\:bg-border-bold-3000:hover {
  background-color: var(--border-bold-3000);
}

.hover\:border-border-bold-3000:hover {
  border-color: var(--border-bold-3000);
}

.hover\:text-glass-bg-3000:hover {
  color: var(--glass-bg-3000);
}

.hover\:bg-glass-bg-3000:hover {
  background-color: var(--glass-bg-3000);
}

.hover\:border-glass-bg-3000:hover {
  border-color: var(--glass-bg-3000);
}

.hover\:text-glass-border-3000:hover {
  color: var(--glass-border-3000);
}

.hover\:bg-glass-border-3000:hover {
  background-color: var(--glass-border-3000);
}

.hover\:border-glass-border-3000:hover {
  border-color: var(--glass-border-3000);
}

.hover\:text-link-3000:hover {
  color: var(--link-3000);
}

.hover\:bg-link-3000:hover {
  background-color: var(--link-3000);
}

.hover\:border-link-3000:hover {
  border-color: var(--link-3000);
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.w-min {
  width: min-content;
}

.w-max {
  width: max-content;
}

.w-fit {
  width: fit-content;
}

.min-w-full {
  min-width: 100%;
}

.min-w-screen {
  min-width: 100vw;
}

.max-w-full {
  max-width: 100%;
}

.max-w-screen {
  max-width: 100vw;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-1\/4 {
  width: 25%;
}

.w-2\/4 {
  width: 50%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-1\/6 {
  width: 16.66667%;
}

.w-2\/6 {
  width: 33.33333%;
}

.w-3\/6 {
  width: 50%;
}

.w-4\/6 {
  width: 66.66667%;
}

.w-5\/6 {
  width: 83.33333%;
}

.max-w-1\/2 {
  max-width: 50%;
}

.max-w-1\/3 {
  max-width: 33.3333%;
}

.max-w-2\/3 {
  max-width: 66.6667%;
}

.max-w-1\/4 {
  max-width: 25%;
}

.max-w-2\/4 {
  max-width: 50%;
}

.max-w-3\/4 {
  max-width: 75%;
}

.max-w-1\/5 {
  max-width: 20%;
}

.max-w-2\/5 {
  max-width: 40%;
}

.max-w-3\/5 {
  max-width: 60%;
}

.max-w-4\/5 {
  max-width: 80%;
}

.max-w-1\/6 {
  max-width: 16.66667%;
}

.max-w-2\/6 {
  max-width: 33.33333%;
}

.max-w-3\/6 {
  max-width: 50%;
}

.max-w-4\/6 {
  max-width: 66.66667%;
}

.max-w-5\/6 {
  max-width: 83.33333%;
}

.min-w-1\/2 {
  min-width: 50%;
}

.min-w-1\/3 {
  min-width: 33.3333%;
}

.min-w-2\/3 {
  min-width: 66.6667%;
}

.min-w-1\/4 {
  min-width: 25%;
}

.min-w-2\/4 {
  min-width: 50%;
}

.min-w-3\/4 {
  min-width: 75%;
}

.min-w-1\/5 {
  min-width: 20%;
}

.min-w-2\/5 {
  min-width: 40%;
}

.min-w-3\/5 {
  min-width: 60%;
}

.min-w-4\/5 {
  min-width: 80%;
}

.min-w-1\/6 {
  min-width: 16.66667%;
}

.min-w-2\/6 {
  min-width: 33.33333%;
}

.min-w-3\/6 {
  min-width: 50%;
}

.min-w-4\/6 {
  min-width: 66.66667%;
}

.min-w-5\/6 {
  min-width: 83.33333%;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.h-min {
  height: min-content;
}

.h-max {
  height: max-content;
}

.h-fit {
  height: fit-content;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.max-h-full {
  max-height: 100%;
}

.max-h-screen {
  max-height: 100vh;
}

.h-1\/2 {
  height: 50%;
}

.h-1\/3 {
  height: 33.3333%;
}

.h-2\/3 {
  height: 66.6667%;
}

.h-1\/4 {
  height: 25%;
}

.h-2\/4 {
  height: 50%;
}

.h-3\/4 {
  height: 75%;
}

.h-1\/5 {
  height: 20%;
}

.h-2\/5 {
  height: 40%;
}

.h-3\/5 {
  height: 60%;
}

.h-4\/5 {
  height: 80%;
}

.h-1\/6 {
  height: 16.66667%;
}

.h-2\/6 {
  height: 33.33333%;
}

.h-3\/6 {
  height: 50%;
}

.h-4\/6 {
  height: 66.66667%;
}

.h-5\/6 {
  height: 83.33333%;
}

.max-h-1\/2 {
  max-height: 50%;
}

.max-h-1\/3 {
  max-height: 33.3333%;
}

.max-h-2\/3 {
  max-height: 66.6667%;
}

.max-h-1\/4 {
  max-height: 25%;
}

.max-h-2\/4 {
  max-height: 50%;
}

.max-h-3\/4 {
  max-height: 75%;
}

.max-h-1\/5 {
  max-height: 20%;
}

.max-h-2\/5 {
  max-height: 40%;
}

.max-h-3\/5 {
  max-height: 60%;
}

.max-h-4\/5 {
  max-height: 80%;
}

.max-h-1\/6 {
  max-height: 16.66667%;
}

.max-h-2\/6 {
  max-height: 33.33333%;
}

.max-h-3\/6 {
  max-height: 50%;
}

.max-h-4\/6 {
  max-height: 66.66667%;
}

.max-h-5\/6 {
  max-height: 83.33333%;
}

.min-h-1\/2 {
  min-height: 50%;
}

.min-h-1\/3 {
  min-height: 33.3333%;
}

.min-h-2\/3 {
  min-height: 66.6667%;
}

.min-h-1\/4 {
  min-height: 25%;
}

.min-h-2\/4 {
  min-height: 50%;
}

.min-h-3\/4 {
  min-height: 75%;
}

.min-h-1\/5 {
  min-height: 20%;
}

.min-h-2\/5 {
  min-height: 40%;
}

.min-h-3\/5 {
  min-height: 60%;
}

.min-h-4\/5 {
  min-height: 80%;
}

.min-h-1\/6 {
  min-height: 16.66667%;
}

.min-h-2\/6 {
  min-height: 33.33333%;
}

.min-h-3\/6 {
  min-height: 50%;
}

.min-h-4\/6 {
  min-height: 66.66667%;
}

.min-h-5\/6 {
  min-height: 83.33333%;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.align-middle {
  vertical-align: middle;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.hidden {
  display: none;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

.flex-7 {
  flex: 7;
}

.flex-8 {
  flex: 8;
}

.flex-9 {
  flex: 9;
}

.flex-10 {
  flex: 10;
}

.flex-auto {
  flex: auto;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.self-baseline {
  align-self: baseline;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-none {
  grid-template-columns: none;
}

.col-auto {
  grid-column: auto;
}

.col-span-1 {
  grid-column: span 1/span 1;
}

.col-span-2 {
  grid-column: span 2/span 2;
}

.col-span-3 {
  grid-column: span 3/span 3;
}

.col-span-4 {
  grid-column: span 4/span 4;
}

.col-span-5 {
  grid-column: span 5/span 5;
}

.col-span-6 {
  grid-column: span 6/span 6;
}

.col-span-7 {
  grid-column: span 7/span 7;
}

.col-span-8 {
  grid-column: span 8/span 8;
}

.col-span-9 {
  grid-column: span 9/span 9;
}

.col-span-10 {
  grid-column: span 10/span 10;
}

.col-span-11 {
  grid-column: span 11/span 11;
}

.col-span-12 {
  grid-column: span 12/span 12;
}

.col-span-full {
  grid-column: 1/-1;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-start-7 {
  grid-column-start: 7;
}

.col-start-8 {
  grid-column-start: 8;
}

.col-start-9 {
  grid-column-start: 9;
}

.col-start-10 {
  grid-column-start: 10;
}

.col-start-11 {
  grid-column-start: 11;
}

.col-start-12 {
  grid-column-start: 12;
}

.col-start-13 {
  grid-column-start: 13;
}

.col-start-auto {
  grid-column-start: auto;
}

.col-end-1 {
  grid-column-end: 1;
}

.col-end-2 {
  grid-column-end: 2;
}

.col-end-3 {
  grid-column-end: 3;
}

.col-end-4 {
  grid-column-end: 4;
}

.col-end-5 {
  grid-column-end: 5;
}

.col-end-6 {
  grid-column-end: 6;
}

.col-end-7 {
  grid-column-end: 7;
}

.col-end-8 {
  grid-column-end: 8;
}

.col-end-9 {
  grid-column-end: 9;
}

.col-end-10 {
  grid-column-end: 10;
}

.col-end-11 {
  grid-column-end: 11;
}

.col-end-12 {
  grid-column-end: 12;
}

.col-end-13 {
  grid-column-end: 13;
}

.col-end-auto {
  grid-column-end: auto;
}

.row-auto {
  grid-row: auto;
}

.row-span-1 {
  grid-row: span 1/span 1;
}

.row-span-2 {
  grid-row: span 2/span 2;
}

.row-span-3 {
  grid-row: span 3/span 3;
}

.row-span-4 {
  grid-row: span 4/span 4;
}

.row-span-5 {
  grid-row: span 5/span 5;
}

.row-span-6 {
  grid-row: span 6/span 6;
}

.row-span-full {
  grid-row: 1/-1;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-start-4 {
  grid-row-start: 4;
}

.row-start-5 {
  grid-row-start: 5;
}

.row-start-6 {
  grid-row-start: 6;
}

.row-start-7 {
  grid-row-start: 7;
}

.row-start-auto {
  grid-row-start: auto;
}

.row-end-1 {
  grid-row-end: 1;
}

.row-end-2 {
  grid-row-end: 2;
}

.row-end-3 {
  grid-row-end: 3;
}

.row-end-4 {
  grid-row-end: 4;
}

.row-end-5 {
  grid-row-end: 5;
}

.row-end-6 {
  grid-row-end: 6;
}

.row-end-7 {
  grid-row-end: 7;
}

.row-end-auto {
  grid-row-end: auto;
}

.gap-0 {
  gap: 0rem;
}

.gap-x-0 {
  column-gap: 0rem;
}

.gap-y-0 {
  row-gap: 0rem;
}

.gap-0\.5 {
  gap: 0.125rem;
}

.gap-x-0\.5 {
  column-gap: 0.125rem;
}

.gap-y-0\.5 {
  row-gap: 0.125rem;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-x-1 {
  column-gap: 0.25rem;
}

.gap-y-1 {
  row-gap: 0.25rem;
}

.gap-1\.5 {
  gap: 0.375rem;
}

.gap-x-1\.5 {
  column-gap: 0.375rem;
}

.gap-y-1\.5 {
  row-gap: 0.375rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-x-2 {
  column-gap: 0.5rem;
}

.gap-y-2 {
  row-gap: 0.5rem;
}

.gap-2\.5 {
  gap: 0.625rem;
}

.gap-x-2\.5 {
  column-gap: 0.625rem;
}

.gap-y-2\.5 {
  row-gap: 0.625rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-x-3 {
  column-gap: 0.75rem;
}

.gap-y-3 {
  row-gap: 0.75rem;
}

.gap-3\.5 {
  gap: 0.875rem;
}

.gap-x-3\.5 {
  column-gap: 0.875rem;
}

.gap-y-3\.5 {
  row-gap: 0.875rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-x-7 {
  column-gap: 1.75rem;
}

.gap-y-7 {
  row-gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.gap-9 {
  gap: 2.25rem;
}

.gap-x-9 {
  column-gap: 2.25rem;
}

.gap-y-9 {
  row-gap: 2.25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-11 {
  gap: 2.75rem;
}

.gap-x-11 {
  column-gap: 2.75rem;
}

.gap-y-11 {
  row-gap: 2.75rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-13 {
  gap: 3.25rem;
}

.gap-x-13 {
  column-gap: 3.25rem;
}

.gap-y-13 {
  row-gap: 3.25rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-x-14 {
  column-gap: 3.5rem;
}

.gap-y-14 {
  row-gap: 3.5rem;
}

.gap-15 {
  gap: 3.75rem;
}

.gap-x-15 {
  column-gap: 3.75rem;
}

.gap-y-15 {
  row-gap: 3.75rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-x-16 {
  column-gap: 4rem;
}

.gap-y-16 {
  row-gap: 4rem;
}

.gap-17 {
  gap: 4.25rem;
}

.gap-x-17 {
  column-gap: 4.25rem;
}

.gap-y-17 {
  row-gap: 4.25rem;
}

.gap-18 {
  gap: 4.5rem;
}

.gap-x-18 {
  column-gap: 4.5rem;
}

.gap-y-18 {
  row-gap: 4.5rem;
}

.gap-19 {
  gap: 4.75rem;
}

.gap-x-19 {
  column-gap: 4.75rem;
}

.gap-y-19 {
  row-gap: 4.75rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-x-20 {
  column-gap: 5rem;
}

.gap-y-20 {
  row-gap: 5rem;
}

.gap-24 {
  gap: 6rem;
}

.gap-x-24 {
  column-gap: 6rem;
}

.gap-y-24 {
  row-gap: 6rem;
}

.gap-30 {
  gap: 7.5rem;
}

.gap-x-30 {
  column-gap: 7.5rem;
}

.gap-y-30 {
  row-gap: 7.5rem;
}

.gap-32 {
  gap: 8rem;
}

.gap-x-32 {
  column-gap: 8rem;
}

.gap-y-32 {
  row-gap: 8rem;
}

.gap-40 {
  gap: 10rem;
}

.gap-x-40 {
  column-gap: 10rem;
}

.gap-y-40 {
  row-gap: 10rem;
}

.gap-50 {
  gap: 12.5rem;
}

.gap-x-50 {
  column-gap: 12.5rem;
}

.gap-y-50 {
  row-gap: 12.5rem;
}

.gap-60 {
  gap: 15rem;
}

.gap-x-60 {
  column-gap: 15rem;
}

.gap-y-60 {
  row-gap: 15rem;
}

.gap-80 {
  gap: 20rem;
}

.gap-x-80 {
  column-gap: 20rem;
}

.gap-y-80 {
  row-gap: 20rem;
}

.gap-100 {
  gap: 25rem;
}

.gap-x-100 {
  column-gap: 25rem;
}

.gap-y-100 {
  row-gap: 25rem;
}

.gap-120 {
  gap: 30rem;
}

.gap-x-120 {
  column-gap: 30rem;
}

.gap-y-120 {
  row-gap: 30rem;
}

.gap-140 {
  gap: 35rem;
}

.gap-x-140 {
  column-gap: 35rem;
}

.gap-y-140 {
  row-gap: 35rem;
}

.gap-160 {
  gap: 40rem;
}

.gap-x-160 {
  column-gap: 40rem;
}

.gap-y-160 {
  row-gap: 40rem;
}

.gap-180 {
  gap: 45rem;
}

.gap-x-180 {
  column-gap: 45rem;
}

.gap-y-180 {
  row-gap: 45rem;
}

.gap-200 {
  gap: 50rem;
}

.gap-x-200 {
  column-gap: 50rem;
}

.gap-y-200 {
  row-gap: 50rem;
}

.gap-px {
  gap: 1px;
}

.gap-x-px {
  column-gap: 1px;
}

.gap-y-px {
  row-gap: 1px;
}

@media screen and (min-width: 576px) {
  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .sm\:grid-cols-none {
    grid-template-columns: none;
  }
  .sm\:col-auto {
    grid-column: auto;
  }
  .sm\:col-span-1 {
    grid-column: span 1/span 1;
  }
  .sm\:col-span-2 {
    grid-column: span 2/span 2;
  }
  .sm\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .sm\:col-span-4 {
    grid-column: span 4/span 4;
  }
  .sm\:col-span-5 {
    grid-column: span 5/span 5;
  }
  .sm\:col-span-6 {
    grid-column: span 6/span 6;
  }
  .sm\:col-span-7 {
    grid-column: span 7/span 7;
  }
  .sm\:col-span-8 {
    grid-column: span 8/span 8;
  }
  .sm\:col-span-9 {
    grid-column: span 9/span 9;
  }
  .sm\:col-span-10 {
    grid-column: span 10/span 10;
  }
  .sm\:col-span-11 {
    grid-column: span 11/span 11;
  }
  .sm\:col-span-12 {
    grid-column: span 12/span 12;
  }
  .sm\:col-span-full {
    grid-column: 1/-1;
  }
  .sm\:col-start-1 {
    grid-column-start: 1;
  }
  .sm\:col-start-2 {
    grid-column-start: 2;
  }
  .sm\:col-start-3 {
    grid-column-start: 3;
  }
  .sm\:col-start-4 {
    grid-column-start: 4;
  }
  .sm\:col-start-5 {
    grid-column-start: 5;
  }
  .sm\:col-start-6 {
    grid-column-start: 6;
  }
  .sm\:col-start-7 {
    grid-column-start: 7;
  }
  .sm\:col-start-8 {
    grid-column-start: 8;
  }
  .sm\:col-start-9 {
    grid-column-start: 9;
  }
  .sm\:col-start-10 {
    grid-column-start: 10;
  }
  .sm\:col-start-11 {
    grid-column-start: 11;
  }
  .sm\:col-start-12 {
    grid-column-start: 12;
  }
  .sm\:col-start-13 {
    grid-column-start: 13;
  }
  .sm\:col-start-auto {
    grid-column-start: auto;
  }
  .sm\:col-end-1 {
    grid-column-end: 1;
  }
  .sm\:col-end-2 {
    grid-column-end: 2;
  }
  .sm\:col-end-3 {
    grid-column-end: 3;
  }
  .sm\:col-end-4 {
    grid-column-end: 4;
  }
  .sm\:col-end-5 {
    grid-column-end: 5;
  }
  .sm\:col-end-6 {
    grid-column-end: 6;
  }
  .sm\:col-end-7 {
    grid-column-end: 7;
  }
  .sm\:col-end-8 {
    grid-column-end: 8;
  }
  .sm\:col-end-9 {
    grid-column-end: 9;
  }
  .sm\:col-end-10 {
    grid-column-end: 10;
  }
  .sm\:col-end-11 {
    grid-column-end: 11;
  }
  .sm\:col-end-12 {
    grid-column-end: 12;
  }
  .sm\:col-end-13 {
    grid-column-end: 13;
  }
  .sm\:col-end-auto {
    grid-column-end: auto;
  }
  .sm\:row-auto {
    grid-row: auto;
  }
  .sm\:row-span-1 {
    grid-row: span 1/span 1;
  }
  .sm\:row-span-2 {
    grid-row: span 2/span 2;
  }
  .sm\:row-span-3 {
    grid-row: span 3/span 3;
  }
  .sm\:row-span-4 {
    grid-row: span 4/span 4;
  }
  .sm\:row-span-5 {
    grid-row: span 5/span 5;
  }
  .sm\:row-span-6 {
    grid-row: span 6/span 6;
  }
  .sm\:row-span-full {
    grid-row: 1/-1;
  }
  .sm\:row-start-1 {
    grid-row-start: 1;
  }
  .sm\:row-start-2 {
    grid-row-start: 2;
  }
  .sm\:row-start-3 {
    grid-row-start: 3;
  }
  .sm\:row-start-4 {
    grid-row-start: 4;
  }
  .sm\:row-start-5 {
    grid-row-start: 5;
  }
  .sm\:row-start-6 {
    grid-row-start: 6;
  }
  .sm\:row-start-7 {
    grid-row-start: 7;
  }
  .sm\:row-start-auto {
    grid-row-start: auto;
  }
  .sm\:row-end-1 {
    grid-row-end: 1;
  }
  .sm\:row-end-2 {
    grid-row-end: 2;
  }
  .sm\:row-end-3 {
    grid-row-end: 3;
  }
  .sm\:row-end-4 {
    grid-row-end: 4;
  }
  .sm\:row-end-5 {
    grid-row-end: 5;
  }
  .sm\:row-end-6 {
    grid-row-end: 6;
  }
  .sm\:row-end-7 {
    grid-row-end: 7;
  }
  .sm\:row-end-auto {
    grid-row-end: auto;
  }
  .sm\:gap-0 {
    gap: 0rem;
  }
  .sm\:gap-x-0 {
    column-gap: 0rem;
  }
  .sm\:gap-y-0 {
    row-gap: 0rem;
  }
  .sm\:gap-0\.5 {
    gap: 0.125rem;
  }
  .sm\:gap-x-0\.5 {
    column-gap: 0.125rem;
  }
  .sm\:gap-y-0\.5 {
    row-gap: 0.125rem;
  }
  .sm\:gap-1 {
    gap: 0.25rem;
  }
  .sm\:gap-x-1 {
    column-gap: 0.25rem;
  }
  .sm\:gap-y-1 {
    row-gap: 0.25rem;
  }
  .sm\:gap-1\.5 {
    gap: 0.375rem;
  }
  .sm\:gap-x-1\.5 {
    column-gap: 0.375rem;
  }
  .sm\:gap-y-1\.5 {
    row-gap: 0.375rem;
  }
  .sm\:gap-2 {
    gap: 0.5rem;
  }
  .sm\:gap-x-2 {
    column-gap: 0.5rem;
  }
  .sm\:gap-y-2 {
    row-gap: 0.5rem;
  }
  .sm\:gap-2\.5 {
    gap: 0.625rem;
  }
  .sm\:gap-x-2\.5 {
    column-gap: 0.625rem;
  }
  .sm\:gap-y-2\.5 {
    row-gap: 0.625rem;
  }
  .sm\:gap-3 {
    gap: 0.75rem;
  }
  .sm\:gap-x-3 {
    column-gap: 0.75rem;
  }
  .sm\:gap-y-3 {
    row-gap: 0.75rem;
  }
  .sm\:gap-3\.5 {
    gap: 0.875rem;
  }
  .sm\:gap-x-3\.5 {
    column-gap: 0.875rem;
  }
  .sm\:gap-y-3\.5 {
    row-gap: 0.875rem;
  }
  .sm\:gap-4 {
    gap: 1rem;
  }
  .sm\:gap-x-4 {
    column-gap: 1rem;
  }
  .sm\:gap-y-4 {
    row-gap: 1rem;
  }
  .sm\:gap-5 {
    gap: 1.25rem;
  }
  .sm\:gap-x-5 {
    column-gap: 1.25rem;
  }
  .sm\:gap-y-5 {
    row-gap: 1.25rem;
  }
  .sm\:gap-6 {
    gap: 1.5rem;
  }
  .sm\:gap-x-6 {
    column-gap: 1.5rem;
  }
  .sm\:gap-y-6 {
    row-gap: 1.5rem;
  }
  .sm\:gap-7 {
    gap: 1.75rem;
  }
  .sm\:gap-x-7 {
    column-gap: 1.75rem;
  }
  .sm\:gap-y-7 {
    row-gap: 1.75rem;
  }
  .sm\:gap-8 {
    gap: 2rem;
  }
  .sm\:gap-x-8 {
    column-gap: 2rem;
  }
  .sm\:gap-y-8 {
    row-gap: 2rem;
  }
  .sm\:gap-9 {
    gap: 2.25rem;
  }
  .sm\:gap-x-9 {
    column-gap: 2.25rem;
  }
  .sm\:gap-y-9 {
    row-gap: 2.25rem;
  }
  .sm\:gap-10 {
    gap: 2.5rem;
  }
  .sm\:gap-x-10 {
    column-gap: 2.5rem;
  }
  .sm\:gap-y-10 {
    row-gap: 2.5rem;
  }
  .sm\:gap-11 {
    gap: 2.75rem;
  }
  .sm\:gap-x-11 {
    column-gap: 2.75rem;
  }
  .sm\:gap-y-11 {
    row-gap: 2.75rem;
  }
  .sm\:gap-12 {
    gap: 3rem;
  }
  .sm\:gap-x-12 {
    column-gap: 3rem;
  }
  .sm\:gap-y-12 {
    row-gap: 3rem;
  }
  .sm\:gap-13 {
    gap: 3.25rem;
  }
  .sm\:gap-x-13 {
    column-gap: 3.25rem;
  }
  .sm\:gap-y-13 {
    row-gap: 3.25rem;
  }
  .sm\:gap-14 {
    gap: 3.5rem;
  }
  .sm\:gap-x-14 {
    column-gap: 3.5rem;
  }
  .sm\:gap-y-14 {
    row-gap: 3.5rem;
  }
  .sm\:gap-15 {
    gap: 3.75rem;
  }
  .sm\:gap-x-15 {
    column-gap: 3.75rem;
  }
  .sm\:gap-y-15 {
    row-gap: 3.75rem;
  }
  .sm\:gap-16 {
    gap: 4rem;
  }
  .sm\:gap-x-16 {
    column-gap: 4rem;
  }
  .sm\:gap-y-16 {
    row-gap: 4rem;
  }
  .sm\:gap-17 {
    gap: 4.25rem;
  }
  .sm\:gap-x-17 {
    column-gap: 4.25rem;
  }
  .sm\:gap-y-17 {
    row-gap: 4.25rem;
  }
  .sm\:gap-18 {
    gap: 4.5rem;
  }
  .sm\:gap-x-18 {
    column-gap: 4.5rem;
  }
  .sm\:gap-y-18 {
    row-gap: 4.5rem;
  }
  .sm\:gap-19 {
    gap: 4.75rem;
  }
  .sm\:gap-x-19 {
    column-gap: 4.75rem;
  }
  .sm\:gap-y-19 {
    row-gap: 4.75rem;
  }
  .sm\:gap-20 {
    gap: 5rem;
  }
  .sm\:gap-x-20 {
    column-gap: 5rem;
  }
  .sm\:gap-y-20 {
    row-gap: 5rem;
  }
  .sm\:gap-24 {
    gap: 6rem;
  }
  .sm\:gap-x-24 {
    column-gap: 6rem;
  }
  .sm\:gap-y-24 {
    row-gap: 6rem;
  }
  .sm\:gap-30 {
    gap: 7.5rem;
  }
  .sm\:gap-x-30 {
    column-gap: 7.5rem;
  }
  .sm\:gap-y-30 {
    row-gap: 7.5rem;
  }
  .sm\:gap-32 {
    gap: 8rem;
  }
  .sm\:gap-x-32 {
    column-gap: 8rem;
  }
  .sm\:gap-y-32 {
    row-gap: 8rem;
  }
  .sm\:gap-40 {
    gap: 10rem;
  }
  .sm\:gap-x-40 {
    column-gap: 10rem;
  }
  .sm\:gap-y-40 {
    row-gap: 10rem;
  }
  .sm\:gap-50 {
    gap: 12.5rem;
  }
  .sm\:gap-x-50 {
    column-gap: 12.5rem;
  }
  .sm\:gap-y-50 {
    row-gap: 12.5rem;
  }
  .sm\:gap-60 {
    gap: 15rem;
  }
  .sm\:gap-x-60 {
    column-gap: 15rem;
  }
  .sm\:gap-y-60 {
    row-gap: 15rem;
  }
  .sm\:gap-80 {
    gap: 20rem;
  }
  .sm\:gap-x-80 {
    column-gap: 20rem;
  }
  .sm\:gap-y-80 {
    row-gap: 20rem;
  }
  .sm\:gap-100 {
    gap: 25rem;
  }
  .sm\:gap-x-100 {
    column-gap: 25rem;
  }
  .sm\:gap-y-100 {
    row-gap: 25rem;
  }
  .sm\:gap-120 {
    gap: 30rem;
  }
  .sm\:gap-x-120 {
    column-gap: 30rem;
  }
  .sm\:gap-y-120 {
    row-gap: 30rem;
  }
  .sm\:gap-140 {
    gap: 35rem;
  }
  .sm\:gap-x-140 {
    column-gap: 35rem;
  }
  .sm\:gap-y-140 {
    row-gap: 35rem;
  }
  .sm\:gap-160 {
    gap: 40rem;
  }
  .sm\:gap-x-160 {
    column-gap: 40rem;
  }
  .sm\:gap-y-160 {
    row-gap: 40rem;
  }
  .sm\:gap-180 {
    gap: 45rem;
  }
  .sm\:gap-x-180 {
    column-gap: 45rem;
  }
  .sm\:gap-y-180 {
    row-gap: 45rem;
  }
  .sm\:gap-200 {
    gap: 50rem;
  }
  .sm\:gap-x-200 {
    column-gap: 50rem;
  }
  .sm\:gap-y-200 {
    row-gap: 50rem;
  }
  .sm\:gap-px {
    gap: 1px;
  }
  .sm\:gap-x-px {
    column-gap: 1px;
  }
  .sm\:gap-y-px {
    row-gap: 1px;
  }
}
@media screen and (min-width: 768px) {
  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .md\:grid-cols-none {
    grid-template-columns: none;
  }
  .md\:col-auto {
    grid-column: auto;
  }
  .md\:col-span-1 {
    grid-column: span 1/span 1;
  }
  .md\:col-span-2 {
    grid-column: span 2/span 2;
  }
  .md\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .md\:col-span-4 {
    grid-column: span 4/span 4;
  }
  .md\:col-span-5 {
    grid-column: span 5/span 5;
  }
  .md\:col-span-6 {
    grid-column: span 6/span 6;
  }
  .md\:col-span-7 {
    grid-column: span 7/span 7;
  }
  .md\:col-span-8 {
    grid-column: span 8/span 8;
  }
  .md\:col-span-9 {
    grid-column: span 9/span 9;
  }
  .md\:col-span-10 {
    grid-column: span 10/span 10;
  }
  .md\:col-span-11 {
    grid-column: span 11/span 11;
  }
  .md\:col-span-12 {
    grid-column: span 12/span 12;
  }
  .md\:col-span-full {
    grid-column: 1/-1;
  }
  .md\:col-start-1 {
    grid-column-start: 1;
  }
  .md\:col-start-2 {
    grid-column-start: 2;
  }
  .md\:col-start-3 {
    grid-column-start: 3;
  }
  .md\:col-start-4 {
    grid-column-start: 4;
  }
  .md\:col-start-5 {
    grid-column-start: 5;
  }
  .md\:col-start-6 {
    grid-column-start: 6;
  }
  .md\:col-start-7 {
    grid-column-start: 7;
  }
  .md\:col-start-8 {
    grid-column-start: 8;
  }
  .md\:col-start-9 {
    grid-column-start: 9;
  }
  .md\:col-start-10 {
    grid-column-start: 10;
  }
  .md\:col-start-11 {
    grid-column-start: 11;
  }
  .md\:col-start-12 {
    grid-column-start: 12;
  }
  .md\:col-start-13 {
    grid-column-start: 13;
  }
  .md\:col-start-auto {
    grid-column-start: auto;
  }
  .md\:col-end-1 {
    grid-column-end: 1;
  }
  .md\:col-end-2 {
    grid-column-end: 2;
  }
  .md\:col-end-3 {
    grid-column-end: 3;
  }
  .md\:col-end-4 {
    grid-column-end: 4;
  }
  .md\:col-end-5 {
    grid-column-end: 5;
  }
  .md\:col-end-6 {
    grid-column-end: 6;
  }
  .md\:col-end-7 {
    grid-column-end: 7;
  }
  .md\:col-end-8 {
    grid-column-end: 8;
  }
  .md\:col-end-9 {
    grid-column-end: 9;
  }
  .md\:col-end-10 {
    grid-column-end: 10;
  }
  .md\:col-end-11 {
    grid-column-end: 11;
  }
  .md\:col-end-12 {
    grid-column-end: 12;
  }
  .md\:col-end-13 {
    grid-column-end: 13;
  }
  .md\:col-end-auto {
    grid-column-end: auto;
  }
  .md\:row-auto {
    grid-row: auto;
  }
  .md\:row-span-1 {
    grid-row: span 1/span 1;
  }
  .md\:row-span-2 {
    grid-row: span 2/span 2;
  }
  .md\:row-span-3 {
    grid-row: span 3/span 3;
  }
  .md\:row-span-4 {
    grid-row: span 4/span 4;
  }
  .md\:row-span-5 {
    grid-row: span 5/span 5;
  }
  .md\:row-span-6 {
    grid-row: span 6/span 6;
  }
  .md\:row-span-full {
    grid-row: 1/-1;
  }
  .md\:row-start-1 {
    grid-row-start: 1;
  }
  .md\:row-start-2 {
    grid-row-start: 2;
  }
  .md\:row-start-3 {
    grid-row-start: 3;
  }
  .md\:row-start-4 {
    grid-row-start: 4;
  }
  .md\:row-start-5 {
    grid-row-start: 5;
  }
  .md\:row-start-6 {
    grid-row-start: 6;
  }
  .md\:row-start-7 {
    grid-row-start: 7;
  }
  .md\:row-start-auto {
    grid-row-start: auto;
  }
  .md\:row-end-1 {
    grid-row-end: 1;
  }
  .md\:row-end-2 {
    grid-row-end: 2;
  }
  .md\:row-end-3 {
    grid-row-end: 3;
  }
  .md\:row-end-4 {
    grid-row-end: 4;
  }
  .md\:row-end-5 {
    grid-row-end: 5;
  }
  .md\:row-end-6 {
    grid-row-end: 6;
  }
  .md\:row-end-7 {
    grid-row-end: 7;
  }
  .md\:row-end-auto {
    grid-row-end: auto;
  }
  .md\:gap-0 {
    gap: 0rem;
  }
  .md\:gap-x-0 {
    column-gap: 0rem;
  }
  .md\:gap-y-0 {
    row-gap: 0rem;
  }
  .md\:gap-0\.5 {
    gap: 0.125rem;
  }
  .md\:gap-x-0\.5 {
    column-gap: 0.125rem;
  }
  .md\:gap-y-0\.5 {
    row-gap: 0.125rem;
  }
  .md\:gap-1 {
    gap: 0.25rem;
  }
  .md\:gap-x-1 {
    column-gap: 0.25rem;
  }
  .md\:gap-y-1 {
    row-gap: 0.25rem;
  }
  .md\:gap-1\.5 {
    gap: 0.375rem;
  }
  .md\:gap-x-1\.5 {
    column-gap: 0.375rem;
  }
  .md\:gap-y-1\.5 {
    row-gap: 0.375rem;
  }
  .md\:gap-2 {
    gap: 0.5rem;
  }
  .md\:gap-x-2 {
    column-gap: 0.5rem;
  }
  .md\:gap-y-2 {
    row-gap: 0.5rem;
  }
  .md\:gap-2\.5 {
    gap: 0.625rem;
  }
  .md\:gap-x-2\.5 {
    column-gap: 0.625rem;
  }
  .md\:gap-y-2\.5 {
    row-gap: 0.625rem;
  }
  .md\:gap-3 {
    gap: 0.75rem;
  }
  .md\:gap-x-3 {
    column-gap: 0.75rem;
  }
  .md\:gap-y-3 {
    row-gap: 0.75rem;
  }
  .md\:gap-3\.5 {
    gap: 0.875rem;
  }
  .md\:gap-x-3\.5 {
    column-gap: 0.875rem;
  }
  .md\:gap-y-3\.5 {
    row-gap: 0.875rem;
  }
  .md\:gap-4 {
    gap: 1rem;
  }
  .md\:gap-x-4 {
    column-gap: 1rem;
  }
  .md\:gap-y-4 {
    row-gap: 1rem;
  }
  .md\:gap-5 {
    gap: 1.25rem;
  }
  .md\:gap-x-5 {
    column-gap: 1.25rem;
  }
  .md\:gap-y-5 {
    row-gap: 1.25rem;
  }
  .md\:gap-6 {
    gap: 1.5rem;
  }
  .md\:gap-x-6 {
    column-gap: 1.5rem;
  }
  .md\:gap-y-6 {
    row-gap: 1.5rem;
  }
  .md\:gap-7 {
    gap: 1.75rem;
  }
  .md\:gap-x-7 {
    column-gap: 1.75rem;
  }
  .md\:gap-y-7 {
    row-gap: 1.75rem;
  }
  .md\:gap-8 {
    gap: 2rem;
  }
  .md\:gap-x-8 {
    column-gap: 2rem;
  }
  .md\:gap-y-8 {
    row-gap: 2rem;
  }
  .md\:gap-9 {
    gap: 2.25rem;
  }
  .md\:gap-x-9 {
    column-gap: 2.25rem;
  }
  .md\:gap-y-9 {
    row-gap: 2.25rem;
  }
  .md\:gap-10 {
    gap: 2.5rem;
  }
  .md\:gap-x-10 {
    column-gap: 2.5rem;
  }
  .md\:gap-y-10 {
    row-gap: 2.5rem;
  }
  .md\:gap-11 {
    gap: 2.75rem;
  }
  .md\:gap-x-11 {
    column-gap: 2.75rem;
  }
  .md\:gap-y-11 {
    row-gap: 2.75rem;
  }
  .md\:gap-12 {
    gap: 3rem;
  }
  .md\:gap-x-12 {
    column-gap: 3rem;
  }
  .md\:gap-y-12 {
    row-gap: 3rem;
  }
  .md\:gap-13 {
    gap: 3.25rem;
  }
  .md\:gap-x-13 {
    column-gap: 3.25rem;
  }
  .md\:gap-y-13 {
    row-gap: 3.25rem;
  }
  .md\:gap-14 {
    gap: 3.5rem;
  }
  .md\:gap-x-14 {
    column-gap: 3.5rem;
  }
  .md\:gap-y-14 {
    row-gap: 3.5rem;
  }
  .md\:gap-15 {
    gap: 3.75rem;
  }
  .md\:gap-x-15 {
    column-gap: 3.75rem;
  }
  .md\:gap-y-15 {
    row-gap: 3.75rem;
  }
  .md\:gap-16 {
    gap: 4rem;
  }
  .md\:gap-x-16 {
    column-gap: 4rem;
  }
  .md\:gap-y-16 {
    row-gap: 4rem;
  }
  .md\:gap-17 {
    gap: 4.25rem;
  }
  .md\:gap-x-17 {
    column-gap: 4.25rem;
  }
  .md\:gap-y-17 {
    row-gap: 4.25rem;
  }
  .md\:gap-18 {
    gap: 4.5rem;
  }
  .md\:gap-x-18 {
    column-gap: 4.5rem;
  }
  .md\:gap-y-18 {
    row-gap: 4.5rem;
  }
  .md\:gap-19 {
    gap: 4.75rem;
  }
  .md\:gap-x-19 {
    column-gap: 4.75rem;
  }
  .md\:gap-y-19 {
    row-gap: 4.75rem;
  }
  .md\:gap-20 {
    gap: 5rem;
  }
  .md\:gap-x-20 {
    column-gap: 5rem;
  }
  .md\:gap-y-20 {
    row-gap: 5rem;
  }
  .md\:gap-24 {
    gap: 6rem;
  }
  .md\:gap-x-24 {
    column-gap: 6rem;
  }
  .md\:gap-y-24 {
    row-gap: 6rem;
  }
  .md\:gap-30 {
    gap: 7.5rem;
  }
  .md\:gap-x-30 {
    column-gap: 7.5rem;
  }
  .md\:gap-y-30 {
    row-gap: 7.5rem;
  }
  .md\:gap-32 {
    gap: 8rem;
  }
  .md\:gap-x-32 {
    column-gap: 8rem;
  }
  .md\:gap-y-32 {
    row-gap: 8rem;
  }
  .md\:gap-40 {
    gap: 10rem;
  }
  .md\:gap-x-40 {
    column-gap: 10rem;
  }
  .md\:gap-y-40 {
    row-gap: 10rem;
  }
  .md\:gap-50 {
    gap: 12.5rem;
  }
  .md\:gap-x-50 {
    column-gap: 12.5rem;
  }
  .md\:gap-y-50 {
    row-gap: 12.5rem;
  }
  .md\:gap-60 {
    gap: 15rem;
  }
  .md\:gap-x-60 {
    column-gap: 15rem;
  }
  .md\:gap-y-60 {
    row-gap: 15rem;
  }
  .md\:gap-80 {
    gap: 20rem;
  }
  .md\:gap-x-80 {
    column-gap: 20rem;
  }
  .md\:gap-y-80 {
    row-gap: 20rem;
  }
  .md\:gap-100 {
    gap: 25rem;
  }
  .md\:gap-x-100 {
    column-gap: 25rem;
  }
  .md\:gap-y-100 {
    row-gap: 25rem;
  }
  .md\:gap-120 {
    gap: 30rem;
  }
  .md\:gap-x-120 {
    column-gap: 30rem;
  }
  .md\:gap-y-120 {
    row-gap: 30rem;
  }
  .md\:gap-140 {
    gap: 35rem;
  }
  .md\:gap-x-140 {
    column-gap: 35rem;
  }
  .md\:gap-y-140 {
    row-gap: 35rem;
  }
  .md\:gap-160 {
    gap: 40rem;
  }
  .md\:gap-x-160 {
    column-gap: 40rem;
  }
  .md\:gap-y-160 {
    row-gap: 40rem;
  }
  .md\:gap-180 {
    gap: 45rem;
  }
  .md\:gap-x-180 {
    column-gap: 45rem;
  }
  .md\:gap-y-180 {
    row-gap: 45rem;
  }
  .md\:gap-200 {
    gap: 50rem;
  }
  .md\:gap-x-200 {
    column-gap: 50rem;
  }
  .md\:gap-y-200 {
    row-gap: 50rem;
  }
  .md\:gap-px {
    gap: 1px;
  }
  .md\:gap-x-px {
    column-gap: 1px;
  }
  .md\:gap-y-px {
    row-gap: 1px;
  }
}
@media screen and (min-width: 992px) {
  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .lg\:grid-cols-none {
    grid-template-columns: none;
  }
  .lg\:col-auto {
    grid-column: auto;
  }
  .lg\:col-span-1 {
    grid-column: span 1/span 1;
  }
  .lg\:col-span-2 {
    grid-column: span 2/span 2;
  }
  .lg\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .lg\:col-span-4 {
    grid-column: span 4/span 4;
  }
  .lg\:col-span-5 {
    grid-column: span 5/span 5;
  }
  .lg\:col-span-6 {
    grid-column: span 6/span 6;
  }
  .lg\:col-span-7 {
    grid-column: span 7/span 7;
  }
  .lg\:col-span-8 {
    grid-column: span 8/span 8;
  }
  .lg\:col-span-9 {
    grid-column: span 9/span 9;
  }
  .lg\:col-span-10 {
    grid-column: span 10/span 10;
  }
  .lg\:col-span-11 {
    grid-column: span 11/span 11;
  }
  .lg\:col-span-12 {
    grid-column: span 12/span 12;
  }
  .lg\:col-span-full {
    grid-column: 1/-1;
  }
  .lg\:col-start-1 {
    grid-column-start: 1;
  }
  .lg\:col-start-2 {
    grid-column-start: 2;
  }
  .lg\:col-start-3 {
    grid-column-start: 3;
  }
  .lg\:col-start-4 {
    grid-column-start: 4;
  }
  .lg\:col-start-5 {
    grid-column-start: 5;
  }
  .lg\:col-start-6 {
    grid-column-start: 6;
  }
  .lg\:col-start-7 {
    grid-column-start: 7;
  }
  .lg\:col-start-8 {
    grid-column-start: 8;
  }
  .lg\:col-start-9 {
    grid-column-start: 9;
  }
  .lg\:col-start-10 {
    grid-column-start: 10;
  }
  .lg\:col-start-11 {
    grid-column-start: 11;
  }
  .lg\:col-start-12 {
    grid-column-start: 12;
  }
  .lg\:col-start-13 {
    grid-column-start: 13;
  }
  .lg\:col-start-auto {
    grid-column-start: auto;
  }
  .lg\:col-end-1 {
    grid-column-end: 1;
  }
  .lg\:col-end-2 {
    grid-column-end: 2;
  }
  .lg\:col-end-3 {
    grid-column-end: 3;
  }
  .lg\:col-end-4 {
    grid-column-end: 4;
  }
  .lg\:col-end-5 {
    grid-column-end: 5;
  }
  .lg\:col-end-6 {
    grid-column-end: 6;
  }
  .lg\:col-end-7 {
    grid-column-end: 7;
  }
  .lg\:col-end-8 {
    grid-column-end: 8;
  }
  .lg\:col-end-9 {
    grid-column-end: 9;
  }
  .lg\:col-end-10 {
    grid-column-end: 10;
  }
  .lg\:col-end-11 {
    grid-column-end: 11;
  }
  .lg\:col-end-12 {
    grid-column-end: 12;
  }
  .lg\:col-end-13 {
    grid-column-end: 13;
  }
  .lg\:col-end-auto {
    grid-column-end: auto;
  }
  .lg\:row-auto {
    grid-row: auto;
  }
  .lg\:row-span-1 {
    grid-row: span 1/span 1;
  }
  .lg\:row-span-2 {
    grid-row: span 2/span 2;
  }
  .lg\:row-span-3 {
    grid-row: span 3/span 3;
  }
  .lg\:row-span-4 {
    grid-row: span 4/span 4;
  }
  .lg\:row-span-5 {
    grid-row: span 5/span 5;
  }
  .lg\:row-span-6 {
    grid-row: span 6/span 6;
  }
  .lg\:row-span-full {
    grid-row: 1/-1;
  }
  .lg\:row-start-1 {
    grid-row-start: 1;
  }
  .lg\:row-start-2 {
    grid-row-start: 2;
  }
  .lg\:row-start-3 {
    grid-row-start: 3;
  }
  .lg\:row-start-4 {
    grid-row-start: 4;
  }
  .lg\:row-start-5 {
    grid-row-start: 5;
  }
  .lg\:row-start-6 {
    grid-row-start: 6;
  }
  .lg\:row-start-7 {
    grid-row-start: 7;
  }
  .lg\:row-start-auto {
    grid-row-start: auto;
  }
  .lg\:row-end-1 {
    grid-row-end: 1;
  }
  .lg\:row-end-2 {
    grid-row-end: 2;
  }
  .lg\:row-end-3 {
    grid-row-end: 3;
  }
  .lg\:row-end-4 {
    grid-row-end: 4;
  }
  .lg\:row-end-5 {
    grid-row-end: 5;
  }
  .lg\:row-end-6 {
    grid-row-end: 6;
  }
  .lg\:row-end-7 {
    grid-row-end: 7;
  }
  .lg\:row-end-auto {
    grid-row-end: auto;
  }
  .lg\:gap-0 {
    gap: 0rem;
  }
  .lg\:gap-x-0 {
    column-gap: 0rem;
  }
  .lg\:gap-y-0 {
    row-gap: 0rem;
  }
  .lg\:gap-0\.5 {
    gap: 0.125rem;
  }
  .lg\:gap-x-0\.5 {
    column-gap: 0.125rem;
  }
  .lg\:gap-y-0\.5 {
    row-gap: 0.125rem;
  }
  .lg\:gap-1 {
    gap: 0.25rem;
  }
  .lg\:gap-x-1 {
    column-gap: 0.25rem;
  }
  .lg\:gap-y-1 {
    row-gap: 0.25rem;
  }
  .lg\:gap-1\.5 {
    gap: 0.375rem;
  }
  .lg\:gap-x-1\.5 {
    column-gap: 0.375rem;
  }
  .lg\:gap-y-1\.5 {
    row-gap: 0.375rem;
  }
  .lg\:gap-2 {
    gap: 0.5rem;
  }
  .lg\:gap-x-2 {
    column-gap: 0.5rem;
  }
  .lg\:gap-y-2 {
    row-gap: 0.5rem;
  }
  .lg\:gap-2\.5 {
    gap: 0.625rem;
  }
  .lg\:gap-x-2\.5 {
    column-gap: 0.625rem;
  }
  .lg\:gap-y-2\.5 {
    row-gap: 0.625rem;
  }
  .lg\:gap-3 {
    gap: 0.75rem;
  }
  .lg\:gap-x-3 {
    column-gap: 0.75rem;
  }
  .lg\:gap-y-3 {
    row-gap: 0.75rem;
  }
  .lg\:gap-3\.5 {
    gap: 0.875rem;
  }
  .lg\:gap-x-3\.5 {
    column-gap: 0.875rem;
  }
  .lg\:gap-y-3\.5 {
    row-gap: 0.875rem;
  }
  .lg\:gap-4 {
    gap: 1rem;
  }
  .lg\:gap-x-4 {
    column-gap: 1rem;
  }
  .lg\:gap-y-4 {
    row-gap: 1rem;
  }
  .lg\:gap-5 {
    gap: 1.25rem;
  }
  .lg\:gap-x-5 {
    column-gap: 1.25rem;
  }
  .lg\:gap-y-5 {
    row-gap: 1.25rem;
  }
  .lg\:gap-6 {
    gap: 1.5rem;
  }
  .lg\:gap-x-6 {
    column-gap: 1.5rem;
  }
  .lg\:gap-y-6 {
    row-gap: 1.5rem;
  }
  .lg\:gap-7 {
    gap: 1.75rem;
  }
  .lg\:gap-x-7 {
    column-gap: 1.75rem;
  }
  .lg\:gap-y-7 {
    row-gap: 1.75rem;
  }
  .lg\:gap-8 {
    gap: 2rem;
  }
  .lg\:gap-x-8 {
    column-gap: 2rem;
  }
  .lg\:gap-y-8 {
    row-gap: 2rem;
  }
  .lg\:gap-9 {
    gap: 2.25rem;
  }
  .lg\:gap-x-9 {
    column-gap: 2.25rem;
  }
  .lg\:gap-y-9 {
    row-gap: 2.25rem;
  }
  .lg\:gap-10 {
    gap: 2.5rem;
  }
  .lg\:gap-x-10 {
    column-gap: 2.5rem;
  }
  .lg\:gap-y-10 {
    row-gap: 2.5rem;
  }
  .lg\:gap-11 {
    gap: 2.75rem;
  }
  .lg\:gap-x-11 {
    column-gap: 2.75rem;
  }
  .lg\:gap-y-11 {
    row-gap: 2.75rem;
  }
  .lg\:gap-12 {
    gap: 3rem;
  }
  .lg\:gap-x-12 {
    column-gap: 3rem;
  }
  .lg\:gap-y-12 {
    row-gap: 3rem;
  }
  .lg\:gap-13 {
    gap: 3.25rem;
  }
  .lg\:gap-x-13 {
    column-gap: 3.25rem;
  }
  .lg\:gap-y-13 {
    row-gap: 3.25rem;
  }
  .lg\:gap-14 {
    gap: 3.5rem;
  }
  .lg\:gap-x-14 {
    column-gap: 3.5rem;
  }
  .lg\:gap-y-14 {
    row-gap: 3.5rem;
  }
  .lg\:gap-15 {
    gap: 3.75rem;
  }
  .lg\:gap-x-15 {
    column-gap: 3.75rem;
  }
  .lg\:gap-y-15 {
    row-gap: 3.75rem;
  }
  .lg\:gap-16 {
    gap: 4rem;
  }
  .lg\:gap-x-16 {
    column-gap: 4rem;
  }
  .lg\:gap-y-16 {
    row-gap: 4rem;
  }
  .lg\:gap-17 {
    gap: 4.25rem;
  }
  .lg\:gap-x-17 {
    column-gap: 4.25rem;
  }
  .lg\:gap-y-17 {
    row-gap: 4.25rem;
  }
  .lg\:gap-18 {
    gap: 4.5rem;
  }
  .lg\:gap-x-18 {
    column-gap: 4.5rem;
  }
  .lg\:gap-y-18 {
    row-gap: 4.5rem;
  }
  .lg\:gap-19 {
    gap: 4.75rem;
  }
  .lg\:gap-x-19 {
    column-gap: 4.75rem;
  }
  .lg\:gap-y-19 {
    row-gap: 4.75rem;
  }
  .lg\:gap-20 {
    gap: 5rem;
  }
  .lg\:gap-x-20 {
    column-gap: 5rem;
  }
  .lg\:gap-y-20 {
    row-gap: 5rem;
  }
  .lg\:gap-24 {
    gap: 6rem;
  }
  .lg\:gap-x-24 {
    column-gap: 6rem;
  }
  .lg\:gap-y-24 {
    row-gap: 6rem;
  }
  .lg\:gap-30 {
    gap: 7.5rem;
  }
  .lg\:gap-x-30 {
    column-gap: 7.5rem;
  }
  .lg\:gap-y-30 {
    row-gap: 7.5rem;
  }
  .lg\:gap-32 {
    gap: 8rem;
  }
  .lg\:gap-x-32 {
    column-gap: 8rem;
  }
  .lg\:gap-y-32 {
    row-gap: 8rem;
  }
  .lg\:gap-40 {
    gap: 10rem;
  }
  .lg\:gap-x-40 {
    column-gap: 10rem;
  }
  .lg\:gap-y-40 {
    row-gap: 10rem;
  }
  .lg\:gap-50 {
    gap: 12.5rem;
  }
  .lg\:gap-x-50 {
    column-gap: 12.5rem;
  }
  .lg\:gap-y-50 {
    row-gap: 12.5rem;
  }
  .lg\:gap-60 {
    gap: 15rem;
  }
  .lg\:gap-x-60 {
    column-gap: 15rem;
  }
  .lg\:gap-y-60 {
    row-gap: 15rem;
  }
  .lg\:gap-80 {
    gap: 20rem;
  }
  .lg\:gap-x-80 {
    column-gap: 20rem;
  }
  .lg\:gap-y-80 {
    row-gap: 20rem;
  }
  .lg\:gap-100 {
    gap: 25rem;
  }
  .lg\:gap-x-100 {
    column-gap: 25rem;
  }
  .lg\:gap-y-100 {
    row-gap: 25rem;
  }
  .lg\:gap-120 {
    gap: 30rem;
  }
  .lg\:gap-x-120 {
    column-gap: 30rem;
  }
  .lg\:gap-y-120 {
    row-gap: 30rem;
  }
  .lg\:gap-140 {
    gap: 35rem;
  }
  .lg\:gap-x-140 {
    column-gap: 35rem;
  }
  .lg\:gap-y-140 {
    row-gap: 35rem;
  }
  .lg\:gap-160 {
    gap: 40rem;
  }
  .lg\:gap-x-160 {
    column-gap: 40rem;
  }
  .lg\:gap-y-160 {
    row-gap: 40rem;
  }
  .lg\:gap-180 {
    gap: 45rem;
  }
  .lg\:gap-x-180 {
    column-gap: 45rem;
  }
  .lg\:gap-y-180 {
    row-gap: 45rem;
  }
  .lg\:gap-200 {
    gap: 50rem;
  }
  .lg\:gap-x-200 {
    column-gap: 50rem;
  }
  .lg\:gap-y-200 {
    row-gap: 50rem;
  }
  .lg\:gap-px {
    gap: 1px;
  }
  .lg\:gap-x-px {
    column-gap: 1px;
  }
  .lg\:gap-y-px {
    row-gap: 1px;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .xl\:grid-cols-none {
    grid-template-columns: none;
  }
  .xl\:col-auto {
    grid-column: auto;
  }
  .xl\:col-span-1 {
    grid-column: span 1/span 1;
  }
  .xl\:col-span-2 {
    grid-column: span 2/span 2;
  }
  .xl\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .xl\:col-span-4 {
    grid-column: span 4/span 4;
  }
  .xl\:col-span-5 {
    grid-column: span 5/span 5;
  }
  .xl\:col-span-6 {
    grid-column: span 6/span 6;
  }
  .xl\:col-span-7 {
    grid-column: span 7/span 7;
  }
  .xl\:col-span-8 {
    grid-column: span 8/span 8;
  }
  .xl\:col-span-9 {
    grid-column: span 9/span 9;
  }
  .xl\:col-span-10 {
    grid-column: span 10/span 10;
  }
  .xl\:col-span-11 {
    grid-column: span 11/span 11;
  }
  .xl\:col-span-12 {
    grid-column: span 12/span 12;
  }
  .xl\:col-span-full {
    grid-column: 1/-1;
  }
  .xl\:col-start-1 {
    grid-column-start: 1;
  }
  .xl\:col-start-2 {
    grid-column-start: 2;
  }
  .xl\:col-start-3 {
    grid-column-start: 3;
  }
  .xl\:col-start-4 {
    grid-column-start: 4;
  }
  .xl\:col-start-5 {
    grid-column-start: 5;
  }
  .xl\:col-start-6 {
    grid-column-start: 6;
  }
  .xl\:col-start-7 {
    grid-column-start: 7;
  }
  .xl\:col-start-8 {
    grid-column-start: 8;
  }
  .xl\:col-start-9 {
    grid-column-start: 9;
  }
  .xl\:col-start-10 {
    grid-column-start: 10;
  }
  .xl\:col-start-11 {
    grid-column-start: 11;
  }
  .xl\:col-start-12 {
    grid-column-start: 12;
  }
  .xl\:col-start-13 {
    grid-column-start: 13;
  }
  .xl\:col-start-auto {
    grid-column-start: auto;
  }
  .xl\:col-end-1 {
    grid-column-end: 1;
  }
  .xl\:col-end-2 {
    grid-column-end: 2;
  }
  .xl\:col-end-3 {
    grid-column-end: 3;
  }
  .xl\:col-end-4 {
    grid-column-end: 4;
  }
  .xl\:col-end-5 {
    grid-column-end: 5;
  }
  .xl\:col-end-6 {
    grid-column-end: 6;
  }
  .xl\:col-end-7 {
    grid-column-end: 7;
  }
  .xl\:col-end-8 {
    grid-column-end: 8;
  }
  .xl\:col-end-9 {
    grid-column-end: 9;
  }
  .xl\:col-end-10 {
    grid-column-end: 10;
  }
  .xl\:col-end-11 {
    grid-column-end: 11;
  }
  .xl\:col-end-12 {
    grid-column-end: 12;
  }
  .xl\:col-end-13 {
    grid-column-end: 13;
  }
  .xl\:col-end-auto {
    grid-column-end: auto;
  }
  .xl\:row-auto {
    grid-row: auto;
  }
  .xl\:row-span-1 {
    grid-row: span 1/span 1;
  }
  .xl\:row-span-2 {
    grid-row: span 2/span 2;
  }
  .xl\:row-span-3 {
    grid-row: span 3/span 3;
  }
  .xl\:row-span-4 {
    grid-row: span 4/span 4;
  }
  .xl\:row-span-5 {
    grid-row: span 5/span 5;
  }
  .xl\:row-span-6 {
    grid-row: span 6/span 6;
  }
  .xl\:row-span-full {
    grid-row: 1/-1;
  }
  .xl\:row-start-1 {
    grid-row-start: 1;
  }
  .xl\:row-start-2 {
    grid-row-start: 2;
  }
  .xl\:row-start-3 {
    grid-row-start: 3;
  }
  .xl\:row-start-4 {
    grid-row-start: 4;
  }
  .xl\:row-start-5 {
    grid-row-start: 5;
  }
  .xl\:row-start-6 {
    grid-row-start: 6;
  }
  .xl\:row-start-7 {
    grid-row-start: 7;
  }
  .xl\:row-start-auto {
    grid-row-start: auto;
  }
  .xl\:row-end-1 {
    grid-row-end: 1;
  }
  .xl\:row-end-2 {
    grid-row-end: 2;
  }
  .xl\:row-end-3 {
    grid-row-end: 3;
  }
  .xl\:row-end-4 {
    grid-row-end: 4;
  }
  .xl\:row-end-5 {
    grid-row-end: 5;
  }
  .xl\:row-end-6 {
    grid-row-end: 6;
  }
  .xl\:row-end-7 {
    grid-row-end: 7;
  }
  .xl\:row-end-auto {
    grid-row-end: auto;
  }
  .xl\:gap-0 {
    gap: 0rem;
  }
  .xl\:gap-x-0 {
    column-gap: 0rem;
  }
  .xl\:gap-y-0 {
    row-gap: 0rem;
  }
  .xl\:gap-0\.5 {
    gap: 0.125rem;
  }
  .xl\:gap-x-0\.5 {
    column-gap: 0.125rem;
  }
  .xl\:gap-y-0\.5 {
    row-gap: 0.125rem;
  }
  .xl\:gap-1 {
    gap: 0.25rem;
  }
  .xl\:gap-x-1 {
    column-gap: 0.25rem;
  }
  .xl\:gap-y-1 {
    row-gap: 0.25rem;
  }
  .xl\:gap-1\.5 {
    gap: 0.375rem;
  }
  .xl\:gap-x-1\.5 {
    column-gap: 0.375rem;
  }
  .xl\:gap-y-1\.5 {
    row-gap: 0.375rem;
  }
  .xl\:gap-2 {
    gap: 0.5rem;
  }
  .xl\:gap-x-2 {
    column-gap: 0.5rem;
  }
  .xl\:gap-y-2 {
    row-gap: 0.5rem;
  }
  .xl\:gap-2\.5 {
    gap: 0.625rem;
  }
  .xl\:gap-x-2\.5 {
    column-gap: 0.625rem;
  }
  .xl\:gap-y-2\.5 {
    row-gap: 0.625rem;
  }
  .xl\:gap-3 {
    gap: 0.75rem;
  }
  .xl\:gap-x-3 {
    column-gap: 0.75rem;
  }
  .xl\:gap-y-3 {
    row-gap: 0.75rem;
  }
  .xl\:gap-3\.5 {
    gap: 0.875rem;
  }
  .xl\:gap-x-3\.5 {
    column-gap: 0.875rem;
  }
  .xl\:gap-y-3\.5 {
    row-gap: 0.875rem;
  }
  .xl\:gap-4 {
    gap: 1rem;
  }
  .xl\:gap-x-4 {
    column-gap: 1rem;
  }
  .xl\:gap-y-4 {
    row-gap: 1rem;
  }
  .xl\:gap-5 {
    gap: 1.25rem;
  }
  .xl\:gap-x-5 {
    column-gap: 1.25rem;
  }
  .xl\:gap-y-5 {
    row-gap: 1.25rem;
  }
  .xl\:gap-6 {
    gap: 1.5rem;
  }
  .xl\:gap-x-6 {
    column-gap: 1.5rem;
  }
  .xl\:gap-y-6 {
    row-gap: 1.5rem;
  }
  .xl\:gap-7 {
    gap: 1.75rem;
  }
  .xl\:gap-x-7 {
    column-gap: 1.75rem;
  }
  .xl\:gap-y-7 {
    row-gap: 1.75rem;
  }
  .xl\:gap-8 {
    gap: 2rem;
  }
  .xl\:gap-x-8 {
    column-gap: 2rem;
  }
  .xl\:gap-y-8 {
    row-gap: 2rem;
  }
  .xl\:gap-9 {
    gap: 2.25rem;
  }
  .xl\:gap-x-9 {
    column-gap: 2.25rem;
  }
  .xl\:gap-y-9 {
    row-gap: 2.25rem;
  }
  .xl\:gap-10 {
    gap: 2.5rem;
  }
  .xl\:gap-x-10 {
    column-gap: 2.5rem;
  }
  .xl\:gap-y-10 {
    row-gap: 2.5rem;
  }
  .xl\:gap-11 {
    gap: 2.75rem;
  }
  .xl\:gap-x-11 {
    column-gap: 2.75rem;
  }
  .xl\:gap-y-11 {
    row-gap: 2.75rem;
  }
  .xl\:gap-12 {
    gap: 3rem;
  }
  .xl\:gap-x-12 {
    column-gap: 3rem;
  }
  .xl\:gap-y-12 {
    row-gap: 3rem;
  }
  .xl\:gap-13 {
    gap: 3.25rem;
  }
  .xl\:gap-x-13 {
    column-gap: 3.25rem;
  }
  .xl\:gap-y-13 {
    row-gap: 3.25rem;
  }
  .xl\:gap-14 {
    gap: 3.5rem;
  }
  .xl\:gap-x-14 {
    column-gap: 3.5rem;
  }
  .xl\:gap-y-14 {
    row-gap: 3.5rem;
  }
  .xl\:gap-15 {
    gap: 3.75rem;
  }
  .xl\:gap-x-15 {
    column-gap: 3.75rem;
  }
  .xl\:gap-y-15 {
    row-gap: 3.75rem;
  }
  .xl\:gap-16 {
    gap: 4rem;
  }
  .xl\:gap-x-16 {
    column-gap: 4rem;
  }
  .xl\:gap-y-16 {
    row-gap: 4rem;
  }
  .xl\:gap-17 {
    gap: 4.25rem;
  }
  .xl\:gap-x-17 {
    column-gap: 4.25rem;
  }
  .xl\:gap-y-17 {
    row-gap: 4.25rem;
  }
  .xl\:gap-18 {
    gap: 4.5rem;
  }
  .xl\:gap-x-18 {
    column-gap: 4.5rem;
  }
  .xl\:gap-y-18 {
    row-gap: 4.5rem;
  }
  .xl\:gap-19 {
    gap: 4.75rem;
  }
  .xl\:gap-x-19 {
    column-gap: 4.75rem;
  }
  .xl\:gap-y-19 {
    row-gap: 4.75rem;
  }
  .xl\:gap-20 {
    gap: 5rem;
  }
  .xl\:gap-x-20 {
    column-gap: 5rem;
  }
  .xl\:gap-y-20 {
    row-gap: 5rem;
  }
  .xl\:gap-24 {
    gap: 6rem;
  }
  .xl\:gap-x-24 {
    column-gap: 6rem;
  }
  .xl\:gap-y-24 {
    row-gap: 6rem;
  }
  .xl\:gap-30 {
    gap: 7.5rem;
  }
  .xl\:gap-x-30 {
    column-gap: 7.5rem;
  }
  .xl\:gap-y-30 {
    row-gap: 7.5rem;
  }
  .xl\:gap-32 {
    gap: 8rem;
  }
  .xl\:gap-x-32 {
    column-gap: 8rem;
  }
  .xl\:gap-y-32 {
    row-gap: 8rem;
  }
  .xl\:gap-40 {
    gap: 10rem;
  }
  .xl\:gap-x-40 {
    column-gap: 10rem;
  }
  .xl\:gap-y-40 {
    row-gap: 10rem;
  }
  .xl\:gap-50 {
    gap: 12.5rem;
  }
  .xl\:gap-x-50 {
    column-gap: 12.5rem;
  }
  .xl\:gap-y-50 {
    row-gap: 12.5rem;
  }
  .xl\:gap-60 {
    gap: 15rem;
  }
  .xl\:gap-x-60 {
    column-gap: 15rem;
  }
  .xl\:gap-y-60 {
    row-gap: 15rem;
  }
  .xl\:gap-80 {
    gap: 20rem;
  }
  .xl\:gap-x-80 {
    column-gap: 20rem;
  }
  .xl\:gap-y-80 {
    row-gap: 20rem;
  }
  .xl\:gap-100 {
    gap: 25rem;
  }
  .xl\:gap-x-100 {
    column-gap: 25rem;
  }
  .xl\:gap-y-100 {
    row-gap: 25rem;
  }
  .xl\:gap-120 {
    gap: 30rem;
  }
  .xl\:gap-x-120 {
    column-gap: 30rem;
  }
  .xl\:gap-y-120 {
    row-gap: 30rem;
  }
  .xl\:gap-140 {
    gap: 35rem;
  }
  .xl\:gap-x-140 {
    column-gap: 35rem;
  }
  .xl\:gap-y-140 {
    row-gap: 35rem;
  }
  .xl\:gap-160 {
    gap: 40rem;
  }
  .xl\:gap-x-160 {
    column-gap: 40rem;
  }
  .xl\:gap-y-160 {
    row-gap: 40rem;
  }
  .xl\:gap-180 {
    gap: 45rem;
  }
  .xl\:gap-x-180 {
    column-gap: 45rem;
  }
  .xl\:gap-y-180 {
    row-gap: 45rem;
  }
  .xl\:gap-200 {
    gap: 50rem;
  }
  .xl\:gap-x-200 {
    column-gap: 50rem;
  }
  .xl\:gap-y-200 {
    row-gap: 50rem;
  }
  .xl\:gap-px {
    gap: 1px;
  }
  .xl\:gap-x-px {
    column-gap: 1px;
  }
  .xl\:gap-y-px {
    row-gap: 1px;
  }
}
@media screen and (min-width: 1600px) {
  .xxl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .xxl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .xxl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .xxl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .xxl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .xxl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .xxl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .xxl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .xxl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .xxl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .xxl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .xxl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .xxl\:grid-cols-none {
    grid-template-columns: none;
  }
  .xxl\:col-auto {
    grid-column: auto;
  }
  .xxl\:col-span-1 {
    grid-column: span 1/span 1;
  }
  .xxl\:col-span-2 {
    grid-column: span 2/span 2;
  }
  .xxl\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .xxl\:col-span-4 {
    grid-column: span 4/span 4;
  }
  .xxl\:col-span-5 {
    grid-column: span 5/span 5;
  }
  .xxl\:col-span-6 {
    grid-column: span 6/span 6;
  }
  .xxl\:col-span-7 {
    grid-column: span 7/span 7;
  }
  .xxl\:col-span-8 {
    grid-column: span 8/span 8;
  }
  .xxl\:col-span-9 {
    grid-column: span 9/span 9;
  }
  .xxl\:col-span-10 {
    grid-column: span 10/span 10;
  }
  .xxl\:col-span-11 {
    grid-column: span 11/span 11;
  }
  .xxl\:col-span-12 {
    grid-column: span 12/span 12;
  }
  .xxl\:col-span-full {
    grid-column: 1/-1;
  }
  .xxl\:col-start-1 {
    grid-column-start: 1;
  }
  .xxl\:col-start-2 {
    grid-column-start: 2;
  }
  .xxl\:col-start-3 {
    grid-column-start: 3;
  }
  .xxl\:col-start-4 {
    grid-column-start: 4;
  }
  .xxl\:col-start-5 {
    grid-column-start: 5;
  }
  .xxl\:col-start-6 {
    grid-column-start: 6;
  }
  .xxl\:col-start-7 {
    grid-column-start: 7;
  }
  .xxl\:col-start-8 {
    grid-column-start: 8;
  }
  .xxl\:col-start-9 {
    grid-column-start: 9;
  }
  .xxl\:col-start-10 {
    grid-column-start: 10;
  }
  .xxl\:col-start-11 {
    grid-column-start: 11;
  }
  .xxl\:col-start-12 {
    grid-column-start: 12;
  }
  .xxl\:col-start-13 {
    grid-column-start: 13;
  }
  .xxl\:col-start-auto {
    grid-column-start: auto;
  }
  .xxl\:col-end-1 {
    grid-column-end: 1;
  }
  .xxl\:col-end-2 {
    grid-column-end: 2;
  }
  .xxl\:col-end-3 {
    grid-column-end: 3;
  }
  .xxl\:col-end-4 {
    grid-column-end: 4;
  }
  .xxl\:col-end-5 {
    grid-column-end: 5;
  }
  .xxl\:col-end-6 {
    grid-column-end: 6;
  }
  .xxl\:col-end-7 {
    grid-column-end: 7;
  }
  .xxl\:col-end-8 {
    grid-column-end: 8;
  }
  .xxl\:col-end-9 {
    grid-column-end: 9;
  }
  .xxl\:col-end-10 {
    grid-column-end: 10;
  }
  .xxl\:col-end-11 {
    grid-column-end: 11;
  }
  .xxl\:col-end-12 {
    grid-column-end: 12;
  }
  .xxl\:col-end-13 {
    grid-column-end: 13;
  }
  .xxl\:col-end-auto {
    grid-column-end: auto;
  }
  .xxl\:row-auto {
    grid-row: auto;
  }
  .xxl\:row-span-1 {
    grid-row: span 1/span 1;
  }
  .xxl\:row-span-2 {
    grid-row: span 2/span 2;
  }
  .xxl\:row-span-3 {
    grid-row: span 3/span 3;
  }
  .xxl\:row-span-4 {
    grid-row: span 4/span 4;
  }
  .xxl\:row-span-5 {
    grid-row: span 5/span 5;
  }
  .xxl\:row-span-6 {
    grid-row: span 6/span 6;
  }
  .xxl\:row-span-full {
    grid-row: 1/-1;
  }
  .xxl\:row-start-1 {
    grid-row-start: 1;
  }
  .xxl\:row-start-2 {
    grid-row-start: 2;
  }
  .xxl\:row-start-3 {
    grid-row-start: 3;
  }
  .xxl\:row-start-4 {
    grid-row-start: 4;
  }
  .xxl\:row-start-5 {
    grid-row-start: 5;
  }
  .xxl\:row-start-6 {
    grid-row-start: 6;
  }
  .xxl\:row-start-7 {
    grid-row-start: 7;
  }
  .xxl\:row-start-auto {
    grid-row-start: auto;
  }
  .xxl\:row-end-1 {
    grid-row-end: 1;
  }
  .xxl\:row-end-2 {
    grid-row-end: 2;
  }
  .xxl\:row-end-3 {
    grid-row-end: 3;
  }
  .xxl\:row-end-4 {
    grid-row-end: 4;
  }
  .xxl\:row-end-5 {
    grid-row-end: 5;
  }
  .xxl\:row-end-6 {
    grid-row-end: 6;
  }
  .xxl\:row-end-7 {
    grid-row-end: 7;
  }
  .xxl\:row-end-auto {
    grid-row-end: auto;
  }
  .xxl\:gap-0 {
    gap: 0rem;
  }
  .xxl\:gap-x-0 {
    column-gap: 0rem;
  }
  .xxl\:gap-y-0 {
    row-gap: 0rem;
  }
  .xxl\:gap-0\.5 {
    gap: 0.125rem;
  }
  .xxl\:gap-x-0\.5 {
    column-gap: 0.125rem;
  }
  .xxl\:gap-y-0\.5 {
    row-gap: 0.125rem;
  }
  .xxl\:gap-1 {
    gap: 0.25rem;
  }
  .xxl\:gap-x-1 {
    column-gap: 0.25rem;
  }
  .xxl\:gap-y-1 {
    row-gap: 0.25rem;
  }
  .xxl\:gap-1\.5 {
    gap: 0.375rem;
  }
  .xxl\:gap-x-1\.5 {
    column-gap: 0.375rem;
  }
  .xxl\:gap-y-1\.5 {
    row-gap: 0.375rem;
  }
  .xxl\:gap-2 {
    gap: 0.5rem;
  }
  .xxl\:gap-x-2 {
    column-gap: 0.5rem;
  }
  .xxl\:gap-y-2 {
    row-gap: 0.5rem;
  }
  .xxl\:gap-2\.5 {
    gap: 0.625rem;
  }
  .xxl\:gap-x-2\.5 {
    column-gap: 0.625rem;
  }
  .xxl\:gap-y-2\.5 {
    row-gap: 0.625rem;
  }
  .xxl\:gap-3 {
    gap: 0.75rem;
  }
  .xxl\:gap-x-3 {
    column-gap: 0.75rem;
  }
  .xxl\:gap-y-3 {
    row-gap: 0.75rem;
  }
  .xxl\:gap-3\.5 {
    gap: 0.875rem;
  }
  .xxl\:gap-x-3\.5 {
    column-gap: 0.875rem;
  }
  .xxl\:gap-y-3\.5 {
    row-gap: 0.875rem;
  }
  .xxl\:gap-4 {
    gap: 1rem;
  }
  .xxl\:gap-x-4 {
    column-gap: 1rem;
  }
  .xxl\:gap-y-4 {
    row-gap: 1rem;
  }
  .xxl\:gap-5 {
    gap: 1.25rem;
  }
  .xxl\:gap-x-5 {
    column-gap: 1.25rem;
  }
  .xxl\:gap-y-5 {
    row-gap: 1.25rem;
  }
  .xxl\:gap-6 {
    gap: 1.5rem;
  }
  .xxl\:gap-x-6 {
    column-gap: 1.5rem;
  }
  .xxl\:gap-y-6 {
    row-gap: 1.5rem;
  }
  .xxl\:gap-7 {
    gap: 1.75rem;
  }
  .xxl\:gap-x-7 {
    column-gap: 1.75rem;
  }
  .xxl\:gap-y-7 {
    row-gap: 1.75rem;
  }
  .xxl\:gap-8 {
    gap: 2rem;
  }
  .xxl\:gap-x-8 {
    column-gap: 2rem;
  }
  .xxl\:gap-y-8 {
    row-gap: 2rem;
  }
  .xxl\:gap-9 {
    gap: 2.25rem;
  }
  .xxl\:gap-x-9 {
    column-gap: 2.25rem;
  }
  .xxl\:gap-y-9 {
    row-gap: 2.25rem;
  }
  .xxl\:gap-10 {
    gap: 2.5rem;
  }
  .xxl\:gap-x-10 {
    column-gap: 2.5rem;
  }
  .xxl\:gap-y-10 {
    row-gap: 2.5rem;
  }
  .xxl\:gap-11 {
    gap: 2.75rem;
  }
  .xxl\:gap-x-11 {
    column-gap: 2.75rem;
  }
  .xxl\:gap-y-11 {
    row-gap: 2.75rem;
  }
  .xxl\:gap-12 {
    gap: 3rem;
  }
  .xxl\:gap-x-12 {
    column-gap: 3rem;
  }
  .xxl\:gap-y-12 {
    row-gap: 3rem;
  }
  .xxl\:gap-13 {
    gap: 3.25rem;
  }
  .xxl\:gap-x-13 {
    column-gap: 3.25rem;
  }
  .xxl\:gap-y-13 {
    row-gap: 3.25rem;
  }
  .xxl\:gap-14 {
    gap: 3.5rem;
  }
  .xxl\:gap-x-14 {
    column-gap: 3.5rem;
  }
  .xxl\:gap-y-14 {
    row-gap: 3.5rem;
  }
  .xxl\:gap-15 {
    gap: 3.75rem;
  }
  .xxl\:gap-x-15 {
    column-gap: 3.75rem;
  }
  .xxl\:gap-y-15 {
    row-gap: 3.75rem;
  }
  .xxl\:gap-16 {
    gap: 4rem;
  }
  .xxl\:gap-x-16 {
    column-gap: 4rem;
  }
  .xxl\:gap-y-16 {
    row-gap: 4rem;
  }
  .xxl\:gap-17 {
    gap: 4.25rem;
  }
  .xxl\:gap-x-17 {
    column-gap: 4.25rem;
  }
  .xxl\:gap-y-17 {
    row-gap: 4.25rem;
  }
  .xxl\:gap-18 {
    gap: 4.5rem;
  }
  .xxl\:gap-x-18 {
    column-gap: 4.5rem;
  }
  .xxl\:gap-y-18 {
    row-gap: 4.5rem;
  }
  .xxl\:gap-19 {
    gap: 4.75rem;
  }
  .xxl\:gap-x-19 {
    column-gap: 4.75rem;
  }
  .xxl\:gap-y-19 {
    row-gap: 4.75rem;
  }
  .xxl\:gap-20 {
    gap: 5rem;
  }
  .xxl\:gap-x-20 {
    column-gap: 5rem;
  }
  .xxl\:gap-y-20 {
    row-gap: 5rem;
  }
  .xxl\:gap-24 {
    gap: 6rem;
  }
  .xxl\:gap-x-24 {
    column-gap: 6rem;
  }
  .xxl\:gap-y-24 {
    row-gap: 6rem;
  }
  .xxl\:gap-30 {
    gap: 7.5rem;
  }
  .xxl\:gap-x-30 {
    column-gap: 7.5rem;
  }
  .xxl\:gap-y-30 {
    row-gap: 7.5rem;
  }
  .xxl\:gap-32 {
    gap: 8rem;
  }
  .xxl\:gap-x-32 {
    column-gap: 8rem;
  }
  .xxl\:gap-y-32 {
    row-gap: 8rem;
  }
  .xxl\:gap-40 {
    gap: 10rem;
  }
  .xxl\:gap-x-40 {
    column-gap: 10rem;
  }
  .xxl\:gap-y-40 {
    row-gap: 10rem;
  }
  .xxl\:gap-50 {
    gap: 12.5rem;
  }
  .xxl\:gap-x-50 {
    column-gap: 12.5rem;
  }
  .xxl\:gap-y-50 {
    row-gap: 12.5rem;
  }
  .xxl\:gap-60 {
    gap: 15rem;
  }
  .xxl\:gap-x-60 {
    column-gap: 15rem;
  }
  .xxl\:gap-y-60 {
    row-gap: 15rem;
  }
  .xxl\:gap-80 {
    gap: 20rem;
  }
  .xxl\:gap-x-80 {
    column-gap: 20rem;
  }
  .xxl\:gap-y-80 {
    row-gap: 20rem;
  }
  .xxl\:gap-100 {
    gap: 25rem;
  }
  .xxl\:gap-x-100 {
    column-gap: 25rem;
  }
  .xxl\:gap-y-100 {
    row-gap: 25rem;
  }
  .xxl\:gap-120 {
    gap: 30rem;
  }
  .xxl\:gap-x-120 {
    column-gap: 30rem;
  }
  .xxl\:gap-y-120 {
    row-gap: 30rem;
  }
  .xxl\:gap-140 {
    gap: 35rem;
  }
  .xxl\:gap-x-140 {
    column-gap: 35rem;
  }
  .xxl\:gap-y-140 {
    row-gap: 35rem;
  }
  .xxl\:gap-160 {
    gap: 40rem;
  }
  .xxl\:gap-x-160 {
    column-gap: 40rem;
  }
  .xxl\:gap-y-160 {
    row-gap: 40rem;
  }
  .xxl\:gap-180 {
    gap: 45rem;
  }
  .xxl\:gap-x-180 {
    column-gap: 45rem;
  }
  .xxl\:gap-y-180 {
    row-gap: 45rem;
  }
  .xxl\:gap-200 {
    gap: 50rem;
  }
  .xxl\:gap-x-200 {
    column-gap: 50rem;
  }
  .xxl\:gap-y-200 {
    row-gap: 50rem;
  }
  .xxl\:gap-px {
    gap: 1px;
  }
  .xxl\:gap-x-px {
    column-gap: 1px;
  }
  .xxl\:gap-y-px {
    row-gap: 1px;
  }
}
.font-thin {
  font-weight: 100;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}

.break-words {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.text-xxs {
  font-size: 0.625rem; /* 10px */
  line-height: 0.75rem; /* 12px */
}

.text-xs {
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
}

.text-sm, .input-set .caption, .input-set label, .Toastify__toast-body, .page-caption {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}

.text-base {
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
}

.text-lg {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}

.text-xl {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}

.text-2xl {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}

.text-3xl {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
}

.text-4xl {
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
}

.text-5xl {
  font-size: 3rem; /* 48px */
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem; /* 60px */
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem; /* 72px */
  line-height: 1;
}

.text-8xl {
  font-size: 6rem; /* 96px */
  line-height: 1;
}

.text-9xl {
  font-size: 8rem; /* 128px */
  line-height: 1;
}

.leading-0 {
  line-height: 0rem;
}

.leading-0\.5 {
  line-height: 0.125rem;
}

.leading-1 {
  line-height: 0.25rem;
}

.leading-1\.5 {
  line-height: 0.375rem;
}

.leading-2 {
  line-height: 0.5rem;
}

.leading-2\.5 {
  line-height: 0.625rem;
}

.leading-3 {
  line-height: 0.75rem;
}

.leading-3\.5 {
  line-height: 0.875rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-11 {
  line-height: 2.75rem;
}

.leading-12 {
  line-height: 3rem;
}

.leading-13 {
  line-height: 3.25rem;
}

.leading-14 {
  line-height: 3.5rem;
}

.leading-15 {
  line-height: 3.75rem;
}

.leading-16 {
  line-height: 4rem;
}

.leading-17 {
  line-height: 4.25rem;
}

.leading-18 {
  line-height: 4.5rem;
}

.leading-19 {
  line-height: 4.75rem;
}

.leading-20 {
  line-height: 5rem;
}

.rounded {
  border-radius: var(--radius); /* 4px */
}

.rounded-l {
  border-top-left-radius: var(--radius); /* 4px */
  border-bottom-left-radius: var(--radius); /* 4px */
}

.rounded-r {
  border-top-right-radius: var(--radius); /* 4px */
  border-bottom-right-radius: var(--radius); /* 4px */
}

.rounded-t {
  border-top-left-radius: var(--radius); /* 4px */
  border-top-right-radius: var(--radius); /* 4px */
}

.rounded-b {
  border-bottom-left-radius: var(--radius); /* 4px */
  border-bottom-right-radius: var(--radius); /* 4px */
}

.rounded-none {
  border-radius: 0;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-sm {
  border-radius: calc(var(--radius) / 2); /* 2px */
}

.rounded-md {
  border-radius: calc(var(--radius) * 1.5); /* 6px */
}

.rounded-lg {
  border-radius: calc(var(--radius) * 2); /* 8px */
}

.rounded-xl {
  border-radius: calc(var(--radius) * 3); /* 12px */
}

.rounded-2xl {
  border-radius: calc(var(--radius) * 4); /* 16px */
}

.rounded-3xl {
  border-radius: calc(var(--radius) * 6); /* 24px */
}

.rounded-4xl {
  border-radius: calc(var(--radius) * 8); /* 32px */
}

.rounded-full {
  border-radius: 1000rem;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-clip {
  overflow: clip;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.font-sans {
  font-family: var(--font-sans);
}

.font-mono {
  font-family: var(--font-mono);
}

.opacity-0 {
  opacity: 0;
}

.opacity-5 {
  opacity: 0.05;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.opacity-95 {
  opacity: 0.95;
}

.opacity-100 {
  opacity: 1;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.text-clip {
  text-overflow: clip;
}

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait;
}

.cursor-text {
  cursor: text;
}

.cursor-move {
  cursor: move;
}

.cursor-help {
  cursor: help;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-none {
  cursor: none;
}

.cursor-context-menu {
  cursor: context-menu;
}

.cursor-progress {
  cursor: progress;
}

.cursor-cell {
  cursor: cell;
}

.cursor-crosshair {
  cursor: crosshair;
}

.cursor-vertical-text {
  cursor: vertical-text;
}

.cursor-alias {
  cursor: alias;
}

.cursor-copy {
  cursor: copy;
}

.cursor-no-drop {
  cursor: no-drop;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

.cursor-all-scroll {
  cursor: all-scroll;
}

.cursor-col-resize {
  cursor: col-resize;
}

.cursor-row-resize {
  cursor: row-resize;
}

.cursor-n-resize {
  cursor: n-resize;
}

.cursor-e-resize {
  cursor: e-resize;
}

.cursor-s-resize {
  cursor: s-resize;
}

.cursor-w-resize {
  cursor: w-resize;
}

.cursor-ne-resize {
  cursor: ne-resize;
}

.cursor-nw-resize {
  cursor: nw-resize;
}

.cursor-se-resize {
  cursor: se-resize;
}

.cursor-sw-resize {
  cursor: sw-resize;
}

.cursor-ew-resize {
  cursor: ew-resize;
}

.cursor-ns-resize {
  cursor: ns-resize;
}

.cursor-nesw-resize {
  cursor: nesw-resize;
}

.cursor-nwse-resize {
  cursor: nwse-resize;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.cursor-zoom-out {
  cursor: zoom-out;
}

.list-none {
  list-style-type: none;
}

.list-disc {
  list-style-type: disc;
}

.list-decimal {
  list-style-type: decimal;
}

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}

.shadow {
  box-shadow: var(--shadow-elevation);
}

.object-contain {
  object-fit: contain;
}

hover\:object-contain:hover {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

hover\:object-cover:hover {
  object-fit: cover;
}

.object-fill {
  object-fit: fill;
}

hover\:object-fill:hover {
  object-fit: fill;
}

.object-none {
  object-fit: none;
}

hover\:object-none:hover {
  object-fit: none;
}

.object-scale-down {
  object-fit: scale-down;
}

hover\:object-scale-down:hover {
  object-fit: scale-down;
}

.tracking-tighter {
  letter-spacing: -0.05em;
}

.tracking-tight {
  letter-spacing: -0.025em;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.tracking-wider {
  letter-spacing: 0.05em;
}

.tracking-widest {
  letter-spacing: 0.1em;
}

.select-none {
  user-select: none;
}

.select-text {
  user-select: text;
}

.select-all {
  user-select: all;
}

.select-auto {
  user-select: auto;
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-auto {
  z-index: auto;
}

.invisible {
  visibility: hidden;
}

.resize-y {
  resize: vertical;
}

.static {
  position: static;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.aspect-auto {
  aspect-ratio: auto;
}

.aspect-square {
  aspect-ratio: 1/1;
}

.aspect-video {
  aspect-ratio: 16/9;
}

:root {
  --primary-highlight: rgba(29, 74, 255, 0.1);
  --primary-light: #345cff;
  --primary: #1d4aff;
  --primary-dark: #1330a6;
  --danger-highlight: rgba(219, 55, 7, 0.1);
  --danger-lighter: #ffa599;
  --danger-light: #df4b20;
  --danger: #db3707;
  --danger-dark: #992705;
  --warning-highlight: rgba(247, 165, 1, 0.1);
  --warning-light: #f8b633;
  --warning: #f7a501;
  --warning-dark: #a06b01;
  --success-highlight: rgba(56, 134, 0, 0.1);
  --success-light: #5f9d32;
  --success: #388600;
  --success-dark: #245700;
  --primary-alt-highlight: rgba(53, 65, 107, 0.1);
  --primary-alt: #35416b;
  --primary-alt-dark: #222a46;
  --default: #2d2d2d;
  --default-dark: #050505;
  --muted: #5f5f5f;
  --muted-dark: #403939;
  --muted-alt: #747ea1;
  --muted-alt-dark: #515871;
  --mark: hsla(42deg, 93%, 86%, 0.8);
  --white: #fff;
  --bg-light: #fff;
  --side: #fafaf9;
  --mid: #f2f2f2;
  --border: rgba(0, 0, 0, 0.15);
  --border-light: rgba(0, 0, 0, 0.08);
  --border-bold: rgba(0, 0, 0, 0.24);
  --border-active: rgba(0, 0, 0, 0.36);
  --transparent: transparent;
  --link: var(--link);
  --brand-blue: #1d4aff;
  --brand-red: #f54e00;
  --brand-yellow: #f9bd2b;
  --brand-key: #000;
  --text-3000-light: #111;
  --muted-3000-light: rgba(17, 17, 17, 0.5);
  --trace-3000-light: rgba(17, 17, 17, 0.25);
  --primary-3000-light: rgba(0, 0, 0, 0.75);
  --primary-3000-hover-light: #000;
  --secondary-3000-light: rgba(207, 209, 194, 0.6);
  --secondary-3000-hover-light: #cfd1c2;
  --accent-3000-light: #eeefe9;
  --bg-3000-light: #f3f4ef;
  --border-3000-light: #dadbd2;
  --border-bold-3000-light: #c1c2b9;
  --glass-bg-3000-light: rgba(228, 229, 222, 0.7019607843);
  --glass-border-3000-light: #e4e5de;
  --link-3000-light: var(--primary);
  --text-3000-dark: #fff;
  --muted-3000-dark: rgba(255, 255, 255, 0.5);
  --trace-3000-dark: rgba(255, 255, 255, 0.25);
  --primary-3000-dark: var(--primary);
  --primary-3000-hover-dark: var(--primary-light);
  --secondary-3000-dark: #3b4159;
  --secondary-3000-hover-dark: #575d77;
  --accent-3000-dark: #1d1f27;
  --bg-3000-dark: #151619;
  --border-3000-dark: #2b2c32;
  --border-bold-3000-dark: #3f4046;
  --glass-bg-3000-dark: rgba(29, 31, 39, 0.7019607843);
  --glass-border-3000-dark: var(--border-3000-dark);
  --link-3000-dark: rgb(47, 129, 247);
  --text-3000: var(--text-3000);
  --muted-3000: var(--muted-3000);
  --trace-3000: var(--trace-3000);
  --primary-3000: var(--primary-3000);
  --primary-3000-hover: var(--primary-3000-hover);
  --secondary-3000: var(--secondary-3000);
  --secondary-3000-hover: var(--secondary-3000-hover);
  --accent-3000: var(--accent-3000);
  --bg-3000: var(--bg-3000);
  --border-3000: var(--border-3000);
  --border-bold-3000: var(--border-bold-3000);
  --glass-bg-3000: var(--glass-bg-3000);
  --glass-border-3000: var(--glass-border-3000);
  --link-3000: var(--link-3000);
  --primary-bg-hover: var(--primary-highlight);
  --primary-bg-active: rgba(29, 74, 255, 0.2);
  --bg-charcoal: #2d2d2d;
  --bg-bridge: #ebece8;
  --radius: 4px;
  --shadow-elevation: 0px 16px 16px -16px rgb(0 0 0 / 35%);
  --opacity-disabled: 0.6;
  --font-medium: 500;
  --font-semibold: 600;
  --font-sans: -apple-system, blinkmacsystemfont, "Inter", "Segoe UI", "Roboto", "Helvetica Neue", helvetica, arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-mono: ui-monospace, "SFMono-Regular", "SF Mono", "Menlo", "Consolas", "Liberation Mono", monospace;
  --blue: #597dce;
  --purple: #c278cf;
  --green: var(--success);
  --black: var(--default);
  --purple-light: #dcb1e3;
  --data-brand-blue: var(--primary);
  --data-purple: #621da6;
  --data-viridian: #42827e;
  --data-magenta: #ce0e74;
  --data-vermilion: #f14f58;
  --data-brown: #7c440e;
  --data-green: #529a0a;
  --data-blue: #0476fb;
  --data-pink: #fe729e;
  --data-navy: var(--primary-alt);
  --data-turquoise: #41cbc4;
  --data-brick: #b64b02;
  --data-yellow: #e4a604;
  --data-lilac: #a56eff;
  --lifecycle-new: #1d4aff;
  --lifecycle-returning: #388600;
  --lifecycle-resurrecting: #a56eff;
  --lifecycle-dormant: #db3707;
  --lifecycle-new-hover: #0024b6;
  --lifecycle-returning-hover: #0d2000;
  --lifecycle-resurrecting-hover: #6608ff;
  --lifecycle-dormant-hover: #781e04;
  --funnel-default: var(--primary);
  --funnel-background: var(--border-light);
  --funnel-axis: var(--border);
  --funnel-grid: #ddd;
  --antd-table-background-dark: #fafafa;
  --recording-spacing: calc(2rem / 3);
  --recording-player-container-bg: #797973;
  --recording-buffer-bg: #faaf8c;
  --recording-seekbar-red: var(--brand-red);
  --recording-hover-event: var(--primary-bg-hover);
  --recording-hover-event-mid: var(--primary-bg-active);
  --recording-hover-event-dark: var(--primary);
  --recording-current-event: #eef2ff;
  --recording-current-event-dark: var(--primary-alt);
  --recording-failure-event: #fee9e2;
  --recording-failure-event-dark: #cd3000;
  --recording-highlight-event: var(--mark);
  --recording-highlight-event-dark: #946508;
  --z-bottom-notice: 5100;
  --z-command-palette: 1875;
  --z-force-modal-above-popovers: 1850;
  --z-ant-message: 1070;
  --z-ant-select-dropdown: 1065;
  --z-definition-popover: 1064;
  --z-popover: 1063;
  --z-graph-tooltip: 1062;
  --z-annotation-popover: 1061;
  --z-modal: 1060;
  --z-ant-modal-wrap: 1060;
  --z-hedgehog-buddy: 1059;
  --z-ant-modal-mask: 1050;
  --z-drawer: 950;
  --z-notifications-popover: 949;
  --z-main-nav: 948;
  --z-lemon-sidebar: 940;
  --z-lemon-activation-sidebar: 939;
  --z-mobile-nav-overlay: 931;
  --z-top-navigation: 800;
  --z-content-overlay: 488;
  --z-raised: 5;
  --toastify-color-dark: var(--accent-3000-dark);
  --toastify-color-light: var(--bg-light);
  --toastify-color-info: var(--primary);
  --toastify-color-success: var(--success);
  --toastify-color-warning: var(--warning);
  --toastify-color-error: var(--danger);
  --toastify-toast-background: var(--bg-light);
  --toastify-toast-width: 26rem;
  --toastify-toast-min-height: 3.5rem;
  --toastify-toast-max-height: 16rem;
  --toastify-text-color-light: #757575;
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
  --in-app-prompts-width: 26rem;
  --lettermark-1-bg: #dcb1e3;
  --lettermark-1-text: #572e5e;
  --lettermark-2-bg: #ffc4b2;
  --lettermark-2-text: #3e5891;
  --lettermark-3-bg: #fdedc9;
  --lettermark-3-text: #3e5891;
  --lettermark-4-bg: #3e5891;
  --lettermark-4-text: #ffc4b2;
  --lettermark-5-bg: #8da9e7;
  --lettermark-5-text: #572e5e;
  --lettermark-6-bg: #572e5e;
  --lettermark-6-text: #dcb1e3;
  --lettermark-7-bg: #ffc035;
  --lettermark-7-text: #35416b;
  --lettermark-8-bg: #ff906e;
  --lettermark-8-text: #2a3d65;
  --modal-backdrop-blur: 5px;
  --modal-backdrop-color: rgb(0 0 0 / 20%);
  --modal-transition-time: 200ms;
  --notebook-popover-transition-properties: 150ms cubic-bezier(0, 0.5, 0.5, 1);
  --notebook-column-left-width: 27rem;
  --notebook-column-right-width: 20rem;
}

body b,
body strong {
  font-weight: 600;
}

ul {
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;
}

* {
  border-style: solid;
  border-width: 0;
  border-color: var(--border);
}

.page-title-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 2.5rem;
  gap: 0.5rem 1rem;
  margin: 1.25rem 0 0.25rem;
}
@media screen and (min-width: 768px) {
  .page-title-row {
    flex-wrap: nowrap;
  }
}
.page-title-row .ant-form-item {
  margin-bottom: 0 !important;
}
.posthog-3000 .page-title-row {
  margin-top: 1rem;
}

.page-title {
  min-width: 0;
  font-size: 28px;
  line-height: 34px;
  margin: 0;
  font-weight: 600;
}

.page-caption {
  max-width: 48rem;
  margin-bottom: 1rem;
}
.page-caption.tabbed {
  margin-bottom: 0.5rem;
}

.page-description {
  font-style: italic;
  color: var(--muted);
}

.page-buttons {
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 0.5rem;
}

.secondary {
  font-size: 0.9em;
  letter-spacing: 1px;
  color: var(--primary-alt);
  font-weight: var(--font-medium);
  text-transform: uppercase;
  border: 0;
  background: none;
}

.card-secondary {
  font-size: 11px;
  color: var(--muted);
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 600;
}

mark {
  background-color: var(--mark) !important;
  border-radius: var(--radius);
}

.supplement {
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--muted-alt);
  font-size: 0.8125rem;
}
.supplement--danger {
  color: var(--danger);
  font-weight: 600;
}

hr {
  border: 1px solid var(--border);
}

input::-ms-clear {
  display: none;
}

/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes highlight {
  0% {
    background-color: var(--mark);
  }
  100% {
    background-color: initial;
  }
}
/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.Toastify__toast {
  align-items: center;
  opacity: 1 !important;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  border-radius: var(--radius);
  font-family: var(--font-sans);
  border: 1px solid var(--border);
  box-shadow: var(--shadow-elevation);
  font-size: 1rem;
  cursor: unset;
}

.Toastify__toast-container {
  padding: 0;
}

.Toastify__toast-body {
  font-weight: 500;
  padding: 0;
}
.Toastify__toast-body b,
.Toastify__toast-body strong {
  font-weight: 700;
}
.Toastify__toast-body button {
  margin: 0 0.75rem;
}

.Toastify__toast-icon {
  color: var(--muted-alt);
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;
  font-size: 1.5rem;
}
.Toastify__toast--success .Toastify__toast-icon {
  color: var(--toastify-color-success);
}
.Toastify__toast--warning .Toastify__toast-icon {
  color: var(--toastify-color-warning);
}
.Toastify__toast--error .Toastify__toast-icon {
  color: var(--toastify-color-error);
}

.Toastify__progress-bar {
  height: var(--radius);
}

.scrollable::before, .scrollable::after {
  z-index: 1;
  transition: opacity 200ms ease;
  opacity: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.scrollable::before {
  box-shadow: 16px 0 16px -16px rgba(0, 0, 0, 0.25) inset;
}
.scrollable::after {
  box-shadow: -16px 0 16px -16px rgba(0, 0, 0, 0.25) inset;
}
.scrollable.scrollable--right::after, .scrollable.scrollable--left::before {
  opacity: 1;
}

.scrollable__inner {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.input-set {
  padding-bottom: 1rem;
  color: var(--default);
}
.input-set label {
  font-weight: bold;
}
.input-set .caption {
  color: var(--muted);
}
.input-set .ant-radio-button-wrapper {
  font-weight: normal !important;
  line-height: 30px !important;
}
.input-set.errored .caption {
  color: var(--danger);
}
.input-set.errored input[type=password],
.input-set.errored input[type=text] {
  border-color: var(--danger) !important;
}

.error-on-blur.errored:not(:focus) {
  border-color: var(--danger) !important;
}

.btn-close {
  color: var(--muted);
}

.info-indicator {
  color: var(--primary-alt) !important;
  cursor: pointer;
  margin-left: 4px;
}
.info-indicator.left {
  margin-left: 0;
  margin-right: 4px;
}

.title-icon {
  margin-left: 0.375rem;
  font-size: 1rem;
  line-height: 0;
}

.btn-lg-2x {
  font-size: 1.5rem !important;
  line-height: 1 !important;
}
.btn-lg-2x svg {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.graph-series-glyph {
  border-radius: 50%;
  border: 2px solid var(--default);
  font-weight: bold;
  font-size: 12px;
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  flex-shrink: 0;
}
.graph-series-glyph.funnel-step-glyph {
  border-color: var(--border);
  background-color: white;
  color: var(--primary-alt);
  height: 23px;
  width: 23px;
  font-size: unset;
}

#bottom-notice {
  z-index: var(--z-bottom-notice);
  display: flex !important;
  flex-direction: row;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #000;
  color: #fff;
  font-size: 0.75rem;
  line-height: 1.5rem;
}
#bottom-notice code {
  color: inherit;
  font-size: inherit;
}
#bottom-notice.warning div {
  height: auto;
  background: var(--danger);
}
#bottom-notice.tricolor {
  cursor: pointer;
}
#bottom-notice.tricolor div:nth-child(1) {
  background: var(--primary);
}
#bottom-notice.tricolor div:nth-child(2) {
  background: var(--danger);
}
#bottom-notice.tricolor div:nth-child(3) {
  background: var(--warning);
}
#bottom-notice.tricolor span {
  cursor: text;
}
#bottom-notice div {
  flex-basis: 0;
  flex-grow: 1;
  height: 1.5rem;
  text-align: center;
}
#bottom-notice span {
  display: none;
}
#bottom-notice button {
  border: none;
  background: transparent;
  color: inherit;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}
@media screen and (min-width: 750px) {
  #bottom-notice {
    font-size: 1rem;
    line-height: 2rem;
  }
  #bottom-notice div {
    height: 2rem;
  }
  #bottom-notice span {
    display: inline;
  }
  #bottom-notice button {
    width: 2rem;
    height: 2rem;
    font-size: 1.25rem;
  }
}

.overlay__parent {
  position: relative;
}

.overlay__child {
  transition: opacity 0.25s ease;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.overlay--danger .overlay__child {
  background: repeating-linear-gradient(-45deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5) 0.75rem, var(--danger) 0.5rem, var(--danger) 20px);
}

.simple-tag {
  border-radius: var(--radius);
  padding: 2px 6px;
}
.simple-tag.tag-light-blue {
  background-color: var(--primary-highlight);
}

.fullscreen-scroll {
  overflow-y: auto;
}

.main-app-content {
  position: relative;
  min-width: 0;
  width: 100%;
  padding: 0 1rem 1rem;
  flex: 1;
  background-color: var(--bg-light);
}
@media screen and (min-width: 576px) {
  .main-app-content {
    padding: 0 1rem 2rem;
  }
}
@media screen and (min-width: 992px) {
  .main-app-content {
    padding: 0 2rem 4rem;
  }
}
.main-app-content.main-app-content--plain {
  padding: 0;
}
.main-app-content.main-app-content--container {
  max-width: 72rem;
  align-self: center;
}

body {
  --text-3000: var(--default);
  --muted-3000: var(--muted);
  --trace-3000: var(--trace-3000-light);
  --primary-3000: var(--primary);
  --primary-3000-hover: var(--primary-light);
  --secondary-3000: var(--secondary);
  --secondary-3000-hover: var(--secondary-light);
  --accent-3000: var(--side);
  --bg-3000: var(--bg-light);
  --border-3000: var(--border);
  --border-bold-3000: var(--border);
  --glass-bg-3000: var(--bg-light);
  --glass-border-3000: var(--border);
  --bg-light: #fff;
  --link: var(--primary);
  touch-action: manipulation;
}
body.posthog-3000[theme=light] {
  --text-3000: var(--text-3000-light);
  --muted-3000: var(--muted-3000-light);
  --trace-3000: var(--trace-3000-light);
  --primary-3000: var(--primary-3000-light);
  --primary-3000-hover: var(--primary-3000-hover-light);
  --secondary-3000: var(--secondary-3000-light);
  --secondary-3000-hover: var(--secondary-3000-hover-light);
  --accent-3000: var(--accent-3000-light);
  --bg-3000: var(--bg-3000-light);
  --border-3000: var(--border-3000-light);
  --border-bold-3000: var(--border-bold-3000-light);
  --glass-bg-3000: var(--glass-bg-3000-light);
  --glass-border-3000: var(--glass-border-3000-light);
  --bg-light: #fff;
  --link: var(--link-3000-light);
}
body.posthog-3000[theme=dark] {
  --text-3000: var(--text-3000-dark);
  --muted-3000: var(--muted-3000-dark);
  --trace-3000: var(--trace-3000-dark);
  --primary-3000: var(--primary-3000-dark);
  --primary-3000-hover: var(--primary-3000-hover-dark);
  --secondary-3000: var(--secondary-3000-dark);
  --secondary-3000-hover: var(--secondary-3000-hover-dark);
  --accent-3000: var(--accent-3000-dark);
  --bg-3000: var(--bg-3000-dark);
  --border-3000: var(--border-3000-dark);
  --border-bold-3000: var(--border-bold-3000-dark);
  --glass-bg-3000: var(--glass-bg-3000-dark);
  --glass-border-3000: var(--glass-border-3000-dark);
  --bg-light: var(--accent-3000);
  --brand-key: #fff;
  --link: var(--link-3000-dark);
}
body.posthog-3000 {
  --default: var(--text-3000);
  --muted: var(--muted-3000);
  --muted-alt: var(--muted-3000);
  --primary-alt: var(--text-3000);
  --border: var(--border-3000);
  --border-bold: var(--border-bold-3000);
  --mid: var(--bg-3000);
  --side: var(--bg-3000);
  background: var(--bg-3000);
  overflow: hidden;
}
body.posthog-3000 * > ::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
body.posthog-3000 * > ::-webkit-scrollbar-track {
  background: var(--accent-3000);
}
body.posthog-3000 * > ::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  background: var(--trace-3000);
}
body.posthog-3000 * > ::-webkit-scrollbar-thumb:hover {
  background: var(--muted-3000);
}
body h1,
body h2,
body h3,
body h4 {
  color: var(--default);
}
body h5 {
  font-size: 0.6875rem;
  font-weight: 600;
  color: var(--muted-alt);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 0.25rem;
  line-height: 1.5rem;
}
body pre,
body code,
body kbd,
body samp {
  font-family: var(--font-mono);
}
body a {
  color: var(--link);
}
body .ant-tabs-top > .ant-tabs-nav::before, body .ant-tabs-top > div > .ant-tabs-nav::before,
body .ant-tabs-bottom > .ant-tabs-nav::before,
body .ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom-color: var(--border);
}
body .ant-dropdown {
  border-radius: var(--radius);
  color: var(--default);
}
body .ant-dropdown-menu {
  border-radius: var(--radius);
}
body .ant-modal-content {
  box-shadow: var(--shadow-elevation);
}
body .ant-modal-mask {
  z-index: var(--z-ant-modal-mask);
}
body .ant-modal-wrap {
  z-index: var(--z-ant-modal-wrap);
}
body .ant-message {
  z-index: var(--z-ant-message);
}
body .ant-select-dropdown {
  z-index: var(--z-ant-select-dropdown);
  background-color: var(--bg-3000);
}
body .ant-select-dropdown .ant-select-item.ant-select-item-option {
  background-color: var(--bg-3000);
}
body .ant-select-dropdown .ant-select-item.ant-select-item-option.ant-select-item-option-active {
  background-color: var(--border-3000);
}
body .ant-input-number-group-addon {
  background: var(--bg-3000);
}
body .ant-picker-dropdown {
  z-index: var(--z-ant-select-dropdown);
}
body .ant-popconfirm {
  z-index: var(--z-bottom-notice);
}
body .ant-card-bordered {
  border-color: var(--border);
}
body .ant-card-body > :first-child {
  margin-top: 0;
}
body .ant-form-item-label > label {
  font-weight: 600;
}
body .ant-form-item-label > label .ant-form-item-optional {
  font-weight: 400;
  color: var(--muted);
}
body .ant-form-item {
  margin-bottom: 1rem;
}
body .ant-form-item:last-child {
  margin-bottom: 0;
}
body .ant-tooltip-inner {
  background-color: var(--bg-charcoal);
}
body .ant-tooltip {
  max-width: 350px;
}
body .ant-table-thead > tr > th,
body .ant-table-small .ant-table-thead > tr > th {
  background: var(--mid);
}
body .ant-table-tbody > tr > td {
  border-bottom-color: var(--border);
}
body .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: inherit;
}

.storybook-test-runner *,
.storybook-test-runner *::before,
.storybook-test-runner *::after {
  transition-duration: 0ms !important;
  animation-fill-mode: forwards !important;
  animation-duration: 0ms !important;
  animation-iteration-count: 1 !important;
}
.storybook-test-runner ::-webkit-scrollbar,
.storybook-test-runner .LemonTabs__bar::after,
.storybook-test-runner .scrollable::after,
.storybook-test-runner .scrollable::before {
  display: none;
}

.posthog-3000 .ant-radio-button-wrapper {
  background: var(--secondary-3000);
  border-color: transparent;
}

.posthog-3000[theme=dark] .ant-empty-img-simple-path {
  fill: var(--border-3000);
}
.posthog-3000[theme=dark] .ant-empty-img-simple-ellipse {
  fill: var(--border-3000);
}