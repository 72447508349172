.activity-log.notifications-menu {
  max-width: 25rem;
}
.activity-log .summary-card {
  border-radius: var(--radius);
  border: 1px solid var(--border);
  padding: 1rem;
}
.activity-log .summary-card .taxonomic-breakdown-filter.tag-pill {
  font-size: 0.8125rem;
  padding: 0;
  border: none;
  border-radius: 0;
  background: none;
  vertical-align: unset;
}
.activity-log .summary-card section:not(:last-child) {
  margin-bottom: 0.5rem;
}
.activity-log .activity-log-row {
  display: flex;
  margin-top: 0.5rem;
  line-height: 24px;
  max-width: 50rem;
  overflow-wrap: anywhere;
  padding: 0.5rem;
  gap: 0.5rem;
}
.activity-log .activity-log-row.unread {
  background-color: var(--primary-highlight);
}
.activity-log .activity-log-row:first-of-type {
  margin-top: 0.5rem;
}
.activity-log .activity-log-row .ProfilePicture {
  flex-grow: 0;
}
.activity-log .activity-log-row .details {
  flex-grow: 1;
}
.activity-log .activity-log-row .details .highlighted-activity {
  background-color: var(--mark);
  display: inline;
}
.activity-log .activity-log-row .details .highlighted-activity > .PersonDisplay {
  min-width: 5.5rem;
}
.activity-log .activity-description-extended {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.activity-log .page-caption {
  max-width: 50rem;
}