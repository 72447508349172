.Insight .retention-date-picker {
  background-color: transparent;
  border: 0;
}
.Insight .retention-date-picker input::placeholder {
  color: var(--default);
}

.insight-metadata-tags {
  margin-top: 0.5rem;
}
.insight-metadata-tags .ant-tag {
  margin-top: 0;
}