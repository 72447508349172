.LemonBadge {
  --lemon-badge-color: var(--primary);
  --lemon-badge-size: 1.5rem;
  --lemon-badge-font-size: 0.75rem;
  --lemon-badge-position-offset: 0.5rem;
  --lemon-badge-border-width: 0.125rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bg-light);
  border: var(--lemon-badge-border-width) solid var(--bg-light);
  background: var(--lemon-badge-color);
  width: fit-content;
  min-width: var(--lemon-badge-size);
  height: var(--lemon-badge-size);
  font-size: var(--lemon-badge-font-size);
  line-height: var(--lemon-badge-size);
  padding: calc(var(--lemon-badge-size) / 8);
  border-radius: calc(var(--lemon-badge-size) / 2);
  user-select: none;
  pointer-events: none;
  position: absolute;
  font-weight: 700;
  z-index: 1;
}
.LemonBadge > * {
  width: calc(var(--lemon-badge-size) - var(--lemon-badge-border-width) * 2);
  height: calc(var(--lemon-badge-size) - var(--lemon-badge-border-width) * 2);
  margin: calc(-1 * var(--lemon-badge-size) / 8);
}
.LemonBadge.LemonBadge--success {
  --lemon-badge-color: var(--success);
}
.LemonBadge.LemonBadge--warning {
  --lemon-badge-color: var(--warning);
}
.LemonBadge.LemonBadge--danger {
  --lemon-badge-color: var(--danger);
}
.LemonBadge.LemonBadge--muted {
  --lemon-badge-color: var(--muted);
}
.LemonBadge.LemonBadge--position-none {
  position: relative;
}
.LemonBadge.LemonBadge--position-top-left {
  top: calc(var(--lemon-badge-position-offset) * -1);
  left: calc(var(--lemon-badge-position-offset) * -1);
}
.LemonBadge.LemonBadge--position-top-right {
  top: calc(var(--lemon-badge-position-offset) * -1);
  right: calc(var(--lemon-badge-position-offset) * -1);
}
.LemonBadge.LemonBadge--position-bottom-left {
  bottom: calc(var(--lemon-badge-position-offset) * -1);
  left: calc(var(--lemon-badge-position-offset) * -1);
}
.LemonBadge.LemonBadge--position-bottom-right {
  bottom: calc(var(--lemon-badge-position-offset) * -1);
  right: calc(var(--lemon-badge-position-offset) * -1);
}
.LemonBadge.LemonBadge--small {
  --lemon-badge-size: 1.25rem;
  --lemon-badge-font-size: 0.625rem;
}
.LemonBadge.LemonBadge--large {
  --lemon-badge-size: 1.75rem;
  --lemon-badge-font-size: 0.875rem;
}
.LemonBadge.LemonBadge--dot {
  min-width: 0;
  width: calc(var(--lemon-badge-size) * 0.5 + var(--lemon-badge-border-width));
  height: calc(var(--lemon-badge-size) * 0.5 + var(--lemon-badge-border-width));
}
.LemonBadge.LemonBadge--active {
  outline: calc(var(--lemon-badge-font-size) / 5) solid var(--lemon-badge-color);
  z-index: var(--z-raised);
}
.posthog-3000 .LemonBadge {
  border-color: var(--accent-3000);
  background: var(--muted);
}
.posthog-3000 .LemonBadge.LemonBadge--small {
  --lemon-badge-size: 1.125rem;
}
.LemonBadge.LemonBadge--enter {
  transform: scale(0.5);
  opacity: 0;
}
.LemonBadge.LemonBadge--enter-active {
  transform: scale(1);
  opacity: 1;
  transition: all 200ms ease-out;
}
.LemonBadge.LemonBadge--exit {
  transform: scale(1);
  opacity: 1;
}
.LemonBadge.LemonBadge--exit-active {
  transform: scale(0.5);
  opacity: 0;
  transition: all 200ms ease-in;
}