.LemonSelectMultiple .ant-select {
  width: 100%;
}
.LemonSelectMultiple .ant-select .ant-select-selector, .LemonSelectMultiple .ant-select.ant-select-single .ant-select-selector {
  min-height: 40px;
  padding: 0.25rem;
  border-radius: var(--radius);
  font-size: 0.875rem;
  text-align: left;
  line-height: 1.25rem;
  border: 1px solid var(--border);
  background: var(--bg-light);
}
.LemonSelectMultiple .ant-select .ant-select-selector .ant-select-selection-overflow, .LemonSelectMultiple .ant-select.ant-select-single .ant-select-selector .ant-select-selection-overflow {
  gap: 0.25rem;
}
.LemonSelectMultiple .ant-select:not(.ant-select-disabled):hover .ant-select-selector, .LemonSelectMultiple .ant-select.ant-select-focused:not(.ant-select-disabled) .ant-select-selector, .LemonSelectMultiple .ant-select:not(.ant-select-disabled):active .ant-select-selector {
  background: var(--bg-light);
  border-color: var(--primary);
  box-shadow: none;
}
.LemonSelectMultiple .ant-select:not(.ant-select-disabled):active .ant-select-selector {
  color: var(--primary-active);
}
.LemonSelectMultiple .ant-select .ant-select-selection-placeholder {
  color: var(--muted);
}
.LemonSelectMultiple .ant-select.ant-select-single .ant-select-selector {
  height: 40px;
  box-sizing: border-box;
}
.LemonSelectMultiple .ant-select.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  height: 38px;
}
.LemonSelectMultiple .ant-select.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding-left: 0.4rem;
}
.LemonSelectMultiple .ant-select.ant-select-single .ant-select-selector .ant-select-selection-item {
  padding-left: 0.4rem;
}
.LemonSelectMultiple .ant-select .ant-select-arrow {
  display: none;
}

.LemonSelectMultipleDropdown {
  background: var(--bg-light);
  padding: 0.5rem;
  border-radius: var(--radius);
  border: 1px solid var(--primary);
  margin: -4px 0;
}
.LemonSelectMultipleDropdown .ant-select-item {
  padding: 0;
  background: none;
  padding-bottom: 0.2rem;
}
.LemonSelectMultipleDropdown .ant-select-item .ant-select-item-option-content {
  height: 40px;
  cursor: pointer;
  border-radius: var(--radius);
  padding: 0.25rem 0.5rem;
}
.LemonSelectMultipleDropdown .ant-select-item.ant-select-item-option-active .ant-select-item-option-content {
  background: var(--primary-bg-hover);
}
.LemonSelectMultipleDropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-content {
  background: var(--primary-bg-active);
}
.LemonSelectMultipleDropdown .ant-select-item .ant-select-item-option-state {
  display: none;
}
.LemonSelectMultipleDropdown .ant-select-item-empty {
  padding: 0;
}
.LemonSelectMultipleDropdown .ant-select-item-option-content {
  display: flex;
  align-items: center;
}
.LemonSelectMultipleDropdown .LemonSelectMultipleDropdown__skeleton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 40px;
  padding: 0 0.25rem;
}