.InsightViz {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 1rem;
  container-type: inline-size;
  flex: 1;
}
.InsightViz.InsightViz--horizontal {
  flex-flow: row wrap;
  align-items: start;
}
.InsightViz.InsightViz--horizontal .EditorFiltersWrapper {
  width: 100%;
}
@container (min-width: 768px) {
  .InsightViz.InsightViz--horizontal .EditorFiltersWrapper {
    width: 30%;
    min-width: 26rem;
    max-width: 30rem;
  }
}

.InsightVizDisplay {
  --insight-viz-min-height: calc(80vh - 6rem);
  display: flex;
  flex-direction: column;
}
.NotebookNode .InsightVizDisplay {
  height: 100%;
  flex: 1;
}
.InsightVizDisplay .InsightVizDisplay__content {
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
}
.InsightVizDisplay .InsightVizDisplay__content--with-legend {
  flex-direction: row;
}
.InsightVizDisplay .InsightVizDisplay__content .InsightVizDisplay__content__left {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  width: 100%;
}
.InsightVizDisplay .InsightVizDisplay__content .InsightVizDisplay__content__right {
  flex-shrink: 1;
  max-width: 45%;
  max-height: var(--insight-viz-min-height);
  width: fit-content;
  margin: 1rem;
  display: flex;
  align-items: center;
}
.InsightVizDisplay .InsightDisplayConfig {
  border-bottom-width: 1px;
  padding: 0.5rem;
}

.RetentionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.RetentionContainer .RetentionContainer__graph {
  flex: 1;
  margin: 0.5rem;
}
.RetentionContainer .RetentionContainer__table {
  flex-shrink: 0;
}
.RetentionContainer .LineGraph {
  position: relative;
  width: 100%;
  min-height: 30vh;
}
.NotebookNode .RetentionContainer .LineGraph {
  position: relative;
  min-height: 100px;
}

.TrendsInsight {
  position: relative;
  flex: 1;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  min-height: var(--insight-viz-min-height);
}
.NotebookNode .TrendsInsight {
  min-height: auto;
}
.TrendsInsight--ActionsTable, .TrendsInsight--WorldMap, .TrendsInsight--BoldNumber {
  margin: 0;
  min-height: auto;
}
.TrendsInsight--BoldNumber {
  display: flex;
  align-items: center;
  justify-content: center;
}

.FunnelInsight {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}
.FunnelInsight--type-steps-vertical, .FunnelInsight--type-time_to_convert, .FunnelInsight--type-trends {
  min-height: var(--insight-viz-min-height);
}
.NotebookNode .FunnelInsight--type-steps-vertical, .NotebookNode .FunnelInsight--type-time_to_convert, .NotebookNode .FunnelInsight--type-trends {
  min-height: auto;
}

.funnel-significance-highlight {
  display: inline-flex;
  background: var(--primary);
  color: var(--bg-light);
}
.funnel-significance-highlight .LemonIcon {
  color: var(--bg-light);
}