.Breadcrumbs3000 {
  --breadcrumbs-compaction-rate: 0;
  z-index: var(--z-main-nav);
  position: sticky;
  top: 0;
  display: flex;
  align-items: start;
  height: var(--breadcrumbs-height-full);
  pointer-events: none;
  white-space: nowrap;
}

.Breadcrumbs3000__content {
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  height: calc(var(--breadcrumbs-height-full) - (var(--breadcrumbs-height-full) - var(--breadcrumbs-height-compact)) * var(--breadcrumbs-compaction-rate));
  background: var(--glass-bg-3000);
  border-bottom: 1px solid var(--glass-border-3000);
  font-size: calc(0.75rem + 0.0625rem * var(--breadcrumbs-compaction-rate));
  line-height: 1rem;
  font-weight: 600;
  user-select: none;
  pointer-events: auto;
}

.Breadcrumbs3000__crumbs {
  display: flex;
  align-items: center;
}

.Breadcrumbs3000__trail {
  flex-grow: 1;
  flex-shrink: 1;
  overflow-x: auto;
}

.Breadcrumbs3000__here {
  position: relative;
  line-height: 1.2;
  margin: calc(0.25rem * (1 - var(--breadcrumbs-compaction-rate))) 0 0;
  font-size: 1rem;
  font-weight: 700;
  overflow: hidden;
  height: calc(1.2em * (1 - var(--breadcrumbs-compaction-rate)));
}
.Breadcrumbs3000__here > span {
  position: absolute;
  bottom: 0;
}

.Breadcrumbs3000__breadcrumb {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  white-space: pre;
  cursor: default;
  color: var(--default);
  overflow: hidden;
}
.Breadcrumbs3000__breadcrumb.Link:hover {
  color: inherit;
}
.Breadcrumbs3000__breadcrumb.Breadcrumbs3000__breadcrumb--here {
  cursor: default;
}
.Breadcrumbs3000__breadcrumb.Breadcrumbs3000__breadcrumb--here > span {
  opacity: 1;
  transform: translateY(calc(100% * (1 - var(--breadcrumbs-compaction-rate))));
}
.Breadcrumbs3000__breadcrumb.Breadcrumbs3000__breadcrumb--actionable {
  cursor: pointer;
}
.Breadcrumbs3000__breadcrumb.Breadcrumbs3000__breadcrumb--actionable:hover > span, .Breadcrumbs3000__breadcrumb.Breadcrumbs3000__breadcrumb--actionable.Breadcrumbs3000__breadcrumb--open > span {
  opacity: 1;
}
.Breadcrumbs3000__breadcrumb > span {
  transition: opacity 200ms ease;
  opacity: 0.5;
}
.Breadcrumbs3000__breadcrumb > .Lettermark {
  margin-right: 0.5rem;
}
.Breadcrumbs3000__breadcrumb > .LemonIcon {
  font-size: 1rem;
  margin-left: 0.125rem;
  opacity: 0.5;
}

.Breadcrumbs3000__separator {
  flex-shrink: 0;
  margin: 0 0.5rem;
  opacity: 0.5;
}
.Breadcrumbs3000__separator::after {
  content: "/";
}

.Breadcrumbs3000__more {
  margin-left: 0.5rem;
}

.Breadcrumbs3000__actions {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 0.5rem;
  gap: 0.5rem;
}