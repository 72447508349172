.LemonMarkdown > * {
  margin: 0 0 0.5em;
}
.LemonMarkdown > *:last-child {
  margin-bottom: 0;
}
.LemonMarkdown ol,
.LemonMarkdown ul,
.LemonMarkdown dl {
  padding-left: 1.5em;
}
.LemonMarkdown ol {
  list-style-type: decimal;
}
.LemonMarkdown ul {
  list-style-type: disc;
}
.LemonMarkdown strong[level] {
  display: block;
}
.LemonMarkdown hr {
  margin: 1em 0;
}
.LemonMarkdown h1 {
  margin-bottom: 0.25em;
  font-weight: 600;
}