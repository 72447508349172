.DataTable td {
  max-width: 20rem;
}
@keyframes DataTable__highlight {
  0% {
    background-color: var(--mark);
  }
  100% {
    background-color: initial;
  }
}
.DataTable .DataTable__row--highlight_once {
  animation: DataTable__highlight 2000ms ease-out;
}
.DataTable .DataTable__row--category_row {
  background-color: var(--mid);
  color: var(--muted);
  font-weight: 600;
  font-size: 0.75rem;
  height: 2rem;
  text-align: center;
}