.Splotch {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem;
}

.Splotch__paint {
  width: 1rem;
  height: 1rem;
  border-radius: var(--radius);
}
.Splotch--blue .Splotch__paint {
  background: var(--blue);
}
.Splotch--purple .Splotch__paint {
  background: var(--purple);
}
.Splotch--green .Splotch__paint {
  background: var(--green);
}
.Splotch--black .Splotch__paint {
  background: var(--black);
}
.Splotch--white .Splotch__paint {
  background: #fff;
  border: 1px solid var(--border);
}