.NotebookPopover {
  position: fixed;
  inset: 0;
  z-index: var(--z-modal);
  pointer-events: none;
}
.NotebookPopover .NotebookPopover__backdrop {
  position: absolute;
  inset: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: none;
  opacity: 0;
  transition: opacity 200ms ease-out;
}
.NotebookPopover .NotebookPopover__content {
  position: absolute;
  top: 1rem;
  bottom: 1rem;
  right: 1rem;
  z-index: 2;
  min-width: 30rem;
  max-width: calc(100vw - 2rem);
  width: 50rem;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  transform: translateX(calc(100% + 1rem));
  transition: transform var(--notebook-popover-transition-properties), width var(--notebook-popover-transition-properties);
}
.NotebookPopover .NotebookPopover__content .NotebookPopover__content__card {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: var(--radius);
  background-color: var(--bg-3000);
  border: 1px solid var(--border-3000);
  box-shadow: 0 16px 16px rgba(0, 0, 0, 0);
  transition: box-shadow 150ms linear;
  overflow: hidden;
}
.NotebookPopover--visible .NotebookPopover__backdrop {
  pointer-events: all;
  opacity: 1;
}
.NotebookPopover--visible .NotebookPopover__content {
  transform: translateX(0);
}
.NotebookPopover--visible .NotebookPopover__content .NotebookPopover__content__card {
  box-shadow: 0 16px 16px rgba(0, 0, 0, 0.15);
}
.NotebookPopover--peek .NotebookPopover__content {
  transition: none;
  transform: translateX(calc(100% - 5rem));
}
.NotebookPopover--peek .NotebookPopover__content .NotebookPopover__content__card {
  box-shadow: 0 16px 16px rgba(0, 0, 0, 0.15);
}
.NotebookPopover--with-sidebar .NotebookPopover__content {
  width: calc(50rem + var(--notebook-column-left-width));
}
.NotebookPopover--full-screen .NotebookPopover__content {
  width: calc(100vw - 2rem);
}
.NotebookPopover .NotebookPanelDropzone {
  box-shadow: 0 16px 16px rgba(0, 0, 0, 0.15);
  border: 2px dashed var(--border-3000);
  border-radius: var(--radius);
  transition: all 150ms;
  height: 4rem;
  margin-bottom: 1rem;
  backdrop-filter: blur(5px);
  display: flex;
}
.NotebookPopover .NotebookPanelDropzone .NotebookPanelDropzone__message {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
  color: var(--muted-alt);
  text-align: center;
  pointer-events: none;
  background-color: var(--bg-light);
  padding: 1rem;
  opacity: 0.75;
  transition: all 150ms;
}
.NotebookPopover .NotebookPanelDropzone .NotebookPanelDropzone__dropped {
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.NotebookPopover .NotebookPanelDropzone--active {
  border-color: var(--primary);
  height: 8rem;
}
.NotebookPopover .NotebookPanelDropzone--active .NotebookPanelDropzone__message {
  opacity: 1;
}
.NotebookPopover .NotebookPanelDropzone--dropped {
  padding: 1rem;
  border-color: var(--primary);
  background-color: var(--bg-light);
  height: 100%;
  justify-content: flex-start;
  align-items: initial;
}