.TopBar {
  z-index: var(--z-main-nav);
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
  padding: 0.5rem;
  background: var(--bg-bridge);
  border-bottom: 1px solid var(--border);
  gap: 1rem;
}
@media screen and (min-width: 576px) {
  .TopBar {
    padding: 0.5rem 1rem;
  }
}

.TopBar__segment {
  display: flex;
  align-items: center;
  height: 100%;
}
.TopBar__segment--left {
  flex-grow: 1;
}
.TopBar__segment--left > * + * {
  margin-left: 1rem;
}
.TopBar__segment--right > * + * {
  margin-left: 1rem;
}

.TopBar__hamburger {
  display: flex;
  height: 1.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  user-select: none;
}

.TopBar__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 25px;
  /*width: 40px;
  overflow: hidden;
  flex-shrink: 0;

  svg {
      vertical-align: middle;
  }

  @include screen($md) {
      width: auto;
      overflow: hidden;
      font-size: 1rem;
      display: flex;
      align-items: center;
  }*/
}

.TopBar__lightning-mode-box {
  background: var(--bridge) !important;
}
.TopBar__lightning-mode-box .LemonSwitch__slider {
  background-color: var(--border) !important;
}

.Announcement {
  transition: margin 200ms ease;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  font-size: 1rem;
  color: white;
  height: 3rem;
  padding: 0 1rem 0 0.5rem;
  text-align: center;
  font-weight: 500;
}
.Announcement.Announcement--hidden {
  margin-top: -3rem;
}
@media screen and (min-width: 576px) {
  .Announcement {
    padding: 0 1rem;
  }
}
.Announcement p {
  margin: 0;
}
.Announcement b,
.Announcement strong {
  font-weight: 700;
}
.Announcement a {
  color: var(--brand-red);
  text-decoration: underline;
}

.Announcement__close {
  display: flex;
  position: absolute;
  right: 0.5rem;
  cursor: pointer;
  font-size: 1.25rem;
  padding: 0.125rem;
}
@media screen and (min-width: 576px) {
  .Announcement__close {
    right: 1rem;
  }
}

.SitePopover {
  max-width: 22rem;
  min-width: 16rem;
}

.SitePopover__main-info {
  flex-grow: 1;
}

.SitePopover__side-link {
  color: var(--primary);
  margin-left: 0.5rem;
  font-weight: 600;
  font-size: 0.8125rem;
  text-align: right;
}

.SitePopover__crumb {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: var(--primary-alt);
  height: 2.5rem;
  cursor: pointer;
}

.SitePopover__profile-picture {
  position: relative;
}

.SitePopover__danger {
  position: absolute;
  top: -0.375rem;
  right: -0.375rem;
  color: #fff;
  background: var(--danger);
  font-size: 0.75rem;
  box-sizing: content-box;
  border-radius: 100%;
  border: 2px solid var(--bg-bridge);
}

.SitePopover__section {
  width: 100%;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--border);
}
.SitePopover__section:first-child {
  padding-top: 0;
}
.SitePopover__section:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.AccountInfo {
  display: flex;
  align-items: center;
  margin: 0.5rem;
}

.AccountInfo__identification {
  width: 100%;
  margin-left: 0.5rem;
  overflow: hidden;
}

.AccessLevelIndicator {
  font-size: 0.625rem;
  margin-left: 0.5rem;
  text-transform: uppercase;
}