.pay-gate-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
}
.pay-gate-page h2 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 8px;
}
.pay-gate-page h2 .highlight {
  font-weight: bold;
  color: var(--danger);
}
.pay-gate-page .pay-caption {
  max-width: 500px;
}
.pay-gate-page .pay-buttons {
  margin-top: 1rem;
  width: 320px;
  max-width: 90%;
}