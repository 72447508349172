.Lettermark {
  display: inline-block;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: var(--radius);
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: center;
  color: var(--lettermark-1-text);
  background: var(--lettermark-1-bg);
  user-select: none;
}
.Lettermark.Lettermark--rounded {
  border-radius: 1.5rem;
}
.Lettermark.Lettermark--variant-1 {
  color: var(--lettermark-1-text);
  background: var(--lettermark-1-bg);
}
.Lettermark.Lettermark--variant-2 {
  color: var(--lettermark-2-text);
  background: var(--lettermark-2-bg);
}
.Lettermark.Lettermark--variant-3 {
  color: var(--lettermark-3-text);
  background: var(--lettermark-3-bg);
}
.Lettermark.Lettermark--variant-4 {
  color: var(--lettermark-4-text);
  background: var(--lettermark-4-bg);
}
.Lettermark.Lettermark--variant-5 {
  color: var(--lettermark-5-text);
  background: var(--lettermark-5-bg);
}
.Lettermark.Lettermark--variant-6 {
  color: var(--lettermark-6-text);
  background: var(--lettermark-6-bg);
}
.Lettermark.Lettermark--variant-7 {
  color: var(--lettermark-7-text);
  background: var(--lettermark-7-bg);
}
.Lettermark.Lettermark--variant-8 {
  color: var(--lettermark-8-text);
  background: var(--lettermark-8-bg);
}
.Lettermark.Lettermark--variant-gray {
  color: var(--default);
  background: var(--border-light);
}