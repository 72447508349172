.NotebookPanel {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--accent-3000);
  overflow: hidden;
}
.NotebookPanel .NotebookPanelDropzone {
  border: 2px dashed var(--border-3000);
  border-radius: var(--radius);
  margin: 0.5rem;
  transition: all 150ms;
  height: 4rem;
  display: flex;
}
.NotebookPanel .NotebookPanelDropzone .NotebookPanelDropzone__message {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
  color: var(--muted-alt);
  text-align: center;
  pointer-events: none;
  background-color: var(--bg-light);
  padding: 1rem;
  opacity: 0.75;
  transition: all 150ms;
}
.NotebookPanel .NotebookPanelDropzone .NotebookPanelDropzone__dropped {
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.NotebookPanel .NotebookPanelDropzone--active {
  border-color: var(--primary);
  height: 8rem;
}
.NotebookPanel .NotebookPanelDropzone--active .NotebookPanelDropzone__message {
  opacity: 1;
}
.NotebookPanel .NotebookPanelDropzone--dropped {
  border: none;
  height: 100%;
  margin: 1rem;
  justify-content: flex-start;
  align-items: initial;
}