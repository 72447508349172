.insight-empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--muted);
  padding: 1rem;
  font-size: 1.1em;
  flex-grow: 1;
}
.insight-empty-state.error .illustration-main,
.insight-empty-state.error .spinner {
  color: #fb8c6a;
}
.insight-empty-state.error h2 {
  color: var(--danger);
}
.insight-empty-state.warning .illustration-main,
.insight-empty-state.warning .spinner {
  color: #fec34d;
}
.insight-empty-state.warning h2 {
  color: var(--warning);
}
.insight-empty-state h2 {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.6rem;
  color: var(--primary-alt);
}
.insight-empty-state button.ant-btn-lg {
  height: 42px;
}
.insight-empty-state .empty-state-inner {
  max-width: 600px;
}
.insight-empty-state .empty-state-inner .illustration-main {
  display: flex;
  justify-content: center;
  font-size: 4rem;
  height: auto;
  line-height: 1em;
  text-align: center;
  margin-bottom: 0.75rem;
}
.insight-empty-state .empty-state-inner .illustration-main .ant-empty {
  height: 6rem;
  margin: 0;
}
.insight-empty-state .empty-state-inner .illustration-main .ant-empty .ant-empty-image {
  height: 100%;
}
.insight-empty-state .empty-state-inner .illustration-main .ant-empty .ant-empty-image svg {
  width: 4rem;
}
.insight-empty-state .empty-state-inner h2 {
  text-align: center;
}
.insight-empty-state .empty-state-inner ol {
  margin: 0.5rem 0;
}