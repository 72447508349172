.LemonWidget {
  background: var(--bg-light);
  border-radius: var(--radius);
  border: 1px solid var(--border);
}
.LemonWidget .LemonWidget__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  padding: 0.25rem;
  color: var(--muted-alt-3000, var(--primary));
  font-size: 0.875rem;
}