.PlayerFrameOverlay {
  position: absolute;
  inset: 0;
  z-index: 10;
}
.PlayerFrameOverlay .PlayerFrameOverlay__content {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: opacity 100ms;
  background-color: rgba(0, 0, 0, 0.15);
  opacity: 0.8;
}
.PlayerFrameOverlay .PlayerFrameOverlay__content:hover {
  opacity: 1;
}
.PlayerFrameOverlay .PlayerFrameOverlay__content--only-hover {
  opacity: 0;
}