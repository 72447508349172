.definition-page.definition-view-page .definition-tags {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.definition-page.definition-view-page .definition-popover-timemeta {
  display: inline;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.definition-page.definition-view-page .definition-popover-timemeta .definition-popover-timemeta-user {
  display: inline-flex;
}
.definition-page.definition-view-page .definition-popover-timemeta .definition-popover-timemeta-spacer {
  margin-right: 4px;
}
.definition-page.definition-view-page .definition-popover-grid {
  display: grid;
  grid-gap: 1rem;
}
.definition-page.definition-view-page .definition-popover-grid .definition-popover-grid-card {
  display: flex;
  flex-direction: column;
}
.definition-page.definition-view-page .definition-popover-grid .definition-popover-grid-card .definition-popover-grid-card-title {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--muted);
  margin-bottom: 4px;
}
.definition-page.definition-view-page .definition-popover-grid .definition-popover-grid-card .definition-popover-grid-card-content {
  font-weight: 600;
}
.definition-page.definition-view-page .event-properties-wrapper .event-properties-header {
  font-weight: 600;
}
.definition-page.definition-view-page .event-properties-wrapper .definition-pill-value {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.125rem 0.25rem;
  letter-spacing: 0.25px;
  border-radius: var(--radius);
  border: 1px solid var(--border-light);
  background: var(--mid);
  color: var(--muted-alt);
  font-size: 0.625rem;
  font-weight: 500;
  word-break: break-all;
  max-width: 300px;
  cursor: text;
}
.definition-page.definition-view-page .event-properties-wrapper td.definition-column-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.definition-page.definition-view-page .event-properties-wrapper td.definition-column-name .definition-column-name-content {
  display: flex;
  flex-direction: column;
  position: relative;
}
.definition-page.definition-view-page .event-properties-wrapper td.definition-column-name .definition-column-name-content .definition-column-name-content-title {
  font-weight: 600;
  cursor: pointer;
  position: relative;
  overflow: visible;
}
.definition-page.definition-view-page .event-properties-wrapper td.definition-column-name .definition-column-name-content .definition-column-name-content-title::before {
  content: "";
  position: absolute;
  inset: -5px -50px -5px -10px;
  height: 22px;
}
.definition-page.definition-view-page .event-properties-wrapper td.definition-column-name .definition-column-name-content .definition-column-name-content-description {
  font-size: 13px;
  color: var(--muted);
  text-overflow: ellipsis;
}
.definition-page.definition-view-page .event-properties-wrapper td.definition-example-type .definition-pill-value {
  float: right;
}
.definition-page.definition-view-page .definition-matching-events .definition-matching-events-header {
  font-weight: 600;
}
.definition-page.definition-view-page .definition-matching-events .definition-matching-events-subtext {
  margin: 0 0 1rem;
}
.definition-page.definition-edit-page .definition-footer-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.definition-page.definition-edit-page .DefinitionEdit--form {
  max-width: 640px;
}
.definition-page .definition-sent-as {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: var(--muted);
  margin: 0.5rem 0;
}
.definition-page .definition-sent-as pre {
  font-size: 13px;
  font-weight: 600;
  margin: 0 0.25rem;
}