.compact-list {
  border-radius: var(--radius);
  border: 1px solid var(--border);
  height: calc(19.5rem + 1px);
  background: var(--bg-light);
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.compact-list .compact-list-header {
  padding: 0.5rem 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.compact-list .compact-list-header h3 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.4;
}
.compact-list .spacer-container {
  padding: 0 1rem;
}
.compact-list .scrollable-list {
  flex: 1;
  overflow: auto auto;
  padding: 0 0.5rem 0.5rem;
}