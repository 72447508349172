.SelectableElement {
  transition: background-color 200ms ease, color 200ms ease, border 200ms ease;
  border: 1px solid transparent;
  border-radius: 4px;
}
.SelectableElement.SelectableElement--selected {
  background: var(--primary);
}
.SelectableElement:hover {
  background: var(--primary-light);
}
.SelectableElement:active {
  background: var(--primary-dark);
}