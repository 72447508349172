.SessionRecordingsPlaylist {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  border: 1px solid var(--border);
  border-radius: var(--radius);
  height: 100%;
}
.SessionRecordingsPlaylist .SessionRecordingsPlaylist__list {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  max-width: 350px;
  min-width: 300px;
  width: 25%;
  overflow: hidden;
  height: 100%;
}
.SessionRecordingsPlaylist .SessionRecordingsPlaylist__player {
  flex: 1;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.SessionRecordingsPlaylist .SessionRecordingsPlaylist__player .SessionRecordingsPlaylist__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
}
.SessionRecordingsPlaylist--embedded {
  border: none;
}
.SessionRecordingsPlaylist--wide .SessionRecordingsPlaylist__player {
  flex: 1;
  height: 100%;
}

.SessionRecordingPlaylistHeightWrapper {
  height: calc(100vh - 15rem);
  min-height: 41rem;
}

.SessionRecordingPreview {
  display: flex;
  padding: 0.5rem 0 0.5rem 0.5rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-left: 6px solid transparent;
  transition: background-color 200ms ease, border 200ms ease;
}
.SessionRecordingPreview--active {
  border-left-color: var(--primary);
}
.SessionRecordingPreview:hover {
  background-color: var(--primary-highlight);
}
.SessionRecordingPreview .SessionRecordingPreview__property-icon:hover {
  transition: opacity 200ms;
  opacity: 1;
}