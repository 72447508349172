.LemonTag {
  font-size: 0.75rem;
  font-weight: var(--font-medium);
  background: var(--border);
  padding: 0.125rem 0.25rem;
  border-radius: var(--radius);
  display: inline-flex;
  align-items: center;
  color: var(--default);
  line-height: 1rem;
  white-space: nowrap;
}
.LemonTag.primary {
  background-color: var(--primary);
  color: #fff;
}
.LemonTag.highlight {
  background-color: var(--mark);
  color: var(--bg-charcoal);
}
.LemonTag.warning {
  background-color: var(--warning);
  color: var(--bg-charcoal);
}
.LemonTag.danger {
  background-color: var(--danger);
  color: #fff;
}
.LemonTag.success {
  background-color: var(--success);
  color: #fff;
}
.LemonTag.completion {
  background-color: var(--purple-light);
  color: var(--bg-charcoal);
}
.LemonTag.caution {
  background-color: var(--danger-lighter);
  color: var(--bg-charcoal);
}
.LemonTag.none {
  background: none;
}
.LemonTag.LemonTag--size-small {
  font-size: 0.625rem;
  padding: 0.0625rem 0.1875rem;
}
.LemonTag .LemonTag__icon {
  font-size: 0.875rem;
  margin-right: 0.125rem;
  display: flex;
}
.LemonTag .LemonTag__right-button {
  margin-left: 0.25rem;
  min-height: 1.5rem;
  padding: 0.125rem !important;
}