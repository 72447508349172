.PlanComparisonModal {
  max-width: 900px;
}

table.PlanComparison {
  table-layout: fixed;
}
table.PlanComparison td {
  vertical-align: top;
  padding: 0.75rem 1.25rem;
}
table.PlanComparison td.PlanTable__td__upgradeButton {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
table.PlanComparison th {
  vertical-align: top;
  padding: 0.75rem 1.25rem;
  font-weight: 600;
  text-align: left;
}
table.PlanComparison th.PlanTable__th__section {
  padding: 0.25rem 1.25rem;
  font-weight: 500;
}
table.PlanComparison th.PlanTable__th__feature {
  padding: 0.75rem 1.25rem 0.75rem 3.25rem;
  font-weight: 600;
}
table.PlanComparison th.PlanTable__th__feature.PlanTable__th__feature--reduced_padding {
  padding: 0.75rem 1.25rem;
}
table.PlanComparison th.PlanTable__th__last-feature {
  padding-bottom: 2rem;
}
table.PlanComparison th p {
  font-weight: 400;
}

.PlanTable__tr__border {
  border-bottom: 3px rgba(0, 0, 0, 0.07) dotted;
}