.LemonTextArea {
  transition: background-color 200ms ease, color 200ms ease, border 200ms ease, opacity 200ms ease;
  min-height: 2.5rem;
  padding: 0.5rem;
  background: none;
  border-radius: var(--radius);
  font-size: 0.875rem;
  text-align: left;
  line-height: 1.25rem;
  cursor: text;
  color: var(--default);
  border: 1px solid var(--border);
  background-color: var(--bg-light);
  outline: none;
  display: block;
  width: 100%;
  text-overflow: ellipsis;
}
.LemonTextArea:not(:disabled):hover {
  border: 1px solid var(--primary-light);
}
.LemonTextArea:disabled {
  opacity: var(--opacity-disabled);
  cursor: not-allowed;
}
.LemonTextArea:focus:not(:disabled) {
  border: 1px solid var(--primary);
}
.Field--error .LemonTextArea {
  border: 1px solid var(--danger);
}

.LemonTextArea--preview ul {
  list-style-type: disc;
  list-style-position: inside;
}
.LemonTextArea--preview ol {
  list-style-type: decimal;
  list-style-position: inside;
}