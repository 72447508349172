.LemonCheckbox {
  display: flex;
  width: fit-content;
  font-weight: 500;
  align-items: center;
  line-height: 1.5rem;
}
.LemonCheckbox .LemonCheckbox__input {
  appearance: none !important;
  width: 0 !important;
  height: 0 !important;
}
.LemonCheckbox label {
  --tick-length: 12.73;
  --box-color: var(--primary);
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.5rem;
  min-height: 1.5rem;
}
.LemonCheckbox label > .LemonCheckbox__box {
  width: 1rem;
  height: 1rem;
  transition: border 200ms ease, background 200ms ease;
  background: var(--bg-light);
  border: 1.5px solid var(--border-bold);
  border-radius: 3px;
  flex-shrink: 0;
}
.LemonCheckbox label > .LemonCheckbox__box path {
  transition: stroke-dashoffset 200ms ease;
  stroke: var(--bg-light);
  stroke-dasharray: var(--tick-length);
  stroke-dashoffset: var(--tick-length);
}
.LemonCheckbox.LemonCheckbox--disabled label {
  cursor: not-allowed;
  color: var(--muted);
}
.LemonCheckbox.LemonCheckbox--full-width {
  width: 100%;
}
.LemonCheckbox.LemonCheckbox--full-width label {
  width: 100%;
}
.LemonCheckbox.LemonCheckbox--indeterminate label {
  --tick-length: 9;
}
.LemonCheckbox.LemonCheckbox:not(.LemonCheckbox--disabled):hover label, .LemonCheckbox.LemonCheckbox:not(.LemonCheckbox--disabled):active label {
  --box-color: var(--primary-light);
}
.LemonCheckbox.LemonCheckbox:not(.LemonCheckbox--disabled):hover label .LemonCheckbox__box, .LemonCheckbox.LemonCheckbox:not(.LemonCheckbox--disabled):active label .LemonCheckbox__box {
  border-color: var(--box-color);
}
.LemonCheckbox.LemonCheckbox:not(.LemonCheckbox--disabled):active label {
  --box-color: var(--primary-dark);
}
.LemonCheckbox.LemonCheckbox--checked label .LemonCheckbox__box {
  background: var(--box-color);
  border-color: transparent;
}
.LemonCheckbox.LemonCheckbox--checked label .LemonCheckbox__box path {
  stroke-dashoffset: 0;
}
.LemonCheckbox.LemonCheckbox--bordered label {
  padding: 0 0.75rem;
  border-radius: var(--radius);
  border: 1px solid var(--border);
  background: var(--bg-light);
  min-height: 2.5rem;
}
.LemonCheckbox.LemonCheckbox--bordered.LemonCheckbox--small label {
  padding: 0 0.5rem;
  min-height: 2rem;
}
.Field--error .LemonCheckbox.LemonCheckbox--bordered label {
  border: 1px solid var(--danger);
}