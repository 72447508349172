.CohortCriteriaRow {
  padding: 0 0.5rem;
}
.CohortCriteriaRow .CohortCriteriaRow__Criteria {
  border-radius: var(--radius);
  border: 1px solid transparent;
  padding: 0.25rem 0.5rem;
}
.CohortCriteriaRow .CohortCriteriaRow__Criteria.CohortCriteriaRow__Criteria--error {
  border: 1px solid var(--danger);
}
.CohortCriteriaRow .CohortCriteriaRow__Criteria .CohortCriteriaRow__Criteria__error-row {
  margin: 0.25rem 0;
}
.CohortCriteriaRow .CohortCriteriaRow__Criteria .CohortCriteriaRow__Criteria__Field {
  margin: 0.25rem 0;
}
.CohortCriteriaRow .CohortCriteriaRow__Criteria .CohortCriteriaRow__Criteria__Field.CohortCriteriaRow__Criteria__Field--error .CohortField {
  border: 1px solid var(--danger);
}
.CohortCriteriaRow .CohortCriteriaRow__Criteria .CohortCriteriaRow__Criteria__Field:not(:last-child) {
  margin-right: 0.5rem;
}
.CohortCriteriaRow .logical-row-divider {
  color: var(--primary-alt);
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
}
.CohortCriteriaRow .logical-row-divider::before, .CohortCriteriaRow .logical-row-divider::after {
  border-top: 1px solid var(--border);
}
.CohortCriteriaRow .logical-row-divider::before {
  width: 1.5%;
}
.CohortCriteriaRow .CohortCriteriaRow__inline-divider {
  margin: 0 0.5rem;
  height: 1px;
  background-color: var(--border);
  flex: 1;
}
.CohortCriteriaRow .CohortCriteriaRow__Criteria__arrow {
  color: var(--muted);
  font-size: 18px;
  font-weight: bold;
  padding-left: 6px;
  padding-right: 8px;
  position: relative;
  top: 6px;
  user-select: none;
  flex-shrink: 0;
}