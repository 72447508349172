.TZLabelPopover .TZLabelPopover__row {
  display: flex;
  margin-top: 0.5rem;
}
.TZLabelPopover .TZLabelPopover__row > :nth-child(1) {
  font-weight: bold;
  color: var(--primary-alt);
  margin-right: 6px;
}
.TZLabelPopover .TZLabelPopover__row > :nth-child(2) {
  color: var(--muted);
  margin-right: 16px;
  flex-grow: 1;
  font-style: italic;
}
.TZLabelPopover .TZLabelPopover__row > :nth-child(3) {
  min-width: 10rem;
  text-align: right;
}
.TZLabelPopover--seconds .TZLabelPopover .TZLabelPopover__row > :nth-child(3) {
  min-width: 12rem;
}