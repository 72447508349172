.ActivationSideBar {
  z-index: var(--z-lemon-sidebar);
  transition: 200ms ease margin, 200ms ease transform;
  position: absolute;
  right: 0;
  flex-shrink: 0;
  height: 100%;
  background: var(--side);
  border-left: 1px solid var(--border);
  width: 25rem;
  margin-left: -25rem;
}
@media screen and (min-width: 992px) {
  .ActivationSideBar {
    height: initial;
    position: relative;
  }
}

.ActivationSideBar--hidden {
  display: none;
}

.ActivationSideBar__content {
  position: sticky;
  top: 3.5rem;
  right: 0;
  width: 100%;
  height: calc(100vh - 3.5rem);
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
.ActivationSideBar__content > div > ul {
  overflow: auto;
}
.ActivationSideBar__content > div > ul li {
  margin-top: 0.5rem;
}
.ActivationSideBar__content .ActivationSideBar__close_button {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}
.ActivationSideBar__content .ActivationSideBar__hog {
  height: 150px;
}