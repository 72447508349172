.Popover__CohortField .Popover__box {
  max-height: 30rem;
}

.CohortField__dropdown {
  min-width: 14rem;
  max-width: calc(100vw - 14rem);
}

.CohortField__CohortTextField {
  font-weight: 500;
}

.CohortField__CohortNumberField {
  max-width: 4rem;
}

.CohortField__CohortPersonPropertiesValuesField {
  min-width: 150px;
  min-height: 40px;
  border: 1px solid var(--border);
  border-radius: var(--radius);
}
.CohortField__CohortPersonPropertiesValuesField .ant-select-selection-search,
.CohortField__CohortPersonPropertiesValuesField .ant-select-selection-placeholder {
  display: flex;
  align-items: center;
  padding: 0 4px !important;
}
.CohortField__CohortPersonPropertiesValuesField.ant-select-single .ant-select-selector {
  min-height: 38px !important;
  height: unset;
  border: none !important;
  background-color: inherit;
}
.CohortField__CohortPersonPropertiesValuesField.ant-select-multiple .ant-select-selector {
  height: 100% !important;
  border: none !important;
  padding: 5px 40px 5px 11px;
  background-color: inherit;
}
.CohortField__CohortPersonPropertiesValuesField.ant-select-multiple .ant-select-selector .ant-select-selection-search {
  padding-left: 0 !important;
}
.CohortField__CohortPersonPropertiesValuesField.ant-select-multiple .ant-select-selector .ant-select-selection-placeholder {
  padding-left: 6px !important;
}