.InstructionsPanel {
  max-width: 50rem;
}
.InstructionsPanel h1 {
  font-size: 28px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: -0.02em;
}
.InstructionsPanel h2 {
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-top: 0.5rem;
}
.InstructionsPanel h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  margin-top: 0.5rem;
}
.InstructionsPanel ol {
  padding-left: 1rem;
}