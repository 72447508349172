.TimelineSeekbar {
  --timeline-seekbar-thickness: 0.125rem;
  --timeline-seekbar-arrow-width: 0.5rem;
  --timeline-seekbar-arrow-height: 0.75rem;
  margin: 0.75rem 0.5rem;
}

.TimelineSeekbar__meta {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.TimelineSeekbar__note {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  line-height: 1.25rem;
}

.TimelineSeekbar__current {
  width: fit-content;
  padding: 0 0.25rem;
  border-radius: var(--radius);
  background: var(--primary);
  color: var(--bg-light);
  line-height: 1.25rem;
  font-size: 0.75rem;
  font-weight: 500;
}
.TimelineSeekbar__current::selection {
  background: var(--primary-light);
}
.TimelineSeekbar__current .Spinner {
  margin-right: 0.25rem;
}

.TimelineSeekbar__seekbar {
  position: relative;
  height: 1.25rem;
  margin-top: 0.5rem;
}

.TimelineSeekbar__points {
  display: flex;
  height: 100%;
  position: relative;
  top: 0;
  left: calc(var(--timeline-seekbar-thickness) * 2);
  width: calc(100% - var(--timeline-seekbar-arrow-width) - var(--timeline-seekbar-thickness) * 3 - 1.25rem);
}
.TimelineSeekbar__points .LemonBadge:not(.LemonBadge--active) {
  border-right-color: transparent;
  background-clip: padding-box;
}

.TimelineSeekbar__section {
  position: absolute;
  cursor: pointer;
  left: calc(var(--timeline-seekbar-section-progress-current) - var(--timeline-seekbar-thickness));
  width: var(--timeline-seekbar-section-progress-next);
  border-left: var(--timeline-seekbar-thickness) solid var(--bg-light);
}
.TimelineSeekbar__section:last-child {
  width: calc(var(--timeline-seekbar-section-progress-next) + 1.25rem);
}

.TimelineSeekbar__line {
  position: absolute;
  top: calc(50% - var(--timeline-seekbar-thickness) / 2);
  left: 0;
  height: var(--timeline-seekbar-thickness);
  width: calc(100% - var(--timeline-seekbar-arrow-width));
  background: var(--primary);
}

.TimelineSeekbar__line-start,
.TimelineSeekbar__line-end {
  position: absolute;
  top: calc(var(--timeline-seekbar-thickness) / 2 - 0.625rem);
  height: 1.25rem;
}
.TimelineSeekbar__line-start::before,
.TimelineSeekbar__line-end::before {
  content: "";
  display: block;
  margin: calc(var(--timeline-seekbar-thickness) + 0.125rem) 0;
  height: var(--timeline-seekbar-arrow-height);
  background: var(--primary);
}

.TimelineSeekbar__line-start {
  left: 0;
  width: var(--timeline-seekbar-thickness);
}

.TimelineSeekbar__line-end {
  left: 100%;
  width: var(--timeline-seekbar-arrow-width);
  cursor: pointer;
}
.TimelineSeekbar__line-end::before {
  clip-path: polygon(0 0, 100% 50%, 0 100%);
}