.AnnotationsOverlay {
  position: absolute;
  top: var(--annotations-overlay-chart-area-height);
  left: var(--annotations-overlay-chart-area-left);
}

.AnnotationsBadge {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: calc(var(--annotations-overlay-tick-interval) * var(--annotations-badge-index) - var(--annotations-overlay-chart-area-left) + var(--annotations-overlay-first-tick-left));
  height: 3rem;
  width: var(--annotations-overlay-tick-interval);
  border: none;
  background: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
  appearance: none !important;
}
.AnnotationsBadge > .LemonBadge {
  transition: transform 200ms ease;
  transform: scale(var(--annotations-badge-scale));
  transform-origin: center;
}

.AnnotationsPopover {
  --annotations-popover-width: 30rem;
  transition: left 200ms ease, opacity 100ms ease, transform 100ms ease;
  z-index: var(--z-annotation-popover) !important;
}
.AnnotationsPopover .LemonModal {
  margin: 0;
  min-width: var(--annotations-popover-width);
  max-width: var(--annotations-popover-width);
  box-shadow: none;
  border: none;
}
.AnnotationsPopover .Popover__box {
  padding: 0;
}
.AnnotationsPopover ul {
  max-height: 28.5rem;
}

.AnnotationCard .profile-package {
  vertical-align: bottom;
}
.AnnotationCard h5 {
  margin: 0;
}