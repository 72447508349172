.profile-package {
  display: inline-flex;
  align-items: center;
}
.profile-package:not(:first-child) {
  margin-left: 0.375rem;
}

.profile-name {
  margin-left: 0.375rem;
  font-weight: 500;
}

.ProfilePicture {
  flex-shrink: 0;
  border-radius: 50%;
  background-color: var(--lettermark-6-text);
  color: #5f1903;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  user-select: none;
}
.ProfilePicture.xxl {
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 20px;
}
.ProfilePicture.xl {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
}
.ProfilePicture.lg {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 18px;
}
.ProfilePicture.md {
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
}
.ProfilePicture.sm {
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
}
.ProfilePicture.xs {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 11px;
}
.ProfilePicture .Lettermark {
  width: inherit;
  height: inherit;
  line-height: inherit;
  font-size: inherit;
}

.ProfileBubbles {
  display: flex;
  align-items: center;
}
.ProfileBubbles > * {
  outline: 0.125rem solid var(--bg-light);
}
.ProfileBubbles > :not(:first-child) {
  margin-left: -0.125rem;
}

.ProfileBubbles__more {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background: var(--primary);
  color: #fff;
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: -0.05em;
  user-select: none;
}