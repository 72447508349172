.maplibregl-ctrl-attrib-button:focus,
.maplibregl-ctrl-group button:focus {
  box-shadow: 0 0 2px 2px var(--primary);
}

@media screen {
  .maplibregl-ctrl-attrib.maplibregl-compact {
    background-color: var(--bg-light);
    color: var(--muted-3000);
  }
  .maplibregl-ctrl-attrib .maplibregl-ctrl-attrib-button {
    background-color: var(--bg-3000);
  }
  [theme=dark] .maplibregl-ctrl-attrib .maplibregl-ctrl-attrib-button {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' width='24' height='24' fill-rule='evenodd' viewBox='0 0 20 20'%3E%3Cpath d='M4 10a6 6 0 1 0 12 0 6 6 0 1 0-12 0m5-3a1 1 0 1 0 2 0 1 1 0 1 0-2 0m0 3a1 1 0 1 1 2 0v3a1 1 0 1 1-2 0'/%3E%3C/svg%3E");
  }
  .maplibregl-ctrl-attrib.maplibregl-compact-show .maplibregl-ctrl-attrib-button {
    background-color: var(--bg-3000);
  }
  [theme=dark] .maplibregl-ctrl-attrib.maplibregl-compact-show .maplibregl-ctrl-attrib-button {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' width='24' height='24' fill-rule='evenodd' viewBox='0 0 20 20'%3E%3Cpath d='M4 10a6 6 0 1 0 12 0 6 6 0 1 0-12 0m5-3a1 1 0 1 0 2 0 1 1 0 1 0-2 0m0 3a1 1 0 1 1 2 0v3a1 1 0 1 1-2 0'/%3E%3C/svg%3E");
  }
  .maplibregl-ctrl-attrib a {
    color: var(--text-3000);
  }
}