.LemonCalendar {
  --lemon-calendar-row-gap: 2px;
  --lemon-calendar-day-width: 40px;
  --lemon-calendar-today-radius: 2px;
}
.LemonCalendar .LemonCalendar__month > thead > tr:first-child > th,
.LemonCalendar .LemonCalendar__month > tbody > tr > td {
  padding: calc(var(--lemon-calendar-row-gap) / 2) 0;
}
.LemonCalendar .LemonCalendar__month > thead > tr:first-child > th:first-child,
.LemonCalendar .LemonCalendar__month > thead > tr:first-child > th:last-child,
.LemonCalendar .LemonCalendar__month > tbody > tr > td {
  width: var(--lemon-calendar-day-width);
}
.LemonCalendar .LemonCalendar__month tr .LemonButton.rounded-none {
  border-radius: 0;
}
.LemonCalendar .LemonCalendar__month tr .LemonButton.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.LemonCalendar .LemonCalendar__month tr .LemonButton.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.LemonCalendar .LemonCalendar__month tr .LemonCalendar__today {
  position: relative;
}
.LemonCalendar .LemonCalendar__month tr .LemonCalendar__today::after {
  position: absolute;
  bottom: 5px;
  left: calc(50% - var(--lemon-calendar-today-radius));
  content: "";
  border-radius: 100%;
  width: calc(var(--lemon-calendar-today-radius) * 2);
  height: calc(var(--lemon-calendar-today-radius) * 2);
  background: currentColor;
}