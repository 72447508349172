.empty-message {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.empty-message .title {
  text-align: center;
}
.empty-message .description {
  text-align: center;
}