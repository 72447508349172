.ActionsPie {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.ActionsPie .ActionsPie__component {
  min-width: 33%;
  flex: 1;
  padding: 1rem;
}
.ActionsPie .ActionsPie__component .ActionsPie__chart {
  position: relative;
  width: 100%;
  height: calc(100% - 4.5rem);
  transition: height 0.5s;
}
.ActionsPie .ActionsPie__component h3 {
  letter-spacing: -0.025em;
}