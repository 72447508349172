.TextCard .handle,
.InsightCard .handle {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: var(--z-raised);
}
.TextCard .handle.horizontal svg,
.InsightCard .handle.horizontal svg {
  transform: rotate(90deg) translateX(0.75rem);
}
.TextCard .handle.vertical,
.InsightCard .handle.vertical {
  flex-direction: column;
}
.TextCard .handle.vertical svg,
.InsightCard .handle.vertical svg {
  transform: translateX(0.5rem);
}
.TextCard .handle.corner,
.InsightCard .handle.corner {
  justify-content: flex-end;
}
.TextCard .handle.corner svg,
.InsightCard .handle.corner svg {
  transform: translate(0.5rem, 0.5rem);
}

.CardMeta {
  display: flex;
  position: relative;
  flex-shrink: 0;
  flex-direction: column;
  width: 100%;
  max-height: calc(100% - 2rem);
  background: var(--bg-light);
  z-index: 101;
  border-radius: var(--radius);
}
.CardMeta--with-details .CardMeta__top {
  height: 1.5rem;
}
.CardMeta h5 {
  color: var(--muted);
  line-height: 1.5rem;
  margin-bottom: 0;
}
.CardMeta h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 1.5em;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.125rem;
}
.CardMeta .CardMeta__primary {
  display: flex;
  width: 100%;
  padding: 1rem;
}
.CardMeta .CardMeta__divider {
  margin: 0 1rem;
  height: 1px;
  background: var(--border);
  opacity: 0;
  transition: opacity 200ms ease;
}
.CardMeta .CardMeta__details {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  max-height: 18rem;
  margin-top: -1px;
  border-bottom-width: 1px;
  background: var(--bg-light);
  transition: box-shadow 200ms ease, height 200ms ease, margin 200ms ease;
  overflow-y: auto;
}
.CardMeta .CardMeta__details .CardMeta__details__content {
  pointer-events: none;
  overflow-y: auto;
}
.CardMeta.CardMeta--details-shown .CardMeta__details {
  box-shadow: var(--shadow-elevation);
  margin-top: 0;
}
.CardMeta.CardMeta--details-shown .CardMeta__details .CardMeta__details__content {
  opacity: 1;
  pointer-events: all;
}
.CardMeta.CardMeta--details-shown .CardMeta__divider {
  opacity: 1;
}

.CardMeta__ribbon {
  align-self: stretch;
  width: 0.375rem;
  margin: 0 0.75rem 0 -0.25rem;
  border-radius: var(--radius);
  background: var(--muted);
}
.CardMeta__ribbon.blue {
  background: var(--blue);
}
.CardMeta__ribbon.purple {
  background: var(--purple);
}
.CardMeta__ribbon.green {
  background: var(--green);
}
.CardMeta__ribbon.black {
  background: var(--black);
}

.CardMeta__main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  min-height: 2rem;
}
.CardMeta__main > * {
  max-width: 100%;
}

.CardMeta__top {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2rem;
}
.CardMeta__top .LemonButton {
  height: 1.75rem;
}

.CardMeta__controls {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}