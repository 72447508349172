.ant-select-dropdown .scrollable-below::after, .ant-select-dropdown .scrollable-above::after {
  content: "";
  width: 100%;
  height: 3rem;
  background: linear-gradient(to top, var(--bg-light), transparent);
  position: absolute;
  pointer-events: none;
  bottom: 0;
}

.ant-select-dropdown .scrollable-above::after {
  background: linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0));
  bottom: unset;
  top: 0;
}
.ant-select-selection-overflow-item .ant-tag {
  margin: 1px 4px 1px 0;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  overflow: hidden;
  padding: 0 4px 0 8px;
  font-size: inherit;
  line-height: inherit;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  user-select: none;
}
.ant-select-selection-overflow-item .ant-tag .label {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-select-selection-overflow-item .ant-tag .btn-close {
  font-size: 10px;
  margin-left: 4px;
}
.ant-select-selection-overflow-item .ant-tag .btn-close .anticon-close {
  color: rgba(0, 0, 0, 0.45);
}
.ant-select-selection-overflow-item .ant-tag .btn-close .anticon-close :hover {
  color: rgba(0, 0, 0, 0.75);
}