.cohorts-drawer .ant-drawer-content-wrapper {
  max-width: 850px !important;
  width: 100% !important;
}

.cohort-recalculating {
  background-color: var(--mid);
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border-radius: var(--radius);
}
.cohort-recalculating .ant-spin-spinning {
  margin-right: 0.375rem;
}

.cohort-csv-dragger {
  height: 155px !important;
  background-color: transparent !important;
  margin-top: 1rem;
  border: 2px dashed var(--primary) !important;
  border-radius: 4px !important;
}
.cohort-csv-dragger:hover {
  border-color: var(--primary-light) !important;
}
.cohort-csv-dragger .ant-upload-drag-container {
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}
.cohort-csv-dragger .ant-upload-drag-container .ant-upload-text {
  font-weight: 600;
  font-size: 1rem !important;
  margin: 8px 0 0 !important;
}