.merge-split-person .activity-header {
  background-color: var(--mid);
  border-top: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
  margin-left: -24px;
  margin-right: -24px;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.merge-split-person .ant-modal-header {
  border-bottom: 0;
}
.merge-split-person .ant-modal-body {
  padding-top: 0;
}
.merge-split-person .ant-radio-wrapper {
  margin-right: 2px;
}
.merge-split-person .ant-col {
  margin-right: 1rem;
}
.merge-split-person code {
  background-color: var(--mid);
  color: var(--primary-alt);
  padding: 4px 8px;
  border-radius: var(--radius);
}