.ActionFilterRow .ActionFilterRow-content {
  display: flex;
  align-items: flex-start;
}
.ActionFilterRow .ActionFilterRow-content > * + * {
  margin-left: 0.5rem;
}
.ActionFilterRow .ActionFilterRow__start,
.ActionFilterRow .ActionFilterRow__center,
.ActionFilterRow .ActionFilterRow__end {
  display: flex;
  align-items: center;
}
.ActionFilterRow .ActionFilterRow__start,
.ActionFilterRow .ActionFilterRow__end {
  height: 40px;
  display: flex;
  align-items: center;
  flex: 0;
  gap: 0.25rem;
}
.ActionFilterRow .ActionFilterRow__center {
  flex: 1;
  overflow: hidden;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.ActionFilterRowDragHandle {
  cursor: move;
  color: var(--default);
  font-size: 1.2em;
  transform: rotate(90deg);
}