.funnel-correlation-table {
  margin-top: 1rem;
  border: 1px solid var(--border);
  border-radius: var(--radius);
  overflow: hidden;
}
.funnel-correlation-table .ant-table thead th {
  border-bottom: 1px solid var(--border);
}
.funnel-correlation-table .funnel-correlation-header {
  background: var(--mid);
  display: flex;
  align-items: center;
  align-self: stretch;
  padding: 0.25rem 0.5rem;
  place-content: space-between space-between;
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
}
.funnel-correlation-table .funnel-correlation-header .table-header {
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--default);
}
.funnel-correlation-table .funnel-correlation-header .table-options {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
}
.funnel-correlation-table .funnel-correlation-header .table-options .title {
  font-family: var(--font-sans);
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height, or 145% */
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin: 5px;
  color: var(--muted);
}
.funnel-correlation-table .funnel-correlation-header .table-options .LemonCheckbox:not(:last-child) label {
  border-radius: var(--radius) 0 0 var(--radius);
  border-right: none;
}
.funnel-correlation-table .funnel-correlation-header .table-options .LemonCheckbox:last-child label {
  border-radius: 0 var(--radius) var(--radius) 0;
}
.funnel-correlation-table .column-info {
  color: var(--muted-alt);
  cursor: pointer;
  padding-left: 4px;
}
.funnel-correlation-table .nested-properties-table {
  margin: 1rem;
  border: 1px solid var(--border);
  border-radius: var(--radius);
}
.funnel-correlation-table .nested-properties-table thead th {
  border-bottom: 1px solid var(--border);
}