.NotFoundComponent {
  text-align: center;
  margin: 6rem auto;
  max-width: 600px;
}
.NotFoundComponent .NotFoundComponent__graphic {
  background: url("../../../../public/not-found-astrohog.png") no-repeat center center;
  height: 270px;
  width: 100%;
}
.NotebookNode .NotFoundComponent {
  background-color: var(--danger-highlight);
  margin: 0;
  max-width: 100%;
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}