.dashboard-wrapper {
  width: 100%;
  height: calc(100% - 2rem);
}

.histogram-container {
  display: flex;
}
.histogram-container svg {
  margin-left: 1rem;
  margin-right: 1rem;
  /*
   * Axis
   */
  /*
   * Grid
   */
  /*
   * Bars
   */
}
.histogram-container svg g#y-axis .domain {
  stroke: var(--funnel-axis);
}
.histogram-container svg g#x-axis .domain {
  stroke: var(--funnel-axis);
}
.histogram-container svg g#x-axis .tick {
  color: var(--funnel-axis);
}
.histogram-container svg g#x-axis text,
.histogram-container svg g#y-axis text {
  font-family: var(--font-sans);
  font-size: 0.75rem;
  font-weight: 500;
  fill: var(--default);
}
.histogram-container svg g#y-gridlines {
  stroke-dasharray: 4, 2;
}
.histogram-container svg g#y-gridlines g.tick:not(:first-child) {
  color: var(--funnel-grid);
}
.histogram-container svg g#y-gridlines .domain,
.histogram-container svg g#y-gridlines g.tick:nth-of-type(1) {
  display: none;
}
.histogram-container svg g#bars {
  fill: var(--funnel-default);
}
.histogram-container svg g#labels text.bar-label {
  font-size: 12px;
  font-weight: normal;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  fill: var(--white);
}
.histogram-container svg g#labels text.bar-label.outside {
  fill: #0f0f0f;
}