.project-homepage .homepage-dashboard-header {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.project-homepage .homepage-dashboard-header .dashboard-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.project-homepage .homepage-dashboard-header .dashboard-title-container .ant-skeleton {
  width: 300px;
}
.project-homepage .homepage-dashboard-header .dashboard-title-container .ant-skeleton h3 {
  margin: 0;
}
.project-homepage .top-list-container-horizontal {
  display: flex;
}
.project-homepage .top-list-container-horizontal .top-list {
  margin-bottom: 1.5rem;
  width: 33%;
}
.posthog-3000 .project-homepage .top-list-container-horizontal .top-list {
  margin-bottom: 0;
}
.project-homepage .top-list-container-horizontal .spacer {
  width: 1rem;
}
.project-homepage .top-list-container-vertical {
  margin-bottom: 1.5rem;
}
.project-homepage .top-list-container-vertical .spacer {
  height: 1rem;
}
.project-homepage .empty-state-container {
  background-color: var(--side);
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  display: flex;
  flex-direction: column;
}