.legacy-ph-graph-tooltip {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  overflow-x: hidden;
  z-index: var(--z-graph-tooltip);
  transition: all 0.4s;
  max-width: 480px;
  padding: 8px 12px;
  border: 1px solid var(--border);
  border-radius: var(--radius);
  background: #fafafa;
}

.inner-tooltip.multiple header {
  padding-bottom: 8px;
  border-bottom: 1px solid var(--border);
}
.inner-tooltip.multiple footer {
  margin-top: 0 !important;
  padding-top: 6px;
  border-top: 1px solid var(--border);
}
.inner-tooltip header {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.inner-tooltip footer {
  margin-top: 0.5rem;
  font-weight: bold;
  font-size: 85%;
  color: var(--muted);
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-tooltip footer svg {
  font-size: 1.4em;
  margin-right: 4px;
}
.inner-tooltip .series-container {
  position: relative;
}
.inner-tooltip .inspect-persons-label {
  display: flex;
  align-items: center;
  margin-top: 2px;
  font-style: italic;
}
.inner-tooltip .inspect-persons-label svg {
  margin-left: 2px;
  margin-right: 6px;
}
.inner-tooltip ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
  max-height: 32vh;
  overflow-y: hidden;
}
.inner-tooltip ul li {
  display: flex;
  align-items: center;
  padding-bottom: 2px;
}