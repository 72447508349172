.TaxonomicPropertyFilter {
  width: 100%;
  overflow: hidden;
}
.TaxonomicPropertyFilter--in-dropdown {
  width: 900px;
  max-width: 100%;
  min-width: 300px;
  background: var(--bg-light);
}
.TaxonomicPropertyFilter--in-dropdown .TaxonomicPropertyFilter__row__items > :first-child {
  width: 10rem;
}
.TaxonomicPropertyFilter .TaxonomicPropertyFilter__row {
  display: flex;
  gap: 0.5rem;
  overflow: hidden;
}
.TaxonomicPropertyFilter .TaxonomicPropertyFilter__row .TaxonomicPropertyFilter__row__operator {
  flex-shrink: 0;
  width: 4.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
}
.TaxonomicPropertyFilter .TaxonomicPropertyFilter__row .TaxonomicPropertyFilter__row__operator .arrow {
  color: #c4c4c4;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  user-select: none;
}
.TaxonomicPropertyFilter .TaxonomicPropertyFilter__row .TaxonomicPropertyFilter__row__items {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0.5rem;
  overflow: hidden;
}
.TaxonomicPropertyFilter .TaxonomicPropertyFilter__row .TaxonomicPropertyFilter__row__items > * {
  overflow: hidden;
  max-width: 100%;
}
.TaxonomicPropertyFilter .TaxonomicPropertyFilter__row.TaxonomicPropertyFilter__row--showing-operators.width-tiny .TaxonomicPropertyFilter__row__items {
  flex-direction: column;
  align-items: stretch;
}
.TaxonomicPropertyFilter .TaxonomicPropertyFilter__row.TaxonomicPropertyFilter__row--showing-operators.width-small .TaxonomicPropertyFilter__row__items > :first-child {
  flex: 1;
  min-width: 10rem;
}
.TaxonomicPropertyFilter .TaxonomicPropertyFilter__row.TaxonomicPropertyFilter__row--showing-operators.width-small .TaxonomicPropertyFilter__row__items > :last-child {
  width: 100%;
}
.TaxonomicPropertyFilter .TaxonomicPropertyFilter__row.TaxonomicPropertyFilter__row--showing-operators.width-medium .TaxonomicPropertyFilter__row__items > :first-child {
  width: 30%;
  min-width: 10rem;
}
.TaxonomicPropertyFilter .TaxonomicPropertyFilter__row.TaxonomicPropertyFilter__row--or-filtering .TaxonomicPropertyFilter__row__operator {
  width: 2rem;
}