.dated-highlight {
  color: var(--muted);
  margin-right: 4px;
  padding: 0.125rem 0.25rem;
  border: 1px solid var(--border-light);
  background-color: var(--bg-light);
  border-radius: var(--radius);
  white-space: nowrap;
}

.date-display-dates {
  white-space: nowrap;
}
.date-display-dates .secondary-date {
  margin-left: 2px;
  color: var(--muted-alt);
  font-weight: normal;
}