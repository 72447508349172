.InsightLegendMenu {
  box-shadow: none !important;
  background-color: var(--bg-light);
  max-height: 100%;
}
.InsightLegendMenu.InsightLegendMenu--in-card-view {
  max-width: 40%;
}
.InsightLegendMenu.InsightLegendMenu--horizontal {
  max-width: 100%;
}
.InsightLegendMenu.InsightLegendMenu--horizontal .InsightLegendMenu-scroll {
  display: flex;
  flex-wrap: wrap;
}
.InsightLegendMenu.InsightLegendMenu--horizontal .InsightLegendMenu-scroll .InsightLegendMenu-item {
  width: auto;
}