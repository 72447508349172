.property-group-filters .property-group {
  background-color: var(--side);
  padding: 0.5rem;
  border-radius: 4px;
}
.property-group-filters .property-group-and-or-separator {
  color: var(--primary-alt);
  padding: 0.5rem 0;
  font-size: 12px;
  font-weight: 600;
  position: relative;
}
.property-group-filters .property-group-and-or-separator::before {
  position: absolute;
  left: 17px;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: var(--border-light);
  content: " ";
}
.property-group-filters .property-group-and-or-separator > span {
  position: relative;
  display: inline-block;
  z-index: 1;
  background-color: var(--bg-light);
  padding: 0.25rem;
  width: 35px;
  text-align: center;
}

.property-group-title {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 12px;
  color: black;
}

.and-or-filter {
  font-weight: 500;
}

.and-or-filter-select {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.and-or-filter-select .condition-text {
  font-weight: 700;
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: var(--mid);
  color: var(--primary-alt);
  font-size: 12px;
}
.and-or-filter-select .condition-text.selected {
  background-color: var(--primary);
  color: #fff;
}
.and-or-filter-select .condition-option {
  background-color: var(--bg-light);
  padding: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 4px;
}
.and-or-filter-select .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: var(--primary-highlight);
}