.taxonomic-infinite-list {
  min-height: 200px;
  flex-grow: 1;
}
.taxonomic-infinite-list.empty-infinite-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.taxonomic-infinite-list.empty-infinite-list .no-infinite-results {
  color: #666;
}
.taxonomic-infinite-list .taxonomic-list-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--default);
  padding: 4px 12px;
  cursor: pointer;
  border: none;
}
.taxonomic-infinite-list .taxonomic-list-row .taxonomic-list-row-contents {
  display: flex;
  align-items: center;
  user-select: none;
}
.taxonomic-infinite-list .taxonomic-list-row .taxonomic-list-row-contents .taxonomic-list-row-contents-icon {
  min-width: 30px;
  display: flex;
  font-size: 1.25rem;
  justify-content: center;
  margin-right: 4px;
  margin-left: -4px;
}
.taxonomic-infinite-list .taxonomic-list-row .taxonomic-list-row-contents .taxonomic-list-row-contents-icon svg.taxonomy-icon {
  vertical-align: middle;
  flex-shrink: 0;
}
.taxonomic-infinite-list .taxonomic-list-row .taxonomic-list-row-contents .taxonomic-list-row-contents-icon svg.taxonomy-icon.taxonomy-icon-muted {
  color: var(--muted-alt);
}
.taxonomic-infinite-list .taxonomic-list-row .taxonomic-list-row-contents .taxonomic-list-row-contents-icon svg.taxonomy-icon.taxonomy-icon-verified {
  color: var(--success);
}
.taxonomic-infinite-list .taxonomic-list-row .taxonomic-list-row-contents .taxonomic-list-row-contents-icon svg.taxonomy-icon.taxonomy-icon-built-in {
  color: var(--primary);
}
.taxonomic-infinite-list .taxonomic-list-row > div {
  max-width: 100%;
}
.taxonomic-infinite-list .taxonomic-list-row > div > span {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.taxonomic-infinite-list .taxonomic-list-row.hover {
  background-color: var(--primary-bg-hover);
  border-radius: var(--radius);
}
.taxonomic-infinite-list .taxonomic-list-row.selected {
  font-weight: bold;
}
.taxonomic-infinite-list .taxonomic-list-row.skeleton-row .ant-skeleton-paragraph {
  margin-bottom: 0;
}
.taxonomic-infinite-list .taxonomic-list-row.expand-row {
  color: var(--primary);
}