.TextCard {
  background: var(--bg-light);
}

.TextCard__body {
  flex: 1;
  overflow-y: auto;
}
.TextCard__body ul {
  list-style-type: disc;
  list-style-position: inside;
}
.TextCard__body ol {
  list-style-type: numeric;
  list-style-position: inside;
}
.TextCard__body img {
  max-width: 100%;
}