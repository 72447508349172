.IngestionContainer {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  flex-direction: column;
  width: 100%;
}

.IngestionContent .BridgePage__content {
  max-width: 700px;
}

.IngestionTopbar {
  border-bottom: 1px solid var(--border);
  padding: 0.25rem 1rem;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
  z-index: 10;
}
.IngestionTopbar .help-button {
  margin-right: 1rem;
}

.platform-item {
  margin-right: 10px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid gray;
  border-radius: 2px;
  cursor: pointer;
}

.platform-item:hover {
  background-color: gainsboro;
}

.selectable-item:hover {
  background-color: gainsboro;
  cursor: pointer;
}

.IngestionSidebar__bottom {
  margin-top: auto;
}
.IngestionSidebar__bottom .popover {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.IngestionSidebar__help {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  color: var(--primary);
  margin-top: 1rem;
}

.IngestionSidebar__steps {
  color: var(--muted-alt);
  font-size: 14px;
}
.IngestionSidebar__steps .LemonButton {
  font-weight: 600;
  margin-bottom: 0.5rem;
}