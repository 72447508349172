.palette__overlay {
  z-index: var(--z-command-palette);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.palette__box {
  box-shadow: var(--shadow-elevation);
  position: absolute;
  top: 30%;
  display: flex;
  flex-direction: column;
  width: 36rem;
  max-width: 100%;
  max-height: 60%;
  color: #fff;
  border-radius: var(--radius);
  background-color: var(--bg-3000-dark);
  overflow: hidden;
}
@media (max-width: 500px) {
  .palette__box {
    top: 10%;
    max-height: 80%;
    width: 100%;
  }
}
@media (max-height: 500px) {
  .palette__box {
    top: 0%;
    max-height: 100%;
    max-width: 90%;
  }
}

.palette__row {
  display: flex;
  align-items: center;
  height: 4rem;
  width: 100%;
  padding: 0 1.875rem;
  font-size: 1rem;
  line-height: 4rem;
}

.palette__row--small {
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: bold;
}

.palette__display {
  padding: 0 0 0 1.5rem;
  font-size: 1rem;
}

.palette__input {
  flex-grow: 1;
  border: none;
  outline: none;
  background: transparent;
  color: #fff;
  overflow-y: auto;
}

.palette__results {
  overflow-y: auto;
}

.palette__result {
  height: 4rem;
  width: 100%;
  padding: 0 1.875rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: relative;
}

.palette__result--focused {
  background: var(--default-dark);
}
.palette__result--focused::before, .palette__result--focused::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0.375rem;
}
.palette__result--focused::before {
  background: hsl(210deg, 10%, 19%) !important;
}
.palette__result--focused::after {
  background: rgba(255, 255, 255, 0.1);
}

.palette__result--executable {
  cursor: pointer;
}
.palette__result--executable::after {
  background: var(--primary);
}

.palette__scope {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
}

.palette__icon {
  display: flex;
  align-items: center;
  font-size: 1rem;
}