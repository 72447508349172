.toolbar-launch-page {
  margin-bottom: 48px;
}
.toolbar-launch-page .feature-highlight-list {
  max-width: 800px;
}
.toolbar-launch-page .feature-highlight-list .fh-item {
  width: calc(50% - 8px);
}
@media (max-width: 599px) {
  .toolbar-launch-page .feature-highlight-list .fh-item {
    width: calc(100% - 8px);
  }
}
.toolbar-launch-page .feature-highlight-list .fh-item .fh-icon {
  font-size: 1.6em;
}
.toolbar-launch-page .feature-highlight-list .fh-item h4 {
  font-size: 1.2em;
}