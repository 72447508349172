.BillingHero {
  background-color: var(--mark);
  border-radius: 0.5rem;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.BillingHero__hog {
  overflow: hidden;
  position: relative;
  width: 200px;
  padding-top: 1rem;
}

.BillingHero__hog__img {
  height: 200px;
  width: 200px;
  margin: -20px 0;
}