.event-properties-definition-table .LemonTable__content > table > tbody td.definition-column-icon {
  width: 36px;
}
.event-properties-definition-table .LemonTable__content > table > tbody td.definition-column-icon .definition-column-name-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
}
.event-properties-definition-table .LemonTable__content > table > tbody td.definition-column-icon .definition-column-name-icon svg.taxonomy-icon {
  flex-shrink: 0;
}
.event-properties-definition-table .LemonTable__content > table > tbody td.definition-column-icon .definition-column-name-icon svg.taxonomy-icon.taxonomy-icon-muted {
  color: var(--muted-alt);
}
.event-properties-definition-table .LemonTable__content > table > tbody td.definition-column-icon .definition-column-name-icon svg.taxonomy-icon.taxonomy-icon-verified {
  color: var(--success);
}
.event-properties-definition-table .LemonTable__content > table > tbody td.definition-column-name .definition-column-name-content {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 32px;
  justify-content: center;
}
.event-properties-definition-table .LemonTable__content > table > tbody td.definition-column-name .definition-column-name-content .definition-column-name-content-title {
  font-weight: 600;
  cursor: pointer;
}
.event-properties-definition-table .LemonTable__content > table > tbody td.definition-column-name .definition-column-name-content .definition-column-name-content-title::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -10px;
  right: -50px;
  height: 22px;
}