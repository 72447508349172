.saved-insights > .LemonTable td {
  padding-bottom: 0.75rem !important;
  padding-top: 0.75rem !important;
}
.saved-insights > .LemonTable td.icon-column .LemonSkeleton {
  height: 2rem;
  width: 2rem;
}
.saved-insights .new-insight-dropdown-btn {
  cursor: pointer;
  height: 40px;
  background-color: var(--primary);
  padding: 8px 12px 8px 16px;
  border: 1px solid var(--border);
  border-radius: 4px;
  color: white;
}
.saved-insights .saved-insight-empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.saved-insights .saved-insight-empty-state .insight-empty-state__wrapper {
  max-width: 600px;
  margin-top: 5rem;
  margin-bottom: 17rem;
}
.saved-insights .saved-insight-empty-state .insight-empty-state__wrapper .illustration-main {
  font-size: 5rem;
  line-height: 1em;
  text-align: center;
  margin-bottom: 1rem;
  color: var(--border);
}
.saved-insights .saved-insight-empty-state .insight-empty-state__wrapper .empty-state__title {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.6rem;
}
.saved-insights .saved-insight-empty-state .insight-empty-state__wrapper .empty-state__description {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.7rem;
  color: var(--muted);
}
.saved-insights .saved-insight-empty-state .insight-empty-state__wrapper button.add-insight-button {
  height: 50px;
  padding: 0 3rem;
}

.new-insight-overlay {
  width: 21rem;
}

.insight-type-icon-wrapper {
  display: flex;
  align-items: center;
}
.insight-type-icon-wrapper .icon-container {
  height: 22px;
  width: 22px;
  margin-right: 8px;
  position: relative;
}
.insight-type-icon-wrapper .icon-container .icon-container-inner {
  font-size: 22px;
  margin-left: -2px;
}

.saved-insights-grid {
  position: relative;
  display: grid;
  grid-gap: 1rem;
}
@media screen and (min-width: 1200px) {
  .saved-insights-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1600px) {
  .saved-insights-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}