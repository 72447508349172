.ColumnConfiguratorModal .Columns {
  width: 700px;
  display: flex;
  column-gap: 1rem;
  background-color: var(--side);
  border-radius: 0.25rem;
  padding: 0.5rem;
}
@media (max-width: 960px) {
  .ColumnConfiguratorModal .Columns {
    display: block;
    width: auto;
  }
}
.ColumnConfiguratorModal .HalfColumn {
  width: 50%;
}
@media (max-width: 960px) {
  .ColumnConfiguratorModal .HalfColumn {
    width: 100%;
  }
}
.ColumnConfiguratorModal .SelectedColumn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.5rem;
  overflow: hidden;
  border-radius: var(--radius);
  margin: calc(var(--radius) / 2) 0;
  background-color: var(--primary-bg-hover);
}
.ColumnConfiguratorModal .selected-column-col {
  display: flex;
  flex-direction: column;
}
.ColumnConfiguratorModal .drag-handle {
  cursor: move;
  color: var(--default);
  font-size: 1.2em;
  padding-right: 0.25rem;
}
.ColumnConfiguratorModal .drag-handle svg {
  transform: rotate(90deg);
}