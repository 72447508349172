.help-button {
  align-items: center;
  height: 2.5rem;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  color: var(--primary-alt);
}
.help-button.inline {
  display: inline-flex;
}