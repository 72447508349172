.Resizer {
  --resizer-width: 8px;
  position: absolute;
  top: 0;
  bottom: 0;
  width: var(--resizer-width);
  cursor: col-resize;
  user-select: none;
  z-index: var(--z-notifications-popover);
}
.Resizer[aria-hidden=true] .Resizer {
  cursor: e-resize;
}
.Resizer .Resizer__handle {
  position: absolute;
  left: calc(var(--resizer-width) / 2);
  top: 0;
  bottom: 0;
  width: 1px;
}
.Resizer .Resizer__handle::before, .Resizer .Resizer__handle::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  box-sizing: content-box;
}
.Resizer .Resizer__handle::before {
  transition: 100ms ease transform;
  background: var(--border);
}
.Resizer .Resizer__handle::after {
  transition: 100ms ease transform;
  background: var(--text-3000);
  opacity: 0;
}
.Resizer--left {
  left: 0;
  transform: translateX(calc(var(--resizer-width) / 2 * -1));
}
.Resizer--right {
  right: 0;
  transform: translateX(calc(var(--resizer-width) / 2 * 1));
}
.Resizer:hover .Resizer__handle::after, .Resizer--resizing .Resizer__handle::after {
  opacity: 0.25;
}
.Resizer--resizing .Resizer__handle::before, .Resizer--resizing .Resizer__handle::after {
  transform: scaleX(3);
}