.KeyboardShortcut {
  margin: 0 0.125rem;
}

.KeyboardShortcut__key {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1875rem;
  border-radius: 0.125rem;
  border-width: 1px;
  background: var(--accent-3000);
  color: var(--default);
  text-transform: capitalize;
}
.KeyboardShortcut--muted > .KeyboardShortcut__key {
  background: none;
  color: var(--muted);
}
.ant-tooltip .KeyboardShortcut__key {
  color: #fff;
  background: rgba(0, 0, 0, 0.333);
  border-color: rgba(255, 255, 255, 0.333);
}
.KeyboardShortcut__key + .KeyboardShortcut__key {
  margin-left: 0.25rem;
}