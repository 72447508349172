.Popover {
  perspective: 80rem;
  perspective-origin: top;
  z-index: var(--z-popover);
  display: flex;
  flex-direction: row;
}
.Popover h5 {
  margin: 0.25rem 0.5rem;
}
.Popover p:last-child {
  margin-bottom: 0;
}
.Popover[data-floating-placement^=top] {
  perspective-origin: bottom;
}
.Popover.Popover--top-centered {
  top: 1rem;
  left: 50%;
  transform: translate(-50%);
}

.Popover__box {
  position: relative;
  transition: opacity 50ms ease, transform 50ms ease;
  transform-origin: top;
  box-shadow: var(--shadow-elevation);
  background: var(--bg-light);
  flex-grow: 1;
  max-width: 100%;
  padding: 0.5rem;
  border-radius: var(--radius);
  border: 1px solid var(--border);
  opacity: 0;
}
.Popover--actionable .Popover__box {
  border-color: var(--primary);
}
.Popover[data-placement^=bottom] .Popover__box {
  transform-origin: top;
  margin-top: 0.25rem;
  transform: rotateX(-6deg);
}
.Popover[data-placement^=bottom].Popover--with-arrow .Popover__box {
  margin-top: 0.5rem;
}
.Popover[data-placement^=top] .Popover__box {
  transform-origin: bottom;
  margin-bottom: 0.25rem;
  transform: rotateX(6deg);
}
.Popover[data-placement^=top].Popover--with-arrow .Popover__box {
  margin-bottom: 0.5rem;
}
.Popover[data-placement^=left] .Popover__box {
  transform-origin: right;
  margin-right: 0.25rem;
  transform: rotateY(-6deg);
}
.Popover[data-placement^=left].Popover--with-arrow .Popover__box {
  margin-right: 0.5rem;
}
.Popover[data-placement^=right] .Popover__box {
  transform-origin: left;
  margin-left: 0.25rem;
  transform: rotateY(6deg);
}
.Popover[data-placement^=right].Popover--with-arrow .Popover__box {
  margin-left: 0.5rem;
}
.Popover.Popover--enter-active .Popover__box, .Popover.Popover--enter-done .Popover__box {
  opacity: 1;
  transform: none;
}
.Popover--max-content-width .Popover__box {
  width: max-content;
}
.posthog-3000 .Popover__box {
  background: var(--bg-3000);
  padding: 0.25rem;
}
.posthog-3000 .Popover--actionable .Popover__box {
  border-color: var(--border);
}

.Popover__arrow {
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  transform: rotate(45deg);
  background: var(--bg-3000);
}
[data-placement^=bottom] .Popover__arrow {
  top: -0.25rem;
  border-top: 1px solid var(--border);
  border-left: 1px solid var(--border);
}
[data-placement^=top] .Popover__arrow {
  bottom: -0.25rem;
  border-bottom: 1px solid var(--border);
  border-right: 1px solid var(--border);
}
[data-placement^=left] .Popover__arrow {
  right: -0.25rem;
  border-top: 1px solid var(--border);
  border-right: 1px solid var(--border);
}
[data-placement^=right] .Popover__arrow {
  left: -0.25rem;
  border-bottom: 1px solid var(--border);
  border-left: 1px solid var(--border);
}
.Popover--actionable .Popover__arrow {
  border-color: var(--primary);
}

.Popover__content {
  overflow-y: auto;
  max-height: 100%;
}