.Animation {
  max-width: 300px;
  aspect-ratio: 1/1;
  overflow: hidden;
  opacity: 1;
  transition: 400ms ease opacity;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.Animation.Animation--hidden {
  opacity: 0;
}
.Animation .Animation__player {
  display: block;
  width: 100%;
  height: 100%;
}
.Animation .Animation__player svg {
  display: block;
}
.Animation.Animation--large {
  width: 100%;
}
.Animation.Animation--small {
  overflow: visible;
}
.Animation.Animation--small svg {
  width: 45px !important;
  height: 45px !important;
}