.RollingDateRangeFilter {
  display: flex;
  min-height: 2rem;
  height: 2rem;
  padding: 1.25rem 0.5rem;
  align-items: center;
  color: var(--default);
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375em;
  cursor: pointer;
  transition: background 0.3s ease;
  /* Firefox */
}
.RollingDateRangeFilter:hover {
  background-color: var(--mid);
}
.RollingDateRangeFilter input::-webkit-outer-spin-button,
.RollingDateRangeFilter input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
.RollingDateRangeFilter input[type=number] {
  appearance: textfield;
}

.RollingDateRangeFilter__label {
  flex-shrink: 0;
  margin: 0 auto;
}

.RollingDateRangeFilter__counter {
  display: flex;
  margin: 0;
  height: 2rem;
  border: 1px solid var(--border);
  border-radius: 0.25rem;
  background-color: var(--bg-light);
  box-sizing: border-box;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  line-height: 1.25rem;
  align-items: center;
}
.RollingDateRangeFilter__counter input {
  width: 3rem;
  text-align: center;
}
.RollingDateRangeFilter__counter .RollingDateRangeFilter__counter__step {
  margin: 0 0.25rem;
  border-radius: var(--radius);
  padding: 0.25rem;
}
.RollingDateRangeFilter__counter .RollingDateRangeFilter__counter__step:hover {
  background-color: var(--primary-highlight);
}

.RollingDateRangeFilter__select {
  width: 6rem;
}

.RollingDateRangeFilter__popover {
  z-index: 9999;
}