.PropertyFilters {
  display: flex;
  gap: 0.5rem;
}
.PropertyFilters .PropertyFilters__content {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  overflow: hidden;
}
.PropertyFilters .PropertyFilters__prefix {
  color: #c4c4c4;
  font-size: 18px;
  user-select: none;
  padding: 0 5px;
}
.PropertyFilters .logical-row-divider {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  align-items: flex-start;
  margin-top: 0.5rem;
  margin-bottom: 0;
}
.PropertyFilters .logical-row-divider::before, .PropertyFilters .logical-row-divider::after {
  border-top: 1px solid var(--border);
}
.PropertyFilters .logical-row-divider::before {
  width: 1.5%;
}