.Link {
  transition: color 200ms ease, opacity 200ms ease;
  background: none;
  color: var(--link);
  border: none;
  outline: none;
  padding: 0;
  line-height: inherit;
  cursor: pointer;
}
.Link:not(:disabled):hover {
  color: var(--primary-light);
}
.Link:not(:disabled):active {
  color: var(--primary-dark);
}
.Link:disabled {
  opacity: var(--opacity-disabled);
  cursor: not-allowed;
}
.Link > .LemonIcon {
  margin-left: 0.15em;
}