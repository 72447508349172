.system-status-scene {
  margin-bottom: 64px;
}
@media (min-width: 750px) {
  .system-status-scene .metric-column {
    width: 33%;
  }
}

.embedded-svg-wrapper {
  cursor: text;
}
.embedded-svg-wrapper svg {
  width: 100%;
  height: 100%;
}