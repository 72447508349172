.SessionRecordingPlayer {
  --inspector-width: 36rem;
  --inspector-peek-width: 2.5rem;
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  width: 100%;
  border: 1px solid var(--border);
  border-radius: var(--radius);
  background-color: var(--bg-light);
}
.SessionRecordingPlayer--no-border {
  border: none;
  border-radius: 0;
}
.SessionRecordingPlayer .SessionRecordingPlayer__body {
  position: relative;
  z-index: 0;
  background-color: var(--bg-light);
  min-width: 1px;
  height: 100%;
  overflow: hidden;
}
.SessionRecordingPlayer .SessionRecordingPlayer__main {
  flex: 1;
  padding-right: var(--inspector-peek-width);
}
.SessionRecordingPlayer--fullscreen {
  position: fixed;
  inset: 0;
  overflow-y: auto;
  background-color: var(--bg-light);
  z-index: var(--z-modal);
  border: none;
  border-radius: 0;
}
.SessionRecordingPlayer--fullscreen .SessionRecordingPlayer__body {
  height: 100%;
  margin: 0;
  border-radius: 0;
}
.SessionRecordingPlayer .SessionRecordingPlayer__main,
.SessionRecordingPlayer .SessionRecordingPlayer__inspector {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.LemonModal .SessionRecordingPlayer .SessionRecordingPlayer__body {
  height: calc(90vh - 15rem);
}
.SessionRecordingPlayer .SessionRecordingPlayer__inspector {
  flex-shrink: 0;
  border-left: 1px solid var(--border);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  width: var(--inspector-width);
  max-width: 95%;
  transform: translateX(calc(100% - var(--inspector-peek-width)));
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.SessionRecordingPlayer .SessionRecordingPlayer__inspector .PlayerInspectorPreview {
  position: absolute;
  inset: 0;
  z-index: 1;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
}
.SessionRecordingPlayer--inspector-focus .SessionRecordingPlayer__inspector {
  transform: translateX(0);
  box-shadow: -10px 0 20px rgba(0, 0, 0, 0.2);
}
.SessionRecordingPlayer--inspector-focus .SessionRecordingPlayer__inspector .PlayerInspectorPreview {
  opacity: 0;
  pointer-events: none;
}
.SessionRecordingPlayer--widescreen .SessionRecordingPlayer__main {
  padding-right: 0;
}
.SessionRecordingPlayer--widescreen .SessionRecordingPlayer__inspector {
  min-width: 30%;
  width: 40%;
  max-width: 400px;
  position: relative;
  transform: translateX(0);
  box-shadow: none;
  transition: none;
}
.SessionRecordingPlayer--widescreen .SessionRecordingPlayer__inspector .PlayerInspectorPreview {
  display: none;
}
.SessionRecordingPlayer--inspector-hidden .SessionRecordingPlayer__main {
  padding-right: 0;
}
.SessionRecordingPlayer--inspector-hidden .SessionRecordingPlayer__inspector {
  display: none;
}

.PlayerControlSeekIcon {
  position: relative;
  display: flex;
}
.PlayerControlSeekIcon .PlayerControlSeekIcon__seconds {
  position: absolute;
  font-size: 6px;
  font-weight: bold;
  text-align: center;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.PlayerControlSeekIcon .PlayerControlSeekIcon__icon {
  font-size: 1.3rem;
}
.PlayerControlSeekIcon .PlayerControlSeekIcon__icon--forward {
  transform: rotateY(180deg);
}